import React, { useEffect, useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate, useLocation } from "react-router-dom";

import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";

import AdminFrame from "components/Layout/Frame";
import { Page } from "@shopify/polaris";
import { translateRole } from "../type/roleType";
import usePrompt from "utils/Prompt";
import toast from "react-hot-toast";
import { createUser, getRoleList, updateUser, userDetail } from "../../../rest-apis/user";

const MAX_NAME_LENGTH = 20;
const MAX_EMAIL_LENGTH = 50;
const MAX_MEMO_LENGTH = 100;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const UserForm = () => {
  // Path
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const userName = localStorage.getItem("name");

  // User data
  const locationState = useLocation().state?.user;
  const [state, setState] = useState();
  const [isSaving, setIsSaving] = useState(false);

  // User input field
  const [nameInput, setNameInput] = useState(locationState?.name || "");
  const [emailInput, setEmailInput] = useState("");
  const [roles, setRoles] = useState([]);
  const [memoInput, setMemoInput] = useState(locationState?.memo || "");

  const isValidNameInput = (name) => {
    const regex = /^[0-9ㄱ-ㅎㅏ-ㅣ가-힣]*$/;
    return regex.test(name);
  };
  const isValidTextInput = (text) => {
    const regex = /^[A-Za-z0-9ㄱ-ㅎㅏ-ㅣ가-힣~!@#₩$%^&*()\-_+=,./<>?\\|\n ]*$/;
    return regex.test(text);
  };

  const handleRoleClick = (role) => {
    setState({ ...state, role: role.name });
  };

  const fetchUser = async (id) => {
    const response = (await userDetail(id)).data;
    setState({
      name: response.name,
      email: response.email.split("@")[0],
      role: translateRole(response.role),
      memo: response.memo,
      lastModifier: userName,
    });
  };

  const fetchRoles = async () => {
    const response = await getRoleList();
    setRoles(response);
  };

  const [isPrompt, setIsPrompt] = useState(true);
  usePrompt(
    "다른 페이지로 이동하겠습니까? 이 페이지에서 변경한 내용은 저장되지 않습니다.",
    isPrompt
  );

  const uploadUser = async () => {
    // 토스트 에러 출력
    if (!state.name) {
      toast.error("이름을 입력하세요.");
      return;
    }
    if (!state.email) {
      toast.error("이메일을 입력하세요.");
      return;
    }

    if (!window.confirm("상세 정보를 저장하시겠습니까?")) {
      return;
    }

    setIsSaving(true);

    const promise = locationState
      ? updateUser(locationState.id, {
          role: state.role,
          memo: state.memo,
          lastModifier: userName,
        })
      : createUser({
          name: state.name,
          email: state.email,
          role: state.role,
          memo: state.memo,
          lastModifier: userName,
        });

    try {
      const response = await promise;
      if (response.status === 200 || response.status === 201) {
        toast.success(locationState ? "수정되었습니다." : "저장되었습니다.");
        setIsPrompt(false);
        setTimeout(() => {
          navigate(locationState ? `/users/${locationState.id}` : `/users/${response.data.id}`);
        }, 500);
      }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "서버 오류가 존재합니다."
      );
    } finally {
      setTimeout(() => {
        setIsSaving(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (path.split("/").includes("edit") && !locationState) {
      navigate("/users");
      return;
    }
    if (!locationState) {
      setState({
        name: "",
        email: "",
        role: "게스트(조회)",
        memo: "",
        lastModifier: userName,
      });
      return;
    }
    setEmailInput(locationState.email.split("@")[0]);
  }, []);

  useEffect(() => {
    fetchRoles();
    fetchUser(locationState?.id);
  }, []);

  return (
    <>
      <AdminFrame title={`사용자 관리 ${locationState ? "수정" : "추가"}`}>
        <div className="flex justify-between">
          <div className="flex ml-6 mt-6 items-center">
            <ArrowSmallLeftIcon
              type="button"
              className="w-9 h-9 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => {
                navigate("/users");
              }}
            />
            <div className="ml-3 text-xl font-bold text-gray-800">
              사용자 관리 {locationState ? `수정 | ${locationState.name}` : "추가"}
            </div>
          </div>
          <div className="mt-6 mr-10">
            <button
              type="button"
              className={
                isSaving
                  ? "w-24 h-9 rounded-md bg-gray-200 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  : "w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
              onClick={() => {
                uploadUser();
              }}
              disabled={isSaving}
            >
              {isSaving ? (
                <div className="flex">
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                  <div>저장중</div>
                </div>
              ) : (
                "저장"
              )}
            </button>
          </div>
        </div>
        <Page fullWidth>
          <div className="flex">
            <div className="w-full mr-4 bg-white shadow sm:rounded-lg mb-5">
              <div className="px-8 py-6 sm:px-6">
                <div className="flex">
                  <div className="text-base font-semibold">상세 정보</div>
                  <div className="flex items-center ml-2 text-sm text-gray-400">
                    (이름, 이메일은 삭제된 정보를 포함하여 중복 검증합니다.)
                  </div>
                </div>
                <table className="w-full mt-5 text-left table-auto">
                  <tbody>
                    <tr key={"user-name"} className="border-b border-gray-100">
                      <td className="font-medium w-1/3 py-4 align-top">이름</td>
                      {locationState ? (
                        <td className="relative py-4 whitespace-pre-wrap">{locationState.name}</td>
                      ) : (
                        <td className="relative py-4 whitespace-pre-wrap">
                          <input
                            type="text"
                            name="user_name"
                            id="user_name"
                            value={nameInput}
                            onChange={(e) => {
                              if (
                                isValidNameInput(e.target.value) &&
                                e.target.value.length <= MAX_NAME_LENGTH
                              ) {
                                setNameInput(e.target.value);
                                setState({ ...state, name: e.target.value });
                              }
                            }}
                            className={
                              "block w-1/2 rounded-md border-0 py-1.5 pl-2 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-gray-400"
                            }
                            placeholder="이름을 입력하세요. (한글, 숫자만 입력 가능)"
                          />
                          <div className="absolute mr-3 right-1/2 bottom-3 transform -translate-y-1/2 text-s text-gray-500">{`${nameInput.length}/${MAX_NAME_LENGTH}`}</div>
                        </td>
                      )}
                    </tr>
                    <tr key={"user-email"} className="border-b border-gray-100">
                      <td className="font-medium w-1/3 py-4 align-top">이메일</td>
                      {locationState ? (
                        <td className="flex whitespace-pre-wrap">
                          <div className="w-1/2 relative py-4">{locationState.email}</div>
                        </td>
                      ) : (
                        <td className="flex whitespace-pre-wrap">
                          <div className="w-1/2 relative py-4">
                            <input
                              type="text"
                              name="user_email"
                              id="user_email"
                              value={emailInput}
                              onChange={(e) => {
                                if (e.target.value.length <= MAX_EMAIL_LENGTH) {
                                  setEmailInput(e.target.value);
                                  setState({ ...state, email: e.target.value });
                                }
                              }}
                              className={
                                "block w-full rounded-md border-0 py-1.5 pl-2 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-gray-400"
                              }
                              placeholder="이메일을 입력하세요."
                            />
                            <div className="absolute right-3 bottom-3 transform -translate-y-1/2 text-s text-gray-500">{`${emailInput.length}/${MAX_EMAIL_LENGTH}`}</div>
                          </div>
                          <div className="pl-2 my-4">
                            <input
                              type="text"
                              value={"@brownbag.one"}
                              className={
                                "block w-full rounded-md border-0 py-1.5 pl-2 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-gray-400"
                              }
                              disabled
                            ></input>
                          </div>
                        </td>
                      )}
                    </tr>
                    <tr key={"user-role"} className="border-b border-gray-100">
                      <td className="font-medium w-1/3 py-4 align-top">권한</td>
                      <td className="whitespace-pre-wrap py-4">
                        <div className="flex space-x-4">
                          <Menu as="div" className="relative inline-block text-left">
                            <div>
                              <Menu.Button className="inline-flex justify-between w-44 gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                {state?.role
                                  ? (roles.find((role) => role.name === state.role) || {}).name
                                  : "권한 선택"}
                                <ChevronDownIcon
                                  className="-mr-1 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  {roles?.map((role) => (
                                    <Menu.Item key={role.id} onClick={() => handleRoleClick(role)}>
                                      {({ active }) => (
                                        <a
                                          className={classNames(
                                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                            "block px-4 py-2 text-sm"
                                          )}
                                        >
                                          {role.name}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </td>
                    </tr>
                    <tr key={"user-memo"} className="border-b border-gray-100">
                      <td className="font-medium w-1/3 py-4 align-top">
                        <span className="font-medium mr-1">메모</span>
                        <span className="text-sm font-normal text-gray-400">(선택)</span>
                      </td>
                      <td className="relative py-4 text-gray-700">
                        <textarea
                          rows={3}
                          value={state?.memo || ""}
                          maxLength={MAX_MEMO_LENGTH}
                          onChange={(e) => {
                            if (
                              isValidTextInput(e.target.value) &&
                              e.target.value.length <= MAX_MEMO_LENGTH
                            ) {
                              setMemoInput(e.target.value);
                              setState({
                                ...state,
                                memo: e.target.value,
                              });
                            }
                          }}
                          className={
                            "block w-3/4 rounded-md border-0 py-1.5 pl-2 pr-16 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300  sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset focus:ring-indigo-600 placeholder:text-gray-400"
                          }
                        />
                        <div className="absolute mr-5 right-1/4 bottom-3 transform -translate-y-1/2 text-s text-gray-500">
                          {`${state ? state.memo.length : memoInput?.length}/${MAX_MEMO_LENGTH}`}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Page>
      </AdminFrame>
    </>
  );
};

export default UserForm;
