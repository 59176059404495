import React, { useCallback, useState } from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ko from "@shopify/polaris/locales/ko.json";
import { AppProvider, Frame } from "@shopify/polaris";

import SideBarFrame from "components/Layout/Sidebar/SideBar";

const AdminFrame = ({ title, children }) => {
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleMobileNavigationActive = useCallback(
    () => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive),
    []
  );

  const Link = ({ children, url = "", ...rest }) => {
    return (
      <ReactRouterLink to={url} {...rest}>
        {children}
      </ReactRouterLink>
    );
  };

  return (
    <AppProvider i18n={ko} linkComponent={Link}>
      <HelmetProvider>
        <Helmet>
          <title>{title} | 어드민</title>
        </Helmet>
      </HelmetProvider>

      <Frame
        navigation={<SideBarFrame toggleMobileNavigationActive={toggleMobileNavigationActive} />}
        showMobileNavigation={mobileNavigationActive}
        onNavigationDismiss={toggleMobileNavigationActive}
      >
        {children}
      </Frame>
    </AppProvider>
  );
};

export default AdminFrame;
