import React, { useContext } from "react";
import { PermissionContextState } from "contexts/Permission";
import {
  manageAccount,
  appData,
  manageContent,
  manageApp,
  manageUser,
  manageAppDeploy,
} from "../Pages";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

const NavSection = () => {
  const { userPermissions } = useContext(PermissionContextState);

  const canAccess = (pageName) => {
    return userPermissions.permissions.some((p) => p.pageName === pageName && p.canRead);
  };

  const accessibleManageAccount = manageAccount.filter((category) => canAccess(category.engName));
  const accessibleAppData = appData.filter((category) => canAccess(category.engName));
  const accessibleManageContent = manageContent.filter((category) => canAccess(category.engName));
  const accessibleManageApp = manageApp.filter((category) => canAccess(category.engName));
  const accessibleManageUser = manageUser.filter((category) => canAccess(category.engName));
  const accessibleManageAppDeploy = manageAppDeploy.filter((category) =>
    canAccess(category.engName)
  );

  return (
    <nav className="flex flex-1 flex-col">
      <ul role="list" className="flex flex-1 flex-col gap-y-7">
        <li>
          <ul
            role="list"
            className={`-mx-2 space-y-1 ${
              accessibleManageAccount.length > 0 ? "py-3 border-b border-gray-200" : ""
            }`}
          >
            {manageAccount.map(
              (category) =>
                canAccess(category.engName) && (
                  <li key={category.name}>
                    <a
                      href={category.href}
                      className={classNames(
                        category.current
                          ? "bg-gray-50 text-indigo-600"
                          : "text-gray-700 hover:bg-gray-50",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      )}
                    >
                      <category.icon
                        className={classNames(
                          category.current ? "text-indigo-600" : "text-gray-700",
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      {category.initial}
                      <span className="truncate">{category.name}</span>
                    </a>
                  </li>
                )
            )}
          </ul>
          <ul
            role="list"
            className={`-mx-2 space-y-1 ${
              accessibleAppData.length > 0 ? "py-3 border-b border-gray-200" : ""
            }`}
          >
            {appData.map(
              (category) =>
                canAccess(category.engName) && (
                  <li key={category.name}>
                    <a
                      href={category.href}
                      className={classNames(
                        category.current
                          ? "bg-gray-50 text-indigo-600"
                          : "text-gray-700 hover:bg-gray-50",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      )}
                    >
                      <category.icon
                        className={classNames(
                          category.current ? "text-indigo-600" : "text-gray-700",
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      {category.initial}
                      <span className="truncate">{category.name}</span>
                    </a>
                  </li>
                )
            )}
          </ul>
          <ul
            role="list"
            className={`-mx-2 space-y-1 ${
              accessibleManageContent.length > 0 ? "py-3 border-b border-gray-200" : ""
            }`}
          >
            {manageContent.map(
              (category) =>
                canAccess(category.engName) && (
                  <li key={category.name}>
                    <a
                      href={category.href}
                      className={classNames(
                        category.current
                          ? "bg-gray-50 text-indigo-600"
                          : "text-gray-700 hover:bg-gray-50",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      )}
                    >
                      <category.icon
                        className={classNames(
                          category.current ? "text-indigo-600" : "text-gray-700",
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      {category.initial}
                      <span className="truncate">{category.name}</span>
                    </a>
                  </li>
                )
            )}
          </ul>
          <ul
            role="list"
            className={`-mx-2 space-y-1 ${
              accessibleManageApp.length > 0 ? "py-3 border-b border-gray-200" : ""
            }`}
          >
            {manageApp.map(
              (category) =>
                canAccess(category.engName) && (
                  <li key={category.name}>
                    <a
                      href={category.href}
                      className={classNames(
                        category.current
                          ? "bg-gray-50 text-indigo-600"
                          : "text-gray-700 hover:bg-gray-50",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      )}
                    >
                      <category.icon
                        className={classNames(
                          category.current ? "text-indigo-600" : "text-gray-700",
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      {category.initial}
                      <span className="truncate">{category.name}</span>
                    </a>
                  </li>
                )
            )}
          </ul>
          <ul
            role="list"
            className={`-mx-2 space-y-1 ${
              accessibleManageUser.length > 0 ? "py-3 border-b border-gray-200" : ""
            }`}
          >
            {manageUser.map(
              (category) =>
                canAccess(category.engName) && (
                  <li key={category.name}>
                    <a
                      href={category.href}
                      className={classNames(
                        category.current
                          ? "bg-gray-50 text-indigo-600"
                          : "text-gray-700 hover:bg-gray-50",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      )}
                    >
                      <category.icon
                        className={classNames(
                          category.current ? "text-indigo-600" : "text-gray-700",
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      {category.initial}
                      <span className="truncate">{category.name}</span>
                    </a>
                  </li>
                )
            )}
          </ul>
          <ul
            role="list"
            className={`-mx-2 space-y-1 ${
              accessibleManageAppDeploy.length > 0 ? "py-3 border-b border-gray-200" : ""
            }`}
          >
            {manageAppDeploy.map(
              (category) =>
                canAccess(category.engName) && (
                  <li key={category.name}>
                    <a
                      href={category.href}
                      className={classNames(
                        category.current
                          ? "bg-gray-50 text-indigo-600"
                          : "text-gray-700 hover:bg-gray-50",
                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      )}
                    >
                      <category.icon
                        className={classNames(
                          category.current ? "text-indigo-600" : "text-gray-700",
                          "h-6 w-6 shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      {category.initial}
                      <span className="truncate">{category.name}</span>
                    </a>
                  </li>
                )
            )}
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default NavSection;
