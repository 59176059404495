import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";

import AdminFrame from "components/Layout/Frame";
import CardSpinner from "common/PageSpinner/Card";
import { Page } from "@shopify/polaris";
import toast from "react-hot-toast";
import { getAppVersionDevices } from "../../../rest-apis/appVersions";

const AppVersionDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [appVersion, setAppVersion] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const fetchDevices = async () => {
    setIsLoading(true);
    const promise = await getAppVersionDevices(id);

    try {
      if (promise.status === 200) {
        setAppVersion(promise.data);
      }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "서버 오류가 존재합니다."
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  return (
    <>
      <AdminFrame title="앱 버전 관리 조회">
        {isLoading ? (
          <CardSpinner />
        ) : (
          <>
            <div className="flex justify-between">
              <div className="flex ml-6 mt-6 items-center">
                <ArrowSmallLeftIcon
                  type="button"
                  className="w-9 h-9 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => {
                    navigate("/appVersions");
                  }}
                />
                <div className="ml-3 text-xl font-bold text-gray-800">
                  앱 버전 관리 조회 | {appVersion.version}
                </div>
              </div>
            </div>
            <Page fullWidth>
              <div className="flex flex-col">
                <div className="w-full mr-4 overflow-hidden bg-white shadow sm:rounded-lg mb-5">
                  <div className="px-8 py-6 sm:px-6">
                    <div className="text-base font-semibold">머신 목록</div>
                    <table className="m-4 min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="w-1/6 py-3.5 px-2 pr-3 text-left text-sm font-semibold text-gray-500"
                          >
                            NO
                          </th>
                          <th
                            scope="col"
                            className="w-1/3 px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                          >
                            머신 ID
                          </th>
                          <th
                            scope="col"
                            className="w-1/3 hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-500 sm:table-cell"
                          >
                            버전
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {appVersion.devices.length != 0 ? (
                          appVersion.devices.map((device, index) => (
                            <tr key={device.id}>
                              <td className="text-left hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                {index + 1}
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                <Link
                                  to={`/devices/${device.id}`}
                                  key={device.id}
                                  className="text-indigo-600 underline"
                                >
                                  {device.androidId}
                                </Link>
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                {device.appVersion.replace("v", "")}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <td
                            colSpan={3}
                            className="text-center pt-20 pb-12 text-gray-300 font-bold text-lg"
                          >
                            조회된 데이터가 없습니다.
                          </td>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Page>
          </>
        )}
      </AdminFrame>
    </>
  );
};

export default AppVersionDetailPage;
