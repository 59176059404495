import React from "react";
import { Layout, Page } from "@shopify/polaris";

import AdminFrame from "components/Layout/Frame";
import BeansList from "components/Beans/List/index";

const BeansPage = () => {
  return (
    <>
      <AdminFrame title="원두 관리">
        <Page title="원두 관리" fullWidth>
          <Layout>
            <Layout.Section>
              <BeansList />
            </Layout.Section>
          </Layout>
        </Page>
      </AdminFrame>
    </>
  );
};

export default BeansPage;
