import API from "./api";

export const getHome1 = async () => {
  const response = await API.get("/homeSetting/1");
  return response.data;
};

export const getHome2 = async () => {
  const response = await API.get("/homeSetting/2");
  return response.data;
};

export const updateHome1 = async (home1) => {
  const response = await API.patch("/homeSetting/1", home1);
  return response.data;
};

export const updateHome2 = async (home2) => {
  const response = await API.patch("/homeSetting/2", home2);
  return response.data;
};

export const getScreenSaver = async () => {
  const response = await API.get("/homeSetting/screenSaver");
  return response.data;
};

export const updateScreenSaver = async (screenSaver) => {
  const response = await API.patch("/homeSetting/screenSaver", screenSaver);
  return response.data;
};
