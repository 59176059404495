import React, { useState, useEffect } from "react";
import { Page } from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";

import CardSpinner from "common/PageSpinner/Card";
import AdminFrame from "components/Layout/Frame";
import BeanDetailTitle from "./Title";
import Detail from "./Detail";
import { getBeanDetail } from "../../../rest-apis/beans";

const BeansDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [bean, setBean] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchBean = async (id) => {
    setIsLoading(true);

    try {
      const response = await getBeanDetail(id);
      if (response.status === 200) {
        setBean(response.data);
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        if (status === 400 || status === 404 || status === 500) {
          navigate("/not-found");
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBean(id);
  }, [id]);

  return (
    <>
      <AdminFrame title="원두 관리 조회">
        {isLoading ? (
          <CardSpinner />
        ) : (
          <>
            <BeanDetailTitle bean={bean} />
            <Page fullWidth>
              <Detail bean={bean} />
            </Page>
          </>
        )}
      </AdminFrame>
    </>
  );
};
export default BeansDetail;
