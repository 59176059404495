import AWS from "aws-sdk";

const UploadContent = (path, fileName, file) => {
  return new Promise((resolve, reject) => {
    const timeStamp = Date.now();
    const S3_BUCKET = process.env.REACT_APP_S3_BUCKET_NAME;
    const bucketRegion = process.env.REACT_APP_S3_BUCKET_REGION;
    const PoolId = process.env.REACT_APP_S3_POOLID;
    const CloudFrontDomain = process.env.REACT_APP_CLOUDFRONT_DOMAIN;

    AWS.config.update({
      region: bucketRegion,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: PoolId,
      }),
    });

    const myBucket = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: bucketRegion,
    });

    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: `${path}/${timeStamp}${fileName}`,
      ContentType: file.type,
    };

    myBucket.putObject(params, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(`https://${CloudFrontDomain}/${path}/${timeStamp}${fileName}`);
      }
    });
  });
};

export default UploadContent;
