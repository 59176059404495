import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { getGuideList } from "../../../rest-apis/guide";
import { PermissionContextState } from "contexts/Permission";

import CardSpinner from "common/PageSpinner/Card";
import Pagination from "common/Pagination";
import FilterBar from "./FilterBar";
import OrderBar from "./OrderBar/OrderBar";
import GuideList from "./List";

const GuidesList = () => {
  const navigate = useNavigate();

  // permission
  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "GUIDE" && p.canWrite);
  };

  // notices
  const [guides, setGuides] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [guidePage, setGuidePage] = useState([]);
  const totalPages = guidePage.totalPages;
  const pageSize = 10;

  // filterBar
  const [search, setSearch] = useState("");

  // orderBar
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const fetchGuides = async (page) => {
    setIsLoading(true);

    const data = await getGuideList(page, pageSize, search);

    if (data._embedded && Array.isArray(data._embedded.guideListDTOList)) {
      setGuides(data._embedded.guideListDTOList);
    } else {
      setGuides([]);
    }
    setGuidePage(data.page);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchGuides(currentPage);
  }, [currentPage, search]);

  return (
    <>
      <FilterBar setSearch={setSearch} fetchGuides={fetchGuides} />
      <div className="flex justify-end items-center ml-7 mr-5">
        {canWritePage() && (
          <div>
            <button
              type="button"
              className="w-20 h-9 rounded-md bg-white px-2.5 py-1.5 mr-3 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => setOpen(true) && setIsEdit(true)}
            >
              순서변경
            </button>
            <button
              type="button"
              className="w-14 h-9 rounded-md bg-white px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => navigate("/guides/add")}
            >
              추가
            </button>
          </div>
        )}
      </div>
      {isLoading ? (
        <CardSpinner />
      ) : (
        <div>
          <OrderBar open={open} setOpen={setOpen} isEdit={isEdit} setIsEdit={setIsEdit} />
          <GuideList rows={guides} />
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      )}
    </>
  );
};

export default GuidesList;
