import React, { createContext, useState } from "react";

const PermissionContextState = createContext();

const PermissionProvider = ({ children }) => {
  const token = localStorage.getItem("token");
  let initialPermissions = {
    role: "",
    permissions: [],
  };

  if (token) {
    try {
      const payload = token.split(".")[1];
      const base64 = payload.replace(/-/g, "+").replace(/_/g, "/");
      const decodedPayload = atob(base64);
      const jsonObject = JSON.parse(decodedPayload);

      initialPermissions = {
        role: jsonObject.role,
        permissions: jsonObject.permissions,
      };
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("error", error);
    }
  }

  const [userPermissions, setUserPermissions] = useState(initialPermissions);

  return (
    <PermissionContextState.Provider value={{ userPermissions, setUserPermissions }}>
      {children}
    </PermissionContextState.Provider>
  );
};

export { PermissionProvider, PermissionContextState };
