import React, { useState, useEffect, useCallback, useContext } from "react";
import { Page } from "@shopify/polaris";

import AdminFrame from "components/Layout/Frame";
import CardSpinner from "common/PageSpinner/Card";

import Home1Page from "./Home_1";
import Home1Form from "./Home_1/Form";
import Home2Page from "./Home_2";
import Home2Form from "./Home_2/Form";
import ScreenSaverPage from "./ScreenSaver";
import ScreenSaverForm from "./ScreenSaver/Form";

import {
  getHome1,
  getHome2,
  getScreenSaver,
  updateHome1,
  updateHome2,
  updateScreenSaver,
} from "../../rest-apis/homeSetting";
import toast from "react-hot-toast";

import { useNavigate } from "react-router-dom";

import { UserInfoContextState } from "contexts/User";
import { PermissionContextState } from "contexts/Permission";

const tabs = [
  { id: "home_1", name: "기본 화면 (1)", current: true, disabled: false },
  { id: "home_2", name: "기본 화면 (2)", current: false, disabled: false },
  { id: "screen_saver", name: "대기 화면", current: false, disabled: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const HomeSettingPage = () => {
  const { userInfo } = useContext(UserInfoContextState);
  const { userPermissions } = useContext(PermissionContextState);
  const navigate = useNavigate();

  const canWriteHomeSettingPage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "HOME_SETTING" && p.canWrite);
  };

  const [home1State, setHome1State] = useState([]);
  const [home2State, setHome2State] = useState([]);
  const [screenSaverState, setScreenSaverState] = useState([]);
  const [isHome1Loading, setIsHome1Loading] = useState(false);
  const [isHome2Loading, setIsHome2Loading] = useState(false);
  const [isScreenSaverLoading, setIsScreenSaverLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [screenSaverImageContent, setScreenSaverImageContent] = useState([]);
  const [screenSaverVideoContent, setScreenSaverVideoContent] = useState([]);

  const [tabsState, setTabsState] = useState(tabs);
  const [selectedTab, setSelectedTab] = useState(0);
  const [contentSettingChildren, setContentSettingChildren] = useState(null);

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      if (isEdit) {
        if (
          !window.confirm(
            "다른 페이지로 이동하겠습니까? 이 페이지에서 변경한 내용은 저장되지 않습니다."
          )
        ) {
          return;
        } else {
          setIsEdit(false);
        }
      }

      setSelectedTab(selectedTabIndex);
      const updatedTabs = tabsState.map((tab, index) => {
        return { ...tab, current: index === selectedTabIndex };
      });
      setTabsState(updatedTabs);
    },
    [isEdit, setIsEdit, setSelectedTab, tabsState, setTabsState]
  );

  const handleHome1SettingSave = async () => {
    home1State.lastModifier = userInfo.name;

    // 토스트 에러 출력
    if (home1State.toggleName === "") return toast.error("토글명을 입력하세요.");
    if (home1State.mainDescription === "") return toast.error("문구를 입력하세요.");
    if (home1State.bannerImageUrl === "") return toast.error("배너를 등록하세요.");

    if (!window.confirm("상세 정보를 저장하시겠습니까?")) {
      return;
    }

    setIsSaving(true);

    const promise = updateHome1(home1State);

    toast.promise(promise, {
      loading: "수정 중입니다.",
      success: "수정되었습니다.",
      error: (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          return error.response.data.message;
        } else {
          return "서버 오류가 존재합니다.";
        }
      },
    });

    try {
      const response = await promise;
      if (response.status === 200) {
        setTimeout(() => {
          navigate("/home-setting");
        }, 500);
      }
    } finally {
      setIsEdit(false);
      setTimeout(() => {
        setIsSaving(false);
      }, 500);
    }
  };

  const handleHome2SettingSave = async () => {
    home2State.lastModifier = userInfo.name;

    // 토스트 에러 출력
    if (home2State.toggleName === "") return toast.error("토글명을 입력하세요.");
    if (home2State.mainDescription === "") return toast.error("문구를 입력하세요.");
    if (home2State.bannerImageUrl === "") return toast.error("배너를 등록하세요.");

    if (!window.confirm("상세 정보를 저장하시겠습니까?")) {
      return;
    }

    setIsSaving(true);

    const promise = updateHome2(home2State);

    toast.promise(promise, {
      loading: "수정 중입니다.",
      success: "수정되었습니다.",
      error: (error) => {
        if (error.response && error.response.data && error.response.data.message) {
          return error.response.data.message;
        } else {
          return "서버 오류가 존재합니다.";
        }
      },
    });

    try {
      const response = await promise;
      if (response.status === 200) {
        setTimeout(() => {
          navigate("/home-setting");
        }, 500);
      }
    } finally {
      setIsEdit(false);
      setTimeout(() => {
        setIsSaving(false);
      }, 500);
    }
  };

  const handleScreenSaverSave = async () => {
    const filteredScreenSaverImageContent = screenSaverImageContent.filter((item) => item !== null);

    const updatedScreenSaverState = {
      ...screenSaverState,
      files:
        screenSaverState.contentType == "이미지"
          ? filteredScreenSaverImageContent
          : screenSaverVideoContent,
      lastModifier: userInfo.name,
    };
    setScreenSaverState(updatedScreenSaverState);

    // 실시간 토스트 에러 출력
    if (updatedScreenSaverState.contentType == "이미지") {
      if (filteredScreenSaverImageContent.length < 1) {
        return toast.error("콘텐츠를 등록하세요.");
      }
    } else if (
      updatedScreenSaverState.contentType == "동영상" &&
      screenSaverVideoContent.length < 1
    ) {
      return toast.error("콘텐츠를 등록하세요.");
    }

    if (!window.confirm("상세 정보를 저장하시겠습니까?")) {
      return;
    }

    setIsSaving(true);

    const promise = updateScreenSaver(updatedScreenSaverState);

    try {
      const response = await promise;
      if (response.status === 200) {
        toast.success("수정되었습니다.");
        setTimeout(() => {
          navigate("/home-setting");
        }, 500);
      }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "서버 오류가 존재합니다."
      );
    } finally {
      setIsEdit(false);
      setTimeout(() => {
        setIsSaving(false);
      }, 300);
    }
  };

  const handleTabSave = () => {
    switch (selectedTab) {
      case 0:
        handleHome1SettingSave();
        break;
      case 1:
        handleHome2SettingSave();
        break;
      case 2:
        handleScreenSaverSave();
        break;
    }
  };

  const handleButtonClick = () => {
    if (isEdit) {
      handleTabSave();
      setIsEdit(false);
    } else {
      if (canWriteHomeSettingPage() === false) {
        navigate("/dashboard");
      }
      setIsEdit(true);
    }
  };

  const fetchHome1 = async () => {
    setIsHome1Loading(true);

    const response = await getHome1();
    setHome1State(response);

    setIsHome1Loading(false);
  };

  const fetchHome2 = async () => {
    setIsHome2Loading(true);

    const response = await getHome2();
    setHome2State(response);

    setIsHome2Loading(false);
  };

  const fetchScreenSaver = async () => {
    setIsScreenSaverLoading(true);

    const response = await getScreenSaver();
    setScreenSaverState(response);

    setIsScreenSaverLoading(false);
  };

  useEffect(() => {
    switch (selectedTab) {
      case 0:
        if (isEdit) {
          setContentSettingChildren(
            <Home1Form
              home1State={home1State}
              setHome1State={setHome1State}
              setIsEdit={setIsEdit}
            />
          );
        } else {
          setContentSettingChildren(<Home1Page home1State={home1State} setIsEdit={setIsEdit} />);
        }
        break;
      case 1:
        if (isEdit) {
          setContentSettingChildren(
            <Home2Form
              home2State={home2State}
              setHome2State={setHome2State}
              setIsEdit={setIsEdit}
            />
          );
        } else {
          setContentSettingChildren(
            <Home2Page
              isHome2Loading={isHome2Loading}
              home2State={home2State}
              setIsEdit={setIsEdit}
            />
          );
        }
        break;
      case 2:
        if (isEdit) {
          setContentSettingChildren(
            <ScreenSaverForm
              isScreenSaverLoading={isScreenSaverLoading}
              screenSaverState={screenSaverState}
              setScreenSaverState={setScreenSaverState}
              setIsEdit={setIsEdit}
              screenSaverImageContent={screenSaverImageContent}
              setScreenSaverImageContent={setScreenSaverImageContent}
              screenSaverVideoContent={screenSaverVideoContent}
              setScreenSaverVideoContent={setScreenSaverVideoContent}
            />
          );
        } else {
          setContentSettingChildren(
            <ScreenSaverPage
              isScreenSaverLoading={isScreenSaverLoading}
              screenSaverState={screenSaverState}
              setIsEdit={setIsEdit}
            />
          );
        }
        break;
    }
  }, [
    selectedTab,
    home1State,
    home2State,
    screenSaverState,
    screenSaverImageContent,
    screenSaverVideoContent,
    isEdit,
  ]);

  useEffect(() => {
    fetchHome1();
    fetchHome2();
    fetchScreenSaver();
  }, [selectedTab]);

  return (
    <>
      <AdminFrame title="기본 화면 관리">
        <div className="flex justify-between">
          <div className="flex ml-6 mt-6 items-center">
            <div className="ml-3 text-xl font-bold text-gray-800">기본 화면 관리</div>
          </div>
          <div className="mt-6 mr-6">
            {canWriteHomeSettingPage() && (
              <button
                type="button"
                className={
                  isSaving
                    ? "w-24 h-9 rounded-md bg-gray-200 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    : "w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                }
                onClick={handleButtonClick}
                disabled={isSaving}
              >
                {isSaving ? (
                  <div className="flex">
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#1C64F2"
                      />
                    </svg>
                    <div>저장중</div>
                  </div>
                ) : isEdit ? (
                  "저장"
                ) : (
                  "수정"
                )}
              </button>
            )}
          </div>
        </div>
        <Page fullWidth>
          <div className="bg-white shadow flex flex-col sm:rounded-lg mb-5">
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                defaultValue={tabs.find((tab) => tab.current).name}
              >
                {tabs.map((tab) => (
                  <option key={tab.name}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex" aria-label="Tabs">
                  {tabsState.map((tab, index) => (
                    <a
                      key={tab.name}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!tab.disabled) {
                          handleTabChange(index);
                        }
                      }}
                      className={classNames(
                        tab.disabled
                          ? "text-gray-300 cursor-not-allowed"
                          : tab.current
                          ? "border-indigo-500 text-indigo-600"
                          : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                        "w-1/3 border-b-2 py-4 px-1 text-center text-sm font-medium cursor-pointer"
                      )}
                      aria-current={tab.current ? "page" : undefined}
                    >
                      {tab.name}
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            <div className="h-full">
              {isHome1Loading ? <CardSpinner /> : contentSettingChildren}
            </div>
          </div>
        </Page>
      </AdminFrame>
    </>
  );
};

export default HomeSettingPage;
