import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PermissionContextState } from "contexts/Permission";

import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";

const BeanDetailTitle = ({ bean }) => {
  const navigate = useNavigate();
  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "BEAN" && p.canWrite);
  };

  return (
    <div className="flex justify-between">
      <div className="flex ml-6 mt-6 items-center">
        <ArrowSmallLeftIcon
          type="button"
          className="w-9 h-9 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => {
            navigate("/beans");
          }}
        />
        <div className="ml-3 text-xl font-bold text-gray-800">원두 관리 조회 | {bean.name}</div>
      </div>
      <div className="mt-6 mr-6">
        {canWritePage() && (
          <button
            type="button"
            className="w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={() => {
              navigate(`/beans/${bean.id}/edit`, {
                state: {
                  bean: bean,
                },
              });
            }}
          >
            수정
          </button>
        )}
      </div>
    </div>
  );
};

export default BeanDetailTitle;
