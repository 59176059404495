import API from "./api";

export const getTotalData = async () => {
  const response = await API.get("/dashboards/total");
  return response;
};

export const getBrewingLogData = async () => {
  const response = await API.get("/dashboards/brewing");
  return response;
};

export const getCertificationData = async () => {
  const response = await API.get("/dashboards/certification");
  return response;
};
