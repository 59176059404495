import API from "./api";

export const getFaqList = async (page, size, tabs, search) => {
  const response = await API.get("/faqs", {
    params: {
      page: page,
      size: size,
      tabs: tabs,
      search: search,
    },
  });
  return response.data;
};

export const getFaqOrder = async (tab) => {
  const response = await API.get("/faqs/order", {
    params: {
      tab: tab,
    },
  });
  return response.data;
};

export const updateFaqOrder = async (faqOrder, lastModifier) => {
  const response = await API.patch("/faqs/order", {
    FAQOrder: faqOrder,
    lastModifier: lastModifier,
  });
  return response.data;
};

export const getFaqDetail = async (id) => {
  const response = await API.get(`/faqs/${id}`);
  return response.data;
};

export const updateFaqDeactivate = async (id, deactivate, lastModifier) => {
  const response = await API.patch(`/faqs/${id}/deactivate`, {
    deactivate: deactivate,
    lastModifier: lastModifier,
  });
  return response.data;
};

export const createFaq = async (faq) => {
  const response = await API.post("/faqs", faq);
  return response;
};

export const updateFaq = async (id, faq) => {
  const response = await API.patch(`/faqs/${id}`, faq);
  return response;
};
