import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import { initializeLogger } from "common/logger";
import App from "./App";

import { UserContextProvider } from "contexts/User";
import { FeatureProvider } from "contexts/FeatureFlag";
import ContextProvider from "contexts/Provider";
import { UserLevelProvider } from "contexts/UserLevel";
import { PermissionProvider } from "contexts/Permission";
import { Toaster } from "react-hot-toast";

initializeLogger();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ContextProvider
    contexts={[UserContextProvider, FeatureProvider, UserLevelProvider, PermissionProvider]}
  >
    <React.StrictMode>
      <App />
      <Toaster
        toastOptions={{
          duration: 2000,
          error: {
            duration: 2000,
            style: {
              background: "red",
              color: "white",
            },
          },
        }}
      />
    </React.StrictMode>
  </ContextProvider>
);
