import React from "react";
import moment from "moment/moment";
import CardSpinner from "common/PageSpinner/Card";

const Home2Page = ({ isHome2Loading, home2State }) => {
  return (
    <>
      <div className="flex flex-col h-full px-4 my-6">
        {isHome2Loading ? (
          <CardSpinner />
        ) : (
          <div className="w-full h-full mr-4 overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="px-8 py-6 sm:px-6">
              <div className="text-base font-semibold">기본 화면 (2)</div>
              <table className="w-full mt-5 text-left table-auto">
                <tbody>
                  <tr key={"currentBeanName"} className="border-b border-gray-100">
                    <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-center">
                      토글명
                    </td>
                    <td className="text-gray-700">{home2State.toggleName}</td>
                  </tr>
                  <tr key={"menus"} className="border-b border-gray-100">
                    <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                      문구
                    </td>
                    <td className="py-4 text-gray-700 whitespace-pre-wrap">
                      {home2State.mainDescription}
                    </td>
                  </tr>
                  <tr key={"appVersion"} className="border-b border-gray-100">
                    <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                      <div>화면 2 배너</div>
                      <div className="text-sm font-normal text-gray-400">(718*234px, png)</div>
                    </td>
                    <td className="py-4 text-gray-700">
                      <img src={home2State.bannerImageUrl} className="w-64" />
                    </td>
                  </tr>
                  <tr key={"esperDeviceName"} className="border-b border-gray-100">
                    <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                      최종 수정자
                    </td>
                    <td className="text-gray-700">{home2State.lastModifier}</td>
                  </tr>
                  <tr key={"certificatedAt"} className="border-b border-gray-100">
                    <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                      최종 수정 일시
                    </td>
                    <td className="text-gray-700">
                      {moment(home2State.lastModifiedAt)
                        .add(9, "hour")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Home2Page;
