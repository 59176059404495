import React from "react";

const CertificatedBadge = ({ isCertificated }) => {
  return isCertificated ? (
    <span className="inline-flex items-center gap-x-1.5 rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700">
      인증
    </span>
  ) : (
    <span className="inline-flex items-center gap-x-1.5 rounded-full bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600">
      미인증
    </span>
  );
};

export default CertificatedBadge;
