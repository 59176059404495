import React from "react";

import TopSection from "./TopSection";
import NavSection from "./NavSection";

const SideBarFrame = () => {
  return (
    <>
      <div className="fixed inset-y-0 z-50 flex w-60 flex-col">
        <div
          className="flex grow flex-col gap-y-2 overflow-y-auto border-r border-gray-200 px-6"
          style={{ backgroundColor: "F6F6F7" }}
        >
          <TopSection />
          <NavSection />
        </div>
      </div>
    </>
  );
};

export default SideBarFrame;
