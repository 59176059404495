import React from "react";
import { Loading, EmptyState, Spinner } from "@shopify/polaris";

const TableSpinner = () => {
  return (
    <>
      <Loading />
      <EmptyState />
      <EmptyState>
        <Spinner size="large"></Spinner>
      </EmptyState>
      <EmptyState />
    </>
  );
};

export default TableSpinner;
