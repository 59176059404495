import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";
import AdminFrame from "components/Layout/Frame";
import { Page } from "@shopify/polaris";

import CardSpinner from "common/PageSpinner/Card";
import CertificatedBadge from "common/Badge/certificated";

import { getAccountDetail } from "../../../rest-apis/accounts";
import { PermissionContextState } from "contexts/Permission";

const AccountDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "ACCOUNT" && p.canWrite);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState([]);

  const fetchAccount = async (id) => {
    setIsLoading(true);

    const response = await getAccountDetail(id);
    setAccount(response);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchAccount(id);
  }, [id]);

  return (
    <>
      <AdminFrame title="계정 관리 조회">
        {isLoading ? (
          <CardSpinner />
        ) : (
          <>
            <div className="flex justify-between">
              <div className="flex ml-6 mt-6 items-center">
                <ArrowSmallLeftIcon
                  type="button"
                  className="w-9 h-9 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => {
                    navigate("/accounts");
                  }}
                />
                <div className="ml-3 text-xl font-bold text-gray-800">
                  계정 관리 조회 | {account.name}
                </div>
              </div>
              <div className="mt-6 mr-10">
                {canWritePage() && (
                  <button
                    type="button"
                    className="w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => {
                      setTimeout(() => {
                        navigate(`/accounts/${id}/edit`, {
                          state: {
                            account: account,
                          },
                        });
                      }, 500);
                    }}
                  >
                    수정
                  </button>
                )}
              </div>
            </div>
            <Page fullWidth>
              {isLoading ? (
                <CardSpinner />
              ) : (
                <div className="flex overflow-x-auto">
                  <div className="w-full mr-4 bg-white shadow sm:rounded-lg mb-5">
                    <div className="px-8 py-6 sm:px-6">
                      <div className="text-base font-semibold">상세 정보</div>
                      <table className="w-full mt-5 text-left table-auto">
                        <tbody>
                          <tr key="account-type" className="border-b border-gray-100">
                            <td className="font-medium w-1/12 py-4 align-top text-gray-700">
                              고객 종류
                            </td>
                            <td className="py-4 text-gray-700">{account.type}</td>
                          </tr>
                          <tr key={"account-code"} className="border-b border-gray-100">
                            <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium text-gray-700">
                              어웨어 고객 코드
                            </td>
                            <td className="relative py-4 text-gray-700">{account.code}</td>
                          </tr>
                          <tr key={`${account?.id}-name`} className="border-b border-gray-100">
                            <td className="w-1/5 py-4 align-top whitespace-nowrap font-medium text-gray-700">
                              고객명
                            </td>
                            <td className="relative py-4 text-gray-700">{account.name}</td>
                          </tr>
                          <tr key={`${account?.id}-device`} className="border-b border-gray-100">
                            <td className="w-1/5 py-4 align-top whitespace-nowrap font-medium text-gray-700">
                              정품 인증 머신
                            </td>
                            <td className="relative py-4 text-gray-700">
                              <div className="overflow-hidden ring-1 ring-black ring-opacity-5 rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-xs font-normal text-gray-500 sm:pl-6"
                                      >
                                        NO
                                      </th>
                                      <th
                                        scope="col"
                                        className="w-40 px-3 py-3.5 text-left text-xs font-normal text-gray-500"
                                      >
                                        머신 ID
                                      </th>
                                      <th
                                        scope="col"
                                        className="w-3/12 px-3 py-3.5 text-left text-xs font-normal text-gray-500"
                                      >
                                        <span>메모 </span>
                                        <span className="text-gray-400">(선택)</span>
                                      </th>
                                      <th
                                        scope="col"
                                        className="whitespace-nowrap px-3 py-3.5 text-left text-xs font-normal text-gray-500"
                                      >
                                        정품 인증
                                      </th>
                                      <th
                                        scope="col"
                                        className="whitespace-nowrap px-3 py-3.5 text-left text-xs font-normal text-gray-500"
                                      >
                                        정품 인증 일시
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200 bg-white">
                                    {account.deviceList.length === 0 ? (
                                      <td
                                        colSpan={5}
                                        className="text-center pt-16 pb-16 text-gray-300 font-bold text-lg"
                                      >
                                        조회된 데이터가 없습니다.
                                      </td>
                                    ) : (
                                      account.deviceList.map((row, index) => (
                                        <tr key={row.no}>
                                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
                                            {row.no}
                                          </td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <button
                                              className="text-indigo-600 underline hover:text-indigo-900"
                                              onClick={() => {
                                                navigate(`/devices/${row.id}`);
                                              }}
                                            >
                                              {row.androidId}
                                            </button>
                                          </td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {row.memo}
                                          </td>
                                          <td className="whitespace-nowrap py-4 pl-2 text-sm text-gray-500">
                                            <CertificatedBadge
                                              isCertificated={row.isCertificated}
                                            />
                                          </td>
                                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {moment(row.certificatedAt)
                                              .add(9, "hour")
                                              .format("YYYY-MM-DD HH:mm:ss")}
                                          </td>
                                        </tr>
                                      ))
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </td>
                          </tr>
                          <tr key={`${account.id}-memo`} className="border-b border-gray-100">
                            <td className="w-1/5 py-4 whitespace-nowrap align-top">
                              <span className="font-medium mr-1 text-gray-700">메모</span>
                              <span className="text-sm font-normal text-gray-400">(선택)</span>
                            </td>
                            <td className="relative py-4 whitespace-pre-wrap text-gray-700">
                              {account.memo}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </Page>
          </>
        )}
      </AdminFrame>
    </>
  );
};

export default AccountDetailPage;
