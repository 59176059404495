import React, { Fragment, useEffect, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { toast } from "react-hot-toast";

import { manageAccount, appData, manageContent, manageApp, manageUser } from "../../Layout/Pages";
import { updatePermission, getPermissionList } from "../../../rest-apis/user";
import { translateRole } from "../type/roleType";
import { translatePage } from "../type/pageType";

const roleOrder = ["관리자", "팀장", "멤버", "게스트(조회)"];

const PermissionModal = ({ isOpen, setIsOpen }) => {
  const userName = localStorage.getItem("name");

  const allPages = [].concat(manageAccount, appData, manageContent, manageApp, manageUser);
  const [permissions, setPermissions] = useState([]);
  const [updatedPermissions, setUpdatedPermissions] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const handleCheckboxChange = (role, pageName, isChecked) => {
    setIsEdit(true);
    setUpdatedPermissions((prevPermissions) => {
      let updatedPermissions = prevPermissions.map((p) => ({ ...p }));
      let permission = updatedPermissions.find((p) => p.role === role);

      if (isChecked) {
        if (permission) {
          permission.pages = [...new Set([...permission.pages, pageName])];
        } else {
          updatedPermissions.push({ role, pages: [pageName] });
        }
      } else {
        if (permission) {
          permission.pages = permission.pages.filter((p) => p !== pageName);
          if (permission.pages.length === 0) {
            updatedPermissions = updatedPermissions.filter((p) => p.role !== role);
          }
        }
      }

      return updatedPermissions;
    });
  };

  const handlePermissionSaveButton = async () => {
    if (!window.confirm("현재 설정을 저장하시겠습니까?")) {
      return;
    }

    const request = {
      roleToPage: updatedPermissions,
      lastModifier: userName,
    };

    updatePermission(request)
      .then(() => {
        setIsOpen(false);
        setIsEdit(false);
        return;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("서버 오류가 존재합니다.");
        }
      });
  };

  const fetchPermissions = async () => {
    const response = await getPermissionList();
    const sortedPermissions = response.sort((a, b) => {
      const roleAIndex = roleOrder.indexOf(translateRole(a.role));
      const roleBIndex = roleOrder.indexOf(translateRole(b.role));
      return roleAIndex - roleBIndex;
    });

    setPermissions(sortedPermissions);
    setUpdatedPermissions(sortedPermissions);
  };

  useEffect(() => {
    fetchPermissions();
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setIsEdit(false);
      setUpdatedPermissions(permissions);
    } else {
      fetchPermissions();
    }
  }, [isOpen]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full min-w-full overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all">
                <div className="my-2 mx-3 text-left">
                  <Dialog.Title as="h3" className="mb-10 text-xl font-bold leading-6 text-black">
                    권한 설정
                  </Dialog.Title>
                  <div className="flex">
                    {updatedPermissions.map((permission, permissionIndex) => (
                      <div key={permissionIndex} className="mb-6">
                        <div className="mb-2 pb-2 flex w-full justify-center items-center border-b border-gray-300 text-gray-700">
                          {translateRole(permission.role)}
                        </div>
                        <div className="grid grid-cols-1 gap-2">
                          {allPages.map((page, index) => (
                            <div
                              key={index}
                              className="flex justify-left items-center w-full pb-2 border-b border-gray-200"
                            >
                              <input
                                id={`${page.engName}-${index}-${permission.role}`}
                                type="checkbox"
                                className="mr-2 ml-12 text-indigo-600"
                                value={translatePage(page.engName)}
                                checked={permission.pages.includes(page.engName)}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    permission.role,
                                    page.engName,
                                    e.target.checked
                                  )
                                }
                                disabled={permission.role === "관리자"}
                              />
                              <label
                                htmlFor={`${page.engName}-${index}-${permission.role}`}
                                className="whitespace-nowrap mr-12 text-gray-700"
                              >
                                {page.name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mt-10 mb-3 text-center">
                  <button
                    type="button"
                    className="w-14 h-9 rounded-md bg-white m-1 px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => {
                      setIsOpen(false);
                      setIsEdit(false);
                      setUpdatedPermissions(permissions);
                    }}
                  >
                    취소
                  </button>
                  <button
                    type="button"
                    className={`w-14 h-9 rounded-md px-3 py-2 mt-2 ml-2 text-sm font-semibold shadow-sm ${
                      isEdit
                        ? "text-white bg-indigo-600 hover:bg-indigo-500"
                        : "text-gray-700 bg-gray-200 hover:bg-gray-400"
                    }`}
                    onClick={() => handlePermissionSaveButton()}
                    disabled={!isEdit}
                  >
                    저장
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PermissionModal;
