import React, { createContext, useState } from "react";

const FeatureContext = createContext({
  isFeatureOn: false,
});

const FeatureProvider = ({ children }) => {
  const [isFeatureOn, setIsFeatureOn] = useState(false);

  return (
    <FeatureContext.Provider value={{ isFeatureOn, setIsFeatureOn }}>
      {children}
    </FeatureContext.Provider>
  );
};

export { FeatureProvider, FeatureContext };
