import React, { useState } from "react";
import ReactECharts from "echarts-for-react";

const SuccessChart = ({ result }) => {
  const [options, setOptions] = useState({
    xAxis: {
      type: "category",
      data: ["배포 시작", "업데이트 요청 완료", "앱 다운로드 완료", "사용자 설치 완료"],
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: [
          100,
          result.receiveResult.totalSuccessRate,
          result.downloadResult.totalSuccessRate,
          result.installResult.totalSuccessRate,
        ],
        type: "bar",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
      },
    ],
  });

  return (
    <div className="h-auto w-9/12">
      <ReactECharts option={options} setOptions={setOptions} theme={"westeros"} />
    </div>
  );
};

export default SuccessChart;
