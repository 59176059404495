import React from "react";
import moment from "moment";

const MachineDetailInfo = ({ deviceDetail }) => {
  return (
    <>
      <div className="w-1/2 h-full mr-4 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-8 py-6 sm:px-6">
          <div className="text-base font-semibold">상세 정보</div>
          <table className="w-full mt-5 text-left table-auto">
            <tbody>
              <tr key={"currentBeanName"} className="border-b border-gray-100">
                <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                  현재 원두
                </td>
                <td className="text-gray-700">
                  {deviceDetail.currentBeanName ? deviceDetail.currentBeanName : "-"}
                </td>
              </tr>
              <tr key={"menus"} className="border-b border-gray-100">
                <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                  메뉴
                </td>
                <td className="py-4 text-gray-700">
                  {deviceDetail.menus &&
                    deviceDetail.menus.length > 0 &&
                    deviceDetail.menus.map((menu, index) => (
                      <div key={menu.name} className="flex items-center">
                        {index + 1}. {menu.name}
                        {menu.isCustom ? (
                          ""
                        ) : (
                          <span
                            className="inline-flex items-center rounded-full bg-purple-50 ml-1.5 px-1.5 py-0.5 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10"
                            style={{ fontSize: "10px" }}
                          >
                            기본
                          </span>
                        )}
                      </div>
                    ))}
                </td>
              </tr>
              <tr key={"appVersion"} className="border-b border-gray-100">
                <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                  앱 현재 버전
                </td>
                <td className="text-gray-700">{deviceDetail.appVersion}</td>
              </tr>
              <tr key={"esperDeviceName"} className="border-b border-gray-100">
                <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                  에스퍼 ID
                </td>
                <td className="text-gray-700">{deviceDetail.esperDeviceName}</td>
              </tr>
              <tr key={"certificatedAt"} className="border-b border-gray-100">
                <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                  정품 인증 일시
                </td>
                <td className="text-gray-700">
                  {moment(deviceDetail.certificatedAt).add(9, "hour").format("YYYY-MM-DD HH:mm:ss")}
                </td>
              </tr>
              <tr key={"createdAt"} className="border-b border-gray-100">
                <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                  앱 최초 설치 일시
                </td>
                <td className="text-gray-700">
                  {moment(deviceDetail.createdAt).add(9, "hour").format("YYYY-MM-DD HH:mm:ss")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default MachineDetailInfo;
