import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

import "./App.css";

import ScrollToTop from "utils/ScrollTop";
import AdminLogin from "components/Login/index";
import MachineListPage from "components/Machine/List/index";
import MachineDetailPage from "components/Machine/Detail";
import BeansPage from "components/Beans/index";
import BeansDetailPage from "components/Beans/Detail/index";
import BeansForm from "components/Beans/Form";
import MenuPage from "components/Menu/index";
import DefaultMenuDetailPage from "components/Menu/Detail/Detail";
import DefaultMenuForm from "components/Menu/Form/index";
import ContentsPage from "components/Content";
import ContentsDetailPage from "components/Content/Detail/index";
import ContentForm from "components/Content/Form/index";
import NoticesPage from "components/Notice";
import NoticeForm from "components/Notice/Form";
import NoticeDetail from "./components/Notice/Detail";
import FaqPage from "./components/FAQ";
import FaqForm from "./components/FAQ/Form";
import FaqDetail from "./components/FAQ/Detail";
import AccountPage from "./components/Accounts";
import AccountDetailPage from "./components/Accounts/Detail";
import AccountForm from "./components/Accounts/Form";
import GroupAccountPage from "./components/GroupAccounts";
import GroupAccountDetailPage from "./components/GroupAccounts/Detail";
import GroupAccountForm from "./components/GroupAccounts/Form";
import PresetPage from "./components/Presets";
import PresetDetail from "./components/Presets/Detail";
import PresetForm from "./components/Presets/Form";
import HomeSettingPage from "./components/HomeSetting";
import AppDataPage from "./components/AppData";
import AppDataForm from "./components/AppData/Form";
import { PermissionContextState } from "contexts/Permission";
import { UserInfoContextState } from "contexts/User";
import Dashboard from "./components/Dashboard";
import UserPage from "./components/Users";
import UserDetailPage from "./components/Users/Detail";
import UserForm from "./components/Users/Form";
import GuidePage from "./components/Guides";
import GuideForm from "./components/Guides/Form";
import GuideDetail from "./components/Guides/Detail";
import NotFoundPage from "./error/NotFound";
import AppVersionPage from "./components/AppVersion";
import AppDeployPage from "./components/AppDeploy";
import AppDeployDetailPage from "./components/AppDeploy/Result";
import AppVersionDetailPage from "./components/AppVersion/Detail";

const App = () => {
  const token = localStorage.getItem("token");
  const name = localStorage.getItem("name");
  const { userPermissions } = useContext(PermissionContextState);
  const { setUserInfo } = useContext(UserInfoContextState);

  const canReadPage = (pageName) => {
    return userPermissions.permissions.some((p) => p.pageName === pageName && p.canRead);
  };
  const canWritePage = (pageName) => {
    return userPermissions.permissions.some((p) => p.pageName === pageName && p.canWrite);
  };

  useEffect(() => {
    if (name) {
      setUserInfo({ name });
    }
  }, [name]);

  return (
    <>
      <Router>
        <ScrollToTop />
        {token ? (
          <Routes>
            <Route path="/login" element={<Navigate replace to="/dashboard" />} />
            <Route path="/" element={<Navigate replace to="/dashboard" />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/not-found" element={<NotFoundPage />} />
            <Route
              path="/accounts"
              element={
                canReadPage("ACCOUNT") ? <AccountPage /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/accounts/add"
              element={
                canWritePage("ACCOUNT") ? <AccountForm /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/accounts/:id/edit"
              element={
                canWritePage("ACCOUNT") ? <AccountForm /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/accounts/:id"
              element={
                canReadPage("ACCOUNT") ? (
                  <AccountDetailPage />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            />
            <Route
              path="/group-accounts"
              element={
                canReadPage("GROUP_ACCOUNT") ? (
                  <GroupAccountPage />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            />
            <Route
              path="/group-accounts/add"
              element={
                canWritePage("GROUP_ACCOUNT") ? (
                  <GroupAccountForm />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            />
            <Route
              path="/group-accounts/:id/edit"
              element={
                canWritePage("GROUP_ACCOUNT") ? (
                  <GroupAccountForm />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            />
            <Route
              path="/group-accounts/:id"
              element={
                canReadPage("GROUP_ACCOUNT") ? (
                  <GroupAccountDetailPage />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            />
            <Route
              path="/devices"
              element={
                canReadPage("DEVICE") ? <MachineListPage /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/devices/:id"
              element={
                canReadPage("DEVICE") ? <MachineDetailPage /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/menus"
              element={canReadPage("MENU") ? <MenuPage /> : <Navigate replace to="/dashboard" />}
            />
            <Route
              path="/menus/:id"
              element={
                canReadPage("MENU") ? (
                  <DefaultMenuDetailPage />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            />
            <Route
              path="/menus/add"
              element={
                canWritePage("MENU") ? <DefaultMenuForm /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/menus/:id/edit"
              element={
                canWritePage("MENU") ? <DefaultMenuForm /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/contents"
              element={
                canReadPage("CONTENT") ? <ContentsPage /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/contents/:id"
              element={
                canReadPage("CONTENT") ? (
                  <ContentsDetailPage />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            />
            <Route
              path="/contents/add"
              element={
                canWritePage("CONTENT") ? <ContentForm /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/contents/:id/edit"
              element={
                canWritePage("CONTENT") ? <ContentForm /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/home-setting"
              element={
                canReadPage("HOME_SETTING") ? (
                  <HomeSettingPage />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            />
            <Route
              path="/beans"
              element={canReadPage("BEAN") ? <BeansPage /> : <Navigate replace to="/dashboard" />}
            />
            <Route
              path="/beans/:id"
              element={
                canReadPage("BEAN") ? <BeansDetailPage /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/beans/:id/edit"
              element={canWritePage("BEAN") ? <BeansForm /> : <Navigate replace to="/dashboard" />}
            />
            <Route
              path="/beans/add"
              element={canWritePage("BEAN") ? <BeansForm /> : <Navigate replace to="/dashboard" />}
            />
            <Route
              path="/notices"
              element={
                canReadPage("NOTICE") ? <NoticesPage /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/notices/:id"
              element={
                canReadPage("NOTICE") ? <NoticeDetail /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/notices/add"
              element={
                canWritePage("NOTICE") ? <NoticeForm /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/notices/:id/edit"
              element={
                canWritePage("NOTICE") ? <NoticeForm /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/faqs"
              element={canReadPage("FAQ") ? <FaqPage /> : <Navigate replace to="/dashboard" />}
            />
            <Route
              path="/faqs/:id"
              element={canReadPage("FAQ") ? <FaqDetail /> : <Navigate replace to="/dashboard" />}
            />
            <Route
              path="/faqs/add"
              element={canWritePage("FAQ") ? <FaqForm /> : <Navigate replace to="/dashboard" />}
            />
            <Route
              path="/faqs/:id/edit"
              element={canWritePage("FAQ") ? <FaqForm /> : <Navigate replace to="/dashboard" />}
            />
            <Route
              path="/presets"
              element={
                canReadPage("PRESET") ? <PresetPage /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/presets/:id"
              element={
                canReadPage("PRESET") ? <PresetDetail /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/presets/add"
              element={
                canWritePage("PRESET") ? <PresetForm /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/presets/:id/edit"
              element={
                canWritePage("PRESET") ? <PresetForm /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/app-data"
              element={
                canReadPage("APP_DATA") ? <AppDataPage /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/app-data/edit"
              element={
                canWritePage("APP_DATA") ? <AppDataForm /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/users"
              element={canReadPage("USER") ? <UserPage /> : <Navigate replace to="/dashboard" />}
            />
            <Route
              path="/users/:id"
              element={
                canReadPage("USER") ? <UserDetailPage /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/users/add"
              element={canWritePage("USER") ? <UserForm /> : <Navigate replace to="/dashboard" />}
            />
            <Route
              path="/users/:id/edit"
              element={canWritePage("USER") ? <UserForm /> : <Navigate replace to="/dashboard" />}
            />
            <Route
              path="/guides"
              element={canReadPage("GUIDE") ? <GuidePage /> : <Navigate replace to="/dashboard" />}
            />
            <Route
              path="/guides/:id"
              element={
                canReadPage("GUIDE") ? <GuideDetail /> : <Navigate replace to="/dashboard" />
              }
            />
            <Route
              path="/guides/add"
              element={canWritePage("GUIDE") ? <GuideForm /> : <Navigate replace to="/dashboard" />}
            />
            <Route
              path="/guides/:id/edit"
              element={canWritePage("GUIDE") ? <GuideForm /> : <Navigate replace to="/dashboard" />}
            />
            <Route
              path="/appVersions"
              element={
                canReadPage("APP_VERSION") ? (
                  <AppVersionPage />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            />
            <Route
              path="/appVersions/:id"
              element={
                canReadPage("APP_VERSION") ? (
                  <AppVersionDetailPage />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            />
            <Route
              path="/appDeploys"
              element={
                canWritePage("APP_DEPLOY") ? (
                  <AppDeployPage />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            />
            <Route
              path="/appDeploys/:id"
              element={
                canWritePage("APP_DEPLOY") ? (
                  <AppDeployDetailPage />
                ) : (
                  <Navigate replace to="/dashboard" />
                )
              }
            />
          </Routes>
        ) : (
          <Routes>
            <Route path="/*" element={<Navigate replace to="login" />} />
            <Route path="/login" element={<AdminLogin />} />
          </Routes>
        )}
      </Router>
    </>
  );
};

export default App;
