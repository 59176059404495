import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import AdminFrame from "components/Layout/Frame";
import CardSpinner from "common/PageSpinner/Card";
import { Page } from "@shopify/polaris";
import { getAppDataDetail } from "../../rest-apis/appData";

import { PermissionContextState } from "contexts/Permission";

import moment from "moment/moment";

const AppDataPage = () => {
  const navigate = useNavigate();

  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "APP_DATA" && p.canWrite);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [appData, setAppData] = useState([]);

  const [selectedBean, setSelectedBean] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [selectedData, setSelectedData] = useState({ presets: null, contents: null });

  const fetchAppData = async () => {
    setIsLoading(true);

    const response = await getAppDataDetail();
    setAppData(response);

    setIsLoading(false);
  };

  const handleMenuClick = (beanId, menuId) => {
    setSelectedBean(beanId);
    setSelectedMenu(menuId);

    const beanFilteredData = appData.appDataSets.find((dataSet) => dataSet.beanId === beanId);
    const filteredData = beanFilteredData.menus.find((menu) => menu.menuId === menuId);

    if (filteredData) {
      setSelectedData({ presets: filteredData.presets, contents: filteredData.contents });
    }
  };

  useEffect(() => {
    fetchAppData();
  }, []);

  useEffect(() => {
    if (appData.appDataSets?.length > 0 && appData.appDataSets[0].menus?.length > 0) {
      const firstDataSet = appData.appDataSets[0];
      const firstMenu = firstDataSet.menus[0];

      setSelectedBean(firstDataSet.beanId);
      setSelectedMenu(firstMenu.menuId);
      setSelectedData({
        presets: firstMenu.presets,
        contents: firstMenu.contents,
      });
    }
  }, [appData]);

  return (
    <>
      <AdminFrame title="앱 데이터 관리">
        <div className="flex justify-between">
          <div className="flex ml-6 mt-6 items-center">
            <div className="ml-3 text-xl font-bold text-gray-800">앱 데이터 관리</div>
          </div>
          <div className="mt-6 mr-10">
            {canWritePage() && (
              <button
                type="button"
                className="w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => {
                  setTimeout(() => {
                    navigate("/app-data/edit", {
                      state: {
                        appData: appData,
                      },
                    });
                  }, 500);
                }}
              >
                수정
              </button>
            )}
          </div>
        </div>
        <Page fullWidth>
          {isLoading ? (
            <CardSpinner />
          ) : (
            <>
              <div className="flex">
                <div className="w-7/12 h-screen overflow-y-auto mr-4 bg-white shadow sm:rounded-lg mb-5">
                  <div className="px-8 py-6 sm:px-6">
                    <div className="flex items-center space-x-2">
                      <div className="text-base font-semibold">상세 정보</div>
                      <div>
                        (최종 수정자 : {appData.lastModifier} / 최종 수정 일시{" "}
                        {moment(appData.updatedAt).add(9, "hour").format("YYYY-MM-DD HH:mm:ss")})
                      </div>
                    </div>
                    {appData.appDataSets.map((appDataSet, index) => (
                      <div key={`beans-${index}`} className="flex flex-col h-full my-6">
                        <div className="w-full h-full mr-4 bg-white shadow sm:rounded-lg">
                          <div className="px-6 py-4 sm:px-6">
                            <div className="text-base font-semibold">{appDataSet.beanName}</div>
                            <div className="flex flex-wrap">
                              {appDataSet.menus
                                .sort((a, b) => a.menuOrder - b.menuOrder)
                                .map((menu, index) => (
                                  <button
                                    key={`menus-${index}`}
                                    className={`flex flex-col justify-center items-center w-36 h-16 mt-2 mr-4 overflow-hidden border ${
                                      selectedBean === appDataSet.beanId &&
                                      selectedMenu === menu.menuId
                                        ? "bg-gray-100 border-gray-200"
                                        : "bg-white"
                                    } rounded-lg`}
                                    onClick={() => handleMenuClick(appDataSet.beanId, menu.menuId)}
                                  >
                                    <div className="px-3 py-2 text-center">
                                      <div className="whitespace-nowrap text-sx font-normal">
                                        {menu.menuName.split("\n").map((line, index) => (
                                          <div key={`menuName-${index}`}>{line}</div>
                                        ))}
                                      </div>
                                    </div>
                                  </button>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-8/12 h-screen overflow-y-auto bg-white shadow sm:rounded-lg mb-5">
                  <div className="px-8 py-6">
                    <div className="text-base font-semibold">미리보기</div>
                    <div key={"preset"} className="relative my-8 mx-5">
                      <div className="inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-1.5 border-gray-500" />
                        <span className="bg-white px-6 text-base font-semibold leading-6 text-gray-700 absolute left-0 transform -translate-x-1/2">
                          프리셋
                        </span>
                      </div>
                    </div>
                    <div>
                      <div>{selectedData?.presets?.presetTitle}</div>
                      <div>
                        ({selectedData?.presets?.waterFirst ? "물 먼저" : "에스프레소 먼저"}, 샷{" "}
                        {selectedData?.presets?.shot}, 물{" "}
                        {selectedData?.presets?.hotWater === 0
                          ? selectedData?.presets?.coldWater
                          : selectedData?.presets?.hotWater}{" "}
                        {selectedData?.presets?.hotWater === 0 &&
                        selectedData?.presets?.coldWater === 0
                          ? "온수"
                          : selectedData?.presets?.hotWater === 0
                          ? "정수"
                          : "온수"}
                        , 원두량 {selectedData?.presets?.beanAmount}, 에스프레소량{" "}
                        {selectedData?.presets?.espresso}, 뜸들이기{" "}
                        {selectedData?.presets?.preInfusionTime / 1000}{" "}
                        {selectedData?.presets?.preInfusionTime > 0 ? "사용함" : "사용 안 함"})
                      </div>
                    </div>
                    {selectedData?.contents?.map((content, index) => (
                      <div key={`contents-${index}`}>
                        <div className="relative my-8 mx-5">
                          <div className="inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-1.5 border-gray-500" />
                            <span className="bg-white ml-1 px-6 text-base font-semibold leading-6 text-gray-700 absolute left-0 transform -translate-x-1/2">
                              콘텐츠 {index + 1}
                            </span>
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <div>{content.brewingContent.title}</div>
                          <div className="flex flex-wrap">
                            {content.brewingContent.files.map(
                              (file, index) =>
                                file.fileUrl != "" && (
                                  <div
                                    key={file.fileUrl}
                                    className="overflow-hidden w-28 h-28 mr-2 my-2 flex items-center justify-center border rounded-md border-gray-300"
                                  >
                                    {file.fileUrl.includes("mp4") ? (
                                      <video
                                        src={file.fileUrl}
                                        autoPlay
                                        muted
                                        loop
                                        className="object-contain h-28 w-28"
                                      />
                                    ) : (
                                      <img
                                        src={file.fileUrl}
                                        className="object-contain h-28 w-28"
                                      />
                                    )}
                                  </div>
                                )
                            )}
                          </div>
                          <div>{content.completeContent.title}</div>
                          <div>
                            {content.completeContent.files.map(
                              (file, index) =>
                                file.fileUrl != "" && (
                                  <div
                                    key={file.fileUrl}
                                    className="overflow-hidden w-28 h-28 mr-2 my-2 flex items-center justify-center border rounded-md border-gray-300"
                                  >
                                    {file.fileUrl.includes("mp4") ? (
                                      <video
                                        src={file.fileUrl}
                                        autoPlay
                                        muted
                                        loop
                                        className="object-contain h-28 w-28"
                                      />
                                    ) : (
                                      <img
                                        src={file.fileUrl}
                                        className="object-contain h-28 w-28"
                                      />
                                    )}
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}
        </Page>
      </AdminFrame>
    </>
  );
};

export default AppDataPage;
