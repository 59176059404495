import React, { useEffect, useState, useContext } from "react";
import { useSetState } from "react-use";
import { useLocation, useNavigate } from "react-router-dom";
import { Page } from "@shopify/polaris";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";

import { createMenu, updateMenu } from "../../../rest-apis/menus";

import { UserInfoContextState } from "contexts/User";
import { toast } from "react-hot-toast";
import usePrompt from "utils/Prompt";
import AdminFrame from "components/Layout/Frame";
import UploadContent from "utils/UploadContent";
import UploadImage from "common/UploadImage";

const DefaultMenuForm = () => {
  // Path
  const navigate = useNavigate();
  const path = useLocation().pathname;

  // Menu data
  const locationState = useLocation().state?.menu;
  const [state, setState] = useSetState();
  const [isSaving, setIsSaving] = useState(false);

  // Menu input fields
  const MAX_NAME_LENGTH = 24;
  const [nameInput, setNameInput] = useState(locationState?.name || "");
  const [image, setImage] = useState(state.imageUrl);
  const { userInfo } = useContext(UserInfoContextState);

  const isValidTextInput = (text) => {
    const regex = /^[A-Za-z0-9ㄱ-ㅎㅏ-ㅣ가-힣~!@#₩$%^&*()_+=,./<>?\\| \r\n]*$/;
    return regex.test(text);
  };

  const [isPrompt, setIsPrompt] = useState(true);
  usePrompt(
    "다른 페이지로 이동하겠습니까? 이 페이지에서 변경한 내용은 저장되지 않습니다.",
    isPrompt
  );

  const uploadDefaultMenu = async () => {
    // 토스트 에러 출력
    if (!state.name) {
      toast.error("메뉴명을 입력하세요.");
      return;
    }
    if (!state.file && !state.imageUrl) {
      toast.error("이미지를 등록하세요.");
      return;
    }

    if (!window.confirm("상세 정보를 저장하시겠습니까?")) {
      return;
    }

    setIsSaving(true);

    const promise = locationState
      ? updateMenu(locationState.id, {
          name: state.name,
          imageUrl: state.file
            ? await UploadContent("menus/default", state.file.name, state.file)
            : state.imageUrl,
          status: state.status,
          lastModifier: userInfo.name,
        })
      : createMenu({
          name: state.name,
          imageUrl: state.imageUrl,
          status: state.status,
          lastModifier: userInfo.name,
        });

    try {
      const response = await promise;
      if (response.status === 200 || response.status === 201) {
        toast.success(locationState ? "수정되었습니다." : "저장되었습니다.");
        setIsPrompt(false);
        setTimeout(() => {
          navigate(locationState ? `/menus/${locationState.id}` : `/menus/${response.data.id}`);
        }, 500);
      }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "서버 오류가 존재합니다."
      );
    } finally {
      setTimeout(() => {
        setIsSaving(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (path.split("/").includes("edit") && !locationState) {
      navigate("/menus");
      return;
    }
    if (!locationState) {
      setState({
        name: "",
        imageUrl: "",
        status: "비활성화",
        lastModifier: userInfo.name,
      });
      return;
    }
    setState({
      name: locationState.name,
      imageUrl: locationState.imageUrl,
      status: locationState.status,
      lastModifier: userInfo.name,
    });
  }, []);

  useEffect(() => {
    setImage(state.imageUrl);
  }, [state.imageUrl]);

  return (
    <>
      <AdminFrame title={`메뉴 관리 ${locationState ? "수정" : "추가"}`}>
        <div className="flex justify-between">
          <div className="flex ml-6 mt-6 items-center">
            <ArrowSmallLeftIcon
              type="button"
              className="w-9 h-9 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => {
                navigate("/menus");
              }}
            />
            <div className="ml-3 text-xl font-bold text-gray-800">
              메뉴 관리 {locationState ? `수정 | ${locationState.name}` : "추가"}
            </div>
          </div>
          <div className="mt-6 mr-10">
            <button
              type="button"
              className={
                isSaving
                  ? "w-24 h-9 rounded-md bg-gray-200 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  : "w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
              onClick={() => {
                uploadDefaultMenu();
              }}
              disabled={isSaving}
            >
              {isSaving ? (
                <div className="flex">
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                  <div>저장중</div>
                </div>
              ) : (
                "저장"
              )}
            </button>
          </div>
        </div>
        <Page fullWidth>
          <div className="flex">
            <div className="w-7/12 mr-4 bg-white shadow sm:rounded-lg mb-5">
              <div className="px-8 py-6 sm:px-6">
                <div className="text-base font-semibold">상세 정보</div>
                <table className="w-full mt-5 text-left table-auto">
                  <tbody>
                    <tr key="menu_name" className="border-b border-gray-100">
                      <td className="font-medium w-1/3 py-4 align-top">메뉴명</td>
                      <td className="relative py-4">
                        <textarea
                          rows={2}
                          value={nameInput}
                          maxLength={MAX_NAME_LENGTH}
                          onChange={(e) => {
                            if (
                              isValidTextInput(e.target.value) &&
                              e.target.value.length <= MAX_NAME_LENGTH
                            ) {
                              setNameInput(e.target.value);
                              setState({ name: e.target.value });
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && e.target.value.split("\n").length >= 2) {
                              e.preventDefault();
                            }
                          }}
                          onBlur={(e) => {
                            const trimmedValue = e.target.value.replace(/\s+$/, "");
                            setNameInput(trimmedValue);
                            setState({ ...state, name: trimmedValue });
                          }}
                          className="block w-full rounded-md border-0 py-1.5 pl-2 pr-8 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="메뉴명을 입력하세요."
                        />
                        <div className="absolute mr-3 right-0 bottom-3 transform -translate-y-1/2 text-s text-gray-500">{`${nameInput.length}/${MAX_NAME_LENGTH}`}</div>
                      </td>
                    </tr>
                    <tr key="menu_imageUrl" className="border-b border-gray-100">
                      <td className="font-medium w-1/3 py-4 align-top">
                        <div>이미지</div>
                        <div className="text-sm text-gray-400">(184*188px, png)</div>
                      </td>
                      <td className="py-4">
                        <UploadImage defaultImageUrl={state.imageUrl} setState={setState} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="w-7/12 mr-4 bg-white shadow sm:rounded-lg mb-5">
              <div className="px-8 py-6 sm:px-6">
                <div className="flex">
                  <div className="text-base font-semibold whitespace-nowrap">미리보기</div>
                  <div className="flex items-center ml-2 text-sm text-gray-400">
                    (‘미리 보기’ 화면은 참고용으로 스테이징 환경에서 테스트 후 최종 등록을
                    권장합니다.)
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <div
                    className="flex flex-col items-center rounded-lg m-4"
                    style={{
                      width: "226px",
                      height: "350px",
                      background: "var(--grey-02, #242426)",
                    }}
                  >
                    <div
                      style={{
                        width: "184px",
                        height: "188px",
                        margin: "0 16px",
                        marginTop: "32px",
                        marginBottom: "25px",
                      }}
                    >
                      <img src={image || null} />
                    </div>
                    <div
                      className="text-center text-white whitespace-pre-wrap"
                      style={{
                        margin: "0 24px",
                        // width: "168px",
                        height: "26px",
                        fontSize: "20px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "26px",
                      }}
                    >
                      {nameInput}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Page>
      </AdminFrame>
    </>
  );
};

export default DefaultMenuForm;
