import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PermissionContextState } from "../../../contexts/Permission";

import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";

import AdminFrame from "components/Layout/Frame";
import CardSpinner from "common/PageSpinner/Card";
import { Page } from "@shopify/polaris";
import { initializePassword, userDetail } from "../../../rest-apis/user";
import { translateRole } from "../type/roleType";
import toast from "react-hot-toast";

const UserDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const userName = localStorage.getItem("name");
  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "USER" && p.canWrite);
  };

  const [user, setUser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleInitializePasswordButton = () => {
    if (
      window.confirm("사용자 비밀번호를 초기화하시겠습니까?\n비밀번호는 blmp0000으로 초기화됩니다.")
    ) {
      const request = {
        lastModifier: userName,
      };
      initializePassword(id, request)
        .then(() => {
          toast.success("초기화되었습니다.");
        })
        .catch((error) => {
          toast.error("초기화에 실패했습니다.");
        });
    } else {
      return;
    }
  };

  const fetchUser = async (id) => {
    setIsLoading(true);

    try {
      const response = await userDetail(id);
      if (response.status === 200) {
        setUser(response.data);
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        if (status === 400 || status === 404 || status === 500) {
          navigate("/not-found");
        }
      }
    } finally {
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchUser(id);
  }, [id]);

  return (
    <>
      <AdminFrame title="사용자 관리 조회">
        {isLoading ? (
          <CardSpinner />
        ) : (
          <>
            <div className="flex justify-between">
              <div className="flex ml-6 mt-6 items-center">
                <ArrowSmallLeftIcon
                  type="button"
                  className="w-9 h-9 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => {
                    navigate("/users");
                  }}
                />
                <div className="ml-3 text-xl font-bold text-gray-800">
                  사용자 관리 조회 | {user.name}
                </div>
              </div>
              <div className="mt-6 mr-10">
                {canWritePage() && (
                  <button
                    type="button"
                    className="w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => {
                      setTimeout(() => {
                        navigate(`/users/${id}/edit`, {
                          state: {
                            user: user,
                          },
                        });
                      }, 500);
                    }}
                  >
                    수정
                  </button>
                )}
              </div>
            </div>
            <Page fullWidth>
              <div className="flex">
                <div className="w-full mr-4 overflow-hidden bg-white shadow sm:rounded-lg mb-5">
                  <div className="px-8 py-6 sm:px-6">
                    <div className="text-base font-semibold">상세 정보</div>
                    <table className="w-full mt-5 text-left table-auto">
                      <tbody>
                        <tr key={`${user.id}-name`} className="border-b border-gray-100">
                          <td className="font-medium w-1/3 py-4 align-top">이름</td>
                          <td className="whitespace-pre-wrap">{user.name}</td>
                        </tr>
                        <tr key={`${user.id}-email`} className="border-b border-gray-100">
                          <td className="font-medium w-1/3 py-4 align-top">이메일</td>
                          <td className="whitespace-pre-wrap">{user.email}</td>
                        </tr>
                        <tr key={`${user.id}-role`} className="border-b border-gray-100">
                          <td className="font-medium w-1/3 py-4 align-top">권한</td>
                          <td className="whitespace-pre-wrap">{translateRole(user.role)}</td>
                        </tr>
                        <tr key={`${user.id}-password`} className="border-b border-gray-100">
                          <td className="font-medium w-1/3 py-4 align-top">비밀번호 초기화</td>
                          <td>
                            <button
                              type="button"
                              className="w-auto h-9 rounded-md bg-white px-4 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                              onClick={handleInitializePasswordButton}
                            >
                              비밀번호 초기화
                            </button>
                          </td>
                        </tr>
                        <tr key={`${user.id}-memo`} className="border-b border-gray-100">
                          <td className="font-medium w-1/3 py-4 align-top">
                            <span className="font-medium mr-1">메모</span>
                            <span className="text-sm font-normal text-gray-400">(선택)</span>
                          </td>

                          <td className="whitespace-pre-wrap">{user.memo}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Page>
          </>
        )}
      </AdminFrame>
    </>
  );
};

export default UserDetailPage;
