export const beanCategoryFormatter = (category) => {
  return category === "BROWNBAG"
    ? "브라운백 원두"
    : category === "OTHERS"
    ? "브랜드 원두"
    : category === "ETC"
    ? "그 외 원두"
    : category === "ACCOUNT_PRIVATE"
    ? "회사 전용 원두(계정)"
    : category === "GROUP_PRIVATE"
    ? "전용 원두"
    : "_";
};
