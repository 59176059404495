import * as echarts from "echarts";

var colorPalette = [
  "#2ec7c9",
  "#b6a2de",
  "#5ab1ef",
  "#ffb980",
  "#d87a80",
  "#8d98b3",
  "#e5cf0d",
  "#97b552",
  "#95706d",
  "#dc69aa",
  "#07a2a4",
  "#9a7fd1",
  "#588dd5",
  "#f5994e",
  "#c05050",
  "#59678c",
  "#c9ab00",
  "#7eb00a",
  "#6f5553",
  "#c14089",
];

echarts.registerTheme("macarons", {
  color: colorPalette,

  title: {
    textStyle: {
      fontWeight: "normal",
      color: "#008acd",
    },
  },

  visualMap: {
    itemWidth: 15,
    color: ["#5ab1ef", "#e0ffff"],
  },

  toolbox: {
    iconStyle: {
      borderColor: colorPalette[0],
    },
  },

  tooltip: {
    borderWidth: 0,
    backgroundColor: "rgba(50,50,50,0.5)",
    textStyle: {
      color: "#FFF",
    },
    axisPointer: {
      type: "line",
      lineStyle: {
        color: "#008acd",
      },
      crossStyle: {
        color: "#008acd",
      },
      shadowStyle: {
        color: "rgba(200,200,200,0.2)",
      },
    },
  },

  dataZoom: {
    dataBackgroundColor: "#efefff",
    fillerColor: "rgba(182,162,222,0.2)",
    handleColor: "#008acd",
  },

  grid: {
    borderColor: "#eee",
  },

  categoryAxis: {
    axisLine: {
      lineStyle: {
        color: "#008acd",
      },
    },
    splitLine: {
      lineStyle: {
        color: ["#eee"],
      },
    },
  },

  valueAxis: {
    axisLine: {
      lineStyle: {
        color: "#008acd",
      },
    },
    splitArea: {
      show: true,
      areaStyle: {
        color: ["rgba(250,250,250,0.1)", "rgba(200,200,200,0.1)"],
      },
    },
    splitLine: {
      lineStyle: {
        color: ["#eee"],
      },
    },
  },

  timeline: {
    lineStyle: {
      color: "#008acd",
    },
    controlStyle: {
      color: "#008acd",
      borderColor: "#008acd",
    },
    symbol: "emptyCircle",
    symbolSize: 3,
  },

  line: {
    smooth: true,
    symbol: "emptyCircle",
    symbolSize: 3,
  },

  candlestick: {
    itemStyle: {
      color: "#d87a80",
      color0: "#2ec7c9",
    },
    lineStyle: {
      width: 1,
      color: "#d87a80",
      color0: "#2ec7c9",
    },
    areaStyle: {
      color: "#2ec7c9",
      color0: "#b6a2de",
    },
  },

  scatter: {
    symbol: "circle",
    symbolSize: 4,
  },

  map: {
    itemStyle: {
      color: "#ddd",
    },
    areaStyle: {
      color: "#fe994e",
    },
    label: {
      color: "#d87a80",
    },
  },

  graph: {
    itemStyle: {
      color: "#d87a80",
    },
    linkStyle: {
      color: "#2ec7c9",
    },
  },

  gauge: {
    axisLine: {
      lineStyle: {
        color: [
          [0.2, "#2ec7c9"],
          [0.8, "#5ab1ef"],
          [1, "#d87a80"],
        ],
        width: 10,
      },
    },
    axisTick: {
      splitNumber: 10,
      length: 15,
      lineStyle: {
        color: "auto",
      },
    },
    splitLine: {
      length: 22,
      lineStyle: {
        color: "auto",
      },
    },
    pointer: {
      width: 5,
    },
  },
});

echarts.registerTheme("westeros", {
  color: ["#516b91", "#59c4e6", "#edafda", "#93b7e3", "#a5e7f0", "#cbb0e3"],
  backgroundColor: "rgba(0,0,0,0)",
  textStyle: {},
  title: {
    textStyle: {
      color: "#516b91",
    },
    subtextStyle: {
      color: "#93b7e3",
    },
  },
  line: {
    itemStyle: {
      borderWidth: "2",
    },
    lineStyle: {
      width: "2",
    },
    symbolSize: "6",
    symbol: "emptyCircle",
    smooth: true,
  },
  radar: {
    itemStyle: {
      borderWidth: "2",
    },
    lineStyle: {
      width: "2",
    },
    symbolSize: "6",
    symbol: "emptyCircle",
    smooth: true,
  },
  bar: {
    itemStyle: {
      barBorderWidth: 0,
      barBorderColor: "#ccc",
    },
  },
  pie: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc",
    },
  },
  scatter: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc",
    },
  },
  boxplot: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc",
    },
  },
  parallel: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc",
    },
  },
  sankey: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc",
    },
  },
  funnel: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc",
    },
  },
  gauge: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc",
    },
  },
  candlestick: {
    itemStyle: {
      color: "#edafda",
      color0: "transparent",
      borderColor: "#d680bc",
      borderColor0: "#8fd3e8",
      borderWidth: "2",
    },
  },
  graph: {
    itemStyle: {
      borderWidth: 0,
      borderColor: "#ccc",
    },
    lineStyle: {
      width: 1,
      color: "#aaaaaa",
    },
    symbolSize: "6",
    symbol: "emptyCircle",
    smooth: true,
    color: ["#516b91", "#59c4e6", "#edafda", "#93b7e3", "#a5e7f0", "#cbb0e3"],
    label: {
      color: "#eeeeee",
    },
  },
  map: {
    itemStyle: {
      areaColor: "#f3f3f3",
      borderColor: "#516b91",
      borderWidth: 0.5,
    },
    label: {
      color: "#000",
    },
    emphasis: {
      itemStyle: {
        areaColor: "#a5e7f0",
        borderColor: "#516b91",
        borderWidth: 1,
      },
      label: {
        color: "#516b91",
      },
    },
  },
  geo: {
    itemStyle: {
      areaColor: "#f3f3f3",
      borderColor: "#516b91",
      borderWidth: 0.5,
    },
    label: {
      color: "#000",
    },
    emphasis: {
      itemStyle: {
        areaColor: "#a5e7f0",
        borderColor: "#516b91",
        borderWidth: 1,
      },
      label: {
        color: "#516b91",
      },
    },
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#cccccc",
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: "#333",
      },
    },
    axisLabel: {
      show: true,
      color: "#999999",
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["#eeeeee"],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
      },
    },
  },
  valueAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#cccccc",
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: "#333",
      },
    },
    axisLabel: {
      show: true,
      color: "#999999",
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["#eeeeee"],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
      },
    },
  },
  logAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#cccccc",
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: "#333",
      },
    },
    axisLabel: {
      show: true,
      color: "#999999",
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["#eeeeee"],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
      },
    },
  },
  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: "#cccccc",
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: "#333",
      },
    },
    axisLabel: {
      show: true,
      color: "#999999",
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ["#eeeeee"],
      },
    },
    splitArea: {
      show: false,
      areaStyle: {
        color: ["rgba(250,250,250,0.05)", "rgba(200,200,200,0.02)"],
      },
    },
  },
  toolbox: {
    iconStyle: {
      borderColor: "#999999",
    },
    emphasis: {
      iconStyle: {
        borderColor: "#666666",
      },
    },
  },
  legend: {
    textStyle: {
      color: "#999999",
    },
  },
  tooltip: {
    axisPointer: {
      lineStyle: {
        color: "#cccccc",
        width: 1,
      },
      crossStyle: {
        color: "#cccccc",
        width: 1,
      },
    },
  },
  timeline: {
    lineStyle: {
      color: "#8fd3e8",
      width: 1,
    },
    itemStyle: {
      color: "#8fd3e8",
      borderWidth: 1,
    },
    controlStyle: {
      color: "#8fd3e8",
      borderColor: "#8fd3e8",
      borderWidth: 0.5,
    },
    checkpointStyle: {
      color: "#8fd3e8",
      borderColor: "#8a7ca8",
    },
    label: {
      color: "#8fd3e8",
    },
    emphasis: {
      itemStyle: {
        color: "#8fd3e8",
      },
      controlStyle: {
        color: "#8fd3e8",
        borderColor: "#8fd3e8",
        borderWidth: 0.5,
      },
      label: {
        color: "#8fd3e8",
      },
    },
  },
  visualMap: {
    color: ["#516b91", "#59c4e6", "#a5e7f0"],
  },
  dataZoom: {
    backgroundColor: "rgba(0,0,0,0)",
    dataBackgroundColor: "rgba(255,255,255,0.3)",
    fillerColor: "rgba(167,183,204,0.4)",
    handleColor: "#a7b7cc",
    handleSize: "100%",
    textStyle: {
      color: "#333",
    },
  },
  markPoint: {
    label: {
      color: "#eeeeee",
    },
    emphasis: {
      label: {
        color: "#eeeeee",
      },
    },
  },
});
