import React, { createContext, useState } from "react";

const UserLevelContext = createContext({
  userLevel: [],
});

const UserLevelProvider = ({ children }) => {
  const [userLevel, setUserLevel] = useState([]);

  return (
    <UserLevelContext.Provider value={{ userLevel, setUserLevel }}>
      {children}
    </UserLevelContext.Provider>
  );
};

export { UserLevelProvider, UserLevelContext };
