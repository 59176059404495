import React, { useState } from "react";

import UploadBanner from "common/UploadImage/content/banner";

const Home1Form = ({ home1State, setHome1State }) => {
  const MAX_TOGGLE_NAME_LENGTH = 5;
  const MAX_MAIN_DESCRIPTION_LENGTH = 30;

  const [toggleNameInput, setToggleNameInput] = useState(
    home1State.toggleName ? home1State.toggleName : ""
  );
  const [mainDescriptionInput, setMainDescriptionInput] = useState(
    home1State.mainDescription ? home1State.mainDescription : ""
  );

  const isValidTextInput = (text) => {
    const regex = /^[A-Za-z0-9ㄱ-ㅎㅏ-ㅣ가-힣~!@#₩$%^&*()\-_+=,./<>?\\|\n ]*$/;
    return regex.test(text);
  };

  const handleToggleInput = (e) => {
    const newValue = e.target.value;
    if (newValue.length > MAX_TOGGLE_NAME_LENGTH) {
      setToggleNameInput(newValue.slice(0, MAX_TOGGLE_NAME_LENGTH));
    } else if (isValidTextInput(newValue)) {
      setToggleNameInput(newValue);
      setHome1State({ ...home1State, toggleName: newValue });
    }
  };

  const handleMainDescriptionInput = (e) => {
    const newValue = e.target.value;
    if (newValue.length > MAX_MAIN_DESCRIPTION_LENGTH) {
      setMainDescriptionInput(newValue.slice(0, MAX_MAIN_DESCRIPTION_LENGTH));
    } else if (isValidTextInput(newValue)) {
      setMainDescriptionInput(newValue);
      setHome1State({ ...home1State, mainDescription: newValue });
    }
  };

  return (
    <>
      <div className="flex flex-col h-full px-4 my-6">
        <div className="w-full h-full mr-4 overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-8 py-6 sm:px-6">
            <div className="text-base font-semibold">기본 화면 (1)</div>
            <table className="w-full mt-5 text-left table-auto">
              <tbody>
                <tr key={"currentBeanName"} className="border-b border-gray-100">
                  <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-center">
                    토글명
                  </td>
                  <td className="relative py-4">
                    <input
                      value={toggleNameInput}
                      maxLength={MAX_TOGGLE_NAME_LENGTH}
                      onChange={handleToggleInput}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.replace(/\s+$/, "");
                        setToggleNameInput(trimmedValue);
                        setHome1State({ ...home1State, toggleName: trimmedValue });
                      }}
                      className="block w-1/2 rounded-md border-0 py-1.5 pl-2 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset focus:ring-indigo-600 placeholder:text-gray-400"
                    />
                    <div className="absolute mr-3 right-1/2 top-1/2 transform -translate-y-1/2 text-s text-gray-500">
                      {`${
                        home1State.toggleName
                          ? home1State.toggleName.length
                          : toggleNameInput.length
                      }/${MAX_TOGGLE_NAME_LENGTH}`}
                    </div>
                  </td>
                </tr>
                <tr key={"menus"} className="border-b border-gray-100">
                  <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                    문구
                  </td>
                  <td className="relative py-4">
                    <textarea
                      rows={2}
                      value={mainDescriptionInput}
                      maxLength={MAX_MAIN_DESCRIPTION_LENGTH}
                      onChange={handleMainDescriptionInput}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && e.target.value.split("\n").length >= 2) {
                          e.preventDefault();
                        }
                      }}
                      onBlur={(e) => {
                        const trimmedValue = e.target.value.replace(/\s+$/, "");
                        setMainDescriptionInput(trimmedValue);
                        setHome1State({ ...home1State, mainDescription: trimmedValue });
                      }}
                      className="block w-1/2 rounded-md border-0 py-1.5 pl-2 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset focus:ring-indigo-600 placeholder:text-gray-400"
                    />
                    <div className="absolute right-1/2 bottom-6 mt-2 mr-2 text-sm text-gray-500">
                      {`${
                        home1State.mainDescription
                          ? home1State.mainDescription.length
                          : mainDescriptionInput.length
                      }/${MAX_MAIN_DESCRIPTION_LENGTH}`}
                    </div>
                  </td>
                </tr>
                <tr key={"appVersion"} className="border-b border-gray-100">
                  <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                    <div>화면 1 배너</div>
                    <div className="text-sm text-gray-400">(718*299px, png)</div>
                  </td>
                  <td className="py-4 text-gray-700 align-top">
                    <UploadBanner
                      key="0"
                      defaultImageUrl={home1State.bannerImageUrl}
                      setHome1State={setHome1State}
                      home1State={home1State}
                      type="home1"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home1Form;
