import API from "./api";

export const getAppDataDetail = async () => {
  const response = await API.get("/appData");
  return response.data;
};

export const getSelectList = async () => {
  const response = await API.get("/appData/select");
  return response.data;
};

export const updateAppData = async (appData) => {
  const response = await API.patch("/appData", appData);
  return response;
};

export const updatePresetAppData = async (presetAppData) => {
  const response = await API.patch("/appData/presets", presetAppData);
  return response;
};
