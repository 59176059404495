import React from "react";
import { Layout, Page } from "@shopify/polaris";

import AdminFrame from "components/Layout/Frame";
import AccountsList from "./List";

const AccountPage = () => {
  return (
    <>
      <AdminFrame title="계정 관리">
        <Page fullWidth title="계정 관리">
          <>
            <Layout>
              <Layout.Section>
                <AccountsList />
              </Layout.Section>
            </Layout>
          </>
        </Page>
      </AdminFrame>
    </>
  );
};

export default AccountPage;
