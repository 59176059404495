import React, { useState, useEffect, useRef } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

const PresetDropdown = ({
  beanId,
  menuId,
  presetList,
  presetSearchInput,
  setPresetSearchInput,
  selectedPresets,
  setSelectedPresets,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownMenuRef = useRef(null);

  const selectedPreset = selectedPresets.find(
    (preset) => preset.beanId === beanId && preset.menuId === menuId
  );

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setPresetSearchInput("");
  };

  const handleSearchChange = (e) => {
    setPresetSearchInput(e.target.value);
  };

  const handlePresetSelect = (presetData) => {
    setSelectedPresets((prevSelectedPresets) => [
      ...prevSelectedPresets.filter((acc) => !(acc.beanId === beanId && acc.menuId === menuId)),
      { ...presetData, beanId, menuId },
    ]);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="flex w-full">
        <div className="relative group w-full">
          <button
            onClick={toggleDropdown}
            className={`inline-flex justify-between w-96 gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset text-gray-500 bg-white ${
              selectedPreset ? " ring-gray-300 hover:bg-gray-50" : "ring-red-500"
            }`}
          >
            <span>{selectedPreset?.title || "프리셋 선택"}</span>
            <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
          {isOpen && (
            <div
              ref={dropdownMenuRef}
              className="absolute z-50 w-96 max-h-96 overflow-y-auto left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1"
            >
              <input
                className="block w-full px-2 py-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none"
                type="text"
                placeholder="검색어를 입력하세요."
                autoComplete="off"
                value={presetSearchInput}
                onChange={handleSearchChange}
              />
              {presetList
                .filter((preset) => preset.title.includes(presetSearchInput))
                .map((preset, index) => (
                  <div
                    key={index}
                    className="flex items-center px-2 py-2 hover:bg-gray-100"
                    onClick={() => handlePresetSelect(preset)}
                  >
                    <span className="text-gray-700 cursor-pointer rounded-md">{preset.title}</span>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PresetDropdown;
