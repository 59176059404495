import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";
import AdminFrame from "components/Layout/Frame";
import { Page } from "@shopify/polaris";

import { UserInfoContextState } from "contexts/User";
import { PermissionContextState } from "contexts/Permission";
import CardSpinner from "common/PageSpinner/Card";
import DeactivateBadge from "common/Badge/deactivate";

import { getNoticeDetail, updateNoticeDeactivate } from "../../../rest-apis/notices";
import PreviewComponent from "../Form/preview";
import PreviewDetailComponent from "./preview";

const NoticeDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { userInfo } = useContext(UserInfoContextState);
  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "NOTICE" && p.canWrite);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [notice, setNotice] = useState([]);
  const [previewDescription, setPreviewDescription] = useState("");

  const fetchNotice = async (id) => {
    setIsLoading(true);

    const response = await getNoticeDetail(id);
    setNotice(response);

    setIsLoading(false);
  };

  const fetchNoticeDeactivate = async () => {
    const response = await updateNoticeDeactivate(id, notice.deactivate, userInfo.name);
    return response.status;
  };

  const handleDeactivateButtonClick = () => {
    if (!confirm(`${notice.deactivate ? "활성화" : "비활성화"} 상태로 변경 하시겠습니까?`)) {
      return;
    }

    fetchNoticeDeactivate().then((newStatus) => {
      setNotice((prevNotice) => ({ ...prevNotice, deactivate: !prevNotice.deactivate }));
      toast.success(`${notice.deactivate ? "활성화" : "비활성화"} 상태로 변경되었습니다.`);
    });
  };

  useEffect(() => {
    fetchNotice(id);
  }, [id]);

  useEffect(() => {
    setPreviewDescription(notice.description);
  }, [notice]);

  return (
    <>
      <AdminFrame title="공지 관리 조회">
        {isLoading ? (
          <CardSpinner />
        ) : (
          <>
            <div className="flex justify-between">
              <div className="flex ml-6 mt-6 items-center">
                <ArrowSmallLeftIcon
                  type="button"
                  className="w-9 h-9 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => {
                    navigate("/notices");
                  }}
                />
                <div className="ml-3 text-xl font-bold text-gray-800">
                  공지 관리 조회 | {notice.title}
                </div>
                <div className="ml-3">
                  <DeactivateBadge isDeactivated={notice.deactivate} />
                </div>
              </div>
              <div className="mt-6 mr-10">
                {canWritePage() && (
                  <>
                    <button
                      type="button"
                      className="w-20 h-9 rounded-md bg-white px-2.5 py-1.5 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={handleDeactivateButtonClick}
                    >
                      {notice.deactivate ? "활성화" : "비활성화"}
                    </button>
                    <button
                      type="button"
                      className="w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => {
                        setTimeout(() => {
                          navigate(`/notices/${id}/edit`, {
                            state: {
                              notice: notice,
                            },
                          });
                        }, 500);
                      }}
                    >
                      수정
                    </button>
                  </>
                )}
              </div>
            </div>
            <Page fullWidth>
              {isLoading ? (
                <CardSpinner />
              ) : (
                <div className="flex overflow-x-auto">
                  <div className="w-8/12 mr-4 bg-white shadow sm:rounded-lg mb-5">
                    <div className="px-8 py-6 sm:px-6">
                      <div className="text-base font-semibold">상세 정보</div>
                      <table className="w-full mt-5 text-left table-auto">
                        <tbody>
                          <tr key="notice-type" className="border-b border-gray-100">
                            <td className="font-medium w-1/4 py-4 align-top">공지 종류</td>
                            <td className="py-4">{notice.type}</td>
                          </tr>
                          <tr key={`${notice?.id}-title`} className="border-b border-gray-100">
                            <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                              공지 제목
                            </td>
                            <td className="relative py-4">{notice.title}</td>
                          </tr>
                          <tr
                            key={`${notice?.id}-description`}
                            className="border-b border-gray-100"
                          >
                            <td className="w-1/5 py-4 align-top whitespace-nowrap font-medium">
                              공지 상세
                            </td>
                            <td className="relative py-4">
                              <PreviewDetailComponent previewDescription={previewDescription} />
                            </td>
                          </tr>
                          <tr key={`${notice.id}-memo`} className="border-b border-gray-100">
                            <td className="w-1/5 py-4 whitespace-nowrap align-top">
                              <span className="font-medium mr-1">메모</span>
                              <span className="text-sm font-normal text-gray-400">(선택)</span>
                            </td>
                            <td className="relative py-4 whitespace-pre-wrap">{notice.memo}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="w-6/12 bg-white shadow overflow-x-auto sm:rounded-lg mb-5">
                    <div className="px-8 py-6 sm:px-6">
                      <div className="text-base font-semibold">미리보기</div>
                      <div className="flex flex-col items-center justify-center">
                        <div
                          className="flex flex-col items-start m-4"
                          style={{
                            width: "418px",
                            height: "594px",
                            paddingBottom: "25px",
                            background: "var(--Black, #000)",
                          }}
                        >
                          <div className="w-full flex">
                            <img
                              src="/images/navigate-before.png"
                              style={{
                                position: "absolute",
                                marginLeft: "20px",
                                marginTop: "29px",
                                width: "12px",
                                height: "12px",
                              }}
                            />
                            <div
                              className="w-full flex items-center justify-center"
                              style={{
                                marginTop: "22px",
                                color: "white",
                                fontSize: "18px",
                                fontWeight: "600",
                              }}
                            >
                              공지
                            </div>
                          </div>
                          <div
                            style={{
                              marginLeft: "20px",
                              marginRight: "24px",
                              marginTop: "24px",
                              color: "white",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            {notice.title}
                          </div>
                          <div
                            style={{ marginLeft: "20px", marginTop: "18px", width: "374px" }}
                            className="border-b border-gray-700"
                          ></div>
                          <div style={{ marginBottom: "3px" }}></div>
                          <div
                            className="custom-scroll overflow-y-auto mt-4"
                            style={{ width: "374px", marginLeft: "20px" }}
                          >
                            <PreviewComponent previewDescription={previewDescription} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Page>
          </>
        )}
      </AdminFrame>
    </>
  );
};

export default NoticeDetail;
