import React from "react";

import moment from "moment/moment";
import Pagination from "common/Pagination";
import TableSpinner from "common/PageSpinner/TableBody";
import { ArrowPathIcon } from "@heroicons/react/20/solid";

const RemoteLogList = ({
  fetchRemoteLogs,
  remoteLogs,
  isLoading,
  currentPage,
  setCurrentPage,
  totalPages,
}) => {
  return (
    <div className="w-1/2 bg-white shadow sm:rounded-lg">
      <div className="px-8 py-6 sm:px-6">
        <div className="flex">
          <span className="mr-1 text-base text-gray-700 font-semibold">관리 기록</span>
          <span className="text-gray-400">(최근 30일)</span>
          <span>
            <ArrowPathIcon
              className="w-5 h-5 mx-2 text-gray-500 cursor-pointer hover:text-gray-700"
              onClick={() => fetchRemoteLogs(currentPage)}
            />
          </span>
        </div>
        <div className="mt-2 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="w-auto mx-4 divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="w-40 py-3.5 pr-3 text-left text-sm font-semibold text-gray-500"
                    >
                      관리 일시
                    </th>
                    <th
                      scope="col"
                      className="w-48 py-3.5 text-left text-sm font-semibold text-gray-500"
                    >
                      관리 사항
                    </th>
                    <th
                      scope="col"
                      className="w-32 py-3.5 text-left text-sm font-semibold text-gray-500"
                    >
                      담당자
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {isLoading ? (
                    <td colSpan={3}>
                      <TableSpinner />
                    </td>
                  ) : remoteLogs.length > 0 ? (
                    remoteLogs.map((row) => (
                      <tr key={row.remotedAt}>
                        <td className="whitespace-nowrap py-4 pr-3 text-left text-sm font-normal text-gray-500">
                          {moment(row.remotedAt).add(9, "hours").format("YYYY-MM-DD HH:mm:ss")}
                        </td>
                        <td className="whitespace-nowrap py-4 pr-3 text-left text-sm font-normal text-gray-500">
                          {row.message}
                        </td>
                        <td className="whitespace-nowrap py-4 pr-3 text-left text-sm font-normal text-gray-500">
                          {row.requester}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td
                      colSpan={3}
                      className="text-center pt-20 pb-16 text-gray-300 font-bold text-lg"
                    >
                      조회된 데이터가 없습니다.
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
    </div>
  );
};

export default RemoteLogList;
