import React, { useMemo, useRef, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import UploadContent from "utils/UploadContent";

const Editor = (props) => {
  const quillRef = useRef();
  const [editorHtml, setEditorHtml] = useState(
    props.type === "notice"
      ? props.noticeState.description || ""
      : props.type === "faq"
      ? props.faqState.answer || ""
      : props.type === "guide"
      ? props.guideState.description || ""
      : ""
  );

  if (props.type === "notice") {
    useEffect(() => {
      setEditorHtml(props.noticeState.description || "");
    }, [props.noticeState.description]);
  } else if (props.type === "faq") {
    useEffect(() => {
      setEditorHtml(props.faqState.answer || "");
    }, [props.faqState.answer]);
  } else if (props.type === "guide") {
    useEffect(() => {
      setEditorHtml(props.guideState.description || "");
    }, [props.guideState.description]);
  }

  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];

      if (file && quillRef.current) {
        if (props.type === "notice") {
          const uploadedUrl = await UploadContent("notice", file.name, file);
          const editor = quillRef.current.getEditor();
          const range = editor.getSelection();
          const index = range ? range.index : 0;
          editor.insertEmbed(index, "image", uploadedUrl);
        } else if (props.type === "faq") {
          const uploadedUrl = await UploadContent("faq", file.name, file);
          const editor = quillRef.current.getEditor();
          const range = editor.getSelection();
          const index = range ? range.index : 0;
          editor.insertEmbed(index, "image", uploadedUrl);
        } else if (props.type === "guide") {
          const uploadedUrl = await UploadContent("guides", file.name, file);
          const editor = quillRef.current.getEditor();
          const range = editor.getSelection();
          const index = range ? range.index : 0;
          editor.insertEmbed(index, "image", uploadedUrl);
        }
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ align: null }, { align: "center" }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
          ["image"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  const formats = [
    "align",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "list",
    "ordered",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const handleEditorChange = (html) => {
    setEditorHtml(html);
    if (props.type === "notice") {
      props.setNoticeState({ ...props.noticeState, description: html });
    } else if (props.type === "faq") {
      props.setFaqState({ ...props.faqState, answer: html });
    } else if (props.type === "guide") {
      props.setGuideState({ ...props.guideState, description: html });
    }
  };

  return (
    <div style={{ height: "450px" }}>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={editorHtml}
        onChange={handleEditorChange}
        modules={modules}
        formats={formats}
        style={{ height: "400px" }}
      />
    </div>
  );
};

export default Editor;
