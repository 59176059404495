export const translatePage = (page) => {
  const pageTranslations = {
    ACCOUNT: "계정 관리",
    GROUP_ACCOUNT: "그룹 계정 관리",
    DEVICE: "머신 관리",
    APP_DATA: "앱 데이터 관리",
    BEAN: "원두 관리",
    MENU: "메뉴 관리",
    PRESET: "프리셋 관리",
    CONTENT: "콘텐츠 관리",
    HOME_SETTING: "기본 화면 관리",
    NOTICE: "공지사항 관리",
    GUIDE: "사용 설명 보기",
    FAQ: "자주 묻는 질문",
    USER: "사용자 관리",
  };

  return pageTranslations[page] || page;
};
