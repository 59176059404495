import React, { useContext } from "react";
import { PermissionContextState } from "contexts/Permission";

import moment from "moment/moment";
import { deleteAppVersion } from "../../../rest-apis/appVersions";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const AppVersionList = ({
  rows,
  setIsOpen,
  setAppVersion,
  setIsUpdate,
  setUpdateAppId,
  fetchAppVersion,
  currentPage,
}) => {
  const userName = localStorage.getItem("name");
  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "APP_VERSION" && p.canWrite);
  };

  const handleDeleteButton = async (id) => {
    if (!window.confirm("삭제하시겠습니까? 삭제 후 원복 불가합니다.")) {
      return;
    }

    const promise = deleteAppVersion(id, userName);

    try {
      const response = await promise;

      if (response.status === 200) {
        toast.success("삭제되었습니다.");
        fetchAppVersion(currentPage);
      }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "서버 오류가 존재합니다."
      );
    }
  };

  return (
    <div className="mt-5 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="w-1/12 py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-500 sm:pl-6"
                  >
                    NO
                  </th>
                  <th
                    scope="col"
                    className="w-2/12 whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    버전
                  </th>
                  <th
                    scope="col"
                    className="w-1/12 whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    빌드 넘버
                  </th>
                  <th
                    scope="col"
                    className="w-1/12 whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    앱 설치수
                  </th>
                  <th
                    scope="col"
                    className="w-1/12 whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    크기
                  </th>
                  <th
                    scope="col"
                    className="w-1/12 whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    메모
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    등록자
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    등록 일시
                  </th>
                  {canWritePage() && (
                    <>
                      <th
                        scope="col"
                        className="w-1/12 px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                      >
                        수정
                      </th>
                      <th
                        scope="col"
                        className="w-1/12 px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                      >
                        삭제
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {rows.length > 0 ? (
                  rows.map((row) => (
                    <tr key={row.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
                        {row.no}
                      </td>
                      <td className="whitespace-nowrap w-1/12 px-3 py-4 text-sm text-gray-500">
                        <Link
                          to={{
                            pathname: String(row.id),
                          }}
                          key={row.id}
                          className="text-indigo-600 underline"
                        >
                          {row.version}
                        </Link>
                      </td>
                      <td className="whitespace-nowrap w-2/12 px-3 py-4 text-sm text-gray-500">
                        {row.buildNumber}
                      </td>
                      <td className="whitespace-nowrap w-2/12 px-3 py-4 text-sm text-gray-500">
                        {row.installCount}
                      </td>
                      <td className="whitespace-nowrap w-2/12 px-3 py-4 text-sm text-gray-500">
                        {`${parseFloat((row.size * 0.000001).toFixed(2))} MB`}
                      </td>
                      <td className="whitespace-nowrap w-2/12 px-3 py-4 text-sm text-gray-500">
                        {row.memo}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {row.lastModifier}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {moment(row.createdAt).add(9, "hour").format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      {canWritePage() && (
                        <>
                          <td className="relative whitespace-nowrap px-3 py-4 text-left text-sm font-medium sm:pr-6">
                            <button
                              className="text-indigo-600 hover:text-indigo-900"
                              onClick={() => {
                                setIsUpdate(true);
                                setIsOpen(true);
                                setUpdateAppId(row.id);
                                setAppVersion({
                                  fileUrl: "",
                                  checksum: "",
                                  version: row.version,
                                  buildNumber: row.buildNumber,
                                  size: row.size,
                                  packageName: "",
                                  memo: row.memo,
                                  lastModifier: row.lastModifier,
                                });
                              }}
                            >
                              수정
                            </button>
                          </td>
                          <td className="relative whitespace-nowrap px-3 py-4 text-left text-sm font-medium sm:pr-6">
                            <button
                              className={`${
                                row.installCount > 0
                                  ? "text-gray-400 cursor-not-allowed"
                                  : "text-red-600 hover:text-red-900"
                              }`}
                              disabled={row.installCount > 0}
                              onClick={() => {
                                handleDeleteButton(row.id);
                              }}
                            >
                              삭제
                            </button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))
                ) : (
                  <td
                    colSpan={10}
                    className="text-center pt-20 pb-16 text-gray-300 font-bold text-lg"
                  >
                    조회된 데이터가 없습니다.
                  </td>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppVersionList;
