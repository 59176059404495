import React, { useState, useEffect, useRef } from "react";

const CompleteContentDropdown = ({
  validateData,
  beanId,
  menuId,
  contentIndex,
  completeContentList,
  completeContentSearchInput,
  setCompleteContentSearchInput,
  selectedCompleteContents,
  setSelectedCompleteContents,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownMenuRef = useRef(null);

  const zIndexBase = 1000;
  const zIndexStep = 10;

  const selectedCompleteContent = selectedCompleteContents.find(
    (content) => content.beanId === beanId && content.menuId === menuId
  );

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setCompleteContentSearchInput("");
  };

  const handleSearchChange = (e) => {
    setCompleteContentSearchInput(e.target.value);
  };

  const handleContentSelect = (completeContentId) => {
    setSelectedCompleteContents((prevSelectedContents) =>
      prevSelectedContents.map((content) => {
        if (content.beanId === beanId && content.menuId === menuId) {
          return {
            ...content,
            contentSetIds: content.contentSetIds.map((contentSet, index) =>
              index === contentIndex
                ? {
                    ...contentSet,
                    completeContentId: completeContentId,
                    completeContentTitle: completeContentList.find(
                      (content) => content.id === completeContentId
                    ).title,
                    completeContentFiles: completeContentList.find(
                      (content) => content.id === completeContentId
                    ).files,
                  }
                : contentSet
            ),
          };
        }

        return content;
      })
    );

    setIsOpen(false);
    validateData();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="flex w-full my-2" style={{ zIndex: zIndexBase - zIndexStep * contentIndex }}>
        <div className="relative group w-full">
          <button
            onClick={toggleDropdown}
            className="inline-flex justify-between w-2/3 px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
          >
            <span className="mr-2">
              {selectedCompleteContent?.contentSetIds[contentIndex]?.completeContentTitle
                ? selectedCompleteContent.contentSetIds[contentIndex].completeContentTitle
                : "추출 완료 콘텐츠 선택"}
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 ml-2 -mr-1 text-gray-500"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {isOpen && (
            <div
              ref={dropdownMenuRef}
              className="absolute w-2/3 max-h-96 overflow-y-auto left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1"
              style={{ zIndex: zIndexBase - zIndexStep * contentIndex - 1 }}
            >
              <input
                className="block w-full px-2 py-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none"
                type="text"
                placeholder="검색어를 입력하세요."
                autoComplete="off"
                value={completeContentSearchInput}
                onChange={handleSearchChange}
              />
              {completeContentList
                .filter((content) => content?.title?.includes(completeContentSearchInput))
                .map((content) => (
                  <div
                    key={`complete-${content.id}`}
                    className="flex items-center px-2 py-2 hover:bg-gray-100"
                    onClick={() => handleContentSelect(content.id)}
                  >
                    <span className="text-gray-700 cursor-pointer rounded-md">{content.title}</span>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-wrap">
        {selectedCompleteContent?.contentSetIds[contentIndex]?.completeContentFiles?.map(
          (file, index) =>
            file.fileUrl != "" && (
              <div
                key={file.fileUrl}
                className="overflow-hidden w-28 h-28 mr-2 my-2 flex items-center justify-center border rounded-md border-gray-300"
              >
                {file.fileUrl.includes("mp4") ? (
                  <video
                    src={file.fileUrl}
                    autoPlay
                    muted
                    loop
                    className="object-contain h-28 w-28"
                  />
                ) : (
                  <img src={file.fileUrl} className="object-contain h-28 w-28" />
                )}
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default CompleteContentDropdown;
