import React, { useState, useEffect, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { getGroupAccounts } from "../../../rest-apis/groupAccounts";
import { getCupNotes } from "../../../rest-apis/cupNotes";
import ErrorMessage from "common/Badge/errorMessage";

const categories = [
  { id: 1, label: "브라운백 원두", value: "BROWNBAG" },
  { id: 2, label: "브랜드 원두", value: "OTHERS" },
  { id: 3, label: "그 외 원두", value: "ETC" },
];
const particles = [
  {
    id: 1,
    name: "는",
  },
  {
    id: 2,
    name: "은",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const BeanFormDetail = ({ beanState, setBeanState, brewingInfoError, setBrewingInfoError }) => {
  function getLatestErrorMessage() {
    let latestTimestamp = 0;
    let latestMessage = "";

    ["beanAmounts", "espressoAmounts"].forEach((field) => {
      brewingInfoError[field].forEach((err) => {
        if (err && err.timestamp > latestTimestamp) {
          latestTimestamp = err.timestamp;
          latestMessage = err.message;
        }
      });
    });

    return latestMessage;
  }

  function hasErrorMessage() {
    return ["beanAmounts", "espressoAmounts"].some((field) =>
      brewingInfoError[field].some((err) => err !== undefined)
    );
  }

  const MAX_NAME_LENGTH = 17;
  const MAX_BASIC_DESCRIPTION_LENGTH = 24;

  const [nameInput, setNameInput] = useState(beanState.name ? beanState.name : "");

  const [groupAccounts, setGroupAccounts] = useState([]);
  const [showAccountSelectBox, setShowAccountSelectBox] = useState(false);

  const [selectedParticle, setSelectedParticle] = useState("는");
  const [basicDescription, setBasicDescription] = useState(beanState.basicDescription || "");

  const [cupNotes, setCupNotes] = useState([]);
  const [etcCupNote, setEtcCupNote] = useState([]);
  const [selectedCupNotes, setSelectedCupNotes] = useState([
    {
      id: null,
      name: "컵노트1 선택",
    },
    {
      id: null,
      name: "컵노트2 선택",
    },
    {
      id: null,
      name: "컵노트3 선택",
    },
  ]);

  const isValidTextInput = (text) => {
    const regex = /^[A-Za-z0-9ㄱ-ㅎㅏ-ㅣ가-힣~!@#₩$%^&*()_+=,./<>?\\| \r\n]*$/;
    return regex.test(text);
  };

  useEffect(() => {
    if (beanState.cupNoteIds && beanState.cupNoteIds.length > 0) {
      setSelectedCupNotes(
        beanState.cupNoteIds.map((id, index) => {
          const matchingNote = cupNotes.find((c) => c.id === id);
          const matchingEtcNote = etcCupNote.find((c) => c.id === id);
          return {
            id: id,
            name: matchingNote
              ? matchingNote.name
              : matchingEtcNote
              ? matchingEtcNote.name
              : `컵노트${index + 1} 선택`,
          };
        })
      );
    }
  }, [beanState.cupNoteIds, cupNotes, etcCupNote]);

  const handleBrewingInfoInputChange = (e, index, field) => {
    let value = e.target.value;

    const regex = /^[0-9]*$/;
    if (!regex.test(value)) {
      e.target.value = value.replace(/[^0-9]/g, "");
      return;
    }

    const newBrewingInfoList = { ...beanState.brewingInfo };
    newBrewingInfoList[field][index] = value === "" ? "" : Number(value);

    const newError = { ...brewingInfoError };
    const errorMessage = validateField(field, value);

    if (errorMessage) {
      newError[field][index] = { message: errorMessage, timestamp: Date.now() };
    } else {
      delete newError[field][index];
    }

    setBeanState({ ...beanState, brewingInfo: newBrewingInfoList });
    setBrewingInfoError(newError);
  };

  const validateField = (field, value) => {
    switch (field) {
      case "beanAmounts":
        if (value < 1 || value > 20) return "원두량은 1~20 범위로 입력하세요.";
        break;
      case "espressoAmounts":
        if (value < 20 || value > 80) return "에스프레소량은 20~80 범위로 입력하세요.";
        break;
      default:
        return null;
    }
  };

  const handleCategoryRadioChange = (event) => {
    setBeanState({
      ...beanState,
      category: event.target.value,
      isPublic: Number(event.target.dataset.id) !== 4,
      groupAccountId: null,
    });
  };

  const handleGroupAccountClick = (groupAccount) => {
    setBeanState({ ...beanState, isPublic: false, groupAccountId: groupAccount.id });
  };

  const handleParticleClick = (particle) => {
    setSelectedParticle(particle);
    setBeanState({ ...beanState, basicDescriptionParticle: particle });
  };

  const handleCupNoteClick = (index, cupNote) => {
    if (cupNote === null) {
      setSelectedCupNotes((prev) => {
        const newCupNotes = [...prev];
        newCupNotes[index] = { id: null, name: `컵노트${index + 1} 선택` };
        return newCupNotes;
      });
      setBeanState((prevState) => {
        const newCupNoteIds = [...prevState.cupNoteIds];
        newCupNoteIds[index] = null;
        return { ...prevState, cupNoteIds: newCupNoteIds };
      });
      return;
    }

    setSelectedCupNotes((prev) => {
      const newCupNotes = [...prev];
      newCupNotes[index] = cupNote;
      return newCupNotes;
    });
    setBeanState((prevState) => {
      const newCupNoteIds = [...prevState.cupNoteIds];
      newCupNoteIds[index] = cupNote.id;
      return { ...prevState, cupNoteIds: newCupNoteIds };
    });
  };

  useEffect(() => {
    if (beanState.category === "GROUP_PRIVATE") {
      setShowAccountSelectBox(true);
    } else {
      setShowAccountSelectBox(false);
    }
  }, [beanState.category]);

  const fetchCupNotes = async () => {
    const response = await getCupNotes();
    const filteredCupNotes = response.filter(
      (cupNote) => !["연한 황갈색", "밤색", "연한 갈색", "갈색", "진한 갈색"].includes(cupNote.name)
    );
    const etcCupNotes = response.filter((cupNote) =>
      ["연한 갈색", "연한 황갈색", "밤색", "갈색", "진한 갈색"].includes(cupNote.name)
    );

    setCupNotes(filteredCupNotes);
    setEtcCupNote(etcCupNotes);
  };

  const fetchGroupAccounts = async () => {
    const response = await getGroupAccounts();
    setGroupAccounts(response);
  };

  useEffect(() => {
    fetchGroupAccounts();
    fetchCupNotes();
  }, []);

  useEffect(() => {
    setBasicDescription(beanState.basicDescription || "");
  }, [beanState.basicDescription]);

  return (
    <div className="w-full bg-white shadow sm:rounded-lg mb-5">
      <div className="px-8 py-6 sm:px-6">
        <div className="text-base font-semibold">상세 정보</div>
        <table className="w-full mt-5 text-left table-auto">
          <tbody>
            <tr key={"name"} className="border-b border-gray-100">
              <td className="font-medium w-1/4 py-4 whitespace-nowrap align-top">원두명</td>
              <td className="relative py-4">
                <textarea
                  rows={2}
                  value={beanState.name ? beanState.name : ""}
                  maxLength={MAX_NAME_LENGTH}
                  onChange={(e) => {
                    if (
                      isValidTextInput(e.target.value) &&
                      e.target.value.length <= MAX_NAME_LENGTH
                    ) {
                      setNameInput(e.target.value);
                      setBeanState({ ...beanState, name: e.target.value });
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.target.value.split("\n").length >= 2) {
                      e.preventDefault();
                    }
                    2;
                  }}
                  onBlur={(e) => {
                    const trimmedValue = e.target.value.replace(/\s+$/, "");
                    setNameInput(trimmedValue);
                    setBeanState({ ...beanState, name: trimmedValue });
                  }}
                  className={
                    "block w-1/2 rounded-md border-0 py-1.5 pl-2 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset focus:ring-indigo-600 placeholder:text-gray-400"
                  }
                  placeholder="브랜드와 원두명을 입력하세요."
                />
                <div className="absolute mr-3 right-1/2 bottom-3 transform -translate-y-1/2 text-s text-gray-500">{`${
                  beanState.name ? beanState.name.length : nameInput.length
                }/${MAX_NAME_LENGTH}`}</div>
              </td>
            </tr>
            <tr key={"category"} className="border-b border-gray-100">
              <td className="font-medium w-1/4 py-4 whitespace-nowrap">분류</td>
              <td>
                <fieldset className="my-4">
                  <legend className="sr-only">Bean Categories</legend>
                  <div className="flex flex-wrap justify-start space-y-4  space-x-4 sm:space-y-1">
                    {categories.map((category) => (
                      <div key={`bean-category-${category.id}`} className="flex items-center">
                        <input
                          id={`bean-category-${category.id}`}
                          data-id={category.id}
                          name="bean_category"
                          value={category.value}
                          type="radio"
                          checked={category.value === beanState.category}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          onChange={handleCategoryRadioChange}
                        />
                        <label
                          htmlFor={`bean-category-${category.id}`}
                          className="ml-3 block text-sm font-medium leading-6 text-light whitespace-nowrap"
                        >
                          {category.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
                {showAccountSelectBox && (
                  <div className="flex space-x-4 mb-4">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="inline-flex justify-between w-44 gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          {beanState.groupAccountId
                            ? (
                                groupAccounts.find(
                                  (group) => group.id === beanState.groupAccountId
                                ) || {}
                              ).name
                            : "그룹 선택"}
                          <ChevronDownIcon
                            className="-mr-1 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            {groupAccounts.map((groupAccount) => (
                              <Menu.Item
                                key={groupAccount.id}
                                onClick={() => handleGroupAccountClick(groupAccount)}
                              >
                                {({ active }) => (
                                  <a
                                    className={classNames(
                                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                      "block px-4 py-2 text-sm"
                                    )}
                                  >
                                    {groupAccount.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                )}
              </td>
            </tr>
            <tr key={"customCoffeeInfo"} className="border-b border-gray-100">
              <td className="w-1/4 py-4 whitespace-nowrap align-top font-medium">
                <div>기본 화면 (2)</div>
                <div>추출 기본값</div>
              </td>
              <td className="relative py-4">
                <table>
                  <thead>
                    <tr>
                      <th className="px-3 pb-1"></th>
                      <th className="px-2 pb-1 text-center font-medium text-gray-500">적게</th>
                      <th className="px-2 pb-1 text-center font-medium text-gray-500">보통</th>
                      <th className="px-2 pb-1 text-center font-medium text-gray-500">많이</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-3 whitespace-nowrap">원두량(g)</td>
                      {Array(3)
                        .fill(0)
                        .map((_, index) => (
                          <td className="px-1 pb-1" key={index}>
                            <input
                              type="text"
                              maxLength="2"
                              value={
                                beanState.brewingInfo
                                  ? beanState.brewingInfo.beanAmounts[index] || ""
                                  : ""
                              }
                              onInput={(e) => {
                                handleBrewingInfoInputChange(e, index, "beanAmounts");
                              }}
                              className={`block w-20 rounded-md border-0 py-1.5 pl-2 pr-1 text-gray-900 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6
                                        ${
                                          brewingInfoError.beanAmounts[index]
                                            ? "ring-red-500 focus:ring-red-500"
                                            : "ring-gray-300 focus:ring-indigo-600"
                                        }`}
                            />
                          </td>
                        ))}
                    </tr>
                    <tr>
                      <td className="px-3 whitespace-nowrap">에스프레소량(ml)</td>
                      {Array(3)
                        .fill(0)
                        .map((_, index) => (
                          <td className="px-1 pb-1" key={index}>
                            <input
                              type="text"
                              maxLength="2"
                              value={
                                beanState.brewingInfo
                                  ? beanState.brewingInfo.espressoAmounts[index] || ""
                                  : ""
                              }
                              onInput={(e) => {
                                handleBrewingInfoInputChange(e, index, "espressoAmounts");
                              }}
                              className={`block w-20 rounded-md border-0 py-1.5 pl-2 pr-1 text-gray-900 shadow-sm ring-1 ring-inset sm:text-sm sm:leading-6
                                        ${
                                          brewingInfoError.espressoAmounts[index]
                                            ? "ring-red-500 focus:ring-red-500"
                                            : "ring-gray-300 focus:ring-indigo-600"
                                        }`}
                            />
                          </td>
                        ))}
                    </tr>
                  </tbody>
                </table>
                {hasErrorMessage() && <ErrorMessage msg={getLatestErrorMessage()} />}
              </td>
            </tr>
            <tr key={"itemCode"} className="border-b border-gray-100">
              <td className="w-1/4 py-4 whitespace-nowrap">
                <span className="font-medium mr-1">품목코드</span>
                <span className="text-sm font-normal text-gray-400">(선택)</span>
              </td>
              <td className="relative py-4">
                <input
                  type="text"
                  name="bean_name"
                  id="bean_name"
                  value={beanState.itemCode}
                  onChange={(input) => setBeanState({ ...beanState, itemCode: input.target.value })}
                  className={
                    "block w-1/2 rounded-md border-0 py-1.5 pl-2 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-indigo-600 sm:text-sm sm:leading-6 placeholder:text-gray-400"
                  }
                  placeholder="예시) DAA0001"
                />
              </td>
            </tr>
            <tr key={"beforeDescription"} className="border-b border-gray-100">
              <td className="font-medium w-1/4 py-4">
                <div className="whitespace-nowrap">기본 화면 배너</div>
                <div className="whitespace-pre-wrap">맛 설명</div>
              </td>
              <td className="pb-3">
                <div className="flex items-center mt-3 mb-1">
                  <span className="text-orange-600">{beanState.name || "원두"}</span>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-1 py-2 ml-3 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {selectedParticle}
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-3 z-10 mt-2 w-14 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {particles.map((particle) => (
                            <Menu.Item
                              key={particle.id}
                              onClick={() => handleParticleClick(particle.name)}
                            >
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  {particle.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div className="relative">
                  <input
                    type="text"
                    name="basicDescription"
                    id="basicDescription"
                    value={beanState.basicDescription ? beanState.basicDescription : ""}
                    maxLength={MAX_BASIC_DESCRIPTION_LENGTH}
                    onChange={(e) => {
                      if (e.target.value.length <= MAX_BASIC_DESCRIPTION_LENGTH) {
                        setBeanState({ ...beanState, basicDescription: e.target.value });
                        setBasicDescription(e.target.value);
                      }
                    }}
                    className={
                      "block w-1/2 rounded-md border-0 py-1.5 pl-2 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  sm:text-sm sm:leading-6"
                    }
                  />
                  <div className="absolute mr-3 right-1/2 top-1/2 transform -translate-y-1/2 text-s text-gray-500">{`${basicDescription.length}/${MAX_BASIC_DESCRIPTION_LENGTH}`}</div>
                </div>
              </td>
            </tr>
            <tr key={"cupNote"}>
              <td className="w-1/4 py-4 align-top font-medium">
                <div className="whitespace-nowrap">기본 화면 배너</div>
                <span className="whitespace-pre-wrap">컵노트</span>
              </td>
              <td className="py-4">
                <div className="flex flex-wrap">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-36 justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 mr-3 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {selectedCupNotes[0] ? selectedCupNotes[0].name : "컵노트1 선택"}
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute max-h-80 overflow-y-auto left-0 z-10 mt-1 w-36 divide-y divide-gray-300 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item key={"select-0"} onClick={() => handleCupNoteClick(0, null)}>
                            {({ active }) => (
                              <a
                                className={classNames(
                                  active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                컵노트1 선택
                              </a>
                            )}
                          </Menu.Item>
                          {cupNotes.map((cupNote, index) => (
                            <Menu.Item
                              key={`${cupNote.id}-${cupNote.name}-${index}-0`}
                              onClick={() => handleCupNoteClick(0, cupNote)}
                            >
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  {cupNote.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                        <div className="py-1">
                          {etcCupNote.map((cupNote, index) => (
                            <Menu.Item
                              key={`${cupNote.id}-${cupNote.name}-${index}-0`}
                              onClick={() => handleCupNoteClick(0, cupNote)}
                            >
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  {cupNote.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-36 justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 mr-3 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {selectedCupNotes[1] ? selectedCupNotes[1].name : "컵노트2 선택"}
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute max-h-80 overflow-y-auto divide-y divide-gray-300 left-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item key={"select-1"} onClick={() => handleCupNoteClick(1, null)}>
                            {({ active }) => (
                              <a
                                className={classNames(
                                  active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                컵노트2 선택
                              </a>
                            )}
                          </Menu.Item>
                          {cupNotes.map((cupNote, index) => (
                            <Menu.Item
                              key={`${cupNote.id}-${cupNote.name}-${index}-1`}
                              onClick={() => handleCupNoteClick(1, cupNote)}
                            >
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  {cupNote.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                        <div className="py-1">
                          {etcCupNote.map((cupNote, index) => (
                            <Menu.Item
                              key={`${cupNote.id}-${cupNote.name}-${index}-0`}
                              onClick={() => handleCupNoteClick(1, cupNote)}
                            >
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  {cupNote.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-36 justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 mr-3 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {selectedCupNotes[2] ? selectedCupNotes[2].name : "컵노트3 선택"}
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute max-h-80 overflow-y-auto divide-y divide-gray-300 left-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item key={"select-2"} onClick={() => handleCupNoteClick(2, null)}>
                            {({ active }) => (
                              <a
                                className={classNames(
                                  active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                컵노트3 선택
                              </a>
                            )}
                          </Menu.Item>
                          {cupNotes.map((cupNote, index) => (
                            <Menu.Item
                              key={`${cupNote.id}-${cupNote.name}-${index}-2`}
                              onClick={() => handleCupNoteClick(2, cupNote)}
                            >
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  {cupNote.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                        <div className="py-1">
                          {etcCupNote.map((cupNote, index) => (
                            <Menu.Item
                              key={`${cupNote.id}-${cupNote.name}-${index}-0`}
                              onClick={() => handleCupNoteClick(2, cupNote)}
                            >
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  {cupNote.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BeanFormDetail;
