import React from "react";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

const ErrorMessage = ({ msg }) => {
  return (
    <>
      <div className="flex mt-2">
        <ExclamationCircleIcon className="h-5 w-5 mr-1 text-red-500" aria-hidden="true" />
        <div className="text-sm text-red-600">{msg}</div>
      </div>
    </>
  );
};

export default ErrorMessage;
