import API from "./api";
export const getGroupAccounts = async () => {
  const response = await API.get("/groupAccounts/select");
  return response.data;
};

export const getGroupAccountList = async (page, size, type, search) => {
  const response = await API.get("/groupAccounts", {
    params: {
      page: page,
      size: size,
      type: type,
      search: search,
    },
  });
  return response.data;
};

export const getGroupAccountDetail = async (id) => {
  const response = await API.get(`/groupAccounts/${id}`);
  return response.data;
};

export const getAccountSelect = async (type, name, groupAccountId) => {
  const response = await API.get("/groupAccounts/account/select", {
    params: {
      type: type,
      name: name,
      groupAccountId: groupAccountId,
    },
  });
  return response.data;
};

export const createGroupAccount = async (groupAccount) => {
  const response = await API.post("/groupAccounts", groupAccount);
  return response;
};

export const updateGroupAccount = async (id, groupAccount) => {
  const response = await API.patch(`/groupAccounts/${id}`, groupAccount);
  return response;
};

export const deleteGroupAccount = async (id) => {
  const response = await API.delete(`/groupAccounts/${id}`);
  return response;
};
