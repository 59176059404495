import React, { useState } from "react";

import RemoteButtonGroup from "./Remote";
import RemoteLogList from "./Log";
import WashingCycleBar from "./WashingCycleBar";

import { getRemoteLogs } from "../../../../rest-apis/devices";

const RemotePage = ({ id, deviceDetail }) => {
  // slide bar
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  // remoteLogs
  const [remoteLogs, setRemoteLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;

  const fetchRemoteLogs = async (page) => {
    setIsLoading(true);

    const response = await getRemoteLogs(id, page, pageSize);
    setRemoteLogs(response.data.results);
    setTotalPages(response.data.totalPages);

    setIsLoading(false);
  };

  return (
    <>
      <div className="flex px-4 py-4">
        <WashingCycleBar
          id={id}
          open={open}
          setOpen={setOpen}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          deviceDetail={deviceDetail}
        />
        <RemoteButtonGroup setOpen={setOpen} setIsEdit={setIsEdit} id={id} />
        <RemoteLogList
          fetchRemoteLogs={fetchRemoteLogs}
          remoteLogs={remoteLogs}
          isLoading={isLoading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      </div>
    </>
  );
};

export default RemotePage;
