import React, { useContext } from "react";

import { UserInfoContextState } from "contexts/User";
import { toast } from "react-hot-toast";

import {
  remoteSimpleWashing,
  resetPassword,
  machineReboot,
  brewer,
} from "../../../../rest-apis/devices";

const RemoteButtonGroup = ({ id, setOpen, setIsEdit }) => {
  const { userInfo } = useContext(UserInfoContextState);

  const requestRemoteSimpleWashing = async (id) => {
    if (!confirm("원격 간단 세척 요청 하시겠습니까?")) return;

    remoteSimpleWashing(id, userInfo.name)
      .then((response) => {
        if (response.response === "success") {
          toast.success("원격 간단 세척 요청을 보냈습니다.");
        } else {
          toast.error("원격 간단 세척 요청을 보내지 못했습니다.");
        }
      })
      .catch((response) => {
        if (response.response === "fail") {
          toast.error("원격 간단 세척 요청을 보내지 못했습니다.");
        }
      });
  };

  const requestResetPassword = async (id) => {
    if (!confirm("관리자 비밀번호를 초기화 하시겠습니까?\n비밀번호는 000000으로 초기화됩니다."))
      return;

    resetPassword(id, userInfo.name)
      .then((response) => {
        if (response.response === "success") {
          toast.success("비밀번호 초기화 요청을 보냈습니다.");
        } else {
          toast.error("비밀번호 초기화 요청을 보내지 못했습니다.");
        }
      })
      .catch((response) => {
        if (response.response === "fail") {
          toast.error("비밀번호 초기화 요청을 보내지 못했습니다.");
        }
      });
  };

  const requestMachineReboot = async (id) => {
    if (!confirm("머신 재부팅 요청 하시겠습니까?")) return;

    machineReboot(id, userInfo.name)
      .then((response) => {
        if (response.response === "success") {
          toast.success("머신 재부팅 요청을 보냈습니다.");
        } else {
          toast.error("머신 재부팅 요청을 보내지 못했습니다.");
        }
      })
      .catch((response) => {
        if (response.response === "fail") {
          toast.error("머신 재부팅 요청을 보내지 못했습니다.");
        }
      });
  };

  const requestBrewer = async (id) => {
    if (!confirm("추출기 재정렬 요청 하시겠습니까?")) return;

    brewer(id, userInfo.name)
      .then((response) => {
        if (response.response === "success") {
          toast.success("추출기 재정렬 요청을 보냈습니다.");
        } else {
          toast.error("추출기 재정렬 요청을 보내지 못했습니다.");
        }
      })
      .catch((response) => {
        if (response.response === "fail") {
          toast.error("추출기 재정렬 요청을 보내지 못했습니다.");
        }
      });
  };

  return (
    <div className="w-3/5 mr-6 bg-white shadow sm:rounded-lg">
      <div className="px-8 py-6 sm:px-6">
        <div className="text-base text-gray-700 font-semibold">원격 관리</div>
        <table className="w-full mt-5 text-left table-auto">
          <tbody>
            <tr key={"updateWashingCycle"}>
              <td className="font-medium text-gray-700 w-1/4 py-4 pr-8 whitespace-nowrap align-top">
                자동 세척 주기 변경
              </td>
              <td className="text-gray-700">
                <button
                  type="button"
                  onClick={() => {
                    setOpen(true);
                    setIsEdit(true);
                  }}
                  className="rounded-md bg-white px-4 py-1.5 text-sm font-medium whitespace-nowrap text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  자동 세척 주기 변경
                </button>
              </td>
            </tr>
            <tr key={"resetPassword"} className="border-b border-gray-100">
              <td className="font-medium text-gray-700 w-1/4 py-4 pb-6 pr-8 whitespace-nowrap align-top">
                관리자 비밀번호 초기화
              </td>
              <td className="pb-2 text-gray-700">
                <button
                  type="button"
                  onClick={() => requestResetPassword(id)}
                  className="rounded-md bg-white px-4 py-1.5 text-sm font-medium whitespace-nowrap text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  관리자 비밀번호 초기화
                </button>
              </td>
            </tr>
            <tr key={"brewer"}>
              <td className="font-medium text-gray-700 w-1/4 py-4 pt-6 pr-8 whitespace-nowrap align-top">
                추출기 재정렬
              </td>
              <td className="pt-2 text-gray-700">
                <button
                  type="button"
                  onClick={() => requestBrewer(id)}
                  className="rounded-md bg-white px-4 py-1.5 text-sm font-medium whitespace-nowrap text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  추출기 재정렬
                </button>
              </td>
            </tr>
            <tr key={"remoteWashing"}>
              <td className="font-medium text-gray-700 w-1/4 py-4 pr-8 whitespace-nowrap align-top">
                원격 간단 세척
              </td>
              <td className="text-gray-700">
                <button
                  type="button"
                  onClick={() => requestRemoteSimpleWashing(id)}
                  className="rounded-md bg-white px-4 py-1.5 text-sm font-medium whitespace-nowrap text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  원격 간단 세척
                </button>
              </td>
            </tr>
            <tr key={"rebootApp"}>
              <td className="font-medium text-gray-700 w-1/4 py-4 pr-8 whitespace-nowrap align-top">
                머신 재부팅
              </td>
              <td className="text-gray-700">
                <button
                  type="button"
                  onClick={() => requestMachineReboot(id)}
                  className="rounded-md bg-white px-4 py-1.5 text-sm font-medium whitespace-nowrap text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                >
                  머신 재부팅
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RemoteButtonGroup;
