import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Page } from "@shopify/polaris";

import { getContentDetail } from "../../../rest-apis/contents";

import CardSpinner from "common/PageSpinner/Card";
import AdminFrame from "components/Layout/Frame";
import ContentDetailTitle from "./Title";
import Detail from "./Detail";

const ContentDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [content, setContent] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchContent = async (id) => {
    setIsLoading(true);

    try {
      const response = await getContentDetail(id);
      if (response.status === 200) {
        setContent(response.data);
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        if (status === 400 || status === 404 || status === 500) {
          navigate("/not-found");
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchContent(id);
  }, [id]);

  return (
    <>
      <AdminFrame title="콘텐츠 관리 조회">
        {isLoading ? (
          <CardSpinner />
        ) : (
          <>
            <ContentDetailTitle content={content} setContent={setContent} />
            <Page fullWidth>
              <Detail content={content} />
            </Page>
          </>
        )}
      </AdminFrame>
    </>
  );
};

export default ContentDetail;
