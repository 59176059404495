import API from "./api";

export const getMenuList = async (page, size, search) => {
  const response = await API.get("/menus", {
    params: {
      page: page,
      size: size,
      search: search,
    },
  });
  return response.data;
};

export const getMenuDetail = async (id) => {
  const response = await API.get(`/menus/${id}`);
  return response;
};

export const updateMenuDeactivate = async (id, deactivate, name) => {
  const response = await API.patch(`/menus/${id}/deactivate`, {
    deactivate: deactivate,
    lastModifier: name,
  });
  return response.data;
};

export const updateMenuStatus = async (id, currentStatus, nextStatus, lastModifier) => {
  const response = await API.patch(`/menus/${id}/status`, {
    currentStatus: currentStatus,
    nextStatus: nextStatus,
    lastModifier: lastModifier,
  });
  return response;
};

export const createMenu = async (menu) => {
  const response = await API.post("/menus", menu);
  return response;
};

export const updateMenu = async (id, menu) => {
  const response = await API.patch(`/menus/${id}`, menu);
  return response;
};

export const getMenuSelect = async (id) => {
  const response = await API.get("/menus/select/presets", {
    params: {
      removeMenuId: id,
    },
  });
  return response.data;
};
