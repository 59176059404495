import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { UserInfoContextState } from "contexts/User";
import { PermissionContextState } from "contexts/Permission";
import moment from "moment/moment";
import { toast } from "react-hot-toast";

import { beanCategoryFormatter } from "../../../rest-apis/field/beanCategory";
import { updateBeanDeactivate } from "../../../rest-apis/beans";
import RemoveModal from "../../../common/Modal/Remove";

const BeanList = ({ rows, fetchBeans, currentPage }) => {
  const navigate = useNavigate();
  const { userInfo } = useContext(UserInfoContextState);
  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "BEAN" && p.canWrite);
  };

  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [removeBean, setRemoveBean] = useState({});

  const updateDeactivate = async (id, deactivate) => {
    await updateBeanDeactivate(id, deactivate, userInfo.name);
  };

  const handleDeleteButtonClick = (row) => () => {
    // Case 1. 관련 프리셋 없음 & 진열 프리셋 없음 : 즉시 삭제 가능
    if (row.selectedPresets.length === 0 && row.targetedPresets.length === 0) {
      if (!confirm("삭제하시겠습니까? 삭제 후 원복 불가합니다.")) return;

      updateDeactivate(row.id, row.deactivate).then(() => {
        toast.success("삭제 되었습니다.");
        fetchBeans(currentPage);
      });
    }

    // Case 2. 관련 프리셋 있음 & 진열 프리셋 없음 : 컨펌 후, 삭제 가능
    if (row.selectedPresets.length > 0 && row.targetedPresets.length === 0) {
      const presets = row.selectedPresets
        .map((preset) => preset.title.replace(/\n/g, " "))
        .join(", ");

      if (
        !confirm(
          `삭제하시겠습니까? [${row.name.replace(/\n/g, " ")}] 삭제 시, ${
            row.selectedPresets.length
          }개의 프리셋이 함께 삭제됩니다.\n\n${presets}`
        )
      ) {
        return;
      }

      updateDeactivate(row.id, row.deactivate).then(() => {
        toast.success("삭제 되었습니다.");
        fetchBeans(currentPage);
      });
    }

    // Case 3. 관련 프리셋 있음 & 진열 프리셋 있음 : 프리셋 변경 후, 삭제 가능
    if (row.selectedPresets.length > 0 && row.targetedPresets.length > 0) {
      setIsRemoveModalOpen(true);
      setRemoveBean(row);
    }
  };

  return (
    <div className="mt-5 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-500 sm:pl-6"
                  >
                    NO
                  </th>
                  <th
                    scope="col"
                    className="w-3/12 px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    원두명
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    분류
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    품목코드
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    최종 수정자
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    최종 수정 일시
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    최초 등록 일시
                  </th>
                  {canWritePage() && (
                    <>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                      >
                        수정
                      </th>
                      <th
                        scope="col"
                        className="px-3 pl-3 pr-6 text-center text-sm font-medium text-gray-500"
                      >
                        삭제
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {rows.length > 0 ? (
                  rows.map((row) => (
                    <tr key={row.no}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
                        {row.no}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <Link
                          to={String(row.id)}
                          key={row.id}
                          className="text-indigo-600 underline"
                        >
                          {row.name}
                        </Link>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {beanCategoryFormatter(row.category)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {row.itemCode ? row.itemCode : "-"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {row.lastModifier}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {moment(row.lastModifiedAt).add(9, "hour").format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {moment(row.createdAt).add(9, "hour").format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      {canWritePage() && (
                        <>
                          <td className="relative whitespace-nowrap px-3 py-4 text-left text-sm font-medium sm:pr-6">
                            <button
                              className="text-indigo-600 hover:text-indigo-900"
                              onClick={() => {
                                navigate(`/beans/${row.id}/edit`, {
                                  state: {
                                    bean: row,
                                  },
                                });
                              }}
                            >
                              수정
                            </button>
                          </td>
                          <td className="relative whitespace-nowrap pl-3 pr-6 py-4 text-center text-sm font-medium">
                            {row.category != "ETC" ? (
                              <button
                                className={
                                  row.isDisplayed
                                    ? "text-gray-400"
                                    : "text-red-600 hover:text-red-900"
                                }
                                onClick={handleDeleteButtonClick(row)}
                                disabled={row.isDisplayed}
                              >
                                삭제
                              </button>
                            ) : (
                              <button disabled className="text-gray-600">
                                -
                              </button>
                            )}
                          </td>
                        </>
                      )}
                    </tr>
                  ))
                ) : (
                  <td
                    colSpan={9}
                    className="text-center pt-20 pb-16 text-gray-300 font-bold text-lg"
                  >
                    조회된 데이터가 없습니다.
                  </td>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <RemoveModal
        isOpen={isRemoveModalOpen}
        setIsOpen={setIsRemoveModalOpen}
        removeBean={removeBean}
        fetchBeans={fetchBeans}
      />
    </div>
  );
};

export default BeanList;
