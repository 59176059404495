import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import CardSpinner from "common/PageSpinner/Card";
import FilterBar from "./FilterBar";
import GroupAccountList from "./List";
import Pagination from "common/Pagination";
import { PermissionContextState } from "contexts/Permission";

import { getGroupAccountList } from "../../../rest-apis/groupAccounts";

const GroupAccountsList = () => {
  const navigate = useNavigate();

  // permission
  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "GROUP_ACCOUNT" && p.canWrite);
  };

  // groupAccounts
  const [groupAccounts, setGroupAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [groupAccountPage, setGroupAccountPage] = useState([]);
  const totalPages = groupAccountPage.totalPages;
  const pageSize = 10;

  // filterBar
  const [type, setType] = useState("블리스,리테일");
  const [search, setSearch] = useState("");

  const fetchGroupAccounts = async (page) => {
    setIsLoading(true);

    const data = await getGroupAccountList(page, pageSize, type, search);

    if (data._embedded && Array.isArray(data._embedded.groupAccountListDTOList)) {
      setGroupAccounts(data._embedded.groupAccountListDTOList);
    } else {
      setGroupAccounts([]);
    }
    setGroupAccountPage(data.page);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchGroupAccounts(currentPage);
  }, [currentPage, type, search]);

  return (
    <>
      <FilterBar setType={setType} setSearch={setSearch} fetchGroupAccounts={fetchGroupAccounts} />
      <div className="flex justify-end items-center ml-7 mr-5">
        <div>
          {canWritePage() && (
            <button
              type="button"
              className="w-14 h-9 rounded-md bg-white px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => navigate("/group-accounts/add")}
            >
              추가
            </button>
          )}
        </div>
      </div>
      {isLoading ? (
        <CardSpinner />
      ) : (
        <div>
          <GroupAccountList rows={groupAccounts} />
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      )}
    </>
  );
};

export default GroupAccountsList;
