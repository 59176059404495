import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";

import AdminFrame from "components/Layout/Frame";
import { Page } from "@shopify/polaris";
import CardSpinner from "common/PageSpinner/Card";

import { getGroupAccountDetail, deleteGroupAccount } from "../../../rest-apis/groupAccounts";
import { PermissionContextState } from "contexts/Permission";

const GroupAccountDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "GROUP_ACCOUNT" && p.canWrite);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [groupAccount, setGroupAccount] = useState([]);

  const fetchGroupAccount = async (id) => {
    setIsLoading(true);

    const response = await getGroupAccountDetail(id);
    setGroupAccount(response);

    setIsLoading(false);
  };

  const handleDeleteButtonClick = async (id) => {
    if (!window.confirm("그룹 계정을 삭제하시겠습니까?")) {
      return;
    }

    deleteGroupAccount(id)
      .then(() => {
        toast.success("삭제되었습니다.", { duration: 1500 });
        setTimeout(() => {
          navigate("/group-accounts");
        }, 0);
        return;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("서버 오류가 존재합니다.");
        }
      });
  };

  useEffect(() => {
    fetchGroupAccount(id);
  }, [id]);

  return (
    <>
      <AdminFrame title="그룹 계정 관리 조회">
        {isLoading ? (
          <CardSpinner />
        ) : (
          <>
            <div className="flex justify-between">
              <div className="flex ml-6 mt-6 items-center">
                <ArrowSmallLeftIcon
                  type="button"
                  className="w-9 h-9 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => {
                    navigate("/group-accounts");
                  }}
                />
                <div className="ml-3 text-xl font-bold text-gray-800">
                  그룹 계정 관리 조회 | {groupAccount.name}
                </div>
              </div>
              <div className="mt-6 mr-10">
                {canWritePage() && (
                  <>
                    {groupAccount.accountCount === 0 && (
                      <button
                        type="button"
                        className="w-14 h-9 rounded-md bg-red-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-center text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={() => {
                          handleDeleteButtonClick(id);
                        }}
                      >
                        삭제
                      </button>
                    )}
                    <button
                      type="button"
                      className="w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-center text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => {
                        setTimeout(() => {
                          navigate(`/group-accounts/${id}/edit`, {
                            state: {
                              groupAccount: groupAccount,
                            },
                          });
                        }, 500);
                      }}
                    >
                      수정
                    </button>
                  </>
                )}
              </div>
            </div>
            <Page fullWidth>
              {isLoading ? (
                <CardSpinner />
              ) : (
                <div className="flex">
                  <div className="w-1/2 flex overflow-x-auto">
                    <div className="w-full mr-4 bg-white shadow sm:rounded-lg mb-5">
                      <div className="px-8 py-6 sm:px-6">
                        <div className="text-base font-semibold text-gray-700">상세 정보</div>
                        <table className="w-full mt-5 text-left table-auto">
                          <tbody>
                            <tr key="account-type" className="border-b border-gray-100">
                              <td className="font-medium w-1/12 py-4 align-top text-gray-700">
                                고객 종류
                              </td>
                              <td className="py-4 text-gray-700">{groupAccount.type}</td>
                            </tr>
                            <tr
                              key={`${groupAccount?.id}-name`}
                              className="border-b border-gray-100"
                            >
                              <td className="w-1/5 py-4 align-top whitespace-nowrap font-medium text-gray-700">
                                고객명
                              </td>
                              <td className="relative py-4 text-gray-700">{groupAccount.name}</td>
                            </tr>
                            <tr
                              key={`${groupAccount?.id}-count`}
                              className="border-b border-gray-100"
                            >
                              <td className="w-1/5 py-4 align-top whitespace-nowrap font-medium text-gray-700">
                                하위 계정
                              </td>
                              <td className="relative py-4 text-gray-700">
                                <span>계정 수 {groupAccount.accountCount} </span>
                                <span>/ 머신 수 {groupAccount.deviceCount}</span>
                              </td>
                            </tr>
                            <tr
                              key={`${groupAccount.id}-memo`}
                              className="border-b border-gray-100"
                            >
                              <td className="w-1/5 py-4 whitespace-nowrap align-top">
                                <span className="font-medium mr-1 text-gray-700">메모</span>
                                <span className="text-sm font-normal text-gray-400">(선택)</span>
                              </td>
                              <td className="relative py-4 whitespace-pre-wrap text-gray-700">
                                {groupAccount.memo}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="w-1/2 flex overflow-x-auto">
                    <div className="w-full mr-4 bg-white shadow sm:rounded-lg mb-5">
                      <div className="px-8 py-6 sm:px-6">
                        <div className="text-base font-semibold text-gray-700">머신 정보</div>
                        {groupAccount.accountList.map((account, index) => (
                          <div
                            key={`${account.accountName}-${index}`}
                            className="relative my-8 mx-5"
                          >
                            <div className="inset-0 flex items-center" aria-hidden="true">
                              <div className="w-full border-t border-1.5 border-gray-500" />
                              <span className="bg-white px-6 text-base font-semibold leading-6 text-gray-700 absolute left-0 transform -translate-x-1/2">
                                계정 {index + 1}
                              </span>
                            </div>
                            <div className="flex mt-5 text-gray-700">
                              <div className="mx-20 w-24">
                                <div className="whitespace-nowrap">고객명</div>
                                <div className="whitespace-nowrap font-semibold">
                                  {account.accountName}
                                </div>
                              </div>
                              <div className="text-left w-16">
                                <div className="whitespace-nowrap">머신 ID</div>
                                {account.deviceList.map((device, deviceIndex) => (
                                  <div
                                    key={`${device.androidId}-${deviceIndex}`}
                                    className="whitespace-nowrap font-semibold"
                                  >
                                    {device.androidId}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))}
                        {groupAccount.accountList.length !== 0 && (
                          <div className="pr-4 mb-4">
                            <div className="w-full border-t border-1.5 border-gray-500" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Page>
          </>
        )}
      </AdminFrame>
    </>
  );
};

export default GroupAccountDetailPage;
