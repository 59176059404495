import React, { Fragment, useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import moment from "moment/moment";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { getBrewingLogs } from "../../../../rest-apis/devices";

import Pagination from "common/Pagination";
import CardSpinner from "common/PageSpinner/Card";

const brewingTypefilter = ["전체", "빠른 커피_기본 메뉴", "빠른 커피_나만의 메뉴", "맞춤 커피"];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const BrewingLogPage = ({ id }) => {
  // brewingLogs
  const [brewingLogs, setBrewingLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;

  // filter
  const [selectedFilter, setSelectedFilter] = useState("전체");

  const fetchBrewingLogs = async (page) => {
    setIsLoading(true);

    const response = await getBrewingLogs(id, selectedFilter, page, pageSize);

    if (response.data) {
      setBrewingLogs(response.data.results);
    } else {
      setBrewingLogs([]);
    }
    setTotalPages(response.data.totalPages);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchBrewingLogs(currentPage);
  }, [currentPage, selectedFilter]);

  return (
    <>
      {isLoading ? (
        <CardSpinner />
      ) : (
        <div className="flex flex-col px-4 py-4">
          <div className="w-full bg-white shadow sm:rounded-lg">
            <div className="px-8 py-6 sm:px-6">
              <div className="flex justify-between">
                <div className="text-base font-semibold">커피 추출 기록</div>
                <div>
                  <span className="mr-3">추출 방법</span>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex mt-2 mr-2 w-40 pl-3 items-center justify-between min-w-max h-9 gap-x-1.5 rounded-md bg-white px-2 py-2 text-sm font-medium text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {selectedFilter}
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 right-0 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {brewingTypefilter.map((filter) => (
                            <Menu.Item key={filter}>
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                  onClick={() => {
                                    setSelectedFilter(filter);
                                  }}
                                >
                                  {filter}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="mt-2 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-700 whitespace-nowrap"
                          >
                            추출 일시
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700 whitespace-nowrap"
                          >
                            추출 방법
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700 whitespace-nowrap"
                          >
                            원두
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700 whitespace-nowrap"
                          >
                            메뉴
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700 whitespace-nowrap"
                          >
                            추출 순서
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700 whitespace-nowrap"
                          >
                            샷
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700 whitespace-nowrap"
                          >
                            물 양(ml)
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700 whitespace-nowrap"
                          >
                            물
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700 whitespace-nowrap"
                          >
                            원두량(g)
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700 whitespace-nowrap"
                          >
                            에스프레소량(ml)
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700 whitespace-nowrap"
                          >
                            시간(초)
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700 whitespace-nowrap"
                          >
                            뜸들이기
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {brewingLogs.length > 0 ? (
                          brewingLogs.map((row) => (
                            <tr key={row.brewingEndedAt}>
                              <td className="whitespace-nowrap py-4 pr-3 text-sm font-normal text-gray-700">
                                {moment(row.brewingEndedAt)
                                  .add(9, "hour")
                                  .format("YYYY-MM-DD HH:mm:ss")}
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-sm font-normal text-gray-700">
                                {row.brewingType === "DEFAULT_COFFEE"
                                  ? "빠른 커피_기본 메뉴"
                                  : row.brewingType === "CUSTOM_COFFEE"
                                  ? "빠른 커피_나만의 메뉴"
                                  : row.brewingType === "PERSONAL_COFFEE"
                                  ? "맞춤 커피"
                                  : ""}
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-sm font-normal text-gray-700">
                                {row.beanName}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-700">
                                {row.menuName}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-700">
                                {row.waterFirst ? "물 먼저" : "에스프레소 먼저"}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-700">
                                {row.shot}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-700">
                                {row.coldWater == 0 ? row.hotWater : row.coldWater}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-700">
                                {row.coldWater == 0 ? "온수" : "정수"}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-700">
                                {row.beanAmount}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-700">
                                {row.espresso}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-700">
                                {row.preInfusionTime / 1000}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-700">
                                {row.preInfusionTime != 0 ? "사용함" : "사용 안 함"}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <td
                            colSpan={12}
                            className="text-center pt-20 pb-16 text-gray-300 font-bold text-lg"
                          >
                            조회된 데이터가 없습니다.
                          </td>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BrewingLogPage;
