import React, { useState, Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { getAppVersions, startDeploy, targetAppDeploy } from "../../../rest-apis/appDeploy";
import toast from "react-hot-toast";

const MAX_DESCRIPTION_LENGTH = 20;
const targetTypes = [
  {
    id: 1,
    name: "전체",
    value: "all",
  },
  {
    id: 2,
    name: "버전별",
    value: "version",
  },
  // {
  //     id: 3,
  //     name: "그룹별",
  //     value: "group"
  // },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DeployBar = ({ fetchAppDeploy, isDeploying }) => {
  const userName = localStorage.getItem("name");

  const [appVersions, setAppVersions] = useState([]);
  const [targetAppVerions, setTargetAppVersions] = useState([]);
  const [appDeployTarget, setAppDeployTarget] = useState({
    target: "",
    deployVersion: null,
    targetVersion: null,
  });
  const [appDeployStart, setAppDeployStart] = useState({
    upgradeAppVersionId: null,
    version: null,
    buildNumber: null,
    description: "",
    targetDevices: [],
    requestedBy: userName,
  });

  const [targetDeviceCount, setTargetDeviceCount] = useState(0);
  const [descriptionInput, setDescriptionInput] = useState("");
  const [isTargetSetting, setTargetSetting] = useState(false);

  const handleAppVersionClick = (appVersion) => {
    setAppDeployStart({
      ...appDeployStart,
      upgradeAppVersionId: appVersion.id,
      version: appVersion.version,
      buildNumber: appVersion.buildNumber,
    });
    setTargetAppVersions(appVersions.filter((version) => version.id !== appVersion.id));
  };

  const handleTargetTypeClick = (targetType) => {
    if (targetType.value === "all") {
      setAppDeployTarget({
        target: targetType.value,
        deployVersion: appDeployStart.version,
        targetVersion: null,
      });
    } else if (targetType.value === "version") {
      setAppDeployTarget({
        target: targetType.value,
        deployVersion: null,
        targetVersion: null,
      });
    }
  };

  const handleTargetVersionClick = (appVersion) => {
    setAppDeployTarget({
      ...appDeployTarget,
      targetVersion: appVersion.version,
    });
  };

  const handleDeployStartClick = () => {
    if (!isTargetSetting || isDeploying) {
      return;
    }

    fetchAppDeployStart();
    fetchAppDeploy();

    setAppDeployStart({});
    setAppDeployTarget({});
    setTargetDeviceCount(0);
  };

  const isValidTextInput = (text) => {
    const regex = /^[A-Za-z0-9ㄱ-ㅎㅏ-ㅣ가-힣~!@#₩$%^&*()\-_+=,./<>?\\|\n ]*$/;
    return regex.test(text);
  };

  const fetchAppVersions = async () => {
    const promise = getAppVersions();
    try {
      const response = await promise;
      if (response.status === 200) {
        setAppVersions(response.data);
        setTargetAppVersions(response.data);
      }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "서버 오류가 존재합니다."
      );
    }
  };

  const fetchAppDeployTarget = async () => {
    const promise = targetAppDeploy(appDeployTarget);
    try {
      const response = await promise;
      if (response.status === 200) {
        setTargetDeviceCount(response.data.length);
        setAppDeployStart({
          ...appDeployStart,
          targetDevices: response.data,
        });
      }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "서버 오류가 존재합니다."
      );
    }
  };

  const fetchAppDeployStart = async () => {
    const promise = startDeploy(appDeployStart);
    try {
      const response = await promise;
      if (response.status === 200) {
        fetchAppDeploy();
        toast.success("시작되었습니다.");
      }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "서버 오류가 존재합니다."
      );
    }
  };

  useEffect(() => {
    fetchAppVersions();
  }, []);

  useEffect(() => {
    if (
      appDeployTarget.target === "all" ||
      (appDeployTarget.target === "version" && appDeployTarget.targetVersion)
    ) {
      fetchAppDeployTarget();
    }
  }, [appDeployTarget]);

  useEffect(() => {
    if (
      appDeployStart.targetDevices &&
      appDeployStart.upgradeAppVersionId &&
      appDeployStart.version &&
      appDeployStart.buildNumber &&
      appDeployStart.targetDevices.length > 0
    ) {
      setTargetSetting(true);
    } else {
      setTargetSetting(false);
    }
  }, [appDeployStart]);

  return isDeploying ? (
    <div className=" bg-gray-100 shadow sm:rounded-lg mb-5">
      <div className="flex space-x-3 px-4 py-4 sm:px-6">
        <div className=" w-1/3 bg-gray-100 rounded-lg shadow">
          <div className="px-4 py-4">
            <div className="font-bold text-gray-400">1. 버전 선택</div>
            <div className="flex items-center space-x-6 m-4">
              <div className="font-semibold text-sm whitespace-nowrap text-gray-400">버전</div>
              <div className="w-full">
                <Menu as="div" className="relative w-full inline-block text-left" disabled={true}>
                  <div>
                    <Menu.Button className="w-full inline-flex justify-between gap-x-1.5 rounded-md bg-gray-100 px-3 py-2 mr-3 text-sm font-medium text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 cursor-default">
                      선택
                      <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                </Menu>
              </div>
            </div>
            <div className="flex items-center space-x-6 m-4">
              <div className="font-semibold text-sm whitespace-nowrap text-gray-400">설명</div>
              <div className="relative w-full">
                <input
                  type="text"
                  name="app-deploy-description"
                  id="app-deploy-description"
                  maxLength={MAX_DESCRIPTION_LENGTH}
                  className={
                    "block w-full rounded-md border-0 py-1.5 pl-2 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  sm:text-sm sm:leading-6"
                  }
                  disabled={true}
                />
                <div className="absolute mr-3 right-0 top-1/2 transform -translate-y-1/2 text-s text-gray-400">
                  0/{MAX_DESCRIPTION_LENGTH}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" w-1/3 bg-gray-100 rounded-lg shadow">
          <div className="px-4 py-4">
            <div className="font-bold text-gray-400">2. 대상 선택</div>
            <div className="flex items-center space-x-6 m-4">
              <div className="font-semibold text-sm whitespace-nowrap text-gray-400">기준</div>
              <div className="w-full">
                <Menu as="div" className="relative w-full inline-block text-left">
                  <div>
                    <Menu.Button
                      className="w-full inline-flex justify-between gap-x-1.5 rounded-md bg-gray-100 px-3 py-2 mr-3 text-sm font-medium text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 cursor-default"
                      disabled={true}
                    >
                      선택
                      <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                </Menu>
              </div>
            </div>
            <div className="flex items-center space-x-6 m-4">
              <div className="font-semibold text-sm whitespace-nowrap text-gray-400">선택</div>
              <div className="w-full">
                <Menu as="div" className="relative w-full inline-block text-left">
                  <div>
                    <Menu.Button
                      className="w-full inline-flex justify-between gap-x-1.5 rounded-md bg-gray-100 px-3 py-2 mr-3 text-sm font-medium text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 cursor-default"
                      disabled={true}
                    >
                      선택
                      <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Menu.Button>
                  </div>
                </Menu>
              </div>
            </div>
          </div>
        </div>
        <div className=" w-1/3 bg-gray-100 rounded-lg shadow">
          <div className="px-4 py-4">
            <div className="font-bold text-gray-400">3. 배포 시작</div>
            <div className="flex items-center space-x-6 m-4">
              <div className="font-semibold text-sm whitespace-nowrap text-gray-400">버전</div>
              <div className="text-gray-400">-</div>
            </div>
            <div className="flex items-center space-x-6 m-4">
              <div className="font-semibold text-sm whitespace-nowrap text-gray-400">대상</div>
              <div className="text-gray-400">0개</div>
            </div>
            <div className="flex justify-center items-center">
              <div
                type="button"
                className="flex justify-center items-center w-14 h-9 rounded-md bg-gray-100 px-2.5 py-1.5 text-sm font-medium text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 cursor-default"
                disabled={true}
              >
                시작
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className=" bg-white shadow sm:rounded-lg mb-5">
      <div className="flex space-x-3 px-4 py-4 sm:px-6">
        <div className=" w-1/3 bg-white rounded-lg shadow">
          <div className="px-4 py-4">
            <div className="font-bold text-gray-700">1. 버전 선택</div>
            <div className="flex items-center space-x-6 m-4">
              <div className="font-semibold text-sm whitespace-nowrap">버전</div>
              <div className="w-full">
                <Menu as="div" className="relative w-full inline-block text-left">
                  <div>
                    <Menu.Button className="w-full inline-flex justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 mr-3 text-sm font-medium text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      {appDeployStart.version
                        ? `${appDeployStart.version}(${appDeployStart.buildNumber})`
                        : "선택"}
                      <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute w-full max-h-80 overflow-y-auto left-0 z-10 mt-1 divide-y divide-gray-300 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {appVersions.map((appVersion, index) => (
                          <Menu.Item
                            key={`${appVersion.id}-${index}`}
                            onClick={() => handleAppVersionClick(appVersion)}
                          >
                            {({ active }) => (
                              <a
                                className={classNames(
                                  active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                {appVersion.version}({appVersion.buildNumber})
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <div className="flex items-center space-x-6 m-4">
              <div className="font-semibold text-sm whitespace-nowrap">설명</div>
              <div className="relative w-full">
                <input
                  type="text"
                  name="app-deploy-description"
                  id="app-deploy-description"
                  value={descriptionInput}
                  maxLength={MAX_DESCRIPTION_LENGTH}
                  onChange={(e) => {
                    if (
                      e.target.value.length <= MAX_DESCRIPTION_LENGTH &&
                      isValidTextInput(e.target.value)
                    ) {
                      setDescriptionInput(e.target.value);
                      setAppDeployStart({
                        ...appDeployStart,
                        description: e.target.value,
                      });
                    }
                  }}
                  className={
                    "block w-full rounded-md border-0 py-1.5 pl-2 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  sm:text-sm sm:leading-6"
                  }
                />
                <div className="absolute mr-3 right-0 top-1/2 transform -translate-y-1/2 text-s text-gray-500">{`${
                  descriptionInput ? descriptionInput.length : 0
                }/${MAX_DESCRIPTION_LENGTH}`}</div>
              </div>
            </div>
          </div>
        </div>
        <div className=" w-1/3 bg-white rounded-lg shadow">
          <div className="px-4 py-4">
            <div className="font-bold text-gray-700">2. 대상 선택</div>
            <div className="flex items-center space-x-6 m-4">
              <div className="font-semibold text-sm whitespace-nowrap">기준</div>
              <div className="w-full">
                <Menu as="div" className="relative w-full inline-block text-left">
                  <div>
                    <Menu.Button className="w-full inline-flex justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 mr-3 text-sm font-medium text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      {appDeployTarget.target
                        ? targetTypes.find((type) => type.value === appDeployTarget.target)?.name
                        : "선택"}
                      <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute w-full max-h-80 overflow-y-auto left-0 z-10 mt-1 divide-y divide-gray-300 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {targetTypes.map((targetType, index) => (
                          <Menu.Item
                            key={`${targetType.id}-${index}`}
                            onClick={() => handleTargetTypeClick(targetType)}
                          >
                            {({ active }) => (
                              <a
                                className={classNames(
                                  active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                {targetType.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
            <div className="flex items-center space-x-6 m-4">
              <div className="font-semibold text-sm whitespace-nowrap">선택</div>
              <div className="w-full">
                {appDeployTarget.target === "all" ? (
                  <Menu as="div" className="relative w-full inline-block text-left">
                    <div>
                      <Menu.Button className="w-full inline-flex justify-between gap-x-1.5 rounded-md bg-gray-100 px-3 py-2 mr-3 text-sm font-medium text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 cursor-not-allowed hover:bg-gray-50">
                        -
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                  </Menu>
                ) : (
                  <Menu as="div" className="relative w-full inline-block text-left">
                    <div>
                      <Menu.Button className="w-full inline-flex justify-between gap-x-1.5 rounded-md bg-white px-3 py-2 mr-3 text-sm font-medium text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {appDeployTarget.targetVersion
                          ? `${appDeployTarget.targetVersion}(${
                              targetAppVerions.find(
                                (item) => item.version === appDeployTarget.targetVersion
                              )?.buildNumber ?? "N/A"
                            })`
                          : "선택"}
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute w-full max-h-80 overflow-y-auto left-0 z-10 mt-1 divide-y divide-gray-300 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {targetAppVerions.map((appVersion, index) => (
                            <Menu.Item
                              key={`${appVersion.id}-${index}`}
                              onClick={() => handleTargetVersionClick(appVersion)}
                            >
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  {appVersion.version}({appVersion.buildNumber})
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className=" w-1/3 bg-white rounded-lg shadow">
          <div className="px-4 py-4">
            <div className="font-bold text-gray-700">3. 배포 시작</div>
            <div className="flex items-center space-x-6 m-4">
              <div className="font-semibold text-sm whitespace-nowrap">버전</div>
              <div>
                {appDeployStart.version
                  ? `${appDeployStart.version} (${appDeployStart.buildNumber})`
                  : "-"}
              </div>
            </div>
            <div className="flex items-center space-x-6 m-4">
              <div className="font-semibold text-sm whitespace-nowrap">대상</div>
              <div>{targetDeviceCount ? `${targetDeviceCount}개` : "0개"}</div>
            </div>
            <div className="flex justify-center items-center">
              <div
                type="button"
                className={
                  isTargetSetting && !isDeploying
                    ? "flex justify-center items-center w-14 h-9 rounded-md bg-white px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 cursor-pointer hover:bg-gray-50"
                    : "flex justify-center items-center w-14 h-9 rounded-md bg-gray-200 px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 cursor-not-allowed"
                }
                onClick={handleDeployStartClick}
                disabled={!isTargetSetting || isDeploying}
              >
                시작
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeployBar;
