import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { LoginContextState, UserInfoContextState } from "contexts/User";
import { PermissionContextState } from "contexts/Permission";
import ErrorMessage from "common/Badge/errorMessage";

import { Layout, AppProvider } from "@shopify/polaris";
import en from "@shopify/polaris/locales/en.json";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { LockClosedIcon, EnvelopeIcon } from "@heroicons/react/24/outline";

import { login } from "../../rest-apis/login";

const MAX_EMAIL_LENGTH = 50;
const MAX_PASSWORD_LENGTH = 12;

const AdminLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { setIsLoggedIn } = useContext(LoginContextState);
  const { setUserInfo } = useContext(UserInfoContextState);
  const { setUserPermissions } = useContext(PermissionContextState);

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (email.length === 0) {
      setEmailError(true);
      return;
    }
    if (password.length === 0) {
      setPasswordError(true);
      return;
    }

    const user = {
      email: email,
      password: password,
    };

    try {
      const loginUser = await login(user);
      const token = loginUser.token;
      const payload = token.split(".")[1];
      const base64 = payload.replace(/-/g, "+").replace(/_/g, "/");

      const decodedPayload = atob(base64);
      const jsonObject = JSON.parse(decodedPayload);

      localStorage.setItem("token", loginUser.token);
      localStorage.setItem("email", loginUser.email);
      localStorage.setItem("name", loginUser.name);

      setUserPermissions({
        role: jsonObject.role,
        permissions: jsonObject.permissions,
      });
      setUserInfo({
        name: loginUser.name,
        email: loginUser.email,
      });
      setIsLoggedIn(true);

      navigate("/dashboard");
    } catch (error) {
      setLoginError(true);
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    if (email.length > 0) {
      setEmailError(false);
    }
  }, [email]);

  useEffect(() => {
    if (password.length > 0) {
      setPasswordError(false);
    }
  }, [password]);

  return (
    <>
      <AppProvider i18n={en}>
        <HelmetProvider>
          <Helmet>
            <title>로그인 | 어드민</title>
          </Helmet>
        </HelmetProvider>

        <Layout>
          <Layout.Section>
            <div className="flex items-center justify-center min-h-screen relative">
              <img
                src="/images/login-background-x4.png"
                alt="login-background-image"
                className="absolute inset-0 w-7/12 h-7/12 z-0 object-contain justify-center items-center m-auto"
              />

              <div className="z-10 w-4/12 h-4/12 p-6 bg-white shadow-lg rounded-md relative">
                <form onSubmit={handleSubmit}>
                  <img
                    src="/images/brownbag-logo.png"
                    alt="brownbag-logo-image"
                    className="w-40 h-40 mx-auto mb-3 object-contain"
                  />
                  <div className="flex z-10 items-center justify-center mb-4 mx-14 border-b border-gray-300">
                    <label
                      htmlFor="email"
                      className="block text-sm font-bold text-gray-700 border-r border-gray-300"
                    >
                      <EnvelopeIcon className="w-5 h-5 mx-2" />
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="block w-full rounded-md py-1.5 pl-2 pr-8 text-gray-900 placeholder:text-gray-400 ring-0 focus:ring-0 shadow-none focus:shadow-none focus:outline-none border-none focus:border-none"
                      value={email}
                      onChange={(e) => {
                        if (e.target.value.length <= MAX_EMAIL_LENGTH) {
                          setEmail(e.target.value);
                        }
                      }}
                      placeholder="아이디(이메일)"
                    />
                  </div>
                  <div className="flex items-center justify-center mb-2 mx-14 border-b border-gray-300">
                    <label
                      htmlFor="password"
                      className="block text-sm font-bold text-gray-700 border-r border-gray-300"
                    >
                      <LockClosedIcon className="w-5 h-5 mx-2" />
                    </label>
                    <input
                      type="password"
                      id="password"
                      className="block w-full rounded-md py-1.5 pl-2 pr-8 text-gray-900 placeholder:text-gray-400 ring-0 focus:ring-0 shadow-none focus:shadow-none focus:outline-none border-none focus:border-none"
                      value={password}
                      onChange={(e) => {
                        if (e.target.value.length <= MAX_PASSWORD_LENGTH) {
                          setPassword(e.target.value);
                        }
                      }}
                      placeholder="비밀번호"
                    />
                  </div>
                  <div className="flex flex-col mb-10 mx-14">
                    {emailError && <ErrorMessage msg={"아이디를 입력하세요."} />}
                    {passwordError && <ErrorMessage msg={"비밀번호를 입력하세요."} />}
                    {loginError && (
                      <ErrorMessage
                        msg={"아이디 또는 비밀번호를 잘못 입력했습니다. 다시 확인하세요."}
                      />
                    )}
                  </div>
                  <div className="flex items-center justify-between mx-14 mb-10">
                    <button
                      className="w-full px-4 py-2 font-bold text-white rounded-md focus:outline-none focus:shadow-outline"
                      style={{ backgroundColor: "#EB5604" }}
                      type="submit"
                    >
                      로그인
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Layout.Section>
        </Layout>
      </AppProvider>
    </>
  );
};

export default AdminLogin;
