import React from "react";
import { Layout, Page } from "@shopify/polaris";

import AdminFrame from "components/Layout/Frame";
import UsersList from "./List";

const UserPage = () => {
  return (
    <>
      <AdminFrame title="사용자 관리">
        <Page title="사용자 관리" fullWidth>
          <Layout>
            <Layout.Section>
              <UsersList />
            </Layout.Section>
          </Layout>
        </Page>
      </AdminFrame>
    </>
  );
};

export default UserPage;
