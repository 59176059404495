import React, { useState, useEffect, useRef } from "react";

const PresetDropdown = ({
  beanId,
  menuId,
  presetList,
  presetSearchInput,
  setPresetSearchInput,
  selectedPresets,
  setSelectedPresets,
  validateData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownMenuRef = useRef(null);

  const selectedPreset = selectedPresets.find(
    (preset) => preset.beanId === beanId && preset.menuId === menuId
  );

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setPresetSearchInput("");
  };

  const handleSearchChange = (e) => {
    setPresetSearchInput(e.target.value);
  };

  const handlePresetSelect = (presetData) => {
    setSelectedPresets((prevSelectedPresets) => [
      ...prevSelectedPresets.filter((acc) => !(acc.beanId === beanId && acc.menuId === menuId)),
      { ...presetData, beanId, menuId },
    ]);
    setIsOpen(false);

    validateData();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div className="flex w-full">
        <div className="relative group w-full">
          <button
            onClick={toggleDropdown}
            className="inline-flex justify-between w-2/3 px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
          >
            <span className="mr-2">{selectedPreset.presetTitle || "프리셋 선택"}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 ml-2 -mr-1 text-gray-500"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {isOpen && (
            <div
              ref={dropdownMenuRef}
              className="absolute z-50 w-2/3 max-h-96 overflow-y-auto left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1"
            >
              <input
                className="block w-full px-2 py-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none"
                type="text"
                placeholder="검색어를 입력하세요."
                autoComplete="off"
                value={presetSearchInput}
                onChange={handleSearchChange}
              />
              {presetList
                .filter((preset) => preset.presetTitle.includes(presetSearchInput))
                .map((preset, index) => (
                  <div
                    key={index}
                    className="flex items-center px-2 py-2 hover:bg-gray-100"
                    onClick={() => handlePresetSelect(preset)}
                  >
                    <span className="text-gray-700 cursor-pointer rounded-md">
                      {preset.presetTitle}
                    </span>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <div className="py-3">
        ({selectedPreset.waterFirst ? "물 먼저" : "에스프레소 먼저"}, 샷 {selectedPreset.shot}, 물{" "}
        {selectedPreset.hotWater === 0 ? selectedPreset.coldWater : selectedPreset.hotWater}{" "}
        {selectedPreset.hotWater === 0 && selectedPreset.coldWater === 0
          ? "온수"
          : selectedPreset.hotWater === 0
          ? "정수"
          : "온수"}
        , 원두량 {selectedPreset.beanAmount}, 에스프레소량 {selectedPreset.espresso}, 뜸들이기{" "}
        {selectedPreset.preInfusionTime ? selectedPreset.preInfusionTime / 1000 : ""}{" "}
        {selectedPreset.preInfusionTime > 0 ? "사용함" : "사용 안 함"})
      </div>
    </div>
  );
};

export default PresetDropdown;
