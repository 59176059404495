import API from "./api";

export const getBeanList = async (page, size, category, filter, search) => {
  const response = await API.get("/beans", {
    params: {
      page: page,
      size: size,
      category: category,
      filter: filter,
      search: search,
    },
  });
  return response.data;
};

export const getBeanDetail = async (id) => {
  const response = await API.get(`/beans/${id}`);
  return response;
};

export const updateBeanDeactivate = async (id, deactivate, lastModifier) => {
  const response = await API.patch(`/beans/${id}/deactivate`, {
    deactivate: deactivate,
    lastModifier: lastModifier,
  });
  return response;
};

export const getBeanOrder = async (category) => {
  const response = await API.get("/beans/order", {
    params: {
      category: category,
    },
  });
  return response.data;
};

export const updateBeanOrder = async (beanList, lastModifier) => {
  const response = await API.post("/beans/order", {
    beanOrder: beanList,
    lastModifier: lastModifier,
  });
  return response.data;
};

export const getMenusForPresets = async () => {
  const response = await API.get("/beans/menus");
  return response.data;
};

export const createBean = async (bean) => {
  const response = await API.post("/beans", bean);
  return response;
};

export const updateBean = async (id, bean) => {
  const response = await API.patch(`/beans/${id}`, bean);
  return response;
};

export const getBeanSelect = async (id) => {
  const response = await API.get("/beans/select/presets", {
    params: {
      removeBeanId: id,
    },
  });
  return response.data;
};
