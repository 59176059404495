import React, { Fragment, useState, useContext, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { UserInfoContextState } from "contexts/User";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { updateWashingCycle } from "../../../../rest-apis/devices";
import { toast } from "react-hot-toast";

const washingCycleList = [
  {
    title: "사용 안 함",
    type: "NONE",
    value: "",
  },
  {
    title: "20잔마다",
    type: "EVERY_CUPS",
    value: "20",
  },
  {
    title: "30잔마다",
    type: "EVERY_CUPS",
    value: "30",
  },
  {
    title: "40잔마다",
    type: "EVERY_CUPS",
    value: "40",
  },
  {
    title: "50잔마다",
    type: "EVERY_CUPS",
    value: "50",
  },
  {
    title: "하루에 한 번",
    type: "EVERY_TIMES",
    value: "",
  },
];

const WashingCycleBar = ({ id, open, setOpen, isEdit, setIsEdit, deviceDetail }) => {
  const { userInfo } = useContext(UserInfoContextState);

  const [washingCycleType, setWashingCycleType] = useState(deviceDetail.washingCycleType);
  const [washingCycleValue, setWashingCycleValue] = useState(deviceDetail.washingCycleValue);

  const [ampm, setAmpm] = useState(
    washingCycleType == "EVERY_TIMES" ? (washingCycleValue.split(":")[0] >= 12 ? "PM" : "AM") : "AM"
  );
  const [hour, setHour] = useState(
    washingCycleType == "EVERY_TIMES"
      ? washingCycleValue.split(":")[0] >= 12
        ? parseInt(washingCycleValue.split(":")[0]) - 12
        : washingCycleValue.split(":")[0] === "00"
        ? 12
        : parseInt(washingCycleValue.split(":")[0])
      : 1
  );
  const [minute, setMinute] = useState(
    washingCycleType == "EVERY_TIMES" ? washingCycleValue.split(":")[1] : "00"
  );

  const handleWashingCycleChange = (type, value) => {
    setWashingCycleType(type);
    if (type === "EVERY_TIMES") {
      setting24Hours(ampm, hour, minute);
    } else {
      setWashingCycleValue(value);
    }
  };

  const setting24Hours = (ampm, hour, minute) => {
    if (ampm === "AM") {
      setWashingCycleValue(`${String(hour).padStart(2, "0")}:${minute}`);
      return;
    } else if (ampm === "PM") {
      setWashingCycleValue(`${String(Number(hour) + 12).padStart(2, "0")}:${minute}`);
      return;
    }
  };

  const cancelWashingCycle = () => {
    setOpen(false);
    setIsEdit(false);

    setWashingCycleType(deviceDetail.washingCycleType);
    setWashingCycleValue(deviceDetail.washingCycleValue);

    if (deviceDetail.washingCycleType === "EVERY_TIMES") {
      setAmpm(washingCycleValue.split(":")[0] >= 12 ? "PM" : "AM");
      setHour(
        washingCycleValue.split(":")[0] >= 12
          ? parseInt(washingCycleValue.split(":")[0]) - 12
          : washingCycleValue.split(":")[0] === "00"
          ? 12
          : parseInt(washingCycleValue.split(":")[0])
      );
      setMinute(deviceDetail.washingCycleValue.split(":")[1]);
    }
  };

  const patchWashingCycle = async (washingCycleType, washingCycleValue) => {
    if (!confirm("자동 세척 주기를 변경하시겠습니까?")) return;

    if (ampm === "AM" && hour === "12") {
      washingCycleValue = `00:${minute}`;
    }

    setWashingCycleType(washingCycleType);
    setWashingCycleValue(washingCycleValue);

    await updateWashingCycle(id, userInfo.name, washingCycleType, washingCycleValue)
      .then((response) => {
        if (response.response === "success") {
          toast.success("자동 세척 주기 변경 요청을 보냈습니다.");
        } else {
          toast.error("자동 세척 주기 변경 요청을 보내지 못했습니다.");
        }
      })
      .catch((response) => {
        if (response.response === "fail") {
          toast.error("자동 세척 주기 변경 요청을 보내지 못했습니다.");
        }
      });
  };

  useEffect(() => {
    setting24Hours(ampm, hour, minute);
  }, [ampm, hour, minute]);

  useEffect(() => {
    setWashingCycleType(deviceDetail.washingCycleType);
    setWashingCycleValue(deviceDetail.washingCycleValue);
  }, [deviceDetail]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0" onClick={() => cancelWashingCycle()} />
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          자동 세척 주기 변경
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                            onClick={() => cancelWashingCycle()}
                          >
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <ul
                      role="list"
                      className="flex-1 divide-y divide-gray-200 overflow-y-auto border-t"
                    >
                      {washingCycleList.map((washingCycle, index) => (
                        <li key={washingCycle.title}>
                          <label htmlFor={washingCycle.title} className="block w-full">
                            <div className="group relative flex items-center px-5 py-8">
                              <div className="absolute inset-0" aria-hidden="true" />
                              <input
                                id={washingCycle.title}
                                name="washingCycle"
                                type="radio"
                                checked={
                                  washingCycleType === "EVERY_CUPS"
                                    ? washingCycle.type === washingCycleType &&
                                      washingCycle.value === washingCycleValue
                                    : washingCycle.type === washingCycleType
                                }
                                onChange={() =>
                                  handleWashingCycleChange(washingCycle.type, washingCycle.value)
                                }
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              ></input>
                              <div className="relative flex min-w-0 flex-1 items-center">
                                <div className="w-full flex justify-between items-center ml-4 truncate">
                                  <p className="truncate text-sm font-normal text-gray-700">
                                    {washingCycle.title}
                                  </p>
                                  {washingCycle.type === "EVERY_TIMES" ? (
                                    <div className="flex">
                                      <select
                                        id="ampm"
                                        name="ampm"
                                        value={ampm}
                                        onChange={(e) => {
                                          setWashingCycleType("EVERY_TIMES");
                                          setAmpm(e.target.value);
                                        }}
                                        className="mt-2 block w-16 rounded-md border-0 py-1.5 pl-1.5 pr-2 mr-1 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      >
                                        <option>AM</option>
                                        <option>PM</option>
                                      </select>
                                      <select
                                        id="hour"
                                        name="hour"
                                        value={hour}
                                        onChange={(e) => {
                                          setWashingCycleType("EVERY_TIMES");
                                          setHour(e.target.value);
                                        }}
                                        className="mt-2 block w-16 rounded-md border-0 py-1.5 pl-1.5 pr-2 mr-1 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      >
                                        {Array.from({ length: 12 }, (_, i) => i + 1).map(
                                          (selectHour) => (
                                            <option key={selectHour} value={selectHour}>
                                              {selectHour}
                                            </option>
                                          )
                                        )}
                                      </select>
                                      <select
                                        id="minute"
                                        name="minute"
                                        value={minute}
                                        onChange={(e) => {
                                          setWashingCycleType("EVERY_TIMES");
                                          setMinute(String(e.target.value).padStart(2, "0"));
                                        }}
                                        className="mt-2 block w-16 rounded-md border-0 py-1.5 pl-1.5 pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                      >
                                        {Array.from({ length: 6 }, (_, i) => i * 10).map(
                                          (selectMin) => (
                                            <option
                                              key={selectMin}
                                              value={String(selectMin).padStart(2, "0")}
                                            >
                                              {String(selectMin).padStart(2, "0")}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  ) : (
                                    <div></div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </label>
                        </li>
                      ))}
                    </ul>

                    <div className="flex justify-end items-center h-24 p-5 border-t border-gray-200">
                      <button
                        type="button"
                        className="w-14 h-9 rounded-md bg-white m-1 px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => cancelWashingCycle()}
                      >
                        취소
                      </button>
                      <button
                        type="button"
                        className={
                          isEdit
                            ? "w-14 h-9 rounded-md bg-indigo-600 m-1 px-2.5 py-1.5 text-sm font-medium text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-700"
                            : "w-14 h-9 rounded-md bg-gray-200 m-1 px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300"
                        }
                        onClick={() => {
                          if (!isEdit) return;
                          patchWashingCycle(washingCycleType, washingCycleValue).then(() => {
                            setOpen(false) && setIsEdit(false);
                          });
                        }}
                        disabled={!isEdit}
                      >
                        저장
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default WashingCycleBar;
