import React from "react";

import AdminFrame from "components/Layout/Frame";

const NotFoundPage = () => {
  return (
    <>
      <AdminFrame title="조회된 데이터가 없습니다.">
        <div className="flex justify-center items-center h-screen">
          <div className="flex flex-col justify-center items-center">
            <img src="/images/file-search.png" alt="404" className="w-16 h-16 m-6" />
            <div className="text-gray-300 font-black text-3xl">조회된 데이터가 없습니다.</div>
          </div>
        </div>
      </AdminFrame>
    </>
  );
};

export default NotFoundPage;
