import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Page } from "@shopify/polaris";

import { getContentsList } from "../../../rest-apis/contents";
import { PermissionContextState } from "contexts/Permission";

import CardSpinner from "common/PageSpinner/Card";
import Pagination from "common/Pagination";
import FilterBar from "./FilterBar";
import ContentList from "./List";

const ContentsList = () => {
  const navigate = useNavigate();

  // permissions
  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "CONTENT" && p.canWrite);
  };

  // content
  const [contents, setContents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [contentPage, setContentPage] = useState([]);
  const totalPages = contentPage.totalPages;
  const pageSize = 10;

  // filterBar
  const [search, setSearch] = useState("");
  const [displayType, setDisplayType] = useState("추출중,추출 완료");
  const [contentType, setContentType] = useState("이미지,동영상");

  const fetchContents = async (page) => {
    setIsLoading(true);

    const data = await getContentsList(page, pageSize, displayType, contentType, search);

    if (data._embedded && Array.isArray(data._embedded.contentListDTOList)) {
      setContents(data._embedded.contentListDTOList);
    } else {
      setContents([]);
    }
    setContentPage(data.page);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchContents(currentPage);
  }, [search, displayType, contentType, currentPage]);

  return (
    <>
      <Page title="콘텐츠 관리" fullWidth>
        <FilterBar
          setSearch={setSearch}
          setDisplayType={setDisplayType}
          setContentType={setContentType}
        />
        <div className="flex justify-end items-center ml-7 mr-5">
          <div>
            {canWritePage() && (
              <button
                type="button"
                className="w-14 h-9 rounded-md bg-white px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                onClick={() => navigate("/contents/add")}
              >
                추가
              </button>
            )}
          </div>
        </div>
        {isLoading ? (
          <CardSpinner />
        ) : (
          <div>
            <ContentList rows={contents} fetchContents={fetchContents} currentPage={currentPage} />
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
        )}
      </Page>
    </>
  );
};

export default ContentsList;
