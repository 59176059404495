import React, { useEffect, useState, useContext } from "react";

import CardSpinner from "common/PageSpinner/Card";
import FilterBar from "./FilterBar";
import Pagination from "common/Pagination";

import { PermissionContextState } from "contexts/Permission";
import { getAppVersion } from "../../../rest-apis/appVersions";
import AppVersionList from "./List";
import AppVersionModal from "../Form/modal";

const AppVersionsList = () => {
  // permission
  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "APP_VERSION" && p.canWrite);
  };

  // app Versions
  const [appVersions, setAppVersions] = useState([]);
  const [appVersion, setAppVersion] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [updateAppId, setUpdateAppId] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [appVersionPage, setAppVersionPage] = useState([]);
  const totalPages = appVersionPage.totalPages;
  const pageSize = 10;

  // filterBar
  const [search, setSearch] = useState("");

  const fetchAppVersion = async (page) => {
    setIsLoading(true);

    const response = await getAppVersion(page, pageSize, search);

    if (response.data._embedded && Array.isArray(response.data._embedded.appListDTOList)) {
      setAppVersions(response.data._embedded.appListDTOList);
    } else {
      setAppVersions([]);
    }
    setAppVersionPage(response.data.page);

    setIsLoading(false);
  };

  // permission modal
  const [isAppVersionModalOpen, setIsAppVersionModalOpen] = useState(false);

  useEffect(() => {
    fetchAppVersion(currentPage);
  }, [currentPage, search]);

  return (
    <>
      <FilterBar setSearch={setSearch} fetchAppVersion={fetchAppVersion} />
      <div className="flex justify-end items-center ml-7 mr-5">
        <div>
          {canWritePage() && (
            <button
              type="button"
              className="w-14 h-9 rounded-md bg-white px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => setIsAppVersionModalOpen(true)}
            >
              추가
            </button>
          )}
        </div>
      </div>
      {isLoading ? (
        <CardSpinner />
      ) : (
        <div>
          <AppVersionList
            rows={appVersions}
            fetchAppVersion={fetchAppVersion}
            currentPage={currentPage}
            isOpen={isAppVersionModalOpen}
            setIsOpen={setIsAppVersionModalOpen}
            setAppVersion={setAppVersion}
            setIsUpdate={setIsUpdate}
            setUpdateAppId={setUpdateAppId}
          />
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
          <AppVersionModal
            isOpen={isAppVersionModalOpen}
            setIsOpen={setIsAppVersionModalOpen}
            fetchAppVersion={fetchAppVersion}
            appVersion={appVersion}
            setAppVersion={setAppVersion}
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
            updateAppId={updateAppId}
          />
        </div>
      )}
    </>
  );
};

export default AppVersionsList;
