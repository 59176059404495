import React, { useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

const FilterBar = ({ setSearch }) => {
  const [inputSearch, setInputSearch] = useState("");

  const handleSearchChange = (e) => {
    setInputSearch(e.target.value);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    setSearch(inputSearch);
  };

  return (
    <div className=" bg-white shadow sm:rounded-lg mb-5">
      <div className="px-4 py-4 sm:px-6">
        <form onSubmit={handleSearchClick}>
          <div className="flex columns-3">
            <div className="relative my-2 w-11/12 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                name="text"
                id="text"
                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="검색어를 입력하세요."
                onChange={handleSearchChange}
              />
            </div>
            <button
              type="submit"
              className="w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleSearchClick}
            >
              검색
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FilterBar;
