import React from "react";
import ReactQuill from "react-quill";

const PreviewComponent = ({ previewDescription }) => {
  return (
    <div>
      <ReactQuill
        className="custom-quill"
        theme="bubble"
        value={previewDescription}
        readOnly={true}
      />
    </div>
  );
};

export default PreviewComponent;
