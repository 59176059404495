import React, { Fragment, useEffect, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import UploadApk from "./apk";

import { saveAppVersion, updateAppVersion } from "../../../rest-apis/appVersions";
import toast from "react-hot-toast";

const MAX_MEMO_LENGTH = 30;

const AppVersionModal = ({
  isOpen,
  setIsOpen,
  fetchAppVersion,
  appVersion,
  setAppVersion,
  setIsUpdate,
  isUpdate,
  updateAppId,
}) => {
  const userName = localStorage.getItem("name");

  const [isUploaded, setIsUploaded] = useState(false);
  const [memoInput, setMemoInput] = useState("");

  const [apk, setApk] = useState({
    version: "-",
    buildNumber: "-",
    size: "-",
  });

  const handleSaveAppVersionButton = async () => {
    if (!window.confirm("저장하시겠습니까?")) {
      return;
    }

    if (isUpdate) {
      const promise = updateAppVersion(updateAppId, memoInput, userName);
      try {
        const response = await promise;

        if (response.status === 200) {
          setIsOpen(false);
          setApk({
            version: "-",
            buildNumber: "-",
            size: "-",
          });
          setAppVersion({
            fileUrl: "",
            checksum: "",
            version: "",
            buildNumber: 0,
            size: 0,
            packageName: "",
          });
          setMemoInput("");
          toast.success("저장되었습니다.");
          fetchAppVersion();
        }
      } catch (error) {
        toast.error(
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "서버 오류가 존재합니다."
        );
      } finally {
        setIsUpdate(false);
      }
    } else {
      const newAppVersion = {
        ...appVersion,
        memo: memoInput ? memoInput : "",
        lastModifier: userName,
      };
      const promise = saveAppVersion(newAppVersion);

      try {
        const response = await promise;
        if (response.status === 200) {
          setIsOpen(false);
          setApk({
            version: "-",
            buildNumber: "-",
            size: "-",
          });
          setAppVersion({
            fileUrl: "",
            checksum: "",
            version: "",
            buildNumber: 0,
            size: 0,
            packageName: "",
          });
          setMemoInput("");
          toast.success("저장되었습니다.");
          fetchAppVersion();
        }
      } catch (error) {
        toast.error(
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : "서버 오류가 존재합니다."
        );
      } finally {
        setIsUpdate(false);
      }
    }
  };

  const isValidTextInput = (text) => {
    const regex = /^[A-Za-z0-9ㄱ-ㅎㅏ-ㅣ가-힣~!@#₩$%^&*()\-_+=,./<>?\\|\n ]*$/;
    return regex.test(text);
  };

  useEffect(() => {
    if (!isOpen) {
      setIsUploaded(false);
      setApk({
        ...apk,
        version: "-",
        buildNumber: "-",
        size: "-",
      });
      setMemoInput("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (appVersion.version) {
      setMemoInput(appVersion.memo);
      setApk({
        ...apk,
        version: appVersion.version,
        buildNumber: appVersion.buildNumber,
        size: appVersion.size,
      });
    }
  }, [appVersion]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full min-w-full overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white w-1/2 px-4 pb-4 pt-5 text-left shadow-xl transition-all">
                <div className="w-full my-2 mx-3 text-left">
                  <Dialog.Title as="h3" className="flex w-full mb-10 text-xl leading-6 text-black">
                    <div className="font-bold">앱 버전 관리 추가</div>
                    <div className="flex justify-center items-center ml-4 text-sm text-gray-400">
                      {process.env.REACT_APP_ENVIRONMENT === "production"
                        ? "(패키지명: one.brownbag.aware)"
                        : "(패키지명: one.brownbag.aware.debug)"}
                    </div>
                  </Dialog.Title>
                  <div className="flex">
                    <table className="w-full text-left table-auto">
                      <tbody>
                        <tr key="apk-multipartFile" className="border-b border-gray-200">
                          <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                            APK 파일 등록
                          </td>
                          <td className="relative py-4">
                            <UploadApk
                              apk={apk}
                              setApk={setApk}
                              setAppVersion={setAppVersion}
                              setIsUploaded={setIsUploaded}
                            />
                          </td>
                        </tr>
                        <tr key="apk-version" className="border-b border-gray-200">
                          <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                            버전
                          </td>
                          <td className="relative py-4">
                            {apk.version != "-" ? apk.version : "-"}
                          </td>
                        </tr>
                        <tr key="apk-buildNumber" className="border-b border-gray-200">
                          <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                            빌드 넘버
                          </td>
                          <td className="relative py-4">
                            {apk.buildNumber != "-" ? apk.buildNumber : "-"}
                          </td>
                        </tr>
                        <tr key="apk-size" className="border-b border-gray-200">
                          <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                            크기
                          </td>
                          <td className="relative py-4">
                            {apk.size != "-"
                              ? `${parseFloat((apk.size * 0.000001).toFixed(2))} MB`
                              : "-"}
                          </td>
                        </tr>
                        <tr key="apk-memo">
                          <td className="w-1/5 py-4 whitespace-nowrap align-top">
                            <span className="font-medium mr-1">메모</span>
                            <span className="text-sm font-normal text-gray-400">(선택)</span>
                          </td>
                          <td className="py-4 pr-6">
                            <div className="relative">
                              <input
                                type="text"
                                name="apk-memo"
                                id="apk-memo"
                                value={memoInput}
                                maxLength={MAX_MEMO_LENGTH}
                                onChange={(e) => {
                                  if (
                                    e.target.value.length <= MAX_MEMO_LENGTH &&
                                    isValidTextInput(e.target.value)
                                  ) {
                                    setIsUploaded(true);
                                    setMemoInput(e.target.value);
                                    setApk({
                                      ...apk,
                                      memo: e.target.value,
                                    });
                                  }
                                }}
                                className={
                                  "block w-full rounded-md border-0 py-1.5 pl-2 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  sm:text-sm sm:leading-6"
                                }
                              />
                              <div className="absolute mr-3 right-0 top-1/2 transform -translate-y-1/2 text-s text-gray-500">{`${
                                memoInput ? memoInput.length : 0
                              }/${MAX_MEMO_LENGTH}`}</div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="mt-10 mb-3 text-center">
                  <button
                    type="button"
                    className="w-14 h-9 rounded-md bg-white m-1 px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => {
                      setIsOpen(false);
                      setIsUploaded(false);
                    }}
                  >
                    취소
                  </button>
                  <button
                    type="button"
                    className={`w-14 h-9 rounded-md px-3 py-2 mt-2 ml-2 text-sm font-semibold shadow-sm ${
                      isUploaded
                        ? "text-white bg-indigo-600 hover:bg-indigo-500"
                        : "text-gray-700 bg-gray-200"
                    }`}
                    onClick={() => handleSaveAppVersionButton()}
                    disabled={!isUploaded}
                  >
                    저장
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AppVersionModal;
