import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

const CertificationChart = ({ certificationData }) => {
  const [options, setOptions] = useState({
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "5%",
      left: "center",
    },
    series: [
      {
        name: "정품 인증 비율",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 40,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: 0, name: "인증" },
          { value: 0, name: "미인증" },
        ],
      },
    ],
  });

  useEffect(() => {
    if (certificationData) {
      setOptions((prevOptions) => ({
        ...prevOptions,
        series: [
          {
            ...prevOptions.series[0],
            data: [
              { value: certificationData.certificatedRatio, name: "인증" },
              { value: certificationData.unCertificatedRatio, name: "미인증" },
            ],
          },
        ],
      }));
    }
  }, [certificationData]);

  return (
    <div className="h-72">
      <ReactECharts option={options} setOptions={setOptions} theme={"westeros"} />
    </div>
  );
};

export default CertificationChart;
