import React from "react";
import ReactQuill from "react-quill";

const PreviewComponent = ({ previewAnswer }) => {
  const customStyle = {
    color: "white",
    fontSize: "11px",
    fontWeight: "400",
    overflowY: "auto",
  };

  return (
    <div style={customStyle} className="overflow-y-auto">
      <ReactQuill className="custom-quill" theme="bubble" value={previewAnswer} readOnly={true} />
    </div>
  );
};

export default PreviewComponent;
