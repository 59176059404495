import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

import { Menu } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/20/solid";

const FaqItem = (props) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "listItem",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = props.index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      props.moveFaq(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "listItem",
    item: () => {
      return { id: props.id, index: props.index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <li
      ref={ref}
      style={{ ...{ cursor: "grab" }, opacity }}
      data-handler-id={handlerId}
      key={props.id}
    >
      <div className="group relative flex items-center px-5 py-6">
        <div className="absolute inset-0 group-hover:bg-gray-50" aria-hidden="true" />
        <div className="relative flex min-w-0 flex-1 items-center">
          <div className="ml-4 truncate">
            <p className="truncate text-sm font-normal text-gray-700">{props.question}</p>
          </div>
        </div>
        <Menu as="div" className="relative ml-2 inline-block flex-shrink-0 text-left">
          <Menu.Button className="group relative inline-flex h-8 w-8 items-center justify-center rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span className="flex h-full w-full items-center justify-center rounded-full">
              <Bars3Icon
                className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
            </span>
          </Menu.Button>
        </Menu>
      </div>
    </li>
  );
};

export default FaqItem;
