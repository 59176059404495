import API from "./api";

export const getGuideList = async (page, size, search) => {
  const response = await API.get("/guides", {
    params: {
      page: page,
      size: size,
      search: search,
    },
  });
  return response.data;
};

export const getGuideOrder = async () => {
  const response = await API.get("/guides/order");
  return response.data;
};

export const updateGuideOrder = async (guideIds, lastModifier) => {
  const response = await API.patch("/guides/order", {
    guideIds: guideIds,
    lastModifier: lastModifier,
  });
  return response.data;
};

export const getGuideDetail = async (id) => {
  const response = await API.get(`/guides/${id}`);
  return response.data;
};

export const updateGuideDeactivate = async (id, deactivate, lastModifier) => {
  const response = await API.patch(`/guides/${id}/deactivate`, {
    deactivate: deactivate,
    lastModifier: lastModifier,
  });
  return response.data;
};

export const createGuide = async (guide) => {
  const response = await API.post("/guides", guide);
  return response;
};

export const updateGuide = async (id, guide) => {
  const response = await API.patch(`/guides/${id}`, guide);
  return response;
};
