import React, { useState, useCallback, useContext, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/20/solid";

import { LoginContextState, UserInfoContextState } from "contexts/User";
import { PermissionContextState } from "contexts/Permission";
import { EllipsisVerticalIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import PasswordResetModal from "../../Login/password";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const TopSection = () => {
  const { setUserInfo } = useContext(UserInfoContextState);
  const { isLoggedIn, setIsLoggedIn } = useContext(LoginContextState);
  const { setUserPermissions } = useContext(PermissionContextState);

  const name = localStorage.getItem("name");

  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

  const toggleIsUserMenuOpen = useCallback(
    () => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
    []
  );
  const UpdatePasswordModalOpen = useCallback(
    () => setIsPasswordModalOpen((isPasswordModalOpen) => !isPasswordModalOpen),
    []
  );

  return (
    <div>
      <div className="flex h-16 shrink-0 items-center">
        <a href="/">
          <img className="w-40 pt-7" src="/images/brownbag-logo.png" alt="brownbag_aware_logo" />
        </a>
      </div>
      <Menu as="div" className="mt-4 pt-4 -mx-3">
        <Menu.Button className="flex w-full items-center gap-x-4 px-6 py-3 rounded-md text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-200">
          <div className="flex justify-between w-full min-w-0">
            <div className="font-bold text-base text-left text-gray-600" aria-hidden="true">
              {name}
            </div>
            <EllipsisVerticalIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
          </div>
        </Menu.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="absolute right-0 z-10 mt-1 w-42 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            open={isUserMenuOpen}
            onToggle={isLoggedIn ? toggleIsUserMenuOpen : undefined}
          >
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={UpdatePasswordModalOpen}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 w-full text-sm hover:bg-gray-100"
                    )}
                  >
                    <LockClosedIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    비밀번호 재설정
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => {
                      setUserInfo({});
                      setUserPermissions({});
                      setIsLoggedIn(false);
                      localStorage.removeItem("token");
                      localStorage.removeItem("email");
                      localStorage.removeItem("name");
                    }}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 w-full text-sm hover:bg-gray-100"
                    )}
                  >
                    <ArrowRightOnRectangleIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    로그아웃
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <PasswordResetModal
        onClose={() => setIsPasswordModalOpen(false)}
        isOpen={isPasswordModalOpen}
        setIsOpen={setIsPasswordModalOpen}
      />
    </div>
  );
};

export default TopSection;
