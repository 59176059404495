import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Dialog, Transition } from "@headlessui/react";
import { updatePassword } from "../../rest-apis/login";
import { toast } from "react-hot-toast";
import ErrorMessage from "../../common/Badge/errorMessage";
import { LoginContextState } from "contexts/User";
import { PermissionContextState } from "../../contexts/Permission";

const MAX_PASSWORD_LENGTH = 12;
const MIN_PASSWORD_LENGTH = 8;

const PasswordResetModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate();

  const { setIsLoggedIn } = useContext(LoginContextState);
  const { setUserPermissions } = useContext(PermissionContextState);
  const email = localStorage.getItem("email");

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const [passwordValidError, setPasswordValidError] = useState(false);

  const isValidPassword = (password) => {
    const regex = /^[A-Za-z0-9]*$/;
    return regex.test(password);
  };

  const handlePasswordSaveButton = async () => {
    if (passwordValidError) return;
    if (newPassword.length === 0) {
      toast.error("신규 비밀번호를 입력하세요.");
      return;
    }
    if (newPasswordConfirm.length === 0) {
      toast.error("신규 비밀번호 확인을 입력하세요.");
      return;
    }
    if (newPassword !== newPasswordConfirm) {
      toast.error("신규 비밀번호와 일치하지 않습니다.");
      return;
    }

    await updatePassword({ email, newPassword })
      .then(() => {
        alert("비밀번호가 재설정되었습니다. 다시 로그인 해주세요.");
        setIsOpen(false);

        setUserPermissions({});
        setIsLoggedIn(false);
        localStorage.clear();

        setTimeout(() => {
          navigate("/login");
        }, 200);
        return;
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("서버 오류가 존재합니다.");
        }
      });
  };

  useEffect(() => {
    if (
      newPassword &&
      (newPassword.length < MIN_PASSWORD_LENGTH || !isValidPassword(newPassword))
    ) {
      setPasswordValidError(true);
    } else {
      setPasswordValidError(false);
    }
  }, [newPassword]);

  useEffect(() => {
    if (
      newPasswordConfirm &&
      (newPasswordConfirm.length < MIN_PASSWORD_LENGTH || !isValidPassword(newPasswordConfirm))
    ) {
      setPasswordValidError(true);
    } else {
      setPasswordValidError(false);
    }
  }, [newPasswordConfirm]);

  useEffect(() => {
    if (!isOpen) {
      setNewPassword("");
      setNewPasswordConfirm("");
    }
  }, [isOpen]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="my-4 text-center">
                    <Dialog.Title as="h3" className="mb-12 text-2xl font-bold leading-6 text-black">
                      비밀번호 재설정
                    </Dialog.Title>
                    <div className="flex justify-between w-full my-3 px-4">
                      <div className="flex mr-11 justify-center items-center whitespace-nowrap">
                        신규 비밀번호
                      </div>
                      <input
                        type="password"
                        id="newPassword"
                        className="block w-3/4 rounded-md py-1.5 pl-2 pr-8 text-gray-900 placeholder:text-gray-400 ring-1 ring-gray-300 focus:ring-1 shadow-none focus:shadow-gray-300 focus:outline-none"
                        value={newPassword}
                        onChange={(e) => {
                          if (e.target.value.length <= MAX_PASSWORD_LENGTH) {
                            setNewPassword(e.target.value);
                          }
                        }}
                        placeholder="8-12자의 영문/숫자 조합"
                      />
                    </div>
                    <div className="flex justify-between w-full my-3 px-4">
                      <div className="flex mr-4 justify-center items-center whitespace-nowrap">
                        신규 비밀번호 확인
                      </div>
                      <input
                        type="password"
                        id="newPasswordConfirm"
                        className="block w-3/4 rounded-md py-1.5 pl-2 pr-8 text-gray-900 placeholder:text-gray-400 ring-1 ring-gray-300 focus:ring-1 shadow-none focus:shadow-gray-300 focus:outline-none"
                        value={newPasswordConfirm}
                        onChange={(e) => {
                          if (e.target.value.length <= MAX_PASSWORD_LENGTH) {
                            setNewPasswordConfirm(e.target.value);
                          }
                        }}
                        placeholder="8-12자의 영문/숫자 조합"
                      />
                    </div>
                    <div className="flex flex-col justify-between w-full px-4">
                      {passwordValidError && (
                        <ErrorMessage msg={"8-12자의 영문 또는 숫자로 입력하세요."} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-10 text-center">
                  <button
                    type="button"
                    className="w-14 h-9 rounded-md bg-white m-1 px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => setIsOpen(false)}
                  >
                    취소
                  </button>
                  <button
                    type="button"
                    className="w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => handlePasswordSaveButton()}
                  >
                    저장
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PasswordResetModal;
