import React, { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Page } from "@shopify/polaris";
import { PermissionContextState } from "../../../contexts/Permission";

import AdminFrame from "components/Layout/Frame";
import MachineDetailInfoPage from "components/Machine/Detail/Info";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";
import CardSpinner from "../../../common/PageSpinner/Card";

import { getDeviceDetail } from "../../../rest-apis/devices";
import BrewingLogPage from "./Brewing";
import WashingLogPage from "./Washing";
import AlarmLogPage from "./Alarm";
import RemotePage from "./Remote";

const tabs = [
  { id: "info", name: "상세 정보", current: true, disabled: false },
  { id: "brewing", name: "커피 추출 기록", current: false, disabled: false },
  { id: "washing", name: "세척 기록", current: false, disabled: false },
  { id: "alarm", name: "알림 기록", current: false, disabled: false },
  { id: "remote", name: "원격 관리", current: false, disabled: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MachineDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "MENU" && p.canWrite);
  };

  const [deviceDetail, setDeviceDetail] = useState({});
  const [deviceStatus, setDeviceStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchDevice = async (id) => {
    setIsLoading(true);

    try {
      const response = await getDeviceDetail(id);
      if (response.status === 200) {
        setDeviceDetail(response.data.deviceInfo);
        setDeviceStatus(response.data.status);
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        if (status === 400 || status === 404 || status === 500) {
          navigate("/not-found");
        }
      }
    } finally {
      setIsLoading(false);
      disalbeRemoteTab();
    }
  };

  const disalbeRemoteTab = () => {
    if (!canWritePage()) {
      setTabsState((prevState) => {
        const updatedTabs = [...prevState];
        updatedTabs[4].disabled = true;
        return updatedTabs;
      });
    } else {
      setTabsState((prevState) => {
        const updatedTabs = [...prevState];
        updatedTabs[4].disabled = false;
        return updatedTabs;
      });
    }
  };

  const [tabsState, setTabsState] = useState(tabs);
  const [selectedTab, setSelectedTab] = useState(0);
  const [machineDetailChildren, setMachineDetailChidren] = useState(null);

  const handleTabChange = useCallback((selectedTabIndex) => {
    setSelectedTab(selectedTabIndex);
    const updatedTabs = tabsState.map((tab, index) => {
      if (index === selectedTabIndex) {
        return { ...tab, current: true };
      }
      return { ...tab, current: false };
    });
    setTabsState(updatedTabs);
  }, []);

  useEffect(() => {
    switch (selectedTab) {
      case 0:
        setMachineDetailChidren(
          <MachineDetailInfoPage
            // device={device}
            deviceDetail={deviceDetail}
            deviceStatus={deviceStatus}
            id={id}
          />
        );
        break;
      case 1:
        setMachineDetailChidren(<BrewingLogPage id={id} />);
        break;
      case 2:
        setMachineDetailChidren(<WashingLogPage id={id} />);
        break;
      case 3:
        setMachineDetailChidren(<AlarmLogPage id={id} />);
        break;
      case 4:
        setMachineDetailChidren(<RemotePage id={id} deviceDetail={deviceDetail} />);
        break;
    }
  }, [selectedTab, deviceDetail, deviceStatus, id]);

  useEffect(() => {
    fetchDevice(id);
  }, [id]);

  return (
    <AdminFrame title="머신 관리 조회">
      <div className="flex justify-between">
        <div className="flex ml-6 mt-6 items-center">
          <ArrowSmallLeftIcon
            type="button"
            className="w-9 h-9 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => {
              navigate("/devices");
            }}
          />
          <div className="ml-3 text-xl font-bold text-gray-800">
            머신 관리 조회 | {deviceDetail.androidId}
          </div>
          <div className="ml-4 text-gray-700">그룹</div>
          <div className="ml-3 text-gray-700">
            {deviceDetail.groupAccountName ? (
              <Link
                to={`/group-accounts/${deviceDetail.groupAccountId}`}
                key={deviceDetail.groupAccountId}
                className="font-bold text-blue-600 underline"
              >
                {deviceDetail.groupAccountName}
              </Link>
            ) : (
              <span className="font-bold text-gray-400">없음</span>
            )}
          </div>
          <div className="ml-3 text-gray-700">계정</div>
          <div className="ml-3 text-gray-700">
            {deviceDetail.accountName ? (
              <Link
                to={`/accounts/${deviceDetail.accountId}`}
                key={deviceDetail.accountId}
                className="font-bold text-blue-600 underline"
              >
                {deviceDetail.accountName}
              </Link>
            ) : (
              <span className="font-bold text-gray-400">없음</span>
            )}
          </div>
        </div>
      </div>
      <Page fullWidth>
        <div className="bg-white shadow flex flex-col sm:rounded-lg mb-5">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              defaultValue={tabs.find((tab) => tab.current).name}
            >
              {tabs.map((tab) => (
                <option key={tab.name}>{tab.name}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex" aria-label="Tabs">
                {tabsState.map((tab, index) => (
                  <a
                    key={tab.name}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!tab.disabled) {
                        handleTabChange(index);
                      }
                    }}
                    className={classNames(
                      tab.disabled
                        ? "text-gray-300 cursor-not-allowed"
                        : tab.current
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium cursor-pointer"
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
          <div className="h-full">{isLoading ? <CardSpinner /> : machineDetailChildren}</div>
        </div>
      </Page>
    </AdminFrame>
  );
};
export default MachineDetailPage;
