import React from "react";

const Detail = ({ content }) => {
  return (
    <div className="flex items-start">
      <div className="w-full overflow-hidden bg-white shadow sm:rounded-lg mb-5">
        <div className="px-8 py-6 sm:px-6">
          <div className="text-base font-semibold">상세 정보</div>
          <table className="w-full mt-5 text-left table-auto">
            <tbody>
              <tr key={`${content.id}-title`} className="border-b border-gray-100">
                <td className="w-1/5 py-4 align-top whitespace-nowrap font-medium">콘텐츠명</td>
                <td>{content.title}</td>
              </tr>
              <tr key={`${content.id}-displayType`} className="border-b border-gray-100">
                <td className="w-1/5 py-4 align-top whitespace-nowrap font-medium">노출 위치</td>
                <td>{content.displayType}</td>
              </tr>
              <tr key={`${content.id}-displayType`} className="border-b border-gray-100">
                <td className="w-1/5 py-4 align-top whitespace-nowrap font-medium">콘텐츠 타입</td>
                <td>{content.contentType}</td>
              </tr>
              {content.brewingContent != null && (
                <tr key={`${content.id}-brewing-contents`} className="border-b border-gray-100">
                  <td className="w-1/5 py-4 whitespace-nowrap align-top font-medium">
                    추출중 콘텐츠
                  </td>
                  <td className="py-4">
                    <div>{content.brewingContent.aspectRatio}</div>
                    <div className="flex flex-wrap">
                      {content.brewingContent.files.map(
                        (file) =>
                          file.fileUrl != "" && (
                            <div
                              key={file.fileUrl}
                              className="overflow-hidden w-36 h-36 mr-2 my-2 flex items-center justify-center border rounded-md border-gray-300"
                            >
                              {file.fileUrl.includes("mp4") ? (
                                <video
                                  src={file.fileUrl}
                                  autoPlay
                                  muted
                                  loop
                                  className="object-contain h-36 w-36"
                                />
                              ) : (
                                <img src={file.fileUrl} className="object-contain h-36 w-36" />
                              )}
                            </div>
                          )
                      )}
                    </div>
                  </td>
                </tr>
              )}
              {content.completeContent != null && (
                <tr key={`${content.id}-complete-contents`} className="border-b border-gray-100">
                  <td className="w-1/5 py-4 whitespace-nowrap align-top font-medium">
                    추출 완료 콘텐츠
                  </td>
                  <td className="py-4">
                    <div className="flex flex-wrap">
                      <div
                        key={content.completeContent.fileUrl}
                        className="overflow-hidden w-36 h-36 mr-2 my-2 flex items-center justify-center border rounded-md border-gray-300"
                      >
                        <img
                          src={content.completeContent.files[0].fileUrl}
                          className="object-contain w-36 h-36"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              <tr key={`${content.id}-memo`} className="border-b border-gray-100">
                <td className="w-1/5 py-4 whitespace-nowrap">
                  <span className="font-medium mr-1">메모</span>
                  <span className="text-sm font-normal text-gray-400">(선택)</span>
                </td>
                <td className="py-4 whitespace-pre-wrap">{content.memo}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Detail;
