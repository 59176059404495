import React from "react";
import ReactQuill from "react-quill";

const PreviewDetailComponent = ({ previewDescription }) => {
  return (
    <div>
      <ReactQuill
        className="custom-quill-detail"
        theme="bubble"
        value={previewDescription}
        readOnly={true}
      />
    </div>
  );
};

export default PreviewDetailComponent;
