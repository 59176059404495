import React from "react";

import moment from "moment/moment";
import { Link } from "react-router-dom";

const AppDeployList = ({ rows }) => {
  const formatDateTime = (name) => {
    const [versionPart, dateTimePart] = name.split("_");
    const [datePart, timePart] = dateTimePart.split(" ");

    const dateElements = datePart.split("-");
    const correctedDateStr = `20${dateElements[0]}-${dateElements[1]}-${dateElements[2]}T${timePart}`;

    const date = moment(correctedDateStr).add(9, "hours").format("YY-MM-DD HH:mm:ss");

    return versionPart + "_" + date;
  };

  return (
    <div className="mt-5 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="w-1/12 py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-500 sm:pl-6"
                  >
                    NO
                  </th>
                  <th
                    scope="col"
                    className="w-2/12 whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    배포명
                  </th>
                  <th
                    scope="col"
                    className="w-1/12 whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    대상
                  </th>
                  <th
                    scope="col"
                    className="w-1/12 whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    설치 완료
                  </th>
                  <th
                    scope="col"
                    className="w-1/12 whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    설명
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    등록자
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    배포 시작 일시
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    배포 완료 일시
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-3 pr-4 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    배포 종료 일시
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {rows.length > 0 ? (
                  rows.map((row) => (
                    <tr key={row.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
                        {row.no}
                      </td>
                      <td className="whitespace-nowrap w-1/12 px-3 py-4 text-sm text-gray-500">
                        <Link
                          to={{
                            pathname: String(row.id),
                          }}
                          key={row.id}
                          className="text-indigo-600 underline"
                        >
                          {formatDateTime(row.name)}
                        </Link>
                      </td>
                      <td className="whitespace-nowrap w-1/12 px-3 py-4 text-sm text-gray-500">
                        {row.totalDevices}
                      </td>
                      <td className="whitespace-nowrap w-1/12 px-3 py-4 text-sm text-gray-500">
                        {row.installedDevices}
                      </td>
                      <td className="w-2/12 px-3 py-4 text-sm text-gray-500">
                        {row.description == null || row.description === "" ? "-" : row.description}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {row.requestedBy}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {moment(row.startedAt).add(9, "hour").format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {row.finishedAt != null
                          ? moment(row.finishedAt).add(9, "hour").format("YYYY-MM-DD HH:mm:ss")
                          : "진행중"}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {row.endedAt != null
                          ? moment(row.endedAt).add(9, "hour").format("YYYY-MM-DD HH:mm:ss")
                          : "진행중"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <td
                    colSpan={9}
                    className="text-center pt-20 pb-16 text-gray-300 font-bold text-lg"
                  >
                    조회된 데이터가 없습니다.
                  </td>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppDeployList;
