import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { getMenuSelect, updateMenuStatus } from "../../rest-apis/menus";
import { getBeanSelect, updateBeanDeactivate } from "../../rest-apis/beans";
import { updatePresetAppData } from "../../rest-apis/appData";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import PresetDropdown from "../dropdown/remove/preset";
import toast from "react-hot-toast";

const RemoveModal = ({ isOpen, setIsOpen, removeBean, removeMenu, fetchBeans, fetchMenus }) => {
  const userName = localStorage.getItem("name");

  const [beanPresetSelects, setBeanPresetSelects] = useState([]);
  const [menuPresetSelects, setMenuPresetSelects] = useState([]);
  const [targetedPresets, setTargetedPresets] = useState([]);
  const [updatedTargetedPresets, setUpdatedTargetedPresets] = useState(
    Array(targetedPresets.length).fill({ beanId: null, menuId: null, presetId: null })
  );
  const [presetSearchInput, setPresetSearchInput] = useState("");

  const handlePresetSaveButton = async () => {
    if (!window.confirm("프리셋을 변경하고 삭제하시겠습니까?")) {
      return;
    }

    const presetDataSets = updatedTargetedPresets.map((preset) => ({
      beanId: preset.beanId,
      menuId: preset.menuId,
      presetId: preset.id,
    }));

    const promise = updatePresetAppData({
      presetDataSets: presetDataSets,
      lastModifier: userName,
    });

    try {
      const response = await promise;
      if (response.status === 200) {
        if (removeBean) {
          const response = await updateBeanDeactivate(
            removeBean.id,
            removeBean.deactivate,
            userName
          );
          if (response.status === 200) {
            toast.success("프리셋 변경 후 원두 삭제되었습니다.");
            setIsOpen(false);
            fetchBeans();
          }
        } else if (removeMenu) {
          const response = await updateMenuStatus(
            removeMenu.id,
            removeMenu.status,
            "사용 안 함",
            userName
          );
          if (response.status === 200) {
            toast.success("프리셋 변경 후 메뉴 삭제되었습니다.");
            setIsOpen(false);
            fetchMenus();
          }
        }
      }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "서버 오류가 존재합니다."
      );
    }
  };

  const fetchMenuPresets = async () => {
    const response = await getMenuSelect(removeMenu.id);
    setMenuPresetSelects(response);
  };

  const fetchBeanPresets = async () => {
    const response = await getBeanSelect(removeBean.id);
    setBeanPresetSelects(response);
  };

  useEffect(() => {
    if (isOpen) {
      if (removeBean) {
        fetchBeanPresets();
        setTargetedPresets(removeBean.targetedPresets);
      } else if (removeMenu) {
        fetchMenuPresets();
        setTargetedPresets(removeMenu.targetedPresets);
      }
    } else {
      setUpdatedTargetedPresets([]);
    }
  }, [isOpen]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-full min-w-full overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all">
                <div className="my-2 mx-3 text-left">
                  <Dialog.Title as="h3" className="mb-2 text-xl font-bold leading-6 text-black">
                    {removeBean ? "프리셋 변경 후 원두 삭제" : "프리셋 변경 후 메뉴 삭제"}
                  </Dialog.Title>
                  <div>
                    {removeBean
                      ? `[${removeBean.name}] 원두 삭제 시 해당 원두로 생성된 프리셋이 삭제됩니다.`
                      : `[${removeMenu.name}] 메뉴 삭제 시 해당 메뉴로 생성된 프리셋이 삭제됩니다.`}
                  </div>
                  <div>현재 앱에 노출되고 있는 프리셋을 변경해 주세요.</div>
                  <div className="mt-8 mb-4 flex justify-between">
                    <div className="ml-24">현재 프리셋 설정</div>
                    <div className="mr-24">변경 프리셋 설정</div>
                  </div>
                  <div className="w-full my-1 border border-gray-300"></div>
                  <div>
                    {targetedPresets?.map((preset, index) => (
                      <div key={index} className="flex px-2 py-2">
                        <div>
                          <div>원두명 : {preset.beanName}</div>
                          <div>메뉴명 : {preset.menuName}</div>
                          <div className="my-2">
                            <Menu as="div" className="relative inline-block text-left">
                              <div>
                                <Menu.Button className="inline-flex justify-between w-96  gap-x-1.5 rounded-md bg-gray-100 px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 cursor-default">
                                  {preset.presetName}
                                  <ChevronDownIcon
                                    className="-mr-1 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                              </div>
                            </Menu>
                          </div>
                        </div>
                        <ArrowRightIcon className="w-6 h-6 m-8 text-gray-800" />
                        <div>
                          <div>원두명 : {preset.beanName}</div>
                          <div>메뉴명 : {preset.menuName}</div>
                          <div className="my-2">
                            <PresetDropdown
                              beanId={preset.beanId}
                              menuId={preset.menuId}
                              presetList={removeBean ? beanPresetSelects : menuPresetSelects}
                              presetSearchInput={presetSearchInput}
                              setPresetSearchInput={setPresetSearchInput}
                              selectedPresets={updatedTargetedPresets}
                              setSelectedPresets={setUpdatedTargetedPresets}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mt-10 mb-3 text-center">
                  <button
                    type="button"
                    className="w-14 h-9 rounded-md bg-white m-1 px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => {
                      setIsOpen(false);
                      setUpdatedTargetedPresets([]);
                    }}
                  >
                    취소
                  </button>
                  <button
                    type="button"
                    className={
                      "w-14 h-9 rounded-md px-3 py-2 mt-2 ml-2 text-sm font-semibold shadow-sm text-white bg-red-600 hover:bg-red-500"
                    }
                    onClick={() => handlePresetSaveButton()}
                  >
                    삭제
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default RemoveModal;
