import React, { useContext, useEffect, useState } from "react";
import { Page } from "@shopify/polaris";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { createBean, updateBean, getBeanDetail } from "../../../rest-apis/beans";

import { toast } from "react-hot-toast";
import { UserInfoContextState } from "contexts/User";

import CardSpinner from "common/PageSpinner/Card";
import usePrompt from "utils/Prompt";
import AdminFrame from "components/Layout/Frame";
import BeanFormDetail from "components/Beans/Form/Detail";

const BeansForm = () => {
  const { userInfo } = useContext(UserInfoContextState);
  const { id } = useParams();

  const navigate = useNavigate();
  const locationState = useLocation().state?.bean;
  const pathname = useLocation().pathname;

  const [isPrompt, setIsPrompt] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [beansState, setBeansState] = useState({});

  // 상세 정보 에러
  const [brewingInfoError, setBrewingInfoError] = useState({
    beanAmounts: Array(0).fill({
      message: null,
      timestamp: null,
    }),
    espressoAmounts: Array(0).fill({
      message: null,
      timestamp: null,
    }),
  });

  const hasError = (array) => {
    return array.length > 0 && array.some((item) => item !== undefined);
  };

  const mapResponseToBeansState = (data) => ({
    name: data.name || "",
    category: data.category || "BROWNBAG",
    isPublic: Object.prototype.hasOwnProperty.call(data, "isPublic") ? data.isPublic : true,
    groupAccountId: data.groupAccount ? data.groupAccount.id : null,
    itemCode: data.itemCode || "",
    brewingInfo: {
      beanAmounts: data.brewingInfo?.beanAmounts || [],
      espressoAmounts: data.brewingInfo?.espressoAmounts || [],
    },
    basicDescription: data.basicDescription || "",
    basicDescriptionParticle: data.basicDescriptionParticle || "는",
    cupNoteIds: data.cupNote?.map((c) => c.id) || [null, null, null],
    lastModifier: userInfo.name,
  });

  const fetchBean = async (id) => {
    setIsLoading(true);
    const response = await getBeanDetail(id);
    setBeansState(mapResponseToBeansState(response.data));
    setIsLoading(false);
  };

  useEffect(() => {
    if (pathname.split("/").includes("edit") && !locationState) {
      navigate("/beans");
      return;
    }

    if (locationState?.id) {
      fetchBean(locationState.id);
    } else {
      setBeansState(mapResponseToBeansState(locationState || {}));
    }
  }, [id, locationState]);

  usePrompt(
    "다른 페이지로 이동하겠습니까? 이 페이지에서 변경한 내용은 저장되지 않습니다.",
    isPrompt
  );

  const uploadBeans = async () => {
    const updatedBeansState = {
      ...beansState,
      cupNoteIds: beansState.cupNoteIds.filter((item) => item !== null),
    };

    // 토스트 에러 출력
    if (beansState.name === "") return toast.error("원두명을 입력하세요.");
    if (beansState.category === "GROUP_PRIVATE" && beansState.groupAccountId === null)
      return toast.error("그룹을 지정하세요.");
    if (
      beansState.brewingInfo.beanAmounts.length < 3 ||
      beansState.brewingInfo.beanAmounts.some((item) => item === "") ||
      beansState.brewingInfo.espressoAmounts.length < 3 ||
      beansState.brewingInfo.espressoAmounts.some((item) => item === "")
    ) {
      return toast.error("기본 화면 (2) 정보를 모두 입력하세요.");
    }

    if (beansState.basicDescription === "") return toast.error("맛 설명을 입력하세요.");
    if (updatedBeansState.cupNoteIds.length < 1)
      return toast.error("컵노트를 1개 이상 선택하세요.");
    if (updatedBeansState.cupNoteIds.length !== new Set(updatedBeansState.cupNoteIds).size) {
      toast.error("컵노트는 중복되지 않게 등록하세요.");
      return;
    }

    // 실시간 에러 확인
    if (hasError(brewingInfoError.beanAmounts) || hasError(brewingInfoError.espressoAmounts))
      return;

    if (!window.confirm("상세 정보를 저장하시겠습니까?")) {
      return;
    }

    setIsSaving(true);

    const promise = locationState
      ? updateBean(id, updatedBeansState)
      : createBean(updatedBeansState);

    try {
      const response = await promise;
      if (response.status === 200 || response.status === 201) {
        toast.success(locationState ? "수정되었습니다." : "저장되었습니다.");
        setIsPrompt(false);
        setTimeout(() => {
          navigate(locationState ? `/beans/${id}` : `/beans/${response.data.beanId}`);
        }, 500);
      }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "서버 오류가 존재합니다."
      );
    } finally {
      setTimeout(() => {
        setIsSaving(false);
      }, 300);
    }
  };

  return (
    <AdminFrame title={locationState ? "원두 관리 수정" : "원두 관리 추가"}>
      <div>
        <div className="flex justify-between">
          <div className="flex ml-6 mt-6 items-center">
            <ArrowSmallLeftIcon
              type="button"
              className="w-9 h-9 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => {
                navigate("/beans");
              }}
            />
            <div className="ml-3 text-xl font-bold text-gray-800">
              {locationState ? `원두 관리 수정 | ${locationState.name}` : "원두 관리 추가"}
            </div>
          </div>
          <div className="mt-6 mr-6">
            <button
              type="button"
              className={
                isSaving
                  ? "w-24 h-9 rounded-md bg-gray-200 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  : "w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
              onClick={() => uploadBeans()}
              disabled={isSaving}
            >
              {isSaving ? (
                <div className="flex">
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                  <div>저장중</div>
                </div>
              ) : (
                "저장"
              )}
            </button>
          </div>
        </div>
        <Page fullWidth>
          {isLoading ? (
            <CardSpinner />
          ) : (
            <div className="flex">
              <BeanFormDetail
                beanState={beansState}
                setBeanState={setBeansState}
                brewingInfoError={brewingInfoError}
                setBrewingInfoError={setBrewingInfoError}
              />
            </div>
          )}
        </Page>
      </div>
    </AdminFrame>
  );
};

export default BeansForm;
