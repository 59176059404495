import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

const BrewingTypeChart = ({ brewingTypeRatio }) => {
  const [options, setOptions] = useState({
    tooltip: {
      trigger: "item",
    },
    legend: {
      top: "5%",
      left: "center",
    },
    series: [
      {
        name: "커피 추출 방식",
        type: "pie",
        radius: ["40%", "70%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: false,
            fontSize: 40,
            fontWeight: "bold",
          },
        },
        labelLine: {
          show: false,
        },
        data: [
          { value: 0, name: "기본 화면 1" },
          { value: 0, name: "기본 화면 2" },
        ],
      },
    ],
  });

  useEffect(() => {
    if (brewingTypeRatio) {
      setOptions((prevOptions) => ({
        ...prevOptions,
        series: [
          {
            ...prevOptions.series[0],
            data: brewingTypeRatio.map((item) => ({
              value: item.ratio,
              name: item.brewingType,
            })),
          },
        ],
      }));
    }
  }, [brewingTypeRatio]);

  return (
    <div className="h-72">
      <ReactECharts option={options} setOptions={setOptions} theme={"westeros"} />
    </div>
  );
};

export default BrewingTypeChart;
