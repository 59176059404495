import API from "./api";

export const getDeviceList = async (page, size, status, filter, search) => {
  const response = await API.get("/devices", {
    params: {
      page: page,
      size: size,
      status: status,
      filter: filter,
      search: search,
    },
  });
  return response.data;
};

export const getDeviceDetail = async (id) => {
  const response = await API.get(`/devices/${id}`);
  return response;
};

export const getBrewingLogs = async (id, filter, page, size) => {
  const response = await API.get(`/devices/${id}/brewingLogs`, {
    params: {
      page: page,
      size: size,
      filter: filter,
    },
  });
  return response;
};

export const getWashingLogs = async (id, filter, page, size) => {
  const response = await API.get(`/devices/${id}/washingLogs`, {
    params: {
      page: page,
      size: size,
      filter: filter,
    },
  });
  return response;
};

export const getAlarmLogs = async (id, tabName, displayDateRange, page, size) => {
  const response = await API.get(`/devices/${id}/alarmLogs`, {
    params: {
      page: page,
      size: size,
      tabName: tabName,
      displayDateRange: displayDateRange,
    },
  });
  return response;
};

export const remoteSimpleWashing = async (id, requester) => {
  const response = await API.get(`/devices/${id}/remote/simpleWashing`, {
    params: {
      requester: requester,
    },
  });
  return response.data;
};

export const resetPassword = async (id, requester) => {
  const response = await API.get(`/devices/${id}/remote/resetPassword`, {
    params: {
      requester: requester,
    },
  });
  return response.data;
};

export const updateWashingCycle = async (id, requester, washingCycleType, washingCycleValue) => {
  const response = await API.patch(`/devices/${id}/remote/washingCycle`, {
    washingCycleType: washingCycleType,
    washingCycleValue: washingCycleValue,
    requester: requester,
  });
  return response.data;
};

export const machineReboot = async (id, requester) => {
  const response = await API.get(`/devices/${id}/remote/appReboot`, {
    params: {
      requester: requester,
    },
  });
  return response.data;
};

export const brewer = async (id, requester) => {
  const response = await API.get(`/devices/${id}/remote/brewer`, {
    params: {
      requester: requester,
    },
  });
  return response.data;
};

export const getRemoteLogs = async (id, page, size) => {
  const response = await API.get(`/devices/${id}/remote/logs`, {
    params: {
      page: page,
      size: size,
    },
  });
  return response;
};

export const requestRemainBrewingCount = async (id, requester) => {
  const response = await API.get(`/devices/${id}/remainBrewingCount`, {
    params: {
      requester: requester,
    },
  });
  return response.data;
};
