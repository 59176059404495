import React, { useEffect, useState } from "react";
import { Layout, Page } from "@shopify/polaris";

import CardSpinner from "common/PageSpinner/Card";
import AdminFrame from "components/Layout/Frame";
import FilterBar from "../../Machine/List/FilterBar";
import MachineList from "./List";
import Pagination from "common/Pagination";

import { getDeviceList } from "../../../rest-apis/devices";

const MachineListPage = () => {
  // Check user role
  // const { userLevel } = useContext(UserLevelContext);

  // devices
  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;

  // filterBar
  const [status, setStatus] = useState("on,off");
  const [filter, setFilter] = useState("all");
  const [search, setSearch] = useState("");

  const fetchDevices = async (page) => {
    setIsLoading(true);

    const response = await getDeviceList(page, pageSize, status, filter, search);
    setDevices(response.results);
    setTotalPages(response.totalPages);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchDevices(currentPage);
  }, [currentPage, status, filter, search]);

  return (
    <>
      <AdminFrame title="머신 관리">
        <Page fullWidth title="머신 관리">
          <Layout>
            <Layout.Section>
              <FilterBar setFilter={setFilter} setSearch={setSearch} setStatus={setStatus} />
              {isLoading ? (
                <CardSpinner />
              ) : (
                <div>
                  <MachineList rows={devices} />
                  <Pagination
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalPages={totalPages}
                  />
                </div>
              )}
            </Layout.Section>
          </Layout>
        </Page>
      </AdminFrame>
    </>
  );
};

export default MachineListPage;
