import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PermissionContextState } from "contexts/Permission";

import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";

import { getMenuDetail } from "../../../rest-apis/menus";
import AdminFrame from "components/Layout/Frame";
import CardSpinner from "common/PageSpinner/Card";
import { Page } from "@shopify/polaris";

const DefaultMenuDetailPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "MENU" && p.canWrite);
  };

  const [menu, setMenu] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchMenu = async (id) => {
    setIsLoading(true);

    try {
      const response = await getMenuDetail(id);
      if (response.status === 200) {
        setMenu(response.data);
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        if (status === 400 || status === 404 || status === 500) {
          navigate("/not-found");
        }
      }
    } finally {
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchMenu(id);
  }, [id]);

  return (
    <>
      <AdminFrame title="메뉴 관리 조회">
        {isLoading ? (
          <CardSpinner />
        ) : (
          <>
            <div className="flex justify-between">
              <div className="flex ml-6 mt-6 items-center">
                <ArrowSmallLeftIcon
                  type="button"
                  className="w-9 h-9 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => {
                    navigate("/menus");
                  }}
                />
                <div className="ml-3 text-xl font-bold text-gray-800">
                  메뉴 관리 조회 | {menu.name}
                </div>
              </div>
              <div className="mt-6 mr-10">
                {canWritePage() &&
                  (menu.status === "사용 안 함" ? (
                    <div></div>
                  ) : (
                    <button
                      type="button"
                      className="w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => {
                        setTimeout(() => {
                          navigate(`/menus/${id}/edit`, {
                            state: {
                              menu: menu,
                            },
                          });
                        }, 500);
                      }}
                    >
                      수정
                    </button>
                  ))}
              </div>
            </div>
            <Page fullWidth>
              <div className="flex">
                <div className="w-7/12 mr-4 overflow-hidden bg-white shadow sm:rounded-lg mb-5">
                  <div className="px-8 py-6 sm:px-6">
                    <div className="text-base font-semibold">상세 정보</div>
                    <table className="w-full mt-5 text-left table-auto">
                      <tbody>
                        <tr key={`${menu.id}-name`} className="border-b border-gray-100">
                          <td className="font-medium w-1/3 py-4 align-top">메뉴명</td>
                          <td className="whitespace-pre-wrap">{menu.name}</td>
                        </tr>
                        <tr key={`${menu.imageUrl}-name`} className="border-b border-gray-100">
                          <td className="font-medium w-1/3 py-4 align-top">
                            <div>이미지</div>
                            <div className="text-sm text-gray-400">(184*188px, png)</div>
                          </td>
                          <td className="py-4">
                            <img
                              src={menu.imageUrl}
                              className="object-contain h-24 w-24 border border-gray-300 rounded-md"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="w-7/12 mr-4 overflow-hidden bg-white shadow sm:rounded-lg mb-5">
                  <div className="px-8 py-6 sm:px-6">
                    <div className="text-base font-semibold">미리보기</div>
                    <div className="flex flex-col items-center justify-center">
                      <div
                        className="flex flex-col items-center rounded-lg m-4"
                        style={{
                          width: "226px",
                          height: "350px",
                          background: "var(--grey-02, #242426)",
                        }}
                      >
                        <div
                          style={{
                            width: "184px",
                            height: "188px",
                            margin: "0 16px",
                            marginTop: "32px",
                            marginBottom: "25px",
                          }}
                        >
                          <img src={menu.imageUrl} />
                        </div>
                        <div
                          className="text-center text-white whitespace-pre-wrap"
                          style={{
                            margin: "0 24px",
                            // width: "168px",
                            height: "26px",
                            fontSize: "20px",
                            fontStyle: "normal",
                            fontWeight: "600",
                            lineHeight: "26px",
                          }}
                        >
                          {menu.name}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Page>
          </>
        )}
      </AdminFrame>
    </>
  );
};

export default DefaultMenuDetailPage;
