import React from "react";
import { Layout, Page } from "@shopify/polaris";

import AdminFrame from "components/Layout/Frame";
import GroupAccountsList from "./List";

const GroupAccountPage = () => {
  return (
    <>
      <AdminFrame title="그룹 계정 관리">
        <Page fullWidth title="그룹 계정 관리">
          <>
            <Layout>
              <Layout.Section>
                <GroupAccountsList />
              </Layout.Section>
            </Layout>
          </>
        </Page>
      </AdminFrame>
    </>
  );
};

export default GroupAccountPage;
