import React from "react";
import { Layout, Page } from "@shopify/polaris";

import AdminFrame from "components/Layout/Frame";
import GuidesList from "./List";

const GuidePage = () => {
  return (
    <>
      <AdminFrame title="사용 설명 보기">
        <Page title="사용 설명 보기" fullWidth>
          <Layout>
            <Layout.Section>
              <GuidesList />
            </Layout.Section>
          </Layout>
        </Page>
      </AdminFrame>
    </>
  );
};

export default GuidePage;
