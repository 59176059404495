import axios from "axios";

const baseUrl =
  process.env.REACT_APP_ENVIRONMENT === "production"
    ? "https://backend.brownbag-aware.com/api/v1.1/web"
    : "https://staging-backend.brownbag-aware.com/api/v1.1/web";

const instance = axios.create({
  baseURL: baseUrl,
});

export default instance;
