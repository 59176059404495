export const translateRole = (role) => {
  const roleTranslations = {
    ROLE_ADMIN: "관리자",
    ROLE_LEADER: "팀장",
    ROLE_MEMBER: "멤버",
    ROLE_GUEST: "게스트(조회)",
  };

  return roleTranslations[role] || role;
};
