import React, { useState, useEffect } from "react";
import { PlusCircleIcon } from "@heroicons/react/20/solid";

import UploadScreenSaver from "common/UploadImage/content/screenSaver";

const contentType = [
  {
    id: 1,
    label: "이미지(png, jpg)",
    value: "이미지",
  },
  {
    id: 2,
    label: "동영상(mp4)",
    value: "동영상",
  },
];

const ScreenSaverForm = ({
  isScreenSaverLoading,
  screenSaverState,
  setScreenSaverState,
  screenSaverImageContent,
  setScreenSaverImageContent,
  screenSaverVideoContent,
  setScreenSaverVideoContent,
}) => {
  const MAX_SCREEN_SAVER_BOX_SIZE = 7;

  const [screenSaverCount, setScreenSaverCount] = useState(
    screenSaverImageContent ? screenSaverImageContent.length : 1
  );

  const addScreenSaver = () => {
    if (screenSaverCount < MAX_SCREEN_SAVER_BOX_SIZE) {
      setScreenSaverImageContent((prev) => [...prev, null]);
      setScreenSaverCount((prevCount) => prevCount + 1);
    }
  };

  const handleContentTypeClick = (e) => {
    const newContentType = e.target.dataset.id;
    setScreenSaverState((prev) => ({ ...prev, contentType: newContentType }));
  };

  useEffect(() => {
    if (screenSaverImageContent.length > 0) {
      setScreenSaverCount(screenSaverImageContent.length);
    } else {
      setScreenSaverCount(1);
    }
  }, [screenSaverImageContent]);

  useEffect(() => {
    if (!isScreenSaverLoading) {
      if (screenSaverState?.contentType == "이미지") {
        setScreenSaverImageContent(screenSaverState.files);
        setScreenSaverVideoContent([]);
      } else if (screenSaverState.contentType == "동영상") {
        setScreenSaverVideoContent(screenSaverState.files);
        setScreenSaverImageContent([]);
      } else {
        setScreenSaverImageContent([]);
        setScreenSaverVideoContent([]);
      }
    }
  }, [isScreenSaverLoading]);

  return (
    <>
      <div className="flex flex-col h-full px-4 my-6">
        <div className="w-full h-full mr-4 overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-8 py-6 sm:px-6">
            <div className="text-base font-semibold">대기화면</div>
            <fieldset className="my-4">
              <legend className="sr-only">Screen Saver Content Type</legend>
              <div className="flex flex-wrap justify-start space-y-4  space-x-8 sm:space-y-1">
                {contentType.map((contentType) => (
                  <div
                    key={`screen-saver-content-type-${contentType.value}`}
                    className="flex items-center"
                  >
                    <input
                      id={`screen-saver-content-type-${contentType.value}`}
                      data-id={contentType.value}
                      name="creen-saver-content-type"
                      value={contentType.value}
                      type="radio"
                      checked={
                        screenSaverState
                          ? contentType.value === screenSaverState.contentType
                          : false
                      }
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onClick={handleContentTypeClick}
                    />
                    <label
                      htmlFor={`screen-saver-content-type-${contentType.value}`}
                      className="ml-3 block text-sm font-medium leading-6 text-light whitespace-nowrap"
                    >
                      {contentType.label}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
            <div>
              {screenSaverState.contentType === "이미지" ? (
                <div className="flex flex-wrap mt-4 w-auto overflow-auto">
                  {Array.from({ length: screenSaverCount }).map((_, index) => (
                    <UploadScreenSaver
                      key={index}
                      index={index}
                      contentType={screenSaverState.contentType}
                      length={screenSaverImageContent ? screenSaverImageContent.length : 0}
                      defaultImageUrl={
                        screenSaverImageContent[index] ? screenSaverImageContent[index] : null
                      }
                      screenSaverImageContent={screenSaverImageContent}
                      setScreenSaverImageContent={setScreenSaverImageContent}
                    />
                  ))}
                  <div className="flex items-center">
                    {screenSaverCount < MAX_SCREEN_SAVER_BOX_SIZE - 1 && (
                      <PlusCircleIcon
                        type="button"
                        className="w-8 h-8 ml-2 text-gray-400"
                        onClick={addScreenSaver}
                      ></PlusCircleIcon>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <UploadScreenSaver
                    key="0"
                    contentType={screenSaverState.contentType}
                    defaultImageUrl={screenSaverVideoContent[0] ? screenSaverVideoContent[0] : null}
                    screenSaverVideoContent={screenSaverVideoContent}
                    setScreenSaverVideoContent={setScreenSaverVideoContent}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScreenSaverForm;
