import React, { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { UserInfoContextState } from "contexts/User";
import { PermissionContextState } from "contexts/Permission";

import moment from "moment/moment";
import { toast } from "react-hot-toast";

import { deleteUser } from "../../../rest-apis/user";
import { translateRole } from "../type/roleType";

const UserList = ({ rows, fetchUser, currentPage }) => {
  const navigate = useNavigate();
  const { userInfo } = useContext(UserInfoContextState);
  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "USER" && p.canWrite);
  };

  const fetchUserDelete = async (id) => {
    const response = await deleteUser(id, userInfo.name);
    return response;
  };

  const handleDeleteButtonClick = (id) => () => {
    if (!confirm("삭제하시겠습니까? 삭제 후 원복 불가합니다.")) return;

    fetchUserDelete(id).then(() => {
      toast.success("삭제되었습니다.");
      fetchUser(currentPage);
    });
  };

  return (
    <div className="mt-5 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="w-1/12 py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-500 sm:pl-6"
                  >
                    NO
                  </th>
                  <th
                    scope="col"
                    className="w-5/12 whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    이름
                  </th>
                  <th
                    scope="col"
                    className="w-1/12 whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    아이디(이메일)
                  </th>
                  <th
                    scope="col"
                    className="w-1/12 whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    권한
                  </th>
                  <th
                    scope="col"
                    className="w-1/12 whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    최종 수정자
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    최종 수정 일시
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                  >
                    최초 등록 일시
                  </th>
                  {canWritePage() && (
                    <>
                      <th
                        scope="col"
                        className="w-1/12 px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                      >
                        수정
                      </th>
                      <th
                        scope="col"
                        className="w-1/12 px-3 py-3.5 text-left text-sm font-medium text-gray-500"
                      >
                        삭제
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {rows.length > 0 ? (
                  rows.map((row) => (
                    <tr key={row.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
                        {row.no}
                      </td>
                      <td className="whitespace-nowrap w-1/12 px-3 py-4 text-sm text-gray-500">
                        <Link
                          to={{
                            pathname: String(row.id),
                          }}
                          key={row.id}
                          className="text-indigo-600 underline"
                        >
                          {row.name}
                        </Link>
                      </td>
                      <td className="whitespace-nowrap w-2/12 px-3 py-4 text-sm text-gray-500">
                        {row.email}
                      </td>
                      <td className="whitespace-nowrap w-2/12 px-3 py-4 text-sm text-gray-500">
                        {translateRole(row.role)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {row.lastModifier}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {moment(row.lastModifiedAt).add(9, "hour").format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {moment(row.createdAt).add(9, "hour").format("YYYY-MM-DD HH:mm:ss")}
                      </td>
                      {canWritePage() && (
                        <>
                          <td className="relative whitespace-nowrap px-3 py-4 text-left text-sm font-medium sm:pr-6">
                            <button
                              className="text-indigo-600 hover:text-indigo-900"
                              onClick={() => {
                                setTimeout(() => {
                                  navigate(`/users/${row.id}/edit`, {
                                    state: {
                                      user: row,
                                    },
                                  });
                                }, 500);
                              }}
                            >
                              수정
                            </button>
                          </td>
                          <td className="relative whitespace-nowrap px-3 py-4 text-left text-sm font-medium sm:pr-6">
                            <button
                              className={`${
                                row.email == "aware_admin@brownbag.one" ||
                                row.email == userInfo.email
                                  ? "text-gray-400 cursor-not-allowed"
                                  : "text-red-600 hover:text-red-900"
                              }`}
                              onClick={handleDeleteButtonClick(row.id)}
                              disabled={
                                row.email == "aware_admin@brownbag.one" ||
                                row.email == userInfo.email
                              }
                            >
                              삭제
                            </button>
                          </td>
                        </>
                      )}
                    </tr>
                  ))
                ) : (
                  <td
                    colSpan={9}
                    className="text-center pt-20 pb-16 text-gray-300 font-bold text-lg"
                  >
                    조회된 데이터가 없습니다.
                  </td>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserList;
