import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PermissionContextState } from "../../../contexts/Permission";

import CardSpinner from "common/PageSpinner/Card";
import FilterBar from "./FilterBar";
import Pagination from "common/Pagination";

import { getUserList } from "../../../rest-apis/user";
import UserList from "./List";
import PermissionModal from "./permission";

const UsersList = () => {
  const navigate = useNavigate();

  // permission
  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "USER" && p.canWrite);
  };

  // users
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [userPage, setUserPage] = useState([]);
  const totalPages = userPage.totalPages;
  const pageSize = 10;

  // filterBar
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("관리자,팀장,멤버,게스트(조회)");

  // permission modal
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);

  const fetchUser = async (page) => {
    setIsLoading(true);

    const data = await getUserList(page, pageSize, search, role);

    if (data._embedded && Array.isArray(data._embedded.userListDTOList)) {
      setUsers(data._embedded.userListDTOList);
    } else {
      setUsers([]);
    }
    setUserPage(data.page);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchUser(currentPage);
  }, [currentPage, search, role]);

  return (
    <>
      <FilterBar setSearch={setSearch} setRole={setRole} fetchUser={fetchUser} />
      <div className="flex justify-end items-center ml-7 mr-5">
        {canWritePage() && (
          <div>
            <button
              type="button"
              className="w-20 h-9 rounded-md bg-white px-2.5 py-1.5 mr-3 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => setIsPermissionModalOpen(true)}
            >
              권한설정
            </button>
            <button
              type="button"
              className="w-14 h-9 rounded-md bg-white px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => navigate("/users/add")}
            >
              추가
            </button>
          </div>
        )}
      </div>
      {isLoading ? (
        <CardSpinner />
      ) : (
        <div>
          <UserList rows={users} fetchUser={fetchUser} currentPage={currentPage} />
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
          <PermissionModal
            onClose={() => setIsPermissionModalOpen(false)}
            isOpen={isPermissionModalOpen}
            setIsOpen={setIsPermissionModalOpen}
          />
        </div>
      )}
    </>
  );
};

export default UsersList;
