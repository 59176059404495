import API from "./api";

export const getAppDeploy = async (page, size, search) => {
  const response = await API.get("/deploy", {
    params: {
      page: page,
      size: size,
      search: search,
    },
  });
  return response;
};

export const getAppVersions = async () => {
  const response = await API.get("/deploy/version");
  return response;
};

export const targetAppDeploy = async (appDeployTarget) => {
  const { target, deployVersion, targetVersion } = appDeployTarget;
  const response = await API.get("/deploy/target", {
    params: {
      target: target,
      deployVersion: deployVersion,
      targetVersion: targetVersion,
    },
  });
  return response;
};

export const startDeploy = async (deploy) => {
  const response = await API.post("/deploy/start", deploy);
  return response;
};

export const downloadConfirm = async (id) => {
  const response = await API.get(`${id}/download/confirm`);
  return response;
};

export const getResult = async (id) => {
  const response = await API.get(`/deploy/${id}/result`);
  return response;
};
