import React from "react";

const BeanDetail = ({ bean }) => {
  return (
    <div className="w-full overflow-hidden bg-white shadow sm:rounded-lg mb-5">
      <div className="px-8 py-6 sm:px-6">
        <div className="text-base font-semibold">상세 정보</div>
        <table className="w-full mt-5 text-left table-auto">
          <tbody>
            <tr key={`${bean.id}-name`} className="border-b border-gray-100">
              <td className="font-medium w-1/5 py-4 align-top whitespace-nowrap">원두명</td>
              <td>{bean.name}</td>
            </tr>
            <tr key={`${bean.id}-category`} className="border-b border-gray-100">
              <td className="font-medium w-1/5 py-4 align-top whitespace-nowrap">분류</td>
              <td className="py-4">
                {bean.category === "BROWNBAG"
                  ? "브라운백 원두"
                  : bean?.category === "OTHERS"
                  ? "브랜드 원두"
                  : bean?.category === "ETC"
                  ? "그 외 원두"
                  : bean?.category === "ACCOUNT_PRIVATE"
                  ? "회사 전용 원두(계정)"
                  : bean?.category === "GROUP_PRIVATE"
                  ? `전용 원두 (그룹명 : ${bean.isPublic ? null : bean.groupAccount.name})`
                  : "_"}
                <br />
              </td>
            </tr>
            <tr key={`${bean.id}-customCoffeeInfo`} className="border-b border-gray-100">
              <td className="w-1/5 py-4 whitespace-pre-wrap align-top font-medium">
                {"기본 화면 (2)\n추출 기본값"}
              </td>
              <td className="relative py-4">
                <table className="w-96 my-3">
                  <thead className="bg-gray-100">
                    <tr>
                      <th className="w-32 p-3 border"></th>
                      <th className="p-3 text-center font-medium text-gray-500 whitespace-nowrap border">
                        적게
                      </th>
                      <th className="p-3 text-center font-medium text-gray-500 whitespace-nowrap border">
                        보통
                      </th>
                      <th className="p-3 text-center font-medium text-gray-500 whitespace-nowrap border">
                        많이
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="w-auto py-3 px-3 border whitespace-nowrap">원두량(g)</td>
                      <td className="p-3 text-center border">{bean.brewingInfo.beanAmounts[0]}</td>
                      <td className="p-3 text-center border">{bean.brewingInfo.beanAmounts[1]}</td>
                      <td className="p-3 text-center border">{bean.brewingInfo.beanAmounts[2]}</td>
                    </tr>
                    <tr>
                      <td className="w-auto py-3 px-3 border whitespace-nowrap">
                        에스프레소량(ml)
                      </td>
                      <td className="p-3 text-center border">
                        {bean.brewingInfo.espressoAmounts[0]}
                      </td>
                      <td className="p-3 text-center border">
                        {bean.brewingInfo.espressoAmounts[1]}
                      </td>
                      <td className="p-3 text-center border">
                        {bean.brewingInfo.espressoAmounts[2]}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr key={`${bean.id}-itemCode`} className="border-b border-gray-100">
              <td className="w-1/5 py-4 whitespace-nowrap">
                <span className="font-medium mr-1">품목코드</span>
                <span className="text-sm font-normal text-gray-400">(선택)</span>
              </td>
              <td>{bean.itemCode ? bean.itemCode : "-"}</td>
            </tr>
            <tr key={`${bean.id}-beforeDescription`} className="border-b border-gray-100">
              <td className="font-medium w-1/5 py-4 whitespace-pre-wrap align-top">
                {"기본 화면 배너\n맛 설명"}
              </td>
              <td className="whitespace-pre-wrap py-4">
                <span className="text-orange-600">{bean.name.replace(/\n/g, " ")}</span>
                {`${bean.basicDescriptionParticle}\n${bean.basicDescription}`}
              </td>
            </tr>
            <tr key={`${bean.id}-cupNote`}>
              <td className="w-1/5 py-4 whitespace-pre-wrap align-top font-medium">
                {"기본 화면 배너\n컵노트"}
              </td>
              <td className="flex flex-wrap pt-4 pb-2 items-center">
                {bean.cupNote.length > 0 ? (
                  bean.cupNote.map((cupNote) => (
                    <div
                      key={cupNote.id}
                      className="w-auto h-11 mr-3 mb-2 flex items-center rounded-full bg-white px-3.5 py-3.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200"
                    >
                      <img src={cupNote.imageUrl} className="w-auto h-5 mr-2" />
                      <span className="font-normal text-gray-700">{cupNote.name}</span>
                    </div>
                  ))
                ) : (
                  <div className="h-11 py-2 items-center text-gray-500">없음</div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BeanDetail;
