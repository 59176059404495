import API from "./api";

export const getUserList = async (page, size, search, role) => {
  const response = await API.get("/users", {
    params: {
      page: page,
      size: size,
      search: search,
      role: role,
    },
  });
  return response.data;
};

export const userDetail = async (id) => {
  const response = await API.get(`/users/${id}`);
  return response;
};

export const createUser = async (user) => {
  const response = await API.post("/users", user);
  return response;
};

export const updateUser = async (id, user) => {
  const response = await API.patch(`/users/${id}`, user);
  return response;
};

export const deleteUser = async (id, deletedBy) => {
  const response = await API.patch(`/users/${id}/delete`, { deletedBy });
  return response.data;
};

export const getRoleList = async () => {
  const response = await API.get("/users/roles/select");
  return response.data;
};

export const initializePassword = async (id, lastModifier) => {
  const response = await API.patch(`/users/${id}/password`, lastModifier);
  return response.data;
};

export const getPermissionList = async () => {
  const response = await API.get("/users/permissions");
  return response.data;
};

export const updatePermission = async (permissions) => {
  const response = await API.patch("/users/permissions", permissions);
  return response.data;
};
