import React, { useEffect, useState } from "react";
import { Page } from "@shopify/polaris";
import AdminFrame from "components/Layout/Frame";
import MenuChart from "./chart/Menu";
import BrewingTypeChart from "./chart/BrewingType";
import { ComputerDesktopIcon, UsersIcon } from "@heroicons/react/24/outline";
import BrewingRankTable from "./table/BrewingRank";
import MachineRankTable from "./table/MachineRank";
import { getBrewingLogData, getCertificationData, getTotalData } from "../../rest-apis/dashboard";
import toast from "react-hot-toast";
import CertificationChart from "./chart/Certificate";
import moment, { today } from "moment";

const Dashboard = () => {
  const [totalData, setTotalData] = useState([]);
  const [brewingData, setBrewingData] = useState([]);
  const [certificationData, setCertificationData] = useState([]);

  const fetchTotalData = async () => {
    const promise = await getTotalData();

    try {
      const response = promise;

      if (response.status === 200) {
        setTotalData(response.data);
      }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "서버 오류가 존재합니다."
      );
    }
  };

  const fetchBrewingData = async () => {
    const promise = await getBrewingLogData();

    try {
      const response = promise;

      if (response.status === 200) {
        setBrewingData(response.data);
      }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "서버 오류가 존재합니다."
      );
    }
  };

  const fetchCertificationData = async () => {
    const promise = await getCertificationData();

    try {
      const response = promise;

      if (response.status === 200) {
        setCertificationData(response.data);
      }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "서버 오류가 존재합니다."
      );
    }
  };

  useEffect(() => {
    fetchTotalData();
    fetchBrewingData();
    fetchCertificationData();
  }, []);

  return (
    <>
      <AdminFrame title="대시보드">
        <Page title="대시보드" fullWidth>
          <div className="flex space-x-3">
            <div className="w-1/4 overflow-hidden bg-white shadow-md shadow-indigo-100 rounded-lg mb-5">
              <div className="flex px-10 py-6">
                <div className="flex-shrink-0 w-16 h-16 mr-5 flex items-center justify-center rounded-full bg-indigo-50 shadow-sm shadow-indigo-50">
                  <UsersIcon className="w-10 h-10 text-indigo-600" />
                </div>
                <div className="flex flex-col my-2 text-left">
                  <div className="whitespace-nowrap text-xs text-gray-400">고객수</div>
                  <div className="font-bold text-3xl">
                    {totalData.totalAccounts >= 1000
                      ? new Intl.NumberFormat().format(totalData.totalAccounts)
                      : totalData.totalAccounts}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/4 overflow-hidden bg-white shadow-md shadow-indigo-100 rounded-lg mb-5">
              <div className="flex px-10 py-6">
                <div className="flex-shrink-0 w-16 h-16 mr-5 flex items-center justify-center rounded-full bg-indigo-50 shadow-sm shadow-indigo-50">
                  <ComputerDesktopIcon className="w-10 h-10 text-indigo-600" />
                </div>
                <div className="flex flex-col my-2 text-left">
                  <div className="whitespace-nowrap text-xs text-gray-400">머신수</div>
                  <div className="font-bold text-3xl">
                    {totalData.totalDevices >= 1000
                      ? new Intl.NumberFormat().format(totalData.totalDevices)
                      : totalData.totalDevices}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-1/4 overflow-hidden bg-white shadow-md shadow-indigo-100 rounded-lg mb-5">
              <div className="flex px-10 py-6">
                <div className="flex-shrink-0 w-16 h-16 mr-5 flex items-center justify-center rounded-full bg-indigo-50 shadow-sm shadow-indigo-50">
                  <img src="/images/automate.png" className="w-10 h-10"></img>
                </div>
                <div className="flex flex-col my-2 text-left">
                  <div className="whitespace-nowrap text-xs text-gray-400">자동 세척 사용</div>
                  <div className="font-bold text-3xl">{totalData.washingCycleRatio}%</div>
                </div>
              </div>
            </div>
            <div className="w-1/4 overflow-hidden bg-white shadow-md shadow-indigo-100 rounded-lg mb-5">
              <div className="flex px-10 py-6">
                <div className="flex-shrink-0 w-16 h-16 mr-5 flex items-center justify-center rounded-full bg-indigo-50 shadow-sm shadow-indigo-50">
                  <img src="/images/coffee-outlined.png" className="w-10 h-8"></img>
                </div>
                <div className="flex flex-col my-2 text-left">
                  <div className="whitespace-nowrap text-xs text-gray-400">어제 추출 수</div>
                  <div className="font-bold text-3xl">
                    <div className="font-bold text-3xl">
                      {totalData.yesterdayBrewingCnt >= 1000
                        ? new Intl.NumberFormat().format(totalData.yesterdayBrewingCnt)
                        : totalData.yesterdayBrewingCnt}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex space-x-3">
            <div className="overflow-hidden w-1/2 bg-white shadow-md shadow-indigo-100 rounded-lg mb-5">
              <div className="px-8 py-6">
                <div className="flex justify-between">
                  <div className="text-base font-semibold">추출 횟수가 가장 많은 기본 메뉴</div>
                  <div className="whitespace-nowrap flex justify-center items-center text-xs text-gray-400">
                    {moment(today).format("YYYY.MM")}
                  </div>
                </div>
                <div className="mt-6 mb-3">
                  <MenuChart accumulatedBrewingMenu={brewingData.totalAccumulatedBrewingMenu} />
                </div>
              </div>
            </div>
            <div className="overflow-hidden w-1/4 bg-white shadow-md shadow-indigo-100 rounded-lg mb-5">
              <div className="px-8 py-6">
                <div className="flex justify-between">
                  <div className="text-base font-semibold">커피 추출 방식</div>
                  <div className="whitespace-nowrap flex justify-center items-center text-xs text-gray-400">
                    전체
                  </div>
                </div>
                <div className="mt-2">
                  <BrewingTypeChart brewingTypeRatio={brewingData.brewingTypeRatio} />
                  <div className="flex items-center justify-center space-x-10">
                    {brewingData.brewingTypeRatio ? (
                      brewingData.brewingTypeRatio.map((item) => (
                        <div
                          key={item.ratio}
                          className="flex flex-col items-center justify-items-center"
                        >
                          <div className="text-xs text-gray-400">{item.brewingType}</div>
                          <div className="font-semibold text-xl">{item.ratio}%</div>
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="overflow-hidden w-1/4 bg-white shadow-md shadow-indigo-100 rounded-lg mb-5">
              <div className="px-8 py-6">
                <div className="flex justify-between">
                  <div className="text-base font-semibold">정품 인증 비율</div>
                  <div className="whitespace-nowrap flex justify-center items-center text-xs text-gray-400">
                    전체
                  </div>
                </div>
                <div className="my-2">
                  <CertificationChart certificationData={certificationData} />
                  <div className="flex items-center justify-center space-x-10">
                    <div className="flex flex-col items-center justify-items-center">
                      <div className="text-xs text-gray-400">인증</div>
                      <div className="font-semibold text-xl">
                        {certificationData ? certificationData.certificatedRatio : 0}%
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-items-center">
                      <div className="text-xs text-gray-400">미인증</div>
                      <div className="font-semibold text-xl">
                        {certificationData ? certificationData.unCertificatedRatio : 0}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex space-x-3">
            <div className="overflow-hidden w-1/2 bg-white shadow-md shadow-indigo-100 rounded-lg mb-5">
              <div className="px-8 py-6">
                <div className="flex justify-between">
                  <div className="text-base font-semibold">추출 횟수가 가장 많은 원두</div>
                  <div className="whitespace-nowrap flex justify-center items-center text-xs text-gray-400">
                    {moment(today).format("YYYY.MM")}
                  </div>
                </div>
                <div className="my-6">
                  <BrewingRankTable top3BrewingBeans={brewingData.top3BrewingBeans} />
                </div>
              </div>
            </div>
            <div className="overflow-hidden w-1/2 bg-white shadow-md shadow-indigo-100 rounded-lg mb-5">
              <div className="px-8 py-6">
                <div className="flex justify-between">
                  <div className="text-base font-semibold">추출 횟수가 가장 많은 머신</div>
                  <div className="whitespace-nowrap flex justify-center items-center text-xs text-gray-400">
                    {moment(today).format("YYYY.MM")}
                  </div>
                </div>
                <div className="my-6">
                  <MachineRankTable top3BrewingDevices={brewingData.top3BrewingDevices} />
                </div>
              </div>
            </div>
          </div>
        </Page>
      </AdminFrame>
    </>
  );
};

export default Dashboard;
