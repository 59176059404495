import React from "react";
import BeanDetail from "./BeanDetail";

const Detail = ({ bean }) => {
  return (
    <div className="flex items-start">
      <BeanDetail bean={bean} />
    </div>
  );
};

export default Detail;
