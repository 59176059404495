import React from "react";
import { Layout, Page } from "@shopify/polaris";

import AdminFrame from "components/Layout/Frame";
import DefaultMenu from "components/Menu/List";

const MenuPage = () => {
  return (
    <>
      <AdminFrame title="메뉴 관리">
        <Page title="메뉴 관리" fullWidth>
          <Layout>
            <Layout.Section>
              <DefaultMenu />
            </Layout.Section>
          </Layout>
        </Page>
      </AdminFrame>
    </>
  );
};

export default MenuPage;
