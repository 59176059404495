import React, { Fragment, useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

const statusFilter = [
  { id: "on", title: "ON", value: "on" },
  { id: "off", title: "OFF", value: "off" },
];

const filterMapping = {
  전체: "all",
  "머신 ID": "machineId",
  그룹: "groupAccountName",
  계정: "accountName",
  "현재 원두": "currentBeanName",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const FilterBar = ({ setFilter, setSearch, setStatus }) => {
  const [inputSearch, setInputSearch] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [isChecked, setIsChecked] = useState(
    statusFilter.reduce((acc, status) => ({ ...acc, [status.id]: true }), {})
  );

  const handleSearchChange = (e) => {
    setInputSearch(e.target.value);
  };

  const handleFilterChange = (e) => {
    setSelectedFilter(filterMapping[e.target.innerText]);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    setSearch(inputSearch);
    setFilter(selectedFilter);

    let checkedStatus = Object.entries(isChecked)
      .filter(([key, value]) => value)
      .map(([key]) => key.toLowerCase());

    if (checkedStatus.length === 0) {
      checkedStatus = statusFilter.map((status) => status.id);
      const allChecked = statusFilter.reduce(
        (acc, status) => ({
          ...acc,
          [status.id]: true,
        }),
        {}
      );

      setIsChecked(allChecked);
    }

    setStatus(checkedStatus.join(","));
  };

  const handleCheckboxChange = (id) => {
    setIsChecked((prevState) => ({ ...prevState, [id]: !prevState[id] }));
    setSearch(inputSearch);
  };

  useEffect(() => {
    const checkedStatus = Object.entries(isChecked)
      .filter(([key, value]) => value)
      .map(([key]) => key);
    setStatus(checkedStatus.join(","));
  }, [isChecked, setStatus]);

  return (
    <div className=" bg-white shadow sm:rounded-lg mb-5">
      <div className="px-4 py-4 sm:px-6">
        <form onSubmit={handleSearchClick}>
          <div className="flex columns-3">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex mt-2 mr-2 w-24 pl-3 items-center justify-between min-w-max h-9 gap-x-1.5 rounded-md bg-white px-2 py-2 text-sm font-medium text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  {selectedFilter === "all"
                    ? "전체"
                    : selectedFilter === "machineId"
                    ? "머신 ID"
                    : selectedFilter === "groupAccountName"
                    ? "그룹"
                    : selectedFilter === "accountName"
                    ? "계정"
                    : selectedFilter === "currentBeanName"
                    ? "현재 원두"
                    : ""}
                  <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute left-0 right-0 mt-2 w-24 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          className={classNames(
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                          onClick={handleFilterChange}
                        >
                          전체
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          className={classNames(
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                          onClick={handleFilterChange}
                        >
                          머신 ID
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          className={classNames(
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                          onClick={handleFilterChange}
                        >
                          그룹
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          className={classNames(
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                          onClick={handleFilterChange}
                        >
                          계정
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          className={classNames(
                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                          onClick={handleFilterChange}
                        >
                          현재 원두
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
            <div className="relative mt-2 w-11/12 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                name="text"
                id="text"
                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="검색어를 입력하세요."
                onChange={handleSearchChange}
              />
            </div>
            <button
              type="submit"
              className="w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleSearchClick}
            >
              검색
            </button>
          </div>
        </form>
        <fieldset className="mt-3 ml-1 flex items-start space-x-8 space-y-0">
          <p className="font-medium">온라인 상태</p>
          {statusFilter.map((status) => (
            <div key={status.id} className="flex items-center">
              <input
                id={status.id}
                type="checkbox"
                name="select-all"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                checked={isChecked[status.id] || false}
                onChange={() => handleCheckboxChange(status.id)}
              />
              <label
                htmlFor={status.id}
                className="ml-3 block text-sm font-medium leading-6 text-gray-600"
              >
                {status.title}
              </label>
            </div>
          ))}
        </fieldset>
      </div>
    </div>
  );
};

export default FilterBar;
