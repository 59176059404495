import React, { Fragment, useState, useEffect, useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, InformationCircleIcon } from "@heroicons/react/20/solid";
import moment from "moment/moment";
import { toast } from "react-hot-toast";

import { getWashingLogs, requestRemainBrewingCount } from "../../../../rest-apis/devices";

import { UserInfoContextState } from "contexts/User";
import Pagination from "common/Pagination";
import CardSpinner from "common/PageSpinner/Card";
import { Spinner } from "@shopify/polaris";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const washingLogFilter = [
  "전체",
  "자동 세척(시간)",
  "자동 세척(잔)",
  "바로 간단 세척",
  "바로 집중 세척",
  "재부팅 세척",
  "온보딩 세척",
  "긴급 세척",
  "원격 간단 세척",
];

const WashingLogPage = ({ id }) => {
  const { userInfo } = useContext(UserInfoContextState);

  // washingLogs
  const [washingSetting, setWashingSetting] = useState({});
  const [washingLogs, setWashingLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;

  // filter
  const [selectedFilter, setSelectedFilter] = useState("전체");

  // remain brewing count
  const [isFcmButtonClicked, setIsFcmButtonClicked] = useState(false);
  const [isRemainBrewingCountLoading, setIsRemainBrewingCountLoading] = useState(false);
  const handleRemainBrewingCount = async (event) => {
    setIsRemainBrewingCountLoading(true);

    await requestRemainBrewingCount(id, userInfo.name)
      .then((response) => {
        if (response.response === "success") {
          setTimeout(() => {
            setIsFcmButtonClicked(true);
            fetchWashingLogs(currentPage);
            setIsRemainBrewingCountLoading(false);
          }, 1000);
        }
      })
      .catch((response) => {
        if (response.response === "fail") {
          toast.error("확인 요청을 보내지 못했습니다.");
        }
        setIsRemainBrewingCountLoading(false);
      });
  };

  const fetchWashingLogs = async (page) => {
    setIsLoading(true);

    const response = await getWashingLogs(id, selectedFilter, page, pageSize);

    if (response.data) {
      setWashingSetting(response.data.washingSetting);
      setWashingLogs(response.data.washingLogList.content);
    } else {
      setWashingSetting({});
      setWashingLogs([]);
    }
    setTotalPages(response.data.washingLogList.totalPages);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchWashingLogs(currentPage);
  }, [currentPage, selectedFilter]);

  return (
    <>
      {isLoading ? (
        <CardSpinner />
      ) : (
        <div className="flex flex-col px-4 py-4">
          <div className="w-full bg-white shadow sm:rounded-lg">
            <div className="px-8 py-6 sm:px-6">
              <div className="flex justify-between">
                <div className="flex">
                  <div className="text-base font-semibold">세척 기록</div>
                  <div className="pt-0.5 ml-4">
                    자동 세척 주기 |{" "}
                    {washingSetting.washingCycleType === "NONE"
                      ? "없음"
                      : washingSetting.washingCycleType === "EVERY_TIMES"
                      ? `하루에 한 번 ${washingSetting.washingCycleValue}`
                      : washingSetting.washingCycleType === "EVERY_CUPS"
                      ? `${washingSetting.washingCycleValue}잔마다`
                      : ""}
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  {isRemainBrewingCountLoading ? (
                    <div className="mr-4">
                      <Spinner size="small"></Spinner>
                    </div>
                  ) : (
                    isFcmButtonClicked &&
                    washingSetting.remainBrewingCount &&
                    washingSetting.remainBrewingCount != -1 && (
                      <div className="mr-2 font-normal text-gray-600">
                        <span>(</span>
                        <span className="text-orange-500">
                          {washingSetting.remainBrewingCount}잔
                        </span>
                        <span> 추출 후 간단 세척 실행)</span>
                      </div>
                    )
                  )}
                  <InformationCircleIcon
                    type="button"
                    className={`w-6 mr-3 text-gray-600 ${
                      washingSetting.washingCycleType === "EVERY_TIMES" ||
                      washingSetting.washingCycleType === "NONE"
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:cursor-pointer"
                    }`}
                    onClick={
                      washingSetting.washingCycleType === "EVERY_TIMES"
                        ? null
                        : handleRemainBrewingCount
                    }
                    disabled={washingSetting.washingCycleType === "EVERY_TIMES"}
                  />
                  <span className="mr-3">세척 종류</span>
                  <Menu as="div" className="relative inline-block text-left w-36">
                    <div>
                      <Menu.Button className="inline-flex w-36 mr-2 pl-3 items-center justify-between h-9 gap-x-1.5 rounded-md bg-white px-2 py-2 text-sm font-medium text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {selectedFilter}
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 right-0 mt-2 w-30 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {washingLogFilter.map((filter) => (
                            <Menu.Item key={filter}>
                              {({ active }) => (
                                <a
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                  onClick={() => setSelectedFilter(filter)}
                                >
                                  {filter}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="mt-2 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="w-1/4 py-3.5 pr-3 text-left text-sm font-semibold text-gray-700"
                          >
                            세척 일시
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700"
                          >
                            세척 종류
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700"
                          >
                            세척 시작
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-700"
                          >
                            세척 종료
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {washingLogs.length > 0 ? (
                          washingLogs.map((row) => (
                            <tr key={row.washingStartedAt}>
                              <td className="whitespace-nowrap py-4 pr-3 text-sm font-normal text-gray-700">
                                {moment(row.washingStartedAt)
                                  .add(9, "hour")
                                  .format("YYYY-MM-DD HH:mm:ss")}
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-sm font-normal text-gray-700">
                                {row.logType === "AUTO_TIME"
                                  ? "자동 세척(시간)"
                                  : row.logType === "AUTO_20_CUPS"
                                  ? "자동 세척(잔)"
                                  : row.logType === "AUTO_30_CUPS"
                                  ? "자동 세척(잔)"
                                  : row.logType === "AUTO_40_CUPS"
                                  ? "자동 세척(잔)"
                                  : row.logType === "AUTO_50_CUPS"
                                  ? "자동 세척(잔)"
                                  : row.logType === "MANUAL_SIMPLE"
                                  ? "바로 간단 세척"
                                  : row.logType === "MANUAL_INTENSIVE"
                                  ? "바로 집중 세척"
                                  : row.logType === "REBOOT"
                                  ? "재부팅 세척"
                                  : row.logType === "ONBOARDING"
                                  ? "온보딩 세척"
                                  : row.logType === "MACHINE_WASH_BUTTON"
                                  ? "긴급 세척"
                                  : row.logType === "SIMPLE_WASH"
                                  ? "원격 간단 세척"
                                  : ""}
                              </td>
                              <td className="whitespace-nowrap py-4 px-3 text-sm font-normal text-gray-700">
                                {moment(row.washingStartedAt).add(9, "hour").format("HH:mm:ss")}
                              </td>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-normal text-gray-700">
                                {row.washingEndedAt
                                  ? moment(row.washingEndedAt).add(9, "hour").format("HH:mm:ss")
                                  : "-"}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={4}
                              className="text-center pt-20 pb-16 text-gray-300 font-bold text-lg"
                            >
                              조회된 데이터가 없습니다.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={totalPages}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WashingLogPage;
