import API from "./api";

export const getAccountList = async (page, size, type, search) => {
  const response = await API.get("/accounts", {
    params: {
      page: page,
      size: size,
      type: type,
      search: search,
    },
  });
  return response.data;
};

export const getAccountDetail = async (id) => {
  const response = await API.get(`/accounts/${id}`);
  return response.data;
};

export const createAccount = async (account) => {
  const response = await API.post("/accounts", account);
  return response;
};

export const updateAccount = async (id, account) => {
  const response = await API.patch(`/accounts/${id}`, account);
  return response;
};
