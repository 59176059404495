import API from "./api";

export const getContentsList = async (page, size, displayType, contentType, search) => {
  const response = await API.get("/contents", {
    params: {
      page: page,
      size: size,
      displayType: displayType,
      contentType: contentType,
      search: search,
    },
  });
  return response.data;
};

export const getContentDetail = async (id) => {
  const response = await API.get(`/contents/${id}`);
  return response;
};

export const updateContentDeactivate = async (id, deactivate, lastModifier) => {
  const response = await API.patch(`/contents/${id}/deactivate`, {
    deactivate: deactivate,
    lastModifier: lastModifier,
  });
  return response.data;
};

export const createContent = async (content) => {
  const response = await API.post("/contents", content);
  return response;
};

export const updateContent = async (id, content) => {
  const response = await API.patch(`/contents/${id}`, content);
  return response;
};
