import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";
import AdminFrame from "components/Layout/Frame";
import { Page } from "@shopify/polaris";

import { UserInfoContextState } from "contexts/User";
import { PermissionContextState } from "contexts/Permission";
import CardSpinner from "common/PageSpinner/Card";
import DeactivateBadge from "common/Badge/deactivate";

import PreviewComponent from "../Form/preview";
import PreviewDetailComponent from "./preview";
import { getGuideDetail, updateGuideDeactivate } from "../../../rest-apis/guide";

const GuideDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { userInfo } = useContext(UserInfoContextState);
  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "GUIDE" && p.canWrite);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [guide, setGuide] = useState([]);
  const [previewDescription, setPreviewDescription] = useState("");

  const fetchGuide = async (id) => {
    setIsLoading(true);

    const response = await getGuideDetail(id);
    setGuide(response);

    setIsLoading(false);
  };

  const fetchGuideDeactivate = async () => {
    const response = await updateGuideDeactivate(id, guide.deactivate, userInfo.name);
    return response.status;
  };

  const handleDeactivateButtonClick = () => {
    if (!confirm(`${guide.deactivate ? "활성화" : "비활성화"} 상태로 변경 하시겠습니까?`)) {
      return;
    }

    fetchGuideDeactivate().then(() => {
      setGuide((prev) => ({ ...prev, deactivate: !prev.deactivate }));
      toast.success(`${guide.deactivate ? "활성화" : "비활성화"} 상태로 변경되었습니다.`);
    });
  };

  useEffect(() => {
    fetchGuide(id);
  }, [id]);

  useEffect(() => {
    setPreviewDescription(guide.description);
  }, [guide]);

  return (
    <>
      <AdminFrame title="사용 설명 보기 조회">
        {isLoading ? (
          <CardSpinner />
        ) : (
          <>
            <div className="flex justify-between">
              <div className="flex ml-6 mt-6 items-center">
                <ArrowSmallLeftIcon
                  type="button"
                  className="w-9 h-9 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => {
                    navigate("/guides");
                  }}
                />
                <div className="ml-3 text-xl font-bold text-gray-800">
                  사용 설명 보기 조회 | {guide.title}
                </div>
                <div className="ml-3">
                  <DeactivateBadge isDeactivated={guide.deactivate} />
                </div>
              </div>
              <div className="mt-6 mr-10">
                {canWritePage() && (
                  <>
                    <button
                      type="button"
                      className="w-20 h-9 rounded-md bg-white px-2.5 py-1.5 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      onClick={handleDeactivateButtonClick}
                    >
                      {guide.deactivate ? "활성화" : "비활성화"}
                    </button>
                    <button
                      type="button"
                      className="w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      onClick={() => {
                        setTimeout(() => {
                          navigate(`/guides/${id}/edit`, {
                            state: {
                              guide: guide,
                            },
                          });
                        }, 500);
                      }}
                    >
                      수정
                    </button>
                  </>
                )}
              </div>
            </div>
            <Page fullWidth>
              {isLoading ? (
                <CardSpinner />
              ) : (
                <div className="flex overflow-x-auto">
                  <div className="w-8/12 mr-4 bg-white shadow sm:rounded-lg mb-5">
                    <div className="px-8 py-6 sm:px-6">
                      <div className="text-base font-semibold">상세 정보</div>
                      <table className="w-full mt-5 text-left table-auto">
                        <tbody>
                          <tr key={`${guide?.id}-question`} className="border-b border-gray-100">
                            <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                              제목
                            </td>
                            <td className="relative py-4">{guide.title}</td>
                          </tr>
                          <tr key={`${guide?.id}-answer`} className="border-b border-gray-100">
                            <td className="w-1/5 py-4 align-top whitespace-nowrap font-medium">
                              내용
                            </td>
                            <td className="relative py-4">
                              <PreviewDetailComponent previewDescription={previewDescription} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="w-6/12 bg-white shadow overflow-x-auto sm:rounded-lg mb-5">
                    <div className="px-8 py-6 sm:px-6">
                      <div className="text-base font-semibold">미리 보기</div>
                      <div className="flex flex-col items-center justify-center">
                        <div
                          className="flex flex-col items-start m-4"
                          style={{
                            width: "418px",
                            height: "594px",
                            paddingBottom: "25px",
                            background: "var(--Black, #000)",
                          }}
                        >
                          <div className="w-full flex">
                            <img
                              src="/images/navigate-before.png"
                              style={{
                                position: "absolute",
                                marginLeft: "20px",
                                marginTop: "29px",
                                width: "12px",
                                height: "12px",
                              }}
                            />
                            <div
                              className="w-full flex items-center justify-center"
                              style={{
                                marginTop: "22px",
                                color: "white",
                                fontSize: "18px",
                                fontWeight: "600",
                              }}
                            >
                              사용 설명 보기
                            </div>
                          </div>
                          <div
                            style={{
                              marginLeft: "20px",
                              marginRight: "24px",
                              marginTop: "24px",
                              color: "white",
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            {guide.title}
                          </div>
                          <div
                            style={{ marginLeft: "20px", marginTop: "18px", width: "374px" }}
                            className="border-b border-gray-700"
                          ></div>
                          <div style={{ marginBottom: "3px" }}></div>
                          <div
                            className="custom-scroll overflow-y-auto mt-4"
                            style={{ width: "374px", marginLeft: "20px" }}
                          >
                            <PreviewComponent previewDescription={previewDescription} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Page>
          </>
        )}
      </AdminFrame>
    </>
  );
};

export default GuideDetail;
