import API from "./api";

export const getPresetList = async (page, size, search) => {
  const response = await API.get("/presets", {
    params: {
      page: page,
      size: size,
      search: search,
    },
  });
  return response.data;
};

export const getPresetDetail = async (id) => {
  const response = await API.get(`/presets/${id}`);
  return response;
};

export const updatePresetDeactivate = async (id, deactivate, lastModifier) => {
  const response = await API.patch(`/presets/${id}/deactivate`, {
    deactivate: deactivate,
    lastModifier: lastModifier,
  });
  return response.data;
};

export const getBeanListForPreset = async () => {
  const response = await API.get("/presets/select/beans");
  return response.data;
};

export const getMenuListForPreset = async () => {
  const response = await API.get("/presets/select/menus");
  return response.data;
};

export const createPreset = async (preset) => {
  const response = await API.post("/presets", preset);
  return response;
};

export const updatePreset = async (id, preset) => {
  const response = await API.patch(`/presets/${id}`, preset);
  return response;
};
