import React from "react";

import MachineDetailInfo from "./Boards/Info";
import MachineStatus from "./Boards/Status";

const MachineDetailInfoPage = ({ device, deviceDetail, deviceStatus, id }) => {
  return (
    <div className="flex h-full px-4 py-4">
      <MachineDetailInfo device={device} deviceDetail={deviceDetail} />
      <MachineStatus deviceStatus={deviceStatus} />
    </div>
  );
};

export default MachineDetailInfoPage;
