import React, { useState, useEffect } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

const typeOptions = [
  { id: "BLISS", title: "블리스" },
  { id: "RETAIL", title: "리테일" },
];

const FilterBar = ({ setType, setSearch }) => {
  const [inputSearch, setInputSearch] = useState("");
  const [isChecked, setIsChecked] = useState(
    typeOptions.reduce((acc, type) => ({ ...acc, [type.title]: true }), {})
  );

  const handleSearchChange = (e) => {
    setInputSearch(e.target.value);
  };

  const handleCheckboxChange = (title) => {
    setIsChecked((prevState) => ({ ...prevState, [title]: !prevState[title] }));
    setSearch(inputSearch);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    setSearch(inputSearch);

    let checkedStatus = Object.entries(isChecked)
      .filter(([key, value]) => value)
      .map(([key]) => key);

    if (checkedStatus.length === 0) {
      checkedStatus = typeOptions.map((type) => type.title);
      const allChecked = typeOptions.reduce(
        (acc, type) => ({
          ...acc,
          [type.title]: true,
        }),
        {}
      );
      setIsChecked(allChecked);
    }

    setType(checkedStatus.join(","));
  };

  useEffect(() => {
    const checkedStatus = Object.entries(isChecked)
      .filter(([key, value]) => value)
      .map(([key]) => key);

    setType(checkedStatus.join(","));
  }, [isChecked, setType]);

  return (
    <div className=" bg-white shadow sm:rounded-lg mb-5">
      <div className="px-6 py-4">
        <form>
          <div className="flex columns-3">
            <div className="relative mt-2 w-full rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                name="text"
                id="text"
                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="검색어를 입력하세요."
                onChange={handleSearchChange}
              />
            </div>
            <button
              type="submit"
              className="w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleSearchClick}
            >
              검색
            </button>
          </div>
        </form>
        <fieldset className="mt-3 ml-5 flex items-start space-x-8 space-y-0">
          <p className="font-medium">고객 종류</p>
          {typeOptions.map((type) => (
            <div key={type.title} className="flex items-center">
              <input
                id={type.title}
                type="checkbox"
                name="select-all"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                checked={isChecked[type.title] || false}
                onChange={() => handleCheckboxChange(type.title)}
              />
              <label
                htmlFor={type.title}
                className="ml-3 block text-sm font-medium leading-6 text-gray-600"
              >
                {type.title}
              </label>
            </div>
          ))}
        </fieldset>
      </div>
    </div>
  );
};

export default FilterBar;
