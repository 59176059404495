import React, { Fragment, useState, useCallback, useContext, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import toast from "react-hot-toast";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { UserInfoContextState } from "contexts/User";
import { getGuideOrder, updateGuideOrder } from "../../../../rest-apis/guide";
import GuideItem from "./Item";

const OrderBar = ({ open, setOpen, isEdit, setIsEdit }) => {
  const { userInfo } = useContext(UserInfoContextState);

  const [guides, setGuides] = useState([]);
  const [movedGuideIds, setMovedGuideIds] = useState([]);

  const fetchGuideOrders = async () => {
    const response = await getGuideOrder();
    setGuides(response);
  };

  const updateGuides = async (movedGuideIds) => {
    await updateGuideOrder(movedGuideIds, userInfo.name);
    guides.sort((a, b) => movedGuideIds.indexOf(a.id) - movedGuideIds.indexOf(b.id));
  };

  const moveGuide = useCallback((dragIndex, hoverIndex) => {
    setIsEdit(true);

    setGuides((prev) =>
      update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev[dragIndex]],
        ],
      })
    );
  }, []);

  const renderGuide = useCallback(
    (guide, index) => {
      return (
        <GuideItem
          key={guide.id}
          index={index}
          id={guide.id}
          title={guide.title}
          moveGuide={moveGuide}
        />
      );
    },
    [guides]
  );

  useEffect(() => {
    const fetchGuides = async () => {
      const response = await getGuideOrder();

      setMovedGuideIds(response);
    };

    fetchGuides();
  }, []);

  useEffect(() => {
    fetchGuideOrders();
  }, []);

  useEffect(() => {
    const guideIds = guides.map((guide) => guide.id);
    setMovedGuideIds(guideIds);
  }, [guides]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          순서 변경
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                            onClick={() => setOpen(false) && setIsEdit(false) && fetchGuideOrders()}
                          >
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <DndProvider backend={HTML5Backend}>
                      <ul role="list" className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                        {guides.map((guide, index) => renderGuide(guide, index))}
                      </ul>
                    </DndProvider>
                    <div className="flex justify-end items-center h-24 p-5 border-t border-gray-200">
                      <button
                        type="button"
                        className="w-14 h-9 rounded-md bg-white m-1 px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => setOpen(false) && setIsEdit(false) && fetchGuideOrders()}
                      >
                        취소
                      </button>
                      <button
                        type="button"
                        className={
                          isEdit
                            ? "w-14 h-9 rounded-md bg-indigo-600 m-1 px-2.5 py-1.5 text-sm font-medium text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-700"
                            : "w-14 h-9 rounded-md bg-gray-200 m-1 px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300"
                        }
                        onClick={() => {
                          if (!isEdit) return;
                          if (!confirm("순서 변경을 저장하시겠습니까?")) return;
                          updateGuides(movedGuideIds)
                            .then(() => {
                              toast.success("순서 변경이 저장되었습니다.");
                              fetchGuideOrders();
                              setOpen(false);
                              setIsEdit(false);
                            })
                            .catch((error) => {
                              toast.error("순서 변경에 실패했습니다.");
                            });
                        }}
                        disabled={!isEdit}
                      >
                        저장
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default OrderBar;
