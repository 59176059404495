import React from "react";
import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/20/solid";

const Pagination = ({ currentPage, setCurrentPage, totalPages }) => {
  const paginationGroupSize = 5;
  const currentGroup = Math.floor(currentPage / paginationGroupSize);
  const startPage = currentGroup * paginationGroupSize;
  const endPage = Math.min(startPage + paginationGroupSize - 1, totalPages - 1);

  const handleNextClick = () => {
    if (endPage < totalPages - 1) {
      setCurrentPage(endPage + 1);
    }
  };

  const handlePrevClick = () => {
    if (startPage >= paginationGroupSize) {
      setCurrentPage(startPage - paginationGroupSize);
    }
  };

  return (
    <nav
      className="flex justify-between mb-3 mx-auto border-t border-gray-200 px-4 sm:px-0"
      style={{ maxWidth: "430px" }}
    >
      <div className="flex w-0 pr-4">
        <button
          onClick={handlePrevClick}
          disabled={startPage === 0}
          className={`inline-flex items-center border-t-2 ${
            startPage === 0
              ? "border-transparent pr-1 pt-4 text-sm font-medium text-gray-400"
              : "border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          }`}
        >
          <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          Previous
        </button>
      </div>
      <div className="flex">
        {Array.from({ length: endPage - startPage + 1 }).map((_, index) => {
          const i = startPage + index;
          return (
            <button
              key={i}
              onClick={() => setCurrentPage(startPage + index)}
              className={`inline-flex items-center border-t-2 ${
                currentPage === i
                  ? "border-indigo-500 text-indigo-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              } px-4 pt-4 text-sm font-medium`}
              aria-current={currentPage === i ? "page" : undefined}
            >
              {i + 1}
            </button>
          );
        })}
      </div>
      <div className="flex w-0 justify-end">
        <button
          onClick={handleNextClick}
          disabled={endPage === totalPages - 1}
          className={`inline-flex items-center border-t-2 ${
            endPage === totalPages - 1
              ? "border-transparent pr-1 pt-4 text-sm font-medium text-gray-400"
              : "border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          }`}
        >
          Next
          <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      </div>
    </nav>
  );
};

export default Pagination;
