import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";

import AdminFrame from "components/Layout/Frame";
import CardSpinner from "common/PageSpinner/Card";
import { Page } from "@shopify/polaris";
import { getResult } from "../../../rest-apis/appDeploy";
import toast from "react-hot-toast";
import SuccessChart from "./SuccessChart";
import moment from "moment";

const AppDeployDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [result, setResult] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchResult = async () => {
    setIsLoading(true);
    const promise = await getResult(id);

    try {
      if (promise.status === 200) {
        setResult(promise.data);
      }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "서버 오류가 존재합니다."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formatDateTime = (name) => {
    const versionPart = name.split("_")[0];
    const dateTimePart = name.split("_")[1];
    const [datePart, timePart] = dateTimePart.split(" ");

    const correctedDateStr = `20${datePart.split("-").reverse().join("-")}T${timePart}`;
    const date = moment(correctedDateStr).add(9, "hour").format("YYYY-MM-DD HH:mm:ss");

    return versionPart + "_" + date;
  };

  useEffect(() => {
    fetchResult();
  }, []);

  return (
    <>
      <AdminFrame title="앱 배포 관리 조회">
        {isLoading ? (
          <CardSpinner />
        ) : (
          <>
            <div className="flex justify-between">
              <div className="flex ml-6 mt-6 items-center">
                <ArrowSmallLeftIcon
                  type="button"
                  className="w-9 h-9 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => {
                    navigate("/appDeploys");
                  }}
                />
                <div className="ml-3 text-xl font-bold text-gray-800">
                  앱 배포 관리 조회 | {formatDateTime(result.name)}
                </div>
              </div>
            </div>
            <Page fullWidth>
              <div className="flex flex-col">
                <div className="w-full mr-4 overflow-hidden bg-white shadow sm:rounded-lg mb-5">
                  <div className="px-8 py-6 sm:px-6">
                    <div className="text-base font-semibold">배포 결과</div>
                    <table className="w-8/12 ml-16 mt-10 divide-y divide-gray-300">
                      <thead>
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="w-3/12 pt-3.5 px-4 text-left text-sm font-semibold text-gray-900 bg-gray-100"
                          >
                            [배포 시작]
                          </th>
                          <th
                            scope="col"
                            className="w-3/12 px-4 pt-3.5 text-left text-sm font-semibold text-gray-900 bg-gray-100"
                          >
                            [배포 완료]
                          </th>
                          <th
                            scope="col"
                            className="w-3/12 px-4 pt-3.5 text-left text-sm font-semibold text-gray-900 bg-gray-100"
                          ></th>
                          <th
                            scope="col"
                            className="pt-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 bg-gray-100"
                          >
                            [배포 종료]
                          </th>
                        </tr>
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 bg-gray-100"
                          >
                            배포 시작
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900 bg-gray-100"
                          >
                            업데이트 요청 완료
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900 bg-gray-100"
                          >
                            앱 다운로드 완료
                          </th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 bg-gray-100"
                          >
                            사용자 설치 완료
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        <tr className="divide-x divide-gray-200">
                          <td className="whitespace-nowrap py-3 px-4 text-sm font-medium text-gray-900">
                            100%
                          </td>
                          <td className="whitespace-nowrap py-3 px-4 text-sm font-medium text-gray-900">
                            {result.receiveResult.totalSuccessRate.toFixed(0)}%
                          </td>
                          <td className="whitespace-nowrap py-3 pl-4 pr-4 text-sm font-medium text-gray-900">
                            {result.downloadResult.totalSuccessRate.toFixed(0)}%
                          </td>
                          <td className="whitespace-nowrap py-3 pl-4 pr-4 text-sm font-medium text-gray-900">
                            {result.installResult.totalSuccessRate.toFixed(0)}%
                          </td>
                        </tr>
                        <tr className="divide-x divide-gray-200">
                          <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900">
                            {result.receiveResult.total}개
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900">
                            {result.downloadResult.total}개
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900">
                            {result.installResult.total}개
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900">
                            {result.installResult.success}개
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <SuccessChart result={result} />
                    <table className="w-8/12 ml-16 divide-y divide-gray-300">
                      <thead>
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="w-3/12 pt-3.5 px-4 text-left text-sm font-semibold text-gray-900 bg-gray-50"
                          ></th>
                          <th
                            scope="col"
                            className="w-3/12 pt-3.5 px-4 text-left text-sm font-semibold text-gray-900 bg-gray-50"
                          >
                            업데이트 요청
                          </th>
                          <th
                            scope="col"
                            className="w-3/12 pt-3.5 px-4 text-left text-sm font-semibold text-gray-900 bg-gray-50"
                          >
                            앱 다운로드
                          </th>
                          <th
                            scope="col"
                            className="w-3/12 pt-3.5 px-4 text-left text-sm font-semibold text-gray-900 bg-gray-50"
                          >
                            사용자 설치
                          </th>
                        </tr>
                        <tr className="divide-x divide-gray-200">
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-left text-sm font-medium text-gray-900 bg-gray-50"
                          ></th>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-4 text-left text-sm font-medium text-gray-900 bg-gray-50"
                          >
                            성공
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-medium text-gray-900 bg-gray-50"
                          >
                            성공
                          </th>
                          <th
                            scope="col"
                            className="px-4 py-3.5 text-left text-sm font-medium text-gray-900 bg-gray-50"
                          >
                            성공
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        <tr className="divide-x divide-gray-200">
                          <td className="whitespace-nowrap py-3 px-4 text-sm font-medium text-gray-900"></td>
                          <td className="whitespace-nowrap py-3 px-4 text-sm font-medium text-gray-900">
                            {result.receiveResult.stepSuccessRate.toFixed(0)}% (
                            {result.receiveResult.success}개)
                          </td>
                          <td className="whitespace-nowrap py-3 px-4 text-sm font-medium text-gray-900">
                            {result.downloadResult.stepSuccessRate.toFixed(0)}% (
                            {result.downloadResult.success}개)
                          </td>
                          <td className="whitespace-nowrap py-3 pl-4 pr-4 text-sm font-medium text-gray-900">
                            {result.installResult.stepSuccessRate.toFixed(0)}% (
                            {result.installResult.success}개)
                          </td>
                        </tr>
                        <tr className="divide-x divide-gray-200">
                          <td className="py-3.5 pl-4 pr-4 text-left text-sm font-medium text-gray-900 bg-gray-50"></td>
                          <td className="py-3.5 pl-4 pr-4 text-left text-sm font-medium text-gray-900 bg-gray-50">
                            실패
                          </td>
                          <td className="py-3.5 pl-4 pr-4 text-left text-sm font-medium text-gray-900 bg-gray-50">
                            실패
                          </td>
                          <td className="py-3.5 pl-4 pr-4 text-left text-sm font-medium text-gray-900 bg-gray-50">
                            실패
                          </td>
                        </tr>
                        <tr className="divide-x divide-gray-200">
                          <td className="whitespace-nowrap py-3 px-4 text-sm font-medium text-gray-900"></td>
                          <td className="whitespace-nowrap py-3 px-4 text-sm font-medium text-gray-900">
                            {100 - result.receiveResult.stepSuccessRate.toFixed(0)}% (
                            {result.receiveResult.fail}개)
                          </td>
                          <td className="whitespace-nowrap py-3 px-4 text-sm font-medium text-gray-900">
                            {100 - result.downloadResult.stepSuccessRate.toFixed(0)}% (
                            {result.downloadResult.fail}개)
                          </td>
                          <td className="whitespace-nowrap py-3 pl-4 pr-4 text-sm font-medium text-gray-900">
                            {100 - result.installResult.stepSuccessRate.toFixed(0)}% (
                            {result.installResult.fail}개)
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="w-full mr-4 overflow-hidden bg-white shadow sm:rounded-lg mb-5">
                  <div className="px-8 py-6 sm:px-6">
                    <div className="text-base font-semibold">1. 업데이트 요청 실패 목록</div>
                    <table className="m-4 min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-0"
                          >
                            실패 처리 일시
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-500 sm:table-cell"
                          >
                            버전
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                          >
                            머신 ID
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                          >
                            실패 사유
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {result.receiveFailList.length != 0 ? (
                          result.receiveFailList.map((device) => (
                            <tr key={device.androidId}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-0">
                                {moment(device.failedAt)
                                  .add(9, "hour")
                                  .format("YYYY-MM-DD HH:mm:ss")}
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                {device.version.replace("v", "")}
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                <Link
                                  to={`/devices/${device.deviceId}`}
                                  key={device.deviceId}
                                  className="text-indigo-600 underline"
                                >
                                  {device.androidId}
                                </Link>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {device.failLog}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <td
                            colSpan={4}
                            className="text-center pt-20 pb-12 text-gray-300 font-bold text-lg"
                          >
                            조회된 데이터가 없습니다.
                          </td>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="w-full mr-4 overflow-hidden bg-white shadow sm:rounded-lg mb-5">
                  <div className="px-8 py-6 sm:px-6">
                    <div className="text-base font-semibold">2. 앱 다운로드 실패 목록</div>
                    <table className="m-4 min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-0"
                          >
                            실패 처리 일시
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-500 sm:table-cell"
                          >
                            버전
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                          >
                            머신 ID
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                          >
                            실패 사유
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {result.downloadFailList.length != 0 ? (
                          result.downloadFailList.map((device) => (
                            <tr key={device.androidId}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-0">
                                {moment(device.failedAt)
                                  .add(9, "hour")
                                  .format("YYYY-MM-DD HH:mm:ss")}
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                {device.version.replace("v", "")}
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                <Link
                                  to={`/devices/${device.deviceId}`}
                                  key={device.deviceId}
                                  className="text-indigo-600 underline"
                                >
                                  {device.androidId}
                                </Link>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {device.failLog}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <td
                            colSpan={4}
                            className="text-center pt-20 pb-12 text-gray-300 font-bold text-lg"
                          >
                            조회된 데이터가 없습니다.
                          </td>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="w-full mr-4 overflow-hidden bg-white shadow sm:rounded-lg mb-5">
                  <div className="px-8 py-6 sm:px-6">
                    <div className="text-base font-semibold">3. 사용자 설치 실패 목록</div>
                    <table className="m-4 min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-0"
                          >
                            실패 처리 일시
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-500 sm:table-cell"
                          >
                            버전
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                          >
                            머신 ID
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                          >
                            실패 사유
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {result.installFailList.length != 0 ? (
                          result.installFailList.map((device) => (
                            <tr key={device.androidId}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-0">
                                {moment(device.failedAt)
                                  .add(9, "hour")
                                  .format("YYYY-MM-DD HH:mm:ss")}
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                {device.version.replace("v", "")}
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                <Link
                                  to={`/devices/${device.deviceId}`}
                                  key={device.deviceId}
                                  className="text-indigo-600 underline"
                                >
                                  {device.androidId}
                                </Link>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {device.failLog}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <td
                            colSpan={4}
                            className="text-center pt-20 pb-12 text-gray-300 font-bold text-lg"
                          >
                            조회된 데이터가 없습니다.
                          </td>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="w-full mr-4 overflow-hidden bg-white shadow sm:rounded-lg mb-5">
                  <div className="px-8 py-6 sm:px-6">
                    <div className="text-base font-semibold">4. 성공 목록</div>
                    <table className="m-4 min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-500 sm:pl-0"
                          >
                            성공 일시
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-500 sm:table-cell"
                          >
                            버전
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-500"
                          >
                            머신 ID
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {result.installSuccessList.length != 0 ? (
                          result.installSuccessList.map((device) => (
                            <tr key={device.androidId}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-0">
                                {moment(device.succeedAt)
                                  .add(9, "hour")
                                  .format("YYYY-MM-DD HH:mm:ss")}
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                {device.version.replace("v", "")}
                              </td>
                              <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                                <Link
                                  to={`/devices/${device.deviceId}`}
                                  key={device.deviceId}
                                  className="text-indigo-600 underline"
                                >
                                  {device.androidId}
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <td
                            colSpan={3}
                            className="text-center pt-20 pb-12 text-gray-300 font-bold text-lg"
                          >
                            조회된 데이터가 없습니다.
                          </td>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Page>
          </>
        )}
      </AdminFrame>
    </>
  );
};

export default AppDeployDetailPage;
