import API from "./api";

export const getNoticeList = async (page, size, type, search) => {
  const response = await API.get("/notices", {
    params: {
      page: page,
      size: size,
      type: type,
      search: search,
    },
  });
  return response.data;
};

export const getNoticeDetail = async (id) => {
  const response = await API.get(`/notices/${id}`);
  return response.data;
};

export const updateNoticeDeactivate = async (id, deactivate, lastModifier) => {
  const response = await API.patch(`/notices/${id}/deactivate`, {
    deactivate: deactivate,
    lastModifier: lastModifier,
  });
  return response.data;
};

export const createNotice = async (notice) => {
  const response = await API.post("/notices", notice);
  return response;
};

export const updateNotice = async (id, notice) => {
  const response = await API.patch(`/notices/${id}`, notice);
  return response;
};
