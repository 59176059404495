import API from "./api";

export const getAppVersion = async (page, size, search) => {
  const response = await API.get("/appVersions", {
    params: {
      page: page,
      size: size,
      search: search,
    },
  });
  return response;
};

export const uploadApk = async (apk) => {
  const response = await API.post("/appVersions/upload", apk, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response;
};

export const saveAppVersion = async (appVersion) => {
  const response = await API.post("/appVersions", appVersion);
  return response;
};

export const updateAppVersion = async (id, memo, lastModifier) => {
  const response = await API.patch(`/appVersions/${id}`, {
    memo: memo,
    lastModifier: lastModifier,
  });
  return response;
};

export const deleteAppVersion = async (id, lastModifier) => {
  const response = await API.delete(`/appVersions/${id}`, {
    data: {
      lastModifier: lastModifier,
    },
  });
  return response;
};

export const getAppVersionDevices = async (id) => {
  const response = await API.get(`/appVersions/${id}`);
  return response;
};
