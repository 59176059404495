import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { PermissionContextState } from "../../../contexts/Permission";

import { getNoticeList } from "../../../rest-apis/notices";

import CardSpinner from "common/PageSpinner/Card";
import FilterBar from "./FilterBar";
import NoticeList from "./List";
import Pagination from "common/Pagination";

const NoticesList = () => {
  const navigate = useNavigate();

  // permission
  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "NOTICE" && p.canWrite);
  };

  // notices
  const [notices, setNotices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [noticePage, setNoticePage] = useState([]);
  const totalPages = noticePage.totalPages;
  const pageSize = 10;

  // filterBar
  const [type, setType] = useState("일반,업데이트");
  const [search, setSearch] = useState("");

  const fetchNotices = async (page) => {
    setIsLoading(true);

    const data = await getNoticeList(page, pageSize, type, search);

    if (data._embedded && Array.isArray(data._embedded.noticeListDTOList)) {
      setNotices(data._embedded.noticeListDTOList);
    } else {
      setNotices([]);
    }
    setNoticePage(data.page);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchNotices(currentPage);
  }, [currentPage, type, search]);

  return (
    <>
      <FilterBar setType={setType} setSearch={setSearch} fetchNotices={fetchNotices} />
      <div className="flex justify-end items-center ml-7 mr-5">
        <div>
          {canWritePage() && (
            <button
              type="button"
              className="w-14 h-9 rounded-md bg-white px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => navigate("/notices/add")}
            >
              추가
            </button>
          )}
        </div>
      </div>
      {isLoading ? (
        <CardSpinner />
      ) : (
        <div>
          <NoticeList rows={notices} />
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      )}
    </>
  );
};

export default NoticesList;
