import React, { Fragment, useState, useCallback, useContext, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

import { getBeanOrder, updateBeanOrder } from "../../../../rest-apis/beans";
import OrderBeanItem from "./OrderBeanItem";
import { UserInfoContextState } from "contexts/User";

import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const OrderBar = ({ open, setOpen, isEdit, setIsEdit }) => {
  const { userInfo } = useContext(UserInfoContextState);

  const [tabs, setTabs] = useState([
    { name: "브라운백 원두", value: "brownbag", current: true },
    { name: "브랜드 원두", value: "others", current: false },
    { name: "그 외 원두", value: "etc", current: false },
  ]);

  const [category, setCategory] = useState("brownbag");
  const [beans, setBeans] = useState([]);
  const [movedBeanList, setMovedBeanList] = useState([
    {
      category: "brownbag",
      beanIds: [],
    },
    {
      category: "others",
      beanIds: [],
    },
    {
      category: "etc",
      beanIds: [],
    },
  ]);

  const fetchBeanOrders = async (category) => {
    const response = await getBeanOrder(category);
    setBeans(response);
  };

  const updateBeans = async (movedBeanList) => {
    const response = await updateBeanOrder(movedBeanList, userInfo.name);
    const matchingCategory = response.find((r) => r.category === category);
    setBeans(matchingCategory.beans);
  };

  const handleCategoryChange = (value) => {
    const newTabs = tabs.map((tab) => {
      if (tab.value === value) {
        return { ...tab, current: true };
      } else {
        return { ...tab, current: false };
      }
    });

    setIsEdit(false);
    setTabs(newTabs);
    setCategory(value);
    fetchBeanOrders(value);
  };

  const moveBean = useCallback((dragIndex, hoverIndex) => {
    setIsEdit(true);

    setBeans((prevBeans) =>
      update(prevBeans, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevBeans[dragIndex]],
        ],
      })
    );
  }, []);

  const renderBean = useCallback(
    (bean, index) => {
      return (
        <OrderBeanItem
          key={bean.id}
          index={index}
          id={bean.id}
          name={bean.name}
          category={category}
          moveBean={moveBean}
        />
      );
    },
    [beans]
  );

  useEffect(() => {
    const fetchBeans = async () => {
      const newBeanList = [...movedBeanList];

      for (let beanGroup of newBeanList) {
        const response = await getBeanOrder(beanGroup.category);
        beanGroup.beanIds = response.map((bean) => bean.id);
      }

      setMovedBeanList(newBeanList);
    };

    fetchBeans();
  }, []);

  useEffect(() => {
    fetchBeanOrders(category);
  }, [category]);

  useEffect(() => {
    const beanIds = beans.map((bean) => bean.id);

    setMovedBeanList((prevList) =>
      prevList.map((list) => {
        if (list.category === category) {
          return { ...list, beanIds };
        } else {
          return list;
        }
      })
    );
  }, [beans, category]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          순서 변경
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                            onClick={() => {
                              setOpen(false);
                              setIsEdit(false);
                              fetchBeanOrders("brownbag");
                            }}
                          >
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-200">
                      <div className="px-6">
                        <nav className="-mb-px flex space-x-6">
                          {tabs.map((tab) => (
                            <button
                              key={tab.name}
                              className={classNames(
                                tab.current
                                  ? "border-indigo-500 text-indigo-600"
                                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                              )}
                              onClick={() => {
                                if (isEdit) {
                                  if (
                                    !confirm(
                                      "변경된 순서가 저장되지 않습니다. 카테고리를 이동하시겠습니까?"
                                    )
                                  )
                                    return;
                                }
                                handleCategoryChange(tab.value);
                              }}
                            >
                              {tab.name}
                            </button>
                          ))}
                        </nav>
                      </div>
                    </div>
                    <DndProvider backend={HTML5Backend}>
                      <ul role="list" className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                        {beans.map((bean, index) => renderBean(bean, index))}
                      </ul>
                    </DndProvider>
                    <div className="flex justify-end items-center h-24 p-5 border-t border-gray-200">
                      <button
                        type="button"
                        className="w-14 h-9 rounded-md bg-white m-1 px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => {
                          setOpen(false);
                          setIsEdit(false);
                          fetchBeanOrders("brownbag");
                        }}
                      >
                        취소
                      </button>
                      <button
                        type="button"
                        className={
                          isEdit
                            ? "w-14 h-9 rounded-md bg-indigo-600 m-1 px-2.5 py-1.5 text-sm font-medium text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-700"
                            : "w-14 h-9 rounded-md bg-gray-200 m-1 px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300"
                        }
                        onClick={() => {
                          if (!isEdit) return;
                          if (!confirm("순서 변경을 저장하시겠습니까?")) return;
                          updateBeans(movedBeanList).then(() => {
                            toast.success("순서 변경이 저장되었습니다.");
                            fetchBeanOrders(category);
                            setOpen(false);
                            setIsEdit(false);
                          });
                        }}
                        disabled={!isEdit}
                      >
                        저장
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default OrderBar;
