import React from "react";
import CardSpinner from "common/PageSpinner/Card";

const ScreenSaverPage = ({ isScreenSaverLoading, screenSaverState }) => {
  return (
    <>
      <div className="flex flex-col h-full px-4 my-6">
        {isScreenSaverLoading ? (
          <CardSpinner />
        ) : (
          <div className="w-full h-full mr-4 overflow-hidden bg-white shadow sm:rounded-lg">
            <div className="px-8 py-6 sm:px-6">
              <div className="text-base font-semibold">대기화면</div>
              <div className="mt-4">
                {screenSaverState.contentType === "이미지" ? "이미지(png, jpg)" : "동영상(mp4)"}
              </div>
              <div className="flex mt-4 overflow-auto">
                {screenSaverState.files.map((url, index) => (
                  <div
                    key={index}
                    className="overflow-hidden relative w-36 h-48 mr-8 my-2 flex items-center justify-center border rounded-md border-gray-300"
                  >
                    {screenSaverState.contentType === "이미지" ? (
                      <img
                        src={url}
                        alt={`screen_saver_${index + 1}`}
                        className="object-contain w-36 h-48"
                      />
                    ) : (
                      <video src={url} autoPlay muted loop className="object-contain w-36 h-48" />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ScreenSaverPage;
