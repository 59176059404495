import React from "react";

const DeactivateBadge = ({ isDeactivated }) => {
  return isDeactivated ? (
    <span className="inline-flex items-center gap-x-1.5 rounded-full bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
      비활성화
    </span>
  ) : (
    <span className="inline-flex items-center gap-x-1.5 rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700">
      활성화
    </span>
  );
};

export default DeactivateBadge;
