import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Page } from "@shopify/polaris";
import toast from "react-hot-toast";
import moment from "moment/moment";

import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";

import usePrompt from "utils/Prompt";
import { UserInfoContextState } from "contexts/User";

import CardSpinner from "common/PageSpinner/Card";
import AdminFrame from "components/Layout/Frame";

import { createAccount, getAccountDetail, updateAccount } from "../../../rest-apis/accounts";
import CertificatedBadge from "../../../common/Badge/certificated";

const types = ["블리스", "리테일"];

const AccountForm = () => {
  // Path
  const { id } = useParams();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  const { userInfo } = useContext(UserInfoContextState);

  // account data
  const location = useLocation();
  const locationState = location.state?.account;
  const totalElements = location.state?.totalElements;

  const [accountState, setAccountState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const MAX_NAME_LENGTH = 50;
  const MAX_MEMO_LENGTH = 100;
  const MAX_DEVICE_MEMO_LENGTH = 10;

  // const [code, setCode] = useState("");
  const [nameInput, setNameInput] = useState("");
  const [memoInput, setMemoInput] = useState("");

  const isValidTextInput = (text) => {
    const regex = /^[A-Za-z0-9ㄱ-ㅎㅏ-ㅣ가-힣~!@#₩$%^&*()\-_+=,./<>?\\|\n ]*$/;
    return regex.test(text);
  };

  const handleAccountType = (event) => {
    const newType = event.target.value;
    setAccountState((prevState) => ({ ...prevState, type: newType }));
  };

  const [isPrompt, setIsPrompt] = useState(true);
  usePrompt(
    "다른 페이지로 이동하겠습니까? 이 페이지에서 변경한 내용은 저장되지 않습니다.",
    isPrompt
  );

  const mapResponseToAccountState = (data) => ({
    type: data.type || "블리스",
    code: data.code || "",
    name: data.name || "",
    deviceList: data.deviceList || [],
    memo: data.memo || "",
    lastModifier: userInfo.name,
  });

  const fetchAccount = async (id) => {
    setIsLoading(true);
    const response = await getAccountDetail(id);
    setAccountState(mapResponseToAccountState(response));
    setIsLoading(false);
  };

  const uploadAccount = async () => {
    // 토스트 에러 출력
    if (accountState.name.length < 1) {
      toast.error("고객명을 입력하세요.");
      return;
    }

    if (!window.confirm("상세 정보를 저장하시겠습니까?")) {
      return;
    }

    setIsSaving(true);

    const promise = locationState
      ? updateAccount(locationState.id, {
          type: accountState.type,
          code: accountState.code,
          name: accountState.name,
          deviceList: accountState.deviceList,
          memo: accountState.memo,
          lastModifier: userInfo.name,
        })
      : createAccount({
          type: accountState.type,
          code: accountState.code,
          name: accountState.name,
          memo: accountState.memo,
          lastModifier: userInfo.name,
        });

    try {
      const response = await promise;
      if (response.status === 200) {
        setIsPrompt(false);
        toast.success(locationState ? "수정되었습니다." : "저장되었습니다.");
        setTimeout(() => {
          navigate(
            locationState ? `/accounts/${locationState.id}` : `/accounts/${response.data.id}`
          );
        }, 500);
      }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "서버 오류가 존재합니다."
      );
    } finally {
      setTimeout(() => {
        setIsSaving(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (pathname.split("/").includes("edit") && !locationState) {
      navigate("/accounts");
      return;
    }

    if (locationState?.id) {
      fetchAccount(locationState.id);
    } else {
      setAccountState(mapResponseToAccountState(locationState || {}));
    }
  }, [id, locationState]);

  useEffect(() => {
    if (!locationState) {
      const code = `awo-account-${totalElements + 1}`;
      setAccountState((prevState) => ({ ...prevState, code: code }));
    }
  }, [accountState.type]);

  return (
    <>
      <AdminFrame title={`계정 관리 ${locationState ? "수정" : "추가"}`}>
        <div className="flex justify-between">
          <div className="flex ml-6 mt-6 items-center">
            <ArrowSmallLeftIcon
              type="button"
              className="w-9 h-9 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => {
                navigate("/accounts");
              }}
            />
            <div className="ml-3 text-xl font-bold text-gray-800">
              계정 관리 {locationState ? `수정 | ${locationState.name}` : "추가"}
            </div>
          </div>
          <div className="mt-6 mr-10">
            <button
              type="button"
              className={
                isSaving
                  ? "w-24 h-9 rounded-md bg-gray-200 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  : "w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
              onClick={() => {
                uploadAccount();
              }}
              disabled={isSaving}
            >
              {isSaving ? (
                <div className="flex">
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                  <div>저장중</div>
                </div>
              ) : (
                "저장"
              )}
            </button>
          </div>
        </div>
        <Page fullWidth>
          {isLoading ? (
            <CardSpinner />
          ) : (
            <div className="flex overflow-x-auto">
              <div className="w-full mr-4 bg-white shadow sm:rounded-lg mb-5">
                <div className="px-8 py-6 sm:px-6">
                  <div className="text-base font-semibold">상세 정보</div>
                  <table className="w-full mt-5 text-left table-auto">
                    <tbody>
                      <tr key="notice-type" className="border-b border-gray-100">
                        <td className="font-medium w-1/4 py-4 align-top text-gray-700">
                          고객 종류
                        </td>
                        <td className="py-4 text-gray-700">
                          {locationState ? (
                            <div>{locationState.type}</div>
                          ) : (
                            <div className="flex flex-col">
                              <fieldset>
                                <legend className="sr-only">account type</legend>
                                <div className="flex flex-wrap justify-start items-center space-x-4">
                                  {types.map((type) => (
                                    <div key={`${type}`} className="flex items-center">
                                      <input
                                        id={`${type}`}
                                        value={type}
                                        name="type"
                                        type="radio"
                                        defaultChecked={type === "블리스"}
                                        checked={accountState.type === type}
                                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        onChange={handleAccountType}
                                      />
                                      <label
                                        htmlFor={`${type}`}
                                        className="ml-3 block text-sm font-medium leading-6 text-light whitespace-nowrap"
                                      >
                                        {type}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </fieldset>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr key={`${accountState?.id}-code`} className="border-b border-gray-100">
                        <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium text-gray-700">
                          어웨어 고객 코드
                        </td>
                        <td className="relative py-4 text-gray-700">
                          {locationState ? (
                            <div>{locationState.code}</div>
                          ) : (
                            <input
                              type="text"
                              value={accountState?.code || ""}
                              disabled
                              className="block w-1/2 rounded-md border-0 py-1.5 pl-2 pr-14 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset focus:ring-indigo-600 placeholder:text-gray-400"
                            />
                          )}
                        </td>
                      </tr>
                      <tr key={`${accountState?.id}-name`} className="border-b border-gray-100">
                        <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium text-gray-700">
                          고객명
                        </td>
                        <td className="relative py-4 text-gray-700">
                          <input
                            type="text"
                            value={accountState?.name || ""}
                            maxLength={MAX_NAME_LENGTH}
                            onChange={(e) => {
                              if (
                                isValidTextInput(e.target.value) &&
                                e.target.value.length <= MAX_NAME_LENGTH
                              ) {
                                setNameInput(e.target.value);
                                setAccountState({ ...accountState, name: e.target.value });
                              }
                            }}
                            onBlur={(e) => {
                              const trimmedValue = e.target.value.replace(/\s+$/, "");
                              setNameInput(trimmedValue);
                              setAccountState({ ...accountState, name: trimmedValue });
                            }}
                            className="block w-1/2 rounded-md border-0 py-1.5 pl-2 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset focus:ring-indigo-600 placeholder:text-gray-400"
                            placeholder="고객명을 입력하세요."
                          />
                          <div className="absolute mr-3 right-1/2 top-1/2 transform -translate-y-1/2 text-s text-gray-500">
                            {`${
                              accountState.name ? accountState.name.length : nameInput.length
                            }/${MAX_NAME_LENGTH}`}
                          </div>
                        </td>
                      </tr>
                      {locationState ? (
                        <tr
                          key={`${locationState?.id}-device`}
                          className="border-b border-gray-100"
                        >
                          <td className="w-1/5 py-4 align-top whitespace-nowrap font-medium text-gray-700">
                            정품 인증 머신
                          </td>
                          <td className="relative py-4 text-gray-700">
                            <div className="overflow-hidden ring-1 ring-black ring-opacity-5 rounded-lg">
                              <table className="min-w-full divide-y divide-gray-300">
                                <thead className="bg-gray-50">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="py-3.5 pl-4 pr-3 text-left text-xs font-normal text-gray-500 sm:pl-6"
                                    >
                                      NO
                                    </th>
                                    <th
                                      scope="col"
                                      className="w-40 px-3 py-3.5 text-left text-xs font-normal text-gray-500"
                                    >
                                      머신 ID
                                    </th>
                                    <th
                                      scope="col"
                                      className="w-3/12 px-3 py-3.5 text-left text-xs font-normal text-gray-500"
                                    >
                                      <span>메모 </span>
                                      <span className="text-gray-400">(선택)</span>
                                    </th>
                                    <th
                                      scope="col"
                                      className="whitespace-nowrap px-3 py-3.5 text-left text-xs font-normal text-gray-500"
                                    >
                                      정품 인증
                                    </th>
                                    <th
                                      scope="col"
                                      className="whitespace-nowrap px-3 py-3.5 text-left text-xs font-normal text-gray-500"
                                    >
                                      정품 인증 일시
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                  {locationState.deviceList &&
                                  locationState.deviceList.length === 0 ? (
                                    <td
                                      colSpan={5}
                                      className="text-center pt-16 pb-16 text-gray-300 font-bold text-lg"
                                    >
                                      조회된 데이터가 없습니다.
                                    </td>
                                  ) : (
                                    accountState.deviceList &&
                                    accountState?.deviceList.map((row, index) => (
                                      <tr key={row.no}>
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-500 sm:pl-6">
                                          {row.no}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          <button
                                            className="text-indigo-600 underline hover:text-indigo-900"
                                            onClick={() => {
                                              navigate(`/devices/${row.id}`);
                                            }}
                                          >
                                            {row.androidId}
                                          </button>
                                        </td>
                                        <td className="relative whitespace-nowrap py-4 pr-8 px-3 text-sm font-medium text-gray-500">
                                          <input
                                            type="text"
                                            value={row?.memo}
                                            maxLength={MAX_DEVICE_MEMO_LENGTH}
                                            onChange={(e) => {
                                              const newMemo = e.target.value;
                                              if (
                                                isValidTextInput(newMemo) &&
                                                newMemo.length <= MAX_DEVICE_MEMO_LENGTH
                                              ) {
                                                setAccountState((prevState) => {
                                                  const updatedDeviceList =
                                                    prevState.deviceList.map(
                                                      (device, deviceIndex) =>
                                                        index === deviceIndex
                                                          ? { ...device, memo: newMemo }
                                                          : device
                                                    );

                                                  return {
                                                    ...prevState,
                                                    deviceList: updatedDeviceList,
                                                  };
                                                });
                                              }
                                            }}
                                            onBlur={(e) => {
                                              // const trimmedValue = e.target.value.replace(
                                              //   /\s+$/,
                                              //   ""
                                              // );
                                              // setNameInput(trimmedValue);
                                              // setAccountState({
                                              //   ...accountState,
                                              //   name: trimmedValue,
                                              // });
                                            }}
                                            className="block w-full rounded-md border-0 py-1.5 pl-2 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset focus:ring-indigo-600 placeholder:text-gray-400"
                                          />
                                          <div className="absolute mr-3 right-8 top-1/2 transform -translate-y-1/2 text-s text-gray-500">
                                            {`${
                                              row.memo ? row.memo.length : 0
                                            }/${MAX_DEVICE_MEMO_LENGTH}`}
                                          </div>
                                        </td>
                                        <td className="whitespace-nowrap py-4 pl-2 text-sm text-gray-500">
                                          <CertificatedBadge isCertificated={row.isCertificated} />
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          {moment(row.certificatedAt)
                                            .add(9, "hour")
                                            .format("YYYY-MM-DD HH:mm:ss")}
                                        </td>
                                      </tr>
                                    ))
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        <div></div>
                      )}
                      <tr key={`${accountState.id}-memo`} className="border-b border-gray-100">
                        <td className="w-1/5 py-4 whitespace-nowrap align-top">
                          <span className="font-medium mr-1 text-gray-700">메모</span>
                          <span className="text-sm font-normal text-gray-400">(선택)</span>
                        </td>
                        <td className="relative py-4 text-gray-700">
                          <textarea
                            rows={3}
                            value={accountState?.memo || ""}
                            maxLength={MAX_MEMO_LENGTH}
                            onChange={(e) => {
                              if (
                                isValidTextInput(e.target.value) &&
                                e.target.value.length <= MAX_MEMO_LENGTH
                              ) {
                                setMemoInput(e.target.value);
                                setAccountState({ ...accountState, memo: e.target.value });
                              }
                            }}
                            className={
                              "block w-1/2 rounded-md border-0 py-1.5 pl-2 pr-16 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300  sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset focus:ring-indigo-600 placeholder:text-gray-400"
                            }
                          />
                          <div className="absolute mr-3 right-1/2 bottom-3 transform -translate-y-1/2 text-s text-gray-500">
                            {`${
                              accountState.memo ? accountState.memo.length : memoInput?.length
                            }/${MAX_MEMO_LENGTH}`}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </Page>
      </AdminFrame>
    </>
  );
};

export default AccountForm;
