import React, { Fragment, useEffect, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { getAlarmLogs } from "../../../../rest-apis/devices";

import moment from "moment/moment";
import Pagination from "common/Pagination";
import TableSpinner from "common/PageSpinner/TableBody";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const alarmTabName = ["전체", "세척", "머신", "공지"];
const alarmDisplayDateRange = ["오늘", "최근 3일", "최근 7일", "최근 30일"];

const AlarmLogPage = ({ id }) => {
  // alarmLogs
  const [alarmLogs, setAlarmLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;

  // filter
  const [tabName, setTabName] = useState("전체");
  const [displayDateRange, setDisplayDateRange] = useState("오늘");

  const fetchAlarmLogs = async (page) => {
    setIsLoading(true);

    const response = await getAlarmLogs(id, tabName, displayDateRange, page, pageSize);
    setAlarmLogs(response.data.results);
    setTotalPages(response.data.totalPages);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchAlarmLogs(currentPage);
  }, [currentPage, tabName, displayDateRange]);

  return (
    <>
      <div className="flex flex-col px-4 py-4">
        <div className="w-full bg-white shadow sm:rounded-lg">
          <div className="px-8 py-6 sm:px-6">
            <div className="flex justify-between">
              <div className="text-base font-semibold">알림 기록</div>
              <div className="flex">
                <div className="mr-4">
                  <span className="mr-4">알림 종류</span>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex mt-2 mr-2 w-24 pl-3 items-center justify-between min-w-max h-9 gap-x-1.5 rounded-md bg-white px-2 py-2 text-sm font-medium text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {tabName}
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 right-0 mt-2 w-24 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {alarmTabName.map((name) => (
                            <Menu.Item key={name}>
                              {({ active }) => (
                                <a
                                  key={name}
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                  onClick={() => setTabName(name)}
                                >
                                  {name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div>
                  <span className="mr-4">알림 노출일</span>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex mt-2 mr-2 w-24 pl-3 items-center justify-between min-w-max h-9 gap-x-1.5 rounded-md bg-white px-2 py-2 text-sm font-medium text-gray-600 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        {displayDateRange}
                        <ChevronDownIcon
                          className="-mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 right-0 mt-2 w-24 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {alarmDisplayDateRange.map((name) => (
                            <Menu.Item key={name}>
                              {({ active }) => (
                                <a
                                  key={name}
                                  className={classNames(
                                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                    "block px-4 py-2 text-sm"
                                  )}
                                  onClick={() => setDisplayDateRange(name)}
                                >
                                  {name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
            <div className="mt-2 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="w-48 py-3.5 pr-3 text-left text-sm font-semibold text-gray-500"
                        >
                          알림 노출 일시
                        </th>
                        <th
                          scope="col"
                          className="w-48 py-3.5 text-left text-sm font-semibold text-gray-500"
                        >
                          알림 종류
                        </th>
                        <th
                          scope="col"
                          className="w-88 py-3.5 text-left text-sm font-semibold text-gray-500"
                        >
                          알림 내용
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {isLoading ? (
                        <td colSpan={3}>
                          <TableSpinner />
                        </td>
                      ) : alarmLogs.length > 0 ? (
                        alarmLogs.map((row) => (
                          <tr key={row.displayDate}>
                            <td className="whitespace-nowrap py-4 pr-3 text-left text-sm font-normal text-gray-500">
                              {moment(row.displayDate)
                                .add(9, "hours")
                                .format("YYYY-MM-DD HH:mm:ss")}
                            </td>
                            <td className="whitespace-nowrap py-4 pr-3 text-left text-sm font-normal text-gray-500">
                              {row.tabName}
                            </td>
                            <td className="whitespace-nowrap py-4 pr-3 text-left text-sm font-normal text-gray-500">
                              {row.message}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <td
                          colSpan={3}
                          className="text-center pt-20 pb-16 text-gray-300 font-bold text-lg"
                        >
                          조회된 데이터가 없습니다.
                        </td>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      </div>
    </>
  );
};

export default AlarmLogPage;
