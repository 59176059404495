import React from "react";
import { Loading, Card, EmptyState, Spinner } from "@shopify/polaris";

const CardSpinner = () => {
  return (
    <>
      <Loading />
      <Card>
        <EmptyState />
        <EmptyState />
        <EmptyState />
        <EmptyState>
          <Spinner size="large"></Spinner>
        </EmptyState>
        <EmptyState />
        <EmptyState />
        <EmptyState />
      </Card>
    </>
  );
};

export default CardSpinner;
