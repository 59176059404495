import React, { useEffect, useState } from "react";
import moment from "moment";

const statusConverter = (status) => {
  switch (status) {
    case 1:
      return {
        text: "정상",
        bgColor: "bg-green-100",
        textColor: "text-green-500",
      };
    case 0:
      return {
        text: "비정상",
        bgColor: "bg-red-100",
        textColor: "text-red-500",
      };
    case 60:
      return {
        text: "비정상",
        bgColor: "bg-red-100",
        textColor: "text-red-500",
      };
    default:
      return {
        text: "EMM 없음",
        bgColor: "bg-gray-100",
        textColor: "text-gray-500",
      };
  }
};

const MachineStatus = ({ deviceStatus }) => {
  const [wifiStatus, setWifiStatus] = useState({});
  const [beanTankStatus, setBeanTankStatus] = useState({});
  const [waterTankStatus, setWaterTankStatus] = useState({});
  const [brewerSwitchStatus, setBrewerSwitchStatus] = useState({});
  const [temp1Status, setTemp1Status] = useState({});
  const [temp2Status, setTemp2Status] = useState({});

  useEffect(() => {
    if (deviceStatus && deviceStatus.wifi) {
      setWifiStatus(statusConverter(deviceStatus.wifi.status));
    }
    if (deviceStatus && deviceStatus.beanTank) {
      setBeanTankStatus(statusConverter(deviceStatus.beanTank.status));
    }
    if (deviceStatus && deviceStatus.waterTank) {
      setWaterTankStatus(statusConverter(deviceStatus.waterTank.status));
    }
    if (deviceStatus && deviceStatus.brewerSwitch) {
      setBrewerSwitchStatus(statusConverter(deviceStatus.brewerSwitch.status));
    }
    if (deviceStatus && deviceStatus.boiler) {
      setTemp1Status(statusConverter(deviceStatus.boiler.temp1.status));
    }
    if (deviceStatus && deviceStatus.boiler) {
      setTemp2Status(statusConverter(deviceStatus.boiler.temp2.status));
    }
  }, [deviceStatus]);

  return (
    <>
      {deviceStatus && deviceStatus.wifi && (
        <div className="w-1/2 overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-8 py-6 sm:px-6">
            <div className="text-base font-semibold">머신 상태</div>
            <table className="w-full mt-5 text-left table-auto">
              <tbody>
                <tr key={"wifi"} className="border-b border-gray-300">
                  <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                    Wi-Fi
                  </td>
                  <td className="flex flex-col justify-end items-end py-4 align-top">
                    <div
                      className={`inline-flex items-center rounded-full mb-2 px-3 py-1 text-xs font-medium ${wifiStatus.bgColor} ${wifiStatus.textColor}`}
                    >
                      {wifiStatus.text}
                    </div>
                    <div className="text-xs text-gray-400">
                      {moment(deviceStatus.wifi.updatedAt)
                        .add(9, "hour")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                  </td>
                </tr>
                <tr key={"beanTank"} className="border-b border-gray-300">
                  <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                    원두통
                  </td>
                  <td className="flex flex-col justify-end items-end py-4 align-top">
                    <div
                      className={`inline-flex items-center rounded-full mb-2 px-3 py-1 text-xs font-medium ${beanTankStatus.bgColor} ${beanTankStatus.textColor}`}
                    >
                      {beanTankStatus.text}
                    </div>
                    <div className="text-xs text-gray-400">
                      {moment(deviceStatus.beanTank.updatedAt)
                        .add(9, "hour")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                  </td>
                </tr>
                <tr key={"waterTank"} className="border-b border-gray-300">
                  <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                    물 통
                  </td>
                  <td className="flex flex-col justify-end items-end py-4 align-top">
                    <div
                      className={`inline-flex items-center rounded-full mb-2 px-3 py-1 text-xs font-medium ${waterTankStatus.bgColor} ${waterTankStatus.textColor}`}
                    >
                      {waterTankStatus.text}
                    </div>
                    <div className="text-xs text-gray-400">
                      {moment(deviceStatus.waterTank.updatedAt)
                        .add(9, "hour")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                  </td>
                </tr>
                <tr key={"brewerSwitch"} className="border-b border-gray-300">
                  <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                    추출기
                  </td>
                  <td className="flex flex-col justify-end items-end py-4 align-top">
                    <div
                      className={`inline-flex items-center rounded-full mb-2 px-3 py-1 text-xs font-medium ${brewerSwitchStatus.bgColor} ${brewerSwitchStatus.textColor}`}
                    >
                      {brewerSwitchStatus.text}
                    </div>
                    <div className="text-xs text-gray-400">
                      {moment(deviceStatus.brewerSwitch.updatedAt)
                        .add(9, "hour")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                  </td>
                </tr>
                <tr key={"temp1"} className="border-b border-gray-300">
                  <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                    1번 보일러(℃)
                  </td>
                  <td className="flex flex-col justify-end items-end py-4 align-top">
                    <div className="flex items-center mb-2">
                      <div className="font-medium text-sm text-gray-700 mr-4">
                        {deviceStatus.boiler.temp1.temperature}
                      </div>
                      <div
                        className={`inline-flex items-center rounded-full px-3 py-1 text-xs font-medium ${temp1Status.bgColor} ${temp1Status.textColor}`}
                      >
                        {temp1Status.text}
                      </div>
                    </div>
                    <div className="text-xs text-gray-400">
                      {moment(deviceStatus.boiler.temp1.updatedAt)
                        .add(9, "hour")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                  </td>
                </tr>
                <tr key={"temp2"} className="border-b border-gray-300">
                  <td className="font-medium text-gray-700 w-1/4 py-4 whitespace-nowrap align-top">
                    2번 보일러(℃)
                  </td>
                  <td className="flex flex-col justify-end items-end py-4 align-top">
                    <div className="flex items-center mb-2">
                      <div className="font-medium text-sm text-gray-700 mr-4">
                        {deviceStatus.boiler.temp2.temperature}
                      </div>
                      <div
                        className={`inline-flex items-center rounded-full mb-2 px-3 py-1 text-xs font-medium ${temp2Status.bgColor} ${temp2Status.textColor}`}
                      >
                        {temp2Status.text}
                      </div>
                    </div>
                    <div className="text-xs text-gray-400">
                      {moment(deviceStatus.boiler.temp2.updatedAt)
                        .add(9, "hour")
                        .format("YYYY-MM-DD HH:mm:ss")}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default MachineStatus;
