import React, { Fragment, useState, useCallback, useContext, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import toast from "react-hot-toast";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { getFaqOrder, updateFaqOrder } from "../../../../rest-apis/faqs";

import { UserInfoContextState } from "contexts/User";
import FaqItem from "./Item";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const OrderBar = ({ open, setOpen, isEdit, setIsEdit }) => {
  const { userInfo } = useContext(UserInfoContextState);

  const [tabs, setTabs] = useState([
    { name: "머신 관리 / 세척", value: "머신 관리 / 세척", current: true },
    { name: "커피 메뉴 / 원두", value: "커피 메뉴 / 원두", current: false },
    { name: "관리자 설정 / 기타", value: "관리자 설정 / 기타", current: false },
  ]);

  const [tab, setTab] = useState("머신 관리 / 세척");
  const [faqs, setFaqs] = useState([]);
  const [movedFaqList, setMovedFaqList] = useState([
    {
      tab: "머신 관리 / 세척",
      FAQIds: [],
    },
    {
      tab: "커피 메뉴 / 원두",
      FAQIds: [],
    },
    {
      tab: "관리자 설정 / 기타",
      FAQIds: [],
    },
  ]);

  const fetchFaqOrders = async (tab) => {
    const response = await getFaqOrder(tab);
    setFaqs(response);
  };

  const updateFaqs = async (movedFaqList) => {
    const response = await updateFaqOrder(movedFaqList, userInfo.name);
    const matchingTab = response.find((r) => r.tab === tab);
    setFaqs(matchingTab.faqs);
  };

  const handleTabChange = (value) => {
    const newTabs = tabs.map((tab) => {
      if (tab.value === value) {
        return { ...tab, current: true };
      } else {
        return { ...tab, current: false };
      }
    });

    setIsEdit(false);
    setTabs(newTabs);
    setTab(value);
    fetchFaqOrders(value);
  };

  const moveFaq = useCallback((dragIndex, hoverIndex) => {
    setIsEdit(true);

    setFaqs((prev) =>
      update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev[dragIndex]],
        ],
      })
    );
  }, []);

  const renderFaq = useCallback(
    (faq, index) => {
      return (
        <FaqItem
          key={faq.id}
          index={index}
          id={faq.id}
          question={faq.question}
          tab={tab}
          moveFaq={moveFaq}
        />
      );
    },
    [faqs]
  );

  useEffect(() => {
    const fetchFaqs = async () => {
      const newFaqList = [...movedFaqList];

      for (let faqGroup of newFaqList) {
        const response = await getFaqOrder(faqGroup.tab);
        faqGroup.FAQIds = response.map((faq) => faq.id);
      }

      setMovedFaqList(newFaqList);
    };

    fetchFaqs();
  }, []);

  useEffect(() => {
    fetchFaqOrders(tab);
  }, [tab]);

  useEffect(() => {
    const FAQIds = faqs.map((faq) => faq.id);

    setMovedFaqList((prevList) =>
      prevList.map((list) => {
        if (list.tab === tab) {
          return { ...list, FAQIds };
        } else {
          return list;
        }
      })
    );
  }, [faqs, tab]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          순서 변경
                        </Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                            onClick={() =>
                              setOpen(false) &&
                              setIsEdit(false) &&
                              fetchFaqOrders("머신 관리 / 세척")
                            }
                          >
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-gray-200">
                      <div className="px-6">
                        <nav className="-mb-px flex space-x-6">
                          {tabs.map((tab) => (
                            <button
                              key={tab.name}
                              className={classNames(
                                tab.current
                                  ? "border-indigo-500 text-indigo-600"
                                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                              )}
                              onClick={() => {
                                if (isEdit) {
                                  if (
                                    !confirm(
                                      "변경된 순서가 저장되지 않습니다. 카테고리를 이동하시겠습니까?"
                                    )
                                  )
                                    return;
                                }
                                handleTabChange(tab.value);
                              }}
                            >
                              {tab.name}
                            </button>
                          ))}
                        </nav>
                      </div>
                    </div>
                    <DndProvider backend={HTML5Backend}>
                      <ul role="list" className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                        {faqs.map((faq, index) => renderFaq(faq, index))}
                      </ul>
                    </DndProvider>
                    <div className="flex justify-end items-center h-24 p-5 border-t border-gray-200">
                      <button
                        type="button"
                        className="w-14 h-9 rounded-md bg-white m-1 px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() =>
                          setOpen(false) && setIsEdit(false) && fetchFaqOrders("머신 관리 / 세척")
                        }
                      >
                        취소
                      </button>
                      <button
                        type="button"
                        className={
                          isEdit
                            ? "w-14 h-9 rounded-md bg-indigo-600 m-1 px-2.5 py-1.5 text-sm font-medium text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-700"
                            : "w-14 h-9 rounded-md bg-gray-200 m-1 px-2.5 py-1.5 text-sm font-medium text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300"
                        }
                        onClick={() => {
                          if (!isEdit) return;
                          if (!confirm("순서 변경을 저장하시겠습니까?")) return;
                          updateFaqs(movedFaqList)
                            .then(() => {
                              toast.success("순서 변경이 저장되었습니다.");
                              fetchFaqOrders(tab);
                              setOpen(false);
                              setIsEdit(false);
                            })
                            .catch((error) => {
                              toast.error("순서 변경에 실패했습니다.");
                            });
                        }}
                        disabled={!isEdit}
                      >
                        저장
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default OrderBar;
