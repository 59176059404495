import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { XCircleIcon, PlusCircleIcon } from "@heroicons/react/24/solid";

import { UserInfoContextState } from "contexts/User";
import AdminFrame from "components/Layout/Frame";
import CardSpinner from "common/PageSpinner/Card";
import { Page } from "@shopify/polaris";
import { getSelectList, updateAppData } from "../../../rest-apis/appData";
import BeanDropdown from "common/dropdown/appData/bean";
import MenuDropdown from "common/dropdown/appData/menu";
import PresetDropdown from "../../../common/dropdown/appData/preset";
import BrewingContentDropdown from "../../../common/dropdown/appData/brewingContent";
import CompleteContentDropdown from "../../../common/dropdown/appData/completeContent";
import toast from "react-hot-toast";

const AppDataForm = () => {
  const navigate = useNavigate();
  const path = useLocation().pathname;

  const { userInfo } = useContext(UserInfoContextState);

  // detail data
  const locationState = useLocation().state?.appData;
  const [state, setState] = useState();
  const [invalidMenus, setInvalidMenus] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [beanList, setBeanList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [presetList, setPresetList] = useState([]);
  const [brewingContentList, setBrewingContentList] = useState([]);
  const [completeContentList, setCompleteContentList] = useState([]);

  const [selectedBeans, setSelectedBeans] = useState([]);
  const [selectedMenus, setSelectedMenus] = useState([]);
  const [selectedPresets, setSelectedPresets] = useState(
    Array(state?.length).fill({ beanId: null, menuId: null, presetId: null })
  );
  const [selectedBrewingContents, setSelectedBrewingContents] = useState(
    Array(state?.length).fill({
      beanId: null,
      menuId: null,
      contentSetIds: Array(1).fill({
        brewingContentId: null,
      }),
    })
  );
  const [selectedCompleteContents, setSelectedCompleteContents] = useState(
    Array(state?.length).fill({
      beanId: null,
      menuId: null,
      contentSetIds: Array(1).fill({
        completeContentId: null,
      }),
    })
  );

  const [beanSearchInput, setBeanSearchInput] = useState("");
  const [menuSearchInput, setMenuSearchInput] = useState("");
  const [presetSearchInput, setPresetSearchInput] = useState("");
  const [brewingContentSearchInput, setBrewingContentSearchInput] = useState("");
  const [completeContentSearchInput, setCompleteContentSearchInput] = useState("");

  const [clickedBean, setClickedBean] = useState(null);
  const [clickedMenu, setClickedMenu] = useState(null);
  const [clickedData, setClickedData] = useState({ presets: null, contents: null });

  const fetchSelectList = async () => {
    setIsLoading(true);

    const response = await getSelectList();
    setBeanList(response.beanList);
    setMenuList(response.menuList);
    setPresetList(response.presetList);
    setBrewingContentList(response.brewingContentList);
    setCompleteContentList(response.completeContentList);

    setIsLoading(false);
  };

  const validateData = () => {
    let newInvalidMenus = [];

    selectedPresets.forEach((preset) => {
      if (!preset.presetId) {
        newInvalidMenus.push({ beanId: preset.beanId, menuId: preset.menuId });
      }
    });

    selectedBrewingContents.forEach((content) => {
      content.contentSetIds.forEach((contentSet) => {
        if (!contentSet.brewingContentId) {
          newInvalidMenus.push({ beanId: content.beanId, menuId: content.menuId });
        }
      });
    });

    selectedCompleteContents.forEach((content) => {
      content.contentSetIds.forEach((contentSet) => {
        if (!contentSet.completeContentId) {
          newInvalidMenus.push({ beanId: content.beanId, menuId: content.menuId });
        }
      });
    });

    newInvalidMenus = newInvalidMenus.reduce((acc, current) => {
      if (!acc.some((item) => item.beanId === current.beanId && item.menuId === current.menuId)) {
        return [...acc, current];
      } else {
        return acc;
      }
    }, []);

    setInvalidMenus(newInvalidMenus);
  };

  const handleMenuClick = (beanId, menuId) => {
    validateData();

    setClickedBean(beanId);
    setClickedMenu(menuId);

    const filteredPreset =
      selectedPresets.find((preset) => preset.beanId === beanId && preset.menuId === menuId) || {};
    const filteredBrewingContent =
      selectedBrewingContents.find(
        (content) => content.beanId === beanId && content.menuId === menuId
      ) || {};

    const filteredCompleteContent =
      selectedCompleteContents.find(
        (content) => content.beanId === beanId && content.menuId === menuId
      ) || {};

    if (filteredPreset && filteredBrewingContent && filteredCompleteContent) {
      setClickedData({
        presets: filteredPreset,
        contents: filteredBrewingContent.contentSetIds.map((contentSet, index) => ({
          contentSetId: contentSet.contentSetId,
          brewingContent: {
            id: contentSet.brewingContentId,
            title: contentSet.brewingContentTitle,
            files: contentSet.brewingContentFiles,
          },
          completeContent: {
            id: filteredCompleteContent.contentSetIds[index].completeContentId,
            title: filteredCompleteContent.contentSetIds[index].completeContentTitle,
            files: filteredCompleteContent.contentSetIds[index].completeContentFiles,
          },
        })),
      });
    }
  };

  const handleDeleteContent = (contentIndex) => {
    if (!window.confirm("삭제하시겠습니까?")) {
      return;
    }

    setClickedData((prevData) => {
      const newContents = prevData.contents.filter((_, index) => index !== contentIndex);
      return { ...prevData, contents: newContents };
    });
    setSelectedBrewingContents((prevSelectedContents) =>
      prevSelectedContents.map((content) => {
        if (content.beanId === clickedBean && content.menuId === clickedMenu) {
          return {
            ...content,
            contentSetIds: content.contentSetIds.filter((_, index) => index !== contentIndex),
          };
        }
        return content;
      })
    );
    setSelectedCompleteContents((prevSelectedContents) =>
      prevSelectedContents.map((content) => {
        if (content.beanId === clickedBean && content.menuId === clickedMenu) {
          return {
            ...content,
            contentSetIds: content.contentSetIds.filter((_, index) => index !== contentIndex),
          };
        }
        return content;
      })
    );

    toast.success("삭제되었습니다.");
    validateData();
  };

  const handleAddContent = () => {
    setClickedData((prevData) => {
      const newContents = prevData.contents.concat({
        contentSetId: null,
        brewingContent: {
          id: null,
          title: null,
          files: [],
        },
        completeContent: {
          id: null,
          title: null,
          files: [],
        },
      });
      return { ...prevData, contents: newContents };
    });
    setSelectedBrewingContents((prevSelectedContents) =>
      prevSelectedContents.map((content) => {
        if (content.beanId === clickedBean && content.menuId === clickedMenu) {
          return {
            ...content,
            contentSetIds: content.contentSetIds.concat({
              brewingContentId: null,
              brewingContentTitle: null,
              brewingContentFiles: [],
            }),
          };
        }
        return content;
      })
    );
    setSelectedCompleteContents((prevSelectedContents) =>
      prevSelectedContents.map((content) => {
        if (content.beanId === clickedBean && content.menuId === clickedMenu) {
          return {
            ...content,
            contentSetIds: content.contentSetIds.concat({
              completeContentId: null,
              completeContentTitle: null,
              completeContentFiles: [],
            }),
          };
        }
        return content;
      })
    );

    validateData();
  };

  const handleAppDataSaveButtonClick = async () => {
    validateData();

    // 실시간 오류 체크가 존재하는 경우 저장 불가
    if (invalidMenus.length > 0) {
      toast.error("데이터를 모두 선택하세요.");
      return;
    }

    if (!window.confirm("상세 정보를 저장하시겠습니까?\n저장 즉시 앱에 적용되어 노출됩니다.")) {
      return;
    }

    setIsSaving(true);

    // 앱 데이터 사용자 선택지로 재구성
    const appDataSets = [];
    selectedBeans.forEach((bean) => {
      selectedMenus.forEach((menu, index) => {
        // preset
        const preset = selectedPresets.find((p) => p.beanId === bean.id && p.menuId === menu.id);

        // contents
        const contents = selectedBrewingContents
          .filter((bc) => bc.beanId === bean.id && bc.menuId === menu.id)
          .flatMap((bc, bcIndex) =>
            bc.contentSetIds.map((cs, csIndex) => {
              const completeContent = selectedCompleteContents
                .find((cc) => cc.beanId === bean.id && cc.menuId === menu.id)
                ?.contentSetIds.find((ccs, ccsIndex) => {
                  return ccs.contentSetId
                    ? ccs.contentSetId === cs.contentSetId
                    : ccsIndex === csIndex;
                });
              return {
                brewingContentId: cs.brewingContentId,
                completeContentId: completeContent?.completeContentId || bcIndex,
              };
            })
          );

        // 최종 데이터 구조 생성
        if (preset && contents.length > 0) {
          appDataSets.push({
            beanId: bean.id,
            menuId: menu.id,
            menuOrder: index + 1,
            presetId: preset.presetId,
            contentSetIds: contents,
          });
        }
      });
    });

    const updatedAppData = {
      appDataSets: appDataSets,
      lastModifier: userInfo.name,
    };

    const promise = updateAppData(updatedAppData);

    try {
      const response = await promise;
      if (response.status === 200) {
        toast.success("수정되었습니다.");
        setTimeout(() => {
          navigate("/app-data");
        }, 500);
      }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "서버 오류가 존재합니다."
      );
    } finally {
      setTimeout(() => {
        setIsSaving(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (path.split("/").includes("edit") && !locationState) {
      navigate("/app-data");
      return;
    }
    setState(locationState || {});
    fetchSelectList();
  }, []);

  useEffect(() => {
    if (state) {
      const selectedBeans = state.appDataSets.map((dataSet) => ({
        id: dataSet.beanId,
        name: dataSet.beanName,
      }));
      setSelectedBeans(selectedBeans);

      const selectedMenus = state.appDataSets[0].menus.map((menu) => ({
        id: menu.menuId,
        name: menu.menuName,
      }));
      setSelectedMenus(selectedMenus);

      const selectedPresets = state.appDataSets.flatMap((dataSet) =>
        dataSet.menus.map((menu) => ({
          beanId: dataSet.beanId,
          menuId: menu.menuId,
          presetId: menu.presets?.presetId,
          presetTitle: menu.presets?.presetTitle,
          waterFirst: menu.presets?.waterFirst,
          shot: menu.presets?.shot,
          hotWater: menu.presets?.hotWater,
          coldWater: menu.presets?.coldWater,
          espresso: menu.presets?.espresso,
          preInfusionTime: menu.presets?.preInfusionTime,
        }))
      );
      setSelectedPresets(selectedPresets);

      const selectedBrewingContents = state.appDataSets.flatMap((dataSet) =>
        dataSet.menus.map((menu) => ({
          beanId: dataSet.beanId,
          menuId: menu.menuId,
          contentSetIds: menu.contents.map((contentSet) => ({
            contentSetId: contentSet.contentSetId,
            brewingContentId: contentSet.brewingContent?.id,
            brewingContentTitle: contentSet.brewingContent?.title,
            brewingContentFiles: contentSet.brewingContent?.files,
          })),
        }))
      );
      setSelectedBrewingContents(selectedBrewingContents);

      const selectedCompleteContents = state.appDataSets.flatMap((dataSet) =>
        dataSet.menus.map((menu) => ({
          beanId: dataSet.beanId,
          menuId: menu.menuId,
          contentSetIds: menu.contents.map((contentSet) => ({
            contentSetId: contentSet.contentSetId,
            completeContentId: contentSet.completeContent?.id,
            completeContentTitle: contentSet.completeContent?.title,
            completeContentFiles: contentSet.completeContent?.files,
          })),
        }))
      );
      setSelectedCompleteContents(selectedCompleteContents);

      const firstDataSet = state.appDataSets[0];
      const firstMenu = firstDataSet.menus[0];

      setClickedBean(firstDataSet.beanId);
      setClickedMenu(firstMenu.menuId);
      setClickedData({
        presets: firstMenu.presets,
        contents: firstMenu.contents,
      });
    }
  }, [state]);

  useEffect(() => {
    if (selectedBeans.length > 0 && selectedMenus.length > 0) {
      const beanIds = selectedBeans.map((bean) => bean.id);
      const menuIds = selectedMenus.map((menu) => menu.id);

      const filteredData = state.appDataSets.filter((dataSet) => beanIds.includes(dataSet.beanId));
      const filteredMenus = filteredData[0].menus.filter((menu) => menuIds.includes(menu.menuId));

      if (filteredMenus.length > 0) {
        setClickedBean(filteredData[0].beanId);
        setClickedMenu(filteredMenus[0].menuId);
        setClickedData({
          presets: filteredMenus[0].presets,
          contents: filteredMenus[0].contents,
        });
      }
    }
  }, [selectedBeans, selectedMenus]);

  useEffect(() => {
    validateData();
  }, [
    selectedBeans,
    selectedMenus,
    selectedPresets,
    selectedBrewingContents,
    selectedCompleteContents,
  ]);

  return (
    <>
      <AdminFrame title="앱 데이터 관리">
        <div className="flex justify-between">
          <div className="flex ml-6 mt-6 items-center">
            <div className="ml-3 text-xl font-bold text-gray-800">앱 데이터 관리</div>
          </div>
          <div className="mt-6 mr-10">
            <button
              type="button"
              className={
                isSaving
                  ? "w-24 h-9 rounded-md bg-gray-200 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  : "w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
              onClick={handleAppDataSaveButtonClick}
              disabled={isSaving}
            >
              {isSaving ? (
                <div className="flex">
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                  <div>저장중</div>
                </div>
              ) : (
                "저장"
              )}
            </button>
          </div>
        </div>
        <Page fullWidth>
          {isLoading ? (
            <CardSpinner />
          ) : (
            <>
              <div className="flex">
                <div className="w-7/12 h-screen overflow-y-auto overflow-x-auto mr-4 bg-white shadow">
                  <div className="px-8 py-6 sm:px-6">
                    <div className="flex items-center space-x-2">
                      <div className="text-base font-semibold">상세 정보</div>
                    </div>
                    <div className="flex w-full items-center mb-4 mt-6">
                      <div className="mr-12">원두 목록</div>
                      <div className="w-5/12">
                        <BeanDropdown
                          validateData={validateData}
                          setInvalidMenus={setInvalidMenus}
                          beanList={beanList}
                          beanSearchInput={beanSearchInput}
                          setBeanSearchInput={setBeanSearchInput}
                          selectedBeans={selectedBeans}
                          setSelectedBeans={setSelectedBeans}
                          state={state}
                          setState={setState}
                        />
                      </div>
                    </div>
                    <div className="flex w-full items-center mb-6 mt-4">
                      <div className="mr-12">메뉴 목록</div>
                      <div className="w-5/12">
                        <MenuDropdown
                          validateData={validateData}
                          setInvalidMenus={setInvalidMenus}
                          menuList={menuList}
                          menuSearchInput={menuSearchInput}
                          setMenuSearchInput={setMenuSearchInput}
                          selectedMenus={selectedMenus}
                          setSelectedMenus={setSelectedMenus}
                          state={state}
                          setState={setState}
                        />
                      </div>
                    </div>
                    <div className="border text-gray-400"></div>
                    {state?.appDataSets
                      ?.filter(
                        (appDataSet) =>
                          Array.isArray(selectedBeans) &&
                          selectedBeans.some((bean) => bean.id === appDataSet.beanId)
                      )
                      .map((appDataSet, index) => (
                        <div key={`beans-${index}`} className="flex flex-col h-full my-6">
                          <div className="w-full h-full mr-4 overflow-hidden bg-white shadow sm:rounded-lg">
                            <div className="px-6 py-4 sm:px-6">
                              <div className="text-base font-semibold">{appDataSet.beanName}</div>
                              <div className="flex flex-wrap">
                                {appDataSet.menus
                                  .filter((menu) =>
                                    selectedMenus.some(
                                      (selectedMenu) => selectedMenu.id === menu.menuId
                                    )
                                  )
                                  .map((menu, menuIndex) => (
                                    <button
                                      key={`menus-${menuIndex}`}
                                      className={`flex flex-col justify-center items-center w-36 h-16 mt-2 mr-4 overflow-hidden border rounded-lg ${
                                        clickedBean === appDataSet.beanId &&
                                        clickedMenu === menu.menuId
                                          ? "bg-gray-100 border-gray-200"
                                          : "bg-white"
                                      } ${
                                        invalidMenus.some(
                                          (invalidMenu) =>
                                            invalidMenu.beanId === appDataSet.beanId &&
                                            invalidMenu.menuId === menu.menuId
                                        )
                                          ? "border-3 border-red-500"
                                          : ""
                                      }`}
                                      onClick={() =>
                                        handleMenuClick(appDataSet.beanId, menu.menuId)
                                      }
                                    >
                                      <div className="px-3 py-2 text-center">
                                        <div className="whitespace-nowrap text-sx font-normal">
                                          {menu.menuName.split("\n").map((line, lineIndex) => (
                                            <div key={`menuName-${lineIndex}`}>{line}</div>
                                          ))}
                                        </div>
                                      </div>
                                    </button>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="w-8/12 h-screen overflow-y-visible overflow-x-auto bg-white shadow sm:rounded-lg mb-5">
                  <div className="px-8 py-6">
                    <div className="text-base font-semibold">미리 보기</div>
                    <div key={"preset"} className="relative my-8 mx-5">
                      <div className="inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-1.5 border-gray-500" />
                        <span className="bg-white px-6 text-base font-semibold leading-6 text-gray-700 absolute left-0 transform -translate-x-1/2">
                          프리셋
                        </span>
                      </div>
                    </div>
                    <div>
                      <PresetDropdown
                        validateData={validateData}
                        beanId={clickedBean}
                        menuId={clickedMenu}
                        presetList={presetList}
                        presetSearchInput={presetSearchInput}
                        setPresetSearchInput={setPresetSearchInput}
                        selectedPresets={selectedPresets}
                        setSelectedPresets={setSelectedPresets}
                      />
                    </div>
                    {clickedData?.contents.map((content, index) => (
                      <div key={`contents-${index}`}>
                        <div className="relative z-0 my-8 mx-5">
                          <div className="inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-1.5 border-gray-500" />
                            <span className="bg-white ml-1 px-6 text-base font-semibold leading-6 text-gray-700 absolute left-0 transform -translate-x-1/2">
                              콘텐츠 {index + 1}
                            </span>
                          </div>
                          {index > 0 && (
                            <button
                              type="button"
                              className="float-right mt-4"
                              onClick={() => handleDeleteContent(index)}
                            >
                              <XCircleIcon className="h-6 w-6 text-gray-200" />
                            </button>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <div className="relative">
                            <BrewingContentDropdown
                              validateData={validateData}
                              beanId={clickedBean}
                              menuId={clickedMenu}
                              contentIndex={index}
                              contentSetId={clickedData?.contents[index].contentSetId}
                              brewingContentList={brewingContentList}
                              brewingContentSearchInput={brewingContentSearchInput}
                              setBrewingContentSearchInput={setBrewingContentSearchInput}
                              selectedBrewingContents={selectedBrewingContents}
                              setSelectedBrewingContents={setSelectedBrewingContents}
                            />
                          </div>
                          <div className="relative">
                            <CompleteContentDropdown
                              validateData={validateData}
                              beanId={clickedBean}
                              menuId={clickedMenu}
                              contentIndex={index}
                              contentSetId={clickedData?.contents[index].contentSetId}
                              completeContentList={completeContentList}
                              completeContentSearchInput={completeContentSearchInput}
                              setCompleteContentSearchInput={setCompleteContentSearchInput}
                              selectedCompleteContents={selectedCompleteContents}
                              setSelectedCompleteContents={setSelectedCompleteContents}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    {clickedData?.contents.length < 5 && (
                      <div>
                        <div className="w-full mt-4 border-t border-1.5 border-gray-500" />
                        <button
                          type="button"
                          className="flex w-full justify-center mt-4"
                          onClick={handleAddContent}
                        >
                          <PlusCircleIcon className="h-12 w-12 text-gray-400" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </Page>
      </AdminFrame>
    </>
  );
};

export default AppDataForm;
