import React, { useState, useEffect, useContext, Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Page } from "@shopify/polaris";
import toast from "react-hot-toast";

import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";

import usePrompt from "utils/Prompt";
import { UserInfoContextState } from "contexts/User";

import CardSpinner from "common/PageSpinner/Card";
import AdminFrame from "components/Layout/Frame";
import Editor from "common/Editor/Editor";
import DeactivateBadge from "common/Badge/deactivate";

import { createFaq, getFaqDetail, updateFaq } from "../../../rest-apis/faqs";
import PreviewComponent from "./preview";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const tabs = ["머신 관리 / 세척", "커피 메뉴 / 원두", "관리자 설정 / 기타"];

const FAQForm = () => {
  // Path
  const { id } = useParams();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  const { userInfo } = useContext(UserInfoContextState);

  // faq data
  const locationState = useLocation().state?.faq;
  const [faqState, setFaqState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const MAX_QUESTION_LENGTH = 35;

  const [selectedTab, setSelectedTab] = useState(locationState ? locationState.tabName : "선택");
  const [questionInput, setQuestionInput] = useState(
    locationState ? locationState.question : faqState?.question || ""
  );
  const [previewAnswer, setPreviewAnswer] = useState("");

  const isValidTextInput = (text) => {
    const regex = /^[A-Za-z0-9ㄱ-ㅎㅏ-ㅣ가-힣~!@#₩$%^&*()\-_+=,./<>?\\|\n ]*$/;
    return regex.test(text);
  };

  const isContentEmpty = (htmlContent) => {
    // 이미지 존재 여부 확인
    const hasImage = /<img\s+[^>]*src=[^>]+>/.test(htmlContent);
    if (hasImage) {
      return false;
    }

    // 텍스트 존재 여부 확인
    const text = htmlContent.replace(/<[^>]*>/g, "").trim();
    return text === "";
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    setFaqState({ ...faqState, tabName: tab });
  };

  const [isPrompt, setIsPrompt] = useState(true);
  usePrompt(
    "다른 페이지로 이동하겠습니까? 이 페이지에서 변경한 내용은 저장되지 않습니다.",
    isPrompt
  );

  const mapResponseToFaqState = (data) => ({
    tabName: data.tabName || "선택",
    question: data.question || "",
    answer: data.answer || "",
    lastModifier: userInfo.name,
  });

  const fetchFaq = async (id) => {
    setIsLoading(true);
    const response = await getFaqDetail(id);
    setFaqState(mapResponseToFaqState(response));
    setIsLoading(false);
  };

  const uploadFaq = async () => {
    // 토스트 에러 출력
    if (faqState.tabName === "선택") {
      toast.error("탭명을 선택해 주세요.");
      return;
    }
    if (!faqState.question) {
      toast.error("문의 제목을 입력하세요.");
      return;
    }
    if (!faqState.answer || isContentEmpty(faqState.answer)) {
      toast.error("문의 답변을 입력하세요.");
      return;
    }

    if (!window.confirm("상세 정보를 저장하시겠습니까?")) {
      return;
    }

    setIsSaving(true);

    const promise = locationState
      ? updateFaq(locationState.id, {
          tabName: faqState.tabName,
          question: faqState.question,
          answer: faqState.answer,
          lastModifier: userInfo.name,
        })
      : createFaq({
          tabName: faqState.tabName,
          question: faqState.question,
          answer: faqState.answer,
          lastModifier: userInfo.name,
        });

    try {
      const response = await promise;
      if (response.status === 200 || response.status === 201) {
        toast.success(locationState ? "수정되었습니다." : "저장되었습니다.");
        setIsPrompt(false);
        setTimeout(() => {
          navigate(locationState ? `/faqs/${locationState.id}` : `/faqs/${response.data.id}`);
        }, 500);
      }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "서버 오류가 존재합니다."
      );
    } finally {
      setTimeout(() => {
        setIsSaving(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (pathname.split("/").includes("edit") && !locationState) {
      navigate("/faqs");
      return;
    }

    if (locationState?.id) {
      fetchFaq(locationState.id);
    } else {
      setFaqState(mapResponseToFaqState(locationState || {}));
    }
  }, [id, locationState]);

  useEffect(() => {
    if (!faqState.answer) return;
    setPreviewAnswer(faqState.answer);
  }, [faqState.answer]);

  return (
    <>
      <AdminFrame title={`자주 묻는 질문 ${locationState ? "수정" : "추가"}`}>
        <div className="flex justify-between">
          <div className="flex ml-6 mt-6 items-center">
            <ArrowSmallLeftIcon
              type="button"
              className="w-9 h-9 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => {
                navigate("/faqs");
              }}
            />
            <div className="ml-3 text-xl font-bold text-gray-800">
              자주 묻는 질문 {locationState ? `수정 | ${locationState.question}` : "추가"}
            </div>
            {locationState ? (
              <div className="ml-3">
                <DeactivateBadge isDeactivated={locationState.deactivate} />
              </div>
            ) : null}
          </div>
          <div className="mt-6 mr-10">
            <button
              type="button"
              className={
                isSaving
                  ? "w-24 h-9 rounded-md bg-gray-200 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  : "w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
              onClick={() => {
                uploadFaq();
              }}
              disabled={isSaving}
            >
              {isSaving ? (
                <div className="flex">
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                  <div>저장중</div>
                </div>
              ) : (
                "저장"
              )}
            </button>
          </div>
        </div>
        <Page fullWidth>
          {isLoading ? (
            <CardSpinner />
          ) : (
            <div className="flex overflow-x-auto">
              <div className="w-8/12 mr-4 bg-white shadow sm:rounded-lg mb-5">
                <div className="px-8 py-6 sm:px-6">
                  <div className="text-base font-semibold">상세 정보</div>
                  <table className="w-full mt-5 text-left table-auto">
                    <tbody>
                      <tr key="faq-tab-name" className="border-b border-gray-100">
                        <td className="font-medium w-1/4 py-4 align-top">탭명</td>
                        <td className="py-4">
                          <Menu as="div" className="relative inline-block text-left">
                            <div>
                              <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                {selectedTab}
                                <ChevronDownIcon
                                  className="-mr-1 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                              </Menu.Button>
                            </div>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute left-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="py-1">
                                  {tabs.map((tab) => (
                                    <Menu.Item key={tab} onClick={() => handleTabClick(tab)}>
                                      {({ active }) => (
                                        <a
                                          className={classNames(
                                            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                                            "block px-4 py-2 text-sm"
                                          )}
                                        >
                                          {tab}
                                        </a>
                                      )}
                                    </Menu.Item>
                                  ))}
                                </div>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </td>
                      </tr>
                      <tr key={`${faqState?.id}-question`} className="border-b border-gray-100">
                        <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                          문의 제목
                        </td>
                        <td className="relative py-4">
                          <input
                            type="text"
                            value={faqState?.question || ""}
                            maxLength={MAX_QUESTION_LENGTH}
                            onChange={(e) => {
                              if (
                                isValidTextInput(e.target.value) &&
                                e.target.value.length <= MAX_QUESTION_LENGTH
                              ) {
                                setQuestionInput(e.target.value);
                                setFaqState({ ...faqState, question: e.target.value });
                              }
                            }}
                            onBlur={(e) => {
                              const trimmedValue = e.target.value.replace(/\s+$/, "");
                              setQuestionInput(trimmedValue);
                              setFaqState({ ...faqState, title: trimmedValue });
                            }}
                            className="block w-full rounded-md border-0 py-1.5 pl-2 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset focus:ring-indigo-600 placeholder:text-gray-400"
                            placeholder="문의 제목을 입력하세요."
                          />
                          <div className="absolute mr-3 right-0 top-1/2 transform -translate-y-1/2 text-s text-gray-500">
                            {`${
                              faqState.question ? faqState.question.length : questionInput.length
                            }/${MAX_QUESTION_LENGTH}`}
                          </div>
                        </td>
                      </tr>
                      <tr key={`${faqState?.id}-answer`}>
                        <td className="w-1/5 py-4 align-top whitespace-nowrap font-medium">
                          문의 답변
                        </td>
                        <td className="relative pt-4 pb-8">
                          <Editor type="faq" faqState={faqState} setFaqState={setFaqState} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="w-6/12 bg-white shadow overflow-x-auto sm:rounded-lg mb-5">
                <div className="px-8 py-6 sm:px-6">
                  <div className="flex">
                    <div className="text-base font-semibold whitespace-nowrap">미리보기</div>
                    <div className="flex items-center ml-2 text-sm text-gray-400">
                      (‘미리 보기’ 화면은 참고용으로 스테이징 환경에서 테스트 후 최종 등록을
                      권장합니다.)
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <div
                      className="flex flex-col items-start m-4"
                      style={{
                        width: "418px",
                        height: "594px",
                        paddingBottom: "25px",
                        background: "var(--Black, #000)",
                      }}
                    >
                      <div className="w-full flex">
                        <img
                          src="/images/navigate-before.png"
                          style={{
                            position: "absolute",
                            marginLeft: "20px",
                            marginTop: "29px",
                            width: "12px",
                            height: "12px",
                          }}
                        />
                        <div
                          className="w-full flex items-center justify-center"
                          style={{
                            marginTop: "22px",
                            color: "white",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          {selectedTab}
                        </div>
                      </div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginRight: "24px",
                          marginTop: "24px",
                          color: "white",
                          fontSize: "14px",
                          fontWeight: "600",
                        }}
                      >
                        {questionInput}
                      </div>
                      <div
                        style={{ marginLeft: "20px", marginTop: "18px", width: "374px" }}
                        className="border-b border-gray-700"
                      ></div>
                      <div style={{ marginBottom: "3px" }}></div>
                      <div
                        className="custom-scroll overflow-y-auto mt-4"
                        style={{ width: "374px", marginLeft: "20px" }}
                      >
                        <PreviewComponent previewAnswer={previewAnswer} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Page>
      </AdminFrame>
    </>
  );
};

export default FAQForm;
