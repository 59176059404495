import React, { useEffect, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

const roleType = [
  { id: "관리자", title: "관리자" },
  { id: "팀장", title: "팀장" },
  { id: "멤버", title: "멤버" },
  { id: "게스트(조회)", title: "게스트(조회)" },
];

const FilterBar = ({ setSearch, setRole, fetchUser }) => {
  const [inputSearch, setInputSearch] = useState("");
  const [isRoleTypeChecked, setIsRoleTypeChecked] = useState(
    roleType.reduce((acc, roleType) => ({ ...acc, [roleType.id]: true }), {})
  );

  const handleSearchChange = (e) => {
    setInputSearch(e.target.value);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    setSearch(inputSearch);

    let checkedRoleTypes = Object.entries(isRoleTypeChecked)
      .filter(([key, value]) => value)
      .map(([key]) => key.toLowerCase());

    if (checkedRoleTypes.length === 0) {
      checkedRoleTypes = roleType.map((roleType) => roleType.id);
      const allChecked = roleType.reduce(
        (acc, roleType) => ({
          ...acc,
          [roleType.id]: true,
        }),
        {}
      );

      setIsRoleTypeChecked(allChecked);
    }
    setRole(checkedRoleTypes.join(","));
  };

  const handleRoleTypeCheckboxChange = (id) => {
    setIsRoleTypeChecked((prevState) => ({ ...prevState, [id]: !prevState[id] }));
    setSearch(inputSearch);
  };

  useEffect(() => {
    const checkedRoleTypes = Object.entries(isRoleTypeChecked)
      .filter(([key, value]) => value)
      .map(([key]) => key);
    setRole(checkedRoleTypes.join(","));
  }, [isRoleTypeChecked, setRole]);

  return (
    <div className=" bg-white shadow sm:rounded-lg mb-5">
      <div className="px-4 py-4 sm:px-6">
        <form onSubmit={handleSearchClick}>
          <div className="flex columns-3">
            <div className="relative mt-2 w-11/12 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                name="text"
                id="text"
                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="검색어를 입력하세요."
                onChange={handleSearchChange}
              />
            </div>
            <button
              type="submit"
              className="w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleSearchClick}
            >
              검색
            </button>
          </div>
        </form>
        <fieldset className="mt-4 ml-2 items-start space-y-2">
          <div className="flex items-center">
            <p className="font-medium mr-6">노출 위치</p>
            {roleType.map((roleType) => (
              <div key={roleType.id} className="flex items-center">
                <input
                  id={roleType.id}
                  type="checkbox"
                  name="select-all"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  checked={isRoleTypeChecked[roleType.id] || false}
                  onChange={() => handleRoleTypeCheckboxChange(roleType.id)}
                />
                <label
                  htmlFor={roleType.id}
                  className="mx-3 block text-sm font-medium leading-6 text-gray-600"
                >
                  {roleType.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default FilterBar;
