import {
  UserIcon,
  Squares2X2Icon,
  ComputerDesktopIcon,
  FaceSmileIcon,
  UserGroupIcon,
  PhotoIcon,
  BellIcon,
  DevicePhoneMobileIcon,
  DocumentTextIcon,
  AdjustmentsHorizontalIcon,
  SquaresPlusIcon,
  IdentificationIcon,
  ClipboardDocumentListIcon,
  CloudArrowUpIcon,
  PaperAirplaneIcon,
  // Cog6ToothIcon,
} from "@heroicons/react/24/outline";

export const manageAccount = [
  {
    id: 1,
    name: "계정 관리",
    engName: "ACCOUNT",
    href: "/accounts",
    icon: UserIcon,
    current: false,
  },
  {
    id: 2,
    name: "그룹 계정 관리",
    engName: "GROUP_ACCOUNT",
    href: "/group-accounts",
    icon: UserGroupIcon,
    current: false,
  },
  {
    id: 3,
    name: "머신 관리",
    engName: "DEVICE",
    href: "/devices",
    icon: ComputerDesktopIcon,
    current: false,
  },
];

export const appData = [
  {
    id: 1,
    name: "앱 데이터 관리",
    engName: "APP_DATA",
    href: "/app-data",
    icon: SquaresPlusIcon,
    current: false,
  },
];

export const manageContent = [
  {
    id: 1,
    name: "원두 관리",
    engName: "BEAN",
    href: "/beans",
    icon: FaceSmileIcon,
    current: false,
  },
  {
    id: 2,
    name: "메뉴 관리",
    engName: "MENU",
    href: "/menus",
    icon: Squares2X2Icon,
    current: false,
  },
  {
    id: 3,
    name: "프리셋 관리",
    engName: "PRESET",
    href: "/presets",
    icon: AdjustmentsHorizontalIcon,
    current: false,
  },
  {
    id: 4,
    name: "콘텐츠 관리",
    engName: "CONTENT",
    href: "/contents",
    icon: PhotoIcon,
    current: false,
  },
];

export const manageApp = [
  {
    id: 1,
    name: "기본 화면 관리",
    engName: "HOME_SETTING",
    href: "/home-setting",
    icon: DevicePhoneMobileIcon,
    current: false,
  },
  { id: 2, name: "공지 관리", engName: "NOTICE", href: "/notices", icon: BellIcon, current: false },
  {
    id: 2,
    name: "사용 설명 보기",
    engName: "GUIDE",
    href: "/guides",
    icon: ClipboardDocumentListIcon,
    current: false,
  },
  {
    id: 3,
    name: "자주 묻는 질문",
    engName: "FAQ",
    href: "/faqs",
    icon: DocumentTextIcon,
    current: false,
  },
];

export const manageUser = [
  {
    id: 1,
    name: "사용자 관리",
    engName: "USER",
    href: "/users",
    icon: IdentificationIcon,
    current: false,
  },
];

export const manageAppDeploy = [
  {
    id: 1,
    name: "앱 버전 관리",
    engName: "APP_VERSION",
    href: "/appVersions",
    icon: CloudArrowUpIcon,
    current: false,
  },
  {
    id: 2,
    name: "앱 배포 관리",
    engName: "APP_DEPLOY",
    href: "/appDeploys",
    icon: PaperAirplaneIcon,
    current: false,
  },
  // {
  //   id: 3,
  //   name: "디바이스 관리",
  //   engName: "DEVICE_GROUP",
  //   href: "/deviceGroups",
  //   icon: Cog6ToothIcon,
  //   current: false,
  // },
];
