import React, { createContext, useState } from "react";

const LoginContextState = createContext();
const UserInfoContextState = createContext();

const UserContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
  });

  return (
    <LoginContextState.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      <UserInfoContextState.Provider value={{ userInfo, setUserInfo }}>
        {children}
      </UserInfoContextState.Provider>
    </LoginContextState.Provider>
  );
};

export { UserContextProvider, LoginContextState, UserInfoContextState };
