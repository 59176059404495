import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import "echarts/theme/macarons";
import "./theme.js";

const MenuChart = ({ accumulatedBrewingMenu }) => {
  const [options, setOptions] = useState({
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {},
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
    },
    yAxis: {
      type: "category",
      data: ["21 ~ 말일", "11 ~ 20일", "1 ~ 10일"],
    },
    series: [],
  });

  useEffect(() => {
    if (accumulatedBrewingMenu) {
      setOptions({
        ...options,
        series: accumulatedBrewingMenu.map((menu) => {
          return {
            name: menu.name,
            type: "bar",
            stack: "total",
            label: {
              show: true,
            },
            emphasis: {
              focus: "series",
            },
            data: menu.data,
          };
        }),
      });
    }
  }, [accumulatedBrewingMenu]);

  return (
    <div className="h-auto">
      <ReactECharts option={options} setOptions={setOptions} theme={"westeros"} />
    </div>
  );
};

export default MenuChart;
