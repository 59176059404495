import React, { useEffect, useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

const displayType = [
  { id: "추출중", title: "추출중" },
  { id: "추출 완료", title: "추출 완료" },
];

const contentType = [
  { id: "이미지", title: "이미지" },
  { id: "동영상", title: "동영상" },
];

const FilterBar = ({ setSearch, setDisplayType, setContentType }) => {
  const [inputSearch, setInputSearch] = useState("");
  const [isDisplayTypeChecked, setIsDisplayTypeChecked] = useState(
    displayType.reduce((acc, displayType) => ({ ...acc, [displayType.id]: true }), {})
  );
  const [isContentTypeChecked, setIsContentTypeChecked] = useState(
    contentType.reduce((acc, contentType) => ({ ...acc, [contentType.id]: true }), {})
  );

  const handleSearchChange = (e) => {
    setInputSearch(e.target.value);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    setSearch(inputSearch);

    let checkedDisplayTypes = Object.entries(isDisplayTypeChecked)
      .filter(([key, value]) => value)
      .map(([key]) => key.toLowerCase());

    if (checkedDisplayTypes.length === 0) {
      checkedDisplayTypes = displayType.map((displayType) => displayType.id);
      const allChecked = displayType.reduce(
        (acc, displayType) => ({
          ...acc,
          [displayType.id]: true,
        }),
        {}
      );

      setIsDisplayTypeChecked(allChecked);
    }
    setDisplayType(checkedDisplayTypes.join(","));

    let checkedContentTypes = Object.entries(isContentTypeChecked)
      .filter(([key, value]) => value)
      .map(([key]) => key.toLowerCase());

    if (checkedContentTypes.length === 0) {
      checkedContentTypes = contentType.map((contentType) => contentType.id);
      const allChecked = contentType.reduce(
        (acc, contentType) => ({
          ...acc,
          [contentType.id]: true,
        }),
        {}
      );

      setIsContentTypeChecked(allChecked);
    }
    setContentType(checkedContentTypes.join(","));
  };

  const handleDisplayTypeCheckboxChange = (id) => {
    setIsDisplayTypeChecked((prevState) => ({ ...prevState, [id]: !prevState[id] }));
    setSearch(inputSearch);
  };

  const handleContentTypeCheckboxChange = (id) => {
    setIsContentTypeChecked((prevState) => ({ ...prevState, [id]: !prevState[id] }));
    setSearch(inputSearch);
  };

  useEffect(() => {
    const checkedDisplayTypes = Object.entries(isDisplayTypeChecked)
      .filter(([key, value]) => value)
      .map(([key]) => key);
    setDisplayType(checkedDisplayTypes.join(","));
  }, [isDisplayTypeChecked, setDisplayType]);

  useEffect(() => {
    const checkedContentTypes = Object.entries(isContentTypeChecked)
      .filter(([key, value]) => value)
      .map(([key]) => key);
    setContentType(checkedContentTypes.join(","));
  }, [isContentTypeChecked, setContentType]);

  return (
    <div className=" bg-white shadow sm:rounded-lg mb-5">
      <div className="px-4 py-4 sm:px-6">
        <form onSubmit={handleSearchClick}>
          <div className="flex columns-3">
            <div className="relative mt-2 w-11/12 rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                name="text"
                id="text"
                className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="검색어를 입력하세요."
                onChange={handleSearchChange}
              />
            </div>
            <button
              type="submit"
              className="w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={handleSearchClick}
            >
              검색
            </button>
          </div>
        </form>
        <fieldset className="mt-4 ml-2 items-start space-y-2">
          <div className="flex items-center">
            <p className="font-medium mr-6">노출 위치</p>
            {displayType.map((displayType) => (
              <div key={displayType.id} className="flex items-center">
                <input
                  id={displayType.id}
                  type="checkbox"
                  name="select-all"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  checked={isDisplayTypeChecked[displayType.id] || false}
                  onChange={() => handleDisplayTypeCheckboxChange(displayType.id)}
                />
                <label
                  htmlFor={displayType.id}
                  className="mx-3 block text-sm font-medium leading-6 text-gray-600"
                >
                  {displayType.title}
                </label>
              </div>
            ))}
          </div>
          <div className="flex">
            <p className="font-medium mr-3">콘텐츠 타입</p>
            {contentType.map((contentType) => (
              <div key={contentType.id} className="flex items-center">
                <input
                  id={contentType.id}
                  type="checkbox"
                  name="select-all"
                  className="h-4 w-4 rounded-lg border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  checked={isContentTypeChecked[contentType.id] || false}
                  onChange={() => handleContentTypeCheckboxChange(contentType.id)}
                />
                <label
                  htmlFor={contentType.id}
                  className="mx-3 block text-sm font-medium leading-6 text-gray-600"
                >
                  {contentType.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default FilterBar;
