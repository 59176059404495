import React from "react";
import { Layout, Page } from "@shopify/polaris";

import AdminFrame from "components/Layout/Frame";
import AppVersionsList from "./List";

const AppVersionPage = () => {
  return (
    <>
      <AdminFrame title="앱 버전 관리">
        <Page title="앱 버전 관리" fullWidth>
          <Layout>
            <Layout.Section>
              <AppVersionsList />
            </Layout.Section>
          </Layout>
        </Page>
      </AdminFrame>
    </>
  );
};

export default AppVersionPage;
