import React from "react";
import { Layout, Page } from "@shopify/polaris";

import AdminFrame from "components/Layout/Frame";
import FAQsList from "./List";

const FAQPage = () => {
  return (
    <>
      <AdminFrame title="자주 묻는 질문">
        <Page title="자주 묻는 질문" fullWidth>
          <Layout>
            <Layout.Section>
              <FAQsList />
            </Layout.Section>
          </Layout>
        </Page>
      </AdminFrame>
    </>
  );
};

export default FAQPage;
