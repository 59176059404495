import React, { useState, useEffect, Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { Page } from "@shopify/polaris";
import toast from "react-hot-toast";

import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import usePrompt from "utils/Prompt";

import CardSpinner from "common/PageSpinner/Card";
import AdminFrame from "components/Layout/Frame";

import {
  createPreset,
  getPresetDetail,
  getBeanListForPreset,
  getMenuListForPreset,
  updatePreset,
} from "../../../rest-apis/presets";
import ErrorMessage from "../../../common/Badge/errorMessage";

const waterFirstTypes = [
  {
    label: "물 먼저",
    value: true,
  },
  {
    label: "에스프레소 먼저",
    value: false,
  },
];
const shots = [
  {
    value: Number(1),
    label: "1",
  },
  {
    value: Number(2),
    label: "2",
  },
  {
    value: Number(3),
    label: "3",
  },
];
const waterTypes = [
  {
    value: "온수",
    label: "온수",
  },
  {
    value: "정수",
    label: "정수",
  },
];
const maxLengthForField = {
  waterAmount: 3,
  preInfusionTime: 1,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PresetForm = () => {
  // Path
  const { id } = useParams();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const location = useLocation();

  const userName = localStorage.getItem("name");

  // Preset data
  const locationState = useLocation().state?.preset;
  const [presetState, setPresetState] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const lastId = location.state?.lastId;

  const [titleInput, setTitleInput] = useState(
    locationState ? locationState.title : `프리셋 ${lastId + 1}-` || ""
  );
  const [beanList, setBeanList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [selectedBean, setSelectedBean] = useState(locationState?.bean || null);
  const [selectedMenu, setSelectedMenu] = useState(locationState?.menu || null);
  const [waterAmountInput, setWaterAmountInput] = useState(presetState.waterAmount || "");
  const [preInfusionTimeInput, setPreInfusionTimeInput] = useState(
    presetState.preInfusionTime || ""
  );

  // Error check
  const [waterAmountError, setWaterAmountError] = useState(false);
  const [preInfusionTimeError, setPreInfusionTimeError] = useState(false);

  const handleSelectBean = (bean) => {
    if (!locationState?.id) {
      const parts = titleInput.split("-");
      parts[1] = bean.name;

      setTitleInput(parts.join("-"));
    }

    setSelectedBean(bean);
    setPresetState({
      ...presetState,
      selectBeanId: bean.id,
      beanAmount: bean.beanAmount[1],
      espresso: bean.espresso[1],
    });
  };

  const handleSelectMenu = (menu) => {
    if (!locationState?.id) {
      const parts = titleInput.split("-");
      parts[2] = menu.name;

      setTitleInput(parts.join("-"));
    }

    setSelectedMenu(menu);
    setPresetState({
      ...presetState,
      selectMenuId: menu.id,
    });
  };

  const handleWaterFirstRadioChange = (event) => {
    const value = event.target.value === "true";
    setPresetState({
      ...presetState,
      waterFirst: value,
    });
  };

  const handleShotRadioChange = (event) => {
    setPresetState({
      ...presetState,
      shot: Number(event.target.value),
    });
  };

  const handleWaterTypeRadioChange = (event) => {
    setPresetState({
      ...presetState,
      waterType: event.target.value,
    });
  };

  const handleNumberInputChange = (field, e) => {
    // Number check
    const regex = /^[0-9]*$/;
    if (!regex.test(e.target.value) && e.target.value !== "") {
      e.target.value = e.target.value.replace(/[^0-9]/g, "");
    }

    // Length check
    if (maxLengthForField[field] && e.target.value.length > maxLengthForField[field]) {
      e.target.value = e.target.value.slice(0, maxLengthForField[field]);
    }

    const value = e.target.value === "" ? null : Number(e.target.value);
    handlePresetsChange(field, value);
  };

  const handlePresetsChange = (field, value) => {
    if (field === "waterAmount") {
      setWaterAmountInput(value);
      if (value === null || value < 0 || value > 200) {
        setWaterAmountError(true);
      } else {
        setWaterAmountError(false);
      }
    }
    if (field === "preInfusionTime") {
      setPreInfusionTimeInput(value);
      if (value === null || value < 0 || value > 3) {
        setPreInfusionTimeError(true);
      } else {
        setPreInfusionTimeError(false);
      }
    }

    setPresetState({
      ...presetState,
      [field]: value,
    });
  };

  const [isPrompt, setIsPrompt] = useState(true);
  usePrompt(
    "다른 페이지로 이동하겠습니까? 이 페이지에서 변경한 내용은 저장되지 않습니다.",
    isPrompt
  );

  const mapResponseToPresetState = (data) => ({
    title: data.title || "",
    selectBeanId: data.bean?.id || "",
    selectMenuId: data.menu?.id || "",
    waterFirst: data.waterFirst !== undefined ? data.waterFirst : true,
    shot: data.shot || 1,
    waterType:
      data.waterAmount === 0 ? "온수" : data.waterType !== undefined ? data.waterType : "온수",
    waterAmount:
      data.waterAmount !== null && data.waterAmount !== undefined ? data.waterAmount : "",
    beanAmount: data.beanAmount || 0,
    espresso: data.espresso || 0,
    preInfusionTime:
      data.preInfusionTime !== null && data.preInfusionTime !== undefined
        ? data.preInfusionTime
        : "",
    lastModifier: userName,
    confirm: false,
  });

  const fetchPreset = async (id) => {
    setIsLoading(true);

    const response = await getPresetDetail(id);
    setPresetState(mapResponseToPresetState(response.data));

    setIsLoading(false);
  };

  const fetchBeanList = async () => {
    const response = await getBeanListForPreset();
    setBeanList(response);
  };
  const fetchMenuList = async () => {
    const response = await getMenuListForPreset();
    setMenuList(response);
  };

  const uploadPreset = async () => {
    // 토스트 에러 출력
    if (!selectedBean) {
      toast.error("원두를 선택하세요.");
      return;
    }
    if (!selectedMenu) {
      toast.error("메뉴를 선택하세요.");
      return;
    }
    if (presetState.waterAmount === "") {
      toast.error("물(ml)을 입력하세요.");
      return;
    }
    if (presetState.preInfusionTime === "") {
      toast.error("뜸들이기(초)를 입력하세요.");
      return;
    }
    if (waterAmountError || preInfusionTimeError) {
      return;
    }

    if (!window.confirm("상세 정보를 저장하시겠습니까?")) {
      return;
    }

    setIsSaving(true);

    const promise = locationState
      ? updatePreset(locationState.id, presetState)
      : createPreset(presetState);

    try {
      const response = await promise;
      if (response.status === 200 || response.status === 201) {
        toast.success(locationState ? "수정되었습니다." : "저장되었습니다.");
        setIsPrompt(false);
        setTimeout(() => {
          navigate(locationState ? `/presets/${locationState.id}` : `/presets/${response.data.id}`);
        }, 500);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        if (error.response.data.message.includes("동일한 프리셋명이 있습니다.")) {
          toast.dismiss();

          const message = error.response.data.message;
          const parts = message.split("[");
          parts[1] = parts[1].replace(/\n/g, " ");

          const newMessage = parts.join("[");

          if (window.confirm(newMessage)) {
            const newPresetState = { ...presetState, confirm: true };
            createPreset(newPresetState)
              .then(() => {
                setIsPrompt(false);
                toast.success("저장되었습니다.", { duration: 1500 });
                setTimeout(() => {
                  navigate("/presets");
                }, 0);
              })
              .catch((error) => {
                toast.error(error.response?.data?.message || "서버 오류가 존재합니다.");
              });
          } else {
            return;
          }
        } else {
          toast.error(error.response.data.message);
        }
      } else {
        toast.error("서버 오류가 존재합니다.");
      }
    } finally {
      setTimeout(() => {
        setIsSaving(false);
      }, 300);
    }
  };

  useEffect(() => {
    if (pathname.split("/").includes("edit") && !locationState) {
      navigate("/presets");
      return;
    }

    fetchBeanList();
    fetchMenuList();

    if (locationState?.id) {
      fetchPreset(locationState.id);
    } else {
      setPresetState(mapResponseToPresetState(locationState || {}));
    }
  }, [id, locationState]);

  useEffect(() => {
    setPresetState((prevState) => ({
      ...prevState,
      title: titleInput,
    }));
  }, [selectedBean, selectedMenu, lastId]);

  useEffect(() => {
    if (presetState && beanList.length > 0 && menuList.length > 0) {
      const foundBean = beanList.find((bean) => bean.id === presetState.selectBeanId);
      const foundMenu = menuList.find((menu) => menu.id === presetState.selectMenuId);

      if (foundBean) {
        setSelectedBean(foundBean);
      } else {
        setSelectedBean(null);
      }

      if (foundMenu) {
        setSelectedMenu(foundMenu);
      } else {
        setSelectedMenu(null);
      }
    }
  }, [beanList, menuList, presetState]);

  return (
    <>
      <AdminFrame title={`프리셋 관리 ${locationState ? "수정" : "추가"}`}>
        <div className="flex justify-between">
          <div className="flex ml-6 mt-6 items-center">
            <ArrowSmallLeftIcon
              type="button"
              className="w-9 h-9 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => {
                navigate("/presets");
              }}
            />
            <div className="ml-3 text-xl font-bold text-gray-800">
              프리셋 관리 {locationState ? `수정 | ${locationState.title}` : "추가"}
            </div>
          </div>
          <div className="mt-6 mr-10">
            <button
              type="button"
              className={
                isSaving
                  ? "w-24 h-9 rounded-md bg-gray-200 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-gray-700 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  : "w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              }
              onClick={() => {
                uploadPreset();
              }}
              disabled={isSaving}
            >
              {isSaving ? (
                <div className="flex">
                  <svg
                    aria-hidden="true"
                    role="status"
                    className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="#1C64F2"
                    />
                  </svg>
                  <div>저장중</div>
                </div>
              ) : (
                "저장"
              )}
            </button>
          </div>
        </div>
        <Page fullWidth>
          {isLoading ? (
            <CardSpinner />
          ) : (
            <div className="flex">
              <div className="w-full mr-4 bg-white shadow sm:rounded-lg mb-5">
                <div className="px-8 py-6 sm:px-6">
                  <div className="text-base font-semibold">상세 정보</div>
                  <table className="w-full mt-5 text-left table-auto">
                    <tbody>
                      <tr key={`${presetState?.id}-title`} className="border-b border-gray-100">
                        <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                          프리셋명
                        </td>
                        <td className="relative py-4">
                          <input
                            type="text"
                            value={presetState?.title || titleInput}
                            className="block w-full rounded-md border-0 py-1.5 pl-2 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset focus:ring-indigo-600 placeholder:text-gray-400"
                            disabled
                          />
                        </td>
                      </tr>
                      <tr
                        key={`${presetState?.id}-selectBean`}
                        className="border-b border-gray-100"
                      >
                        <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                          원두명
                        </td>
                        <td className="relative py-4">
                          <div className="flex space-x-4">
                            {locationState?.id ? (
                              selectedBean?.name
                            ) : (
                              <Menu as="div" className="relative inline-block text-left">
                                <div>
                                  <Menu.Button className="inline-flex justify-between whitespace-nowrap w-56 gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                    {selectedBean ? selectedBean.name : "선택"}
                                    <ChevronDownIcon
                                      className="-mr-1 h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute left-0 z-10 mt-2 max-h-80 overflow-y-auto w-56 whitespace-nowrap origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      {beanList.map((bean) => (
                                        <Menu.Item
                                          key={bean.id}
                                          onClick={() => handleSelectBean(bean)}
                                        >
                                          {({ active }) => (
                                            <a
                                              className={classNames(
                                                active
                                                  ? "bg-gray-100 text-gray-900"
                                                  : "text-gray-700",
                                                "block px-4 py-2 text-sm"
                                              )}
                                            >
                                              {bean.name}
                                            </a>
                                          )}
                                        </Menu.Item>
                                      ))}
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr
                        key={`${presetState?.id}-selectMenu`}
                        className="border-b border-gray-100"
                      >
                        <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                          메뉴명
                        </td>
                        <td className="relative py-4">
                          <div className="flex space-x-4">
                            {locationState?.id ? (
                              selectedMenu?.name
                            ) : (
                              <Menu as="div" className="relative inline-block text-left">
                                <div>
                                  <Menu.Button className="inline-flex justify-between w-56 gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-500 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                    {selectedMenu ? selectedMenu.name : "선택"}
                                    <ChevronDownIcon
                                      className="-mr-1 h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items className="absolute left-0 z-10 mt-2 max-h-80 overflow-y-auto w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="py-1">
                                      {menuList.map((menu) => (
                                        <Menu.Item
                                          key={menu.id}
                                          onClick={() => handleSelectMenu(menu)}
                                        >
                                          {({ active }) => (
                                            <a
                                              className={classNames(
                                                active
                                                  ? "bg-gray-100 text-gray-900"
                                                  : "text-gray-700",
                                                "block px-4 py-2 text-sm"
                                              )}
                                            >
                                              {menu.name}
                                            </a>
                                          )}
                                        </Menu.Item>
                                      ))}
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr
                        key={`${presetState?.id}-waterFirst`}
                        className="border-b border-gray-100"
                      >
                        <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                          추출 순서
                        </td>
                        <td className="flex relative py-4">
                          {waterFirstTypes.map((waterFirst) => (
                            <div
                              key={`preset-${waterFirst.value}`}
                              className="flex items-center mr-6"
                            >
                              <input
                                id={`preset-${waterFirst.value}`}
                                name="waterFirst"
                                value={waterFirst.value}
                                type="radio"
                                checked={waterFirst.value === presetState.waterFirst}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                onChange={handleWaterFirstRadioChange}
                              />
                              <label
                                htmlFor={`preset-${waterFirst.value}`}
                                className="ml-3 block text-sm font-medium leading-6 text-light whitespace-nowrap"
                              >
                                {waterFirst.label}
                              </label>
                            </div>
                          ))}
                        </td>
                      </tr>
                      <tr key={`${presetState?.id}-shot`} className="border-b border-gray-100">
                        <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">샷</td>
                        <td className="flex relative py-4">
                          {shots.map((shot) => (
                            <div key={`preset-${shot.value}`} className="flex items-center mr-14">
                              <input
                                id={`preset-${shot.value}`}
                                name="shot"
                                value={shot.value}
                                type="radio"
                                checked={shot.value === presetState.shot}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                onChange={handleShotRadioChange}
                              />
                              <label
                                htmlFor={`preset-${shot.value}`}
                                className="ml-3 block text-sm font-medium leading-6 text-light whitespace-nowrap"
                              >
                                {shot.label}
                              </label>
                            </div>
                          ))}
                        </td>
                      </tr>
                      <tr key={`${presetState?.id}-water`} className="border-b border-gray-100">
                        <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                          물(ml)
                        </td>
                        <td className="relative py-4">
                          <div className="flex">
                            <input
                              type="text"
                              value={presetState ? presetState.waterAmount : waterAmountInput}
                              onChange={(e) => {
                                handleNumberInputChange("waterAmount", e);
                              }}
                              className={`block w-20 rounded-md border-0 py-1.5 pl-2 pr-1 mr-6 text-gray-900 shadow-sm ring-1 ring-inset ${
                                waterAmountError ? "ring-red-500" : "ring-gray-300"
                              } sm:text-sm sm:leading-6`}
                            />
                            {waterTypes.map((waterType) => (
                              <div
                                key={`preset-${waterType.value}`}
                                className="flex items-center mr-8"
                              >
                                <input
                                  id={`preset-${waterType.value}`}
                                  name="waterType"
                                  value={waterType.value}
                                  type="radio"
                                  checked={waterType.value === presetState.waterType}
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                  onChange={handleWaterTypeRadioChange}
                                  disabled={presetState.waterAmount === 0}
                                />
                                <label
                                  htmlFor={`preset-${waterType.value}`}
                                  className="ml-3 block text-sm font-medium leading-6 text-light whitespace-nowrap"
                                >
                                  {waterType.label}
                                </label>
                              </div>
                            ))}
                          </div>
                          <div>
                            {waterAmountError ? (
                              <ErrorMessage msg={"물 양은 0~200 범위로 입력하세요."} />
                            ) : null}
                          </div>
                        </td>
                      </tr>
                      <tr
                        key={`${presetState?.id}-beanAmount`}
                        className="border-b border-gray-100"
                      >
                        <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                          원두량(g)
                        </td>
                        <td className="relative py-4">
                          <input
                            type="text"
                            value={
                              selectedBean &&
                              selectedBean.beanAmount &&
                              selectedBean.beanAmount.length > 1
                                ? selectedBean.beanAmount[1]
                                : ""
                            }
                            className="block w-20 rounded-md border-0 py-1.5 pl-2 pr-1 mr-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                            disabled
                          />
                        </td>
                      </tr>
                      <tr key={`${presetState?.id}-espresso`} className="border-b border-gray-100">
                        <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                          에스프레소량(ml)
                        </td>
                        <td className="relative py-4">
                          <input
                            type="text"
                            value={
                              selectedBean &&
                              selectedBean.espresso &&
                              selectedBean.espresso.length > 1
                                ? selectedBean.espresso[1]
                                : ""
                            }
                            className={
                              "block w-20 rounded-md border-0 py-1.5 pl-2 pr-1 mr-6 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6"
                            }
                            disabled
                          />
                        </td>
                      </tr>
                      <tr
                        key={`${presetState?.id}-preInfusion`}
                        className="border-b border-gray-100"
                      >
                        <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                          뜸들이기(초)
                        </td>
                        <td className="relative py-4">
                          <div className="flex">
                            <input
                              type="text"
                              value={
                                presetState ? presetState.preInfusionTime : preInfusionTimeInput
                              }
                              onChange={(e) => {
                                handleNumberInputChange("preInfusionTime", e);
                              }}
                              className={`block w-20 rounded-md border-0 py-1.5 pl-2 pr-1 mr-6 text-gray-900 shadow-sm ring-1 ring-inset ${
                                preInfusionTimeError ? "ring-red-500" : "ring-gray-300"
                              } sm:text-sm sm:leading-6`}
                            />
                            <div className="py-1.5">
                              {presetState
                                ? presetState.preInfusionTime
                                  ? "사용함"
                                  : "사용 안 함"
                                : preInfusionTimeInput
                                ? "사용함"
                                : "사용 안 함"}
                            </div>
                          </div>
                          <div>
                            {preInfusionTimeError ? (
                              <ErrorMessage msg={"뜸들이기 시간은 0~3 범위로 입력하세요."} />
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </Page>
      </AdminFrame>
    </>
  );
};

export default PresetForm;
