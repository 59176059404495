import React, { useState, useEffect, useRef } from "react";

const BeanDropdown = ({
  validateData,
  setInvalidMenus,
  beanList,
  beanSearchInput,
  setBeanSearchInput,
  selectedBeans,
  setSelectedBeans,
  state,
  setState,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownMenuRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSearchChange = (e) => {
    setBeanSearchInput(e.target.value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCheckboxChange = (beanName, beanData) => {
    setSelectedBeans((prevSelectedBeans) => {
      if (prevSelectedBeans.some((acc) => acc.name === beanName)) {
        setInvalidMenus((prevInvalidMenus) =>
          prevInvalidMenus.filter((invalidMenu) => invalidMenu.beanId !== beanData.id)
        );
        setState((prevState) => ({
          ...prevState,
          appDataSets: prevState.appDataSets.filter((dataSet) => dataSet.beanId !== beanData.id),
        }));

        return prevSelectedBeans.filter((acc) => acc.name !== beanName);
      } else {
        const beanExists = state.appDataSets.some((dataSet) => dataSet.beanId === beanData.id);

        if (!beanExists) {
          const newBean = {
            beanId: beanData.id,
            beanName: beanData.name,
            menus: state.appDataSets[0].menus.map((menu) => ({
              menuId: menu.menuId,
              menuName: menu.menuName,
              presets: null,
              contents: [
                {
                  contentSetId: null,
                  brewingContent: null,
                  completeContent: null,
                },
              ],
            })),
          };

          setState((prevState) => ({
            ...prevState,
            appDataSets: [newBean, ...prevState.appDataSets],
          }));
        }

        return [...prevSelectedBeans, beanData];
      }
    });

    validateData();
  };

  return (
    <div className="flex w-full">
      <div className="relative group w-full">
        <button
          onClick={toggleDropdown}
          className="inline-flex justify-between w-full px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
        >
          <span className="mr-2">원두 선택</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 ml-2 -mr-1 text-gray-500"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        {isOpen && (
          <div
            ref={dropdownMenuRef}
            className="absolute z-50 w-full max-h-96 overflow-y-auto right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 p-1 space-y-1"
          >
            <input
              className="block w-full px-4 py-2 text-gray-800 border rounded-md border-gray-300 focus:outline-none"
              type="text"
              placeholder="검색어를 입력하세요."
              autoComplete="off"
              value={beanSearchInput}
              onChange={handleSearchChange}
            />
            {beanList
              .filter((bean) => bean.name.includes(beanSearchInput))
              .map((bean, index) => (
                <div key={index} className="flex items-center px-2 py-2 hover:bg-gray-100">
                  <input
                    id={bean.name}
                    type="checkbox"
                    checked={selectedBeans.some((acc) => acc.name === bean.name)}
                    onChange={() => handleCheckboxChange(bean.name, bean)}
                    className="h-3 w-3 mr-2 cursor-pointer rounded border-gray-200 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label htmlFor={bean.name} className="text-gray-700 cursor-pointer rounded-md">
                    {bean.name}
                  </label>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BeanDropdown;
