import React, { useEffect, useState, useContext } from "react";

import CardSpinner from "common/PageSpinner/Card";
import FilterBar from "./FilterBar";
import Pagination from "common/Pagination";

import { PermissionContextState } from "contexts/Permission";
import AppVersionList from "./List";
import { getAppDeploy } from "../../../rest-apis/appDeploy";
import DeployBar from "./Deploy";

const AppDeploysList = () => {
  // permission
  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "APP_DEPLOY" && p.canWrite);
  };

  // app Versions
  const [appDeploys, setAppDeploys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeploying, setIsDeploying] = useState(false);

  // pagination
  const [currentPage, setCurrentPage] = useState(0);
  const [appDeployPage, setAppDeployPage] = useState([]);
  const totalPages = appDeployPage.totalPages;
  const pageSize = 10;

  // filterBar
  const [search, setSearch] = useState("");

  const fetchAppDeploy = async (page) => {
    setIsLoading(true);

    const response = await getAppDeploy(page, pageSize, search);

    if (response.data._embedded && Array.isArray(response.data._embedded.appDeployListDTOList)) {
      setAppDeploys(response.data._embedded.appDeployListDTOList);

      const isDeploying = response.data._embedded.appDeployListDTOList.some(
        (deploy) => deploy.endedAt === null
      );
      setIsDeploying(isDeploying);
    } else {
      setAppDeploys([]);
    }
    setAppDeployPage(response.data.page);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchAppDeploy(currentPage);
  }, [currentPage, search]);

  return (
    <>
      <FilterBar setSearch={setSearch} />
      <div>
        {canWritePage() && <DeployBar fetchAppDeploy={fetchAppDeploy} isDeploying={isDeploying} />}
      </div>
      {isLoading ? (
        <CardSpinner />
      ) : (
        <div>
          <AppVersionList
            rows={appDeploys}
            fetchAppDeploy={fetchAppDeploy}
            currentPage={currentPage}
          />
          <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={totalPages}
          />
        </div>
      )}
    </>
  );
};

export default AppDeploysList;
