import React, { useState, useEffect } from "react";
import { PlusCircleIcon } from "@heroicons/react/20/solid";

import UploadBrewingContent from "common/UploadImage/content/brewingContent";
import UploadCompleteContent from "common/UploadImage/content/completeContent";

const displayType = ["추출중", "추출 완료"];
const contentType = [
  {
    id: 1,
    label: "이미지(png, jpg)",
    value: "이미지",
  },
  {
    id: 2,
    label: "동영상(mp4)",
    value: "동영상",
  },
];

const createFileObject = () => ({
  fileUrl: "",
  contentType: "",
  metaData: "",
  byteSize: 0,
});

const ContentFormDetail = ({
  locationState,
  contentsState,
  setContentsState,
  brewingImageContent,
  setBrewingImageContent,
  brewingVideoContent,
  setBrewingVideoContent,
}) => {
  const MAX_TITLE_LENGTH = 50;
  const MAX_MEMO_LENGTH = 100;
  const MAX_BREWING_CONTENT_BOX_SIZE = 6;

  const [titleInput, setTitleInput] = useState(contentsState?.title || "");
  const [memoInput, setMemoInput] = useState(contentsState?.memo || "");

  const [brewingContentCount, setBrewingContentCount] = useState(
    brewingImageContent ? brewingImageContent.length : 3
  );

  const handleDisplayType = (event) => {
    const newDisplayType = event.target.value;
    setContentsState((prevState) => {
      const newState = { ...prevState, displayType: newDisplayType };
      if (newDisplayType === "추출 완료") {
        newState.brewingContent = {
          contentType: "이미지",
          files: Array.from({ length: 3 }, createFileObject),
        };
      } else if (newDisplayType === "추출중") {
        newState.completeContent = {
          contentType: "이미지",
          files: Array.from({ length: 1 }, createFileObject),
        };
      }
      return newState;
    });
  };

  const handleContentType = (event) => {
    const newContentType = event.target.value;
    setContentsState((prevState) => {
      const newState = { ...prevState };
      if (newContentType === "이미지") {
        newState.brewingContent = {
          contentType: newContentType,
          files: Array.from({ length: 3 }, createFileObject),
        };
      } else if (newContentType === "동영상") {
        newState.brewingContent = {
          contentType: newContentType,
          files: Array.from({ length: 1 }, createFileObject),
        };
      }
      return newState;
    });
  };

  const isValidTextInput = (text) => {
    const regex = /^[A-Za-z0-9ㄱ-ㅎㅏ-ㅣ가-힣~!@#₩$%^&*()\-_+=,./<>?\\|\n ]*$/;
    return regex.test(text);
  };

  const addBrewingContent = () => {
    if (brewingContentCount < MAX_BREWING_CONTENT_BOX_SIZE) {
      setBrewingContentCount((prevCount) => prevCount + 1);
    }
  };

  useEffect(() => {
    if (brewingImageContent) {
      setBrewingContentCount(brewingImageContent.length);
    }
  }, [brewingImageContent]);

  return (
    <div className="w-full mr-4 overflow-hidden bg-white shadow sm:rounded-lg mb-5">
      <div className="px-8 py-6 sm:px-6">
        <div className="text-base font-semibold">상세 정보</div>
        <table className="w-full mt-5 text-left table-auto">
          <tbody>
            <tr key={`${contentsState?.id}-title`} className="border-b border-gray-100">
              <td className="w-1/5 py-4 align-top whitespace-nowrap font-medium">콘텐츠명</td>
              <td className="relative py-4">
                <input
                  type="text"
                  value={contentsState?.title || ""}
                  maxLength={MAX_TITLE_LENGTH}
                  onChange={(e) => {
                    if (
                      isValidTextInput(e.target.value) &&
                      e.target.value.length <= MAX_TITLE_LENGTH
                    ) {
                      setTitleInput(e.target.value);
                      setContentsState({ ...contentsState, title: e.target.value });
                    }
                  }}
                  onBlur={(e) => {
                    const trimmedValue = e.target.value.replace(/\s+$/, "");
                    setTitleInput(trimmedValue);
                    setContentsState({ ...contentsState, title: trimmedValue });
                  }}
                  className="block w-full rounded-md border-0 py-1.5 pl-2 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset focus:ring-indigo-600 placeholder:text-gray-400"
                  placeholder="콘텐츠명을 입력하세요."
                />
                <div className="absolute mr-3 right-0 top-1/2 transform -translate-y-1/2 text-s text-gray-500">
                  {`${
                    contentsState.title ? contentsState.title.length : titleInput.length
                  }/${MAX_TITLE_LENGTH}`}
                </div>
              </td>
            </tr>
            <tr key={`${contentsState?.id}-displayType`} className="border-b border-gray-100">
              <td className="w-1/5 py-4 align-top whitespace-nowrap font-medium">노출 위치</td>
              <td className="flex flex-wrap pt-4 overflow-x-auto">
                {locationState ? (
                  contentsState.displayType
                ) : (
                  <div className="flex flex-col">
                    <fieldset className="mb-4">
                      <legend className="sr-only">brewingContent aspect ratio</legend>
                      <div className="flex flex-wrap justify-start items-center space-x-4">
                        {displayType.map((displayType) => (
                          <div key={`brewing-content-${displayType}`} className="flex items-center">
                            <input
                              id={`brewing-content-${displayType}`}
                              value={displayType}
                              name="displayType"
                              type="radio"
                              defaultChecked={displayType === "추출중"}
                              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                              onChange={handleDisplayType}
                            />
                            <label
                              htmlFor={`brewing-content-${displayType}`}
                              className="ml-3 block text-sm font-medium leading-6 text-light whitespace-nowrap"
                            >
                              {displayType}
                            </label>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                  </div>
                )}
              </td>
            </tr>
            {contentsState.displayType === "추출중" && (
              <tr key={`${contentsState?.id}-contentType`} className="border-b border-gray-100">
                <td className="w-1/5 py-4 align-top whitespace-nowrap font-medium">콘텐츠 타입</td>
                <td className="flex flex-wrap pt-4 overflow-x-auto">
                  {locationState ? (
                    contentsState.brewingContent.contentType
                  ) : (
                    <div className="flex flex-col">
                      <fieldset className="mb-4">
                        <legend className="sr-only">brewingContent contentType</legend>
                        <div className="flex flex-wrap justify-start items-center space-x-4">
                          {contentType.map((contentType) => (
                            <div
                              key={`brewing-content-${contentType.value}`}
                              className="flex items-center"
                            >
                              <input
                                id={`brewing-content-${contentType.value}`}
                                value={contentType.value}
                                name="contentType"
                                type="radio"
                                defaultChecked={contentType.value === "이미지"}
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                onChange={handleContentType}
                              />
                              <label
                                htmlFor={`brewing-content-${contentType.value}`}
                                className="ml-3 block text-sm font-medium leading-6 text-light whitespace-nowrap"
                              >
                                {contentType.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </fieldset>
                    </div>
                  )}
                </td>
              </tr>
            )}
            {contentsState.displayType === "추출중" && (
              <tr key={`${contentsState.id}-brewing-contents`} className="border-b border-gray-100">
                <td className="w-1/5 py-4 whitespace-nowrap align-top font-medium">
                  추출중 콘텐츠
                </td>
                <td className="flex flex-wrap py-4 overflow-x-auto items-center justify-start">
                  {contentsState.brewingContent.contentType === "이미지" ? (
                    <div className="flex flex-col">
                      <div className="flex flex-wrap gap-2 items-center align-center">
                        {Array.from({ length: brewingContentCount }).map((_, index) => (
                          <UploadBrewingContent
                            key={index}
                            index={index}
                            contentType={contentsState.brewingContent.contentType}
                            length={brewingImageContent ? brewingImageContent.length : 3}
                            defaultImageUrl={
                              brewingImageContent[index] ? brewingImageContent[index].fileUrl : null
                            }
                            brewingImageContent={brewingImageContent}
                            setBrewingImageContent={setBrewingImageContent}
                          />
                        ))}
                        <div className="flex items-center w-24 h-24">
                          {brewingContentCount < 6 && (
                            <PlusCircleIcon
                              type="button"
                              className="w-8 h-8 ml-2 text-gray-400"
                              onClick={addBrewingContent}
                            ></PlusCircleIcon>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <UploadBrewingContent
                        key="0"
                        contentType={contentsState.brewingContent?.contentType}
                        defaultImageUrl={
                          brewingVideoContent[0] ? brewingVideoContent[0].fileUrl : null
                        }
                        brewingVideoContent={brewingVideoContent}
                        setBrewingVideoContent={setBrewingVideoContent}
                      />
                    </div>
                  )}
                </td>
              </tr>
            )}
            {contentsState.displayType === "추출 완료" && (
              <tr
                key={`${contentsState.id}-complete-contents`}
                className="border-b border-gray-100"
              >
                <td className="w-1/5 py-4 whitespace-nowrap align-top font-medium">
                  <div>추출 완료 콘텐츠</div>
                  <div className="text-sm text-gray-400">(720*660px, png, jpg)</div>
                </td>
                <td className="py-4">
                  <UploadCompleteContent
                    key="0"
                    defaultImageUrl={
                      contentsState.completeContent &&
                      contentsState.completeContent.files.length > 0
                        ? contentsState.completeContent.files[0].fileUrl
                        : null
                    }
                    setContentsState={setContentsState}
                    contentsState={contentsState}
                  />
                </td>
              </tr>
            )}
            <tr key={`${contentsState.id}-memo`} className="border-b border-gray-100">
              <td className="w-1/5 py-4 whitespace-nowrap align-top">
                <span className="font-medium mr-1">메모</span>
                <span className="text-sm font-normal text-gray-400">(선택)</span>
              </td>
              <td className="relative py-4">
                <textarea
                  rows={5}
                  value={contentsState?.memo || ""}
                  maxLength={MAX_MEMO_LENGTH}
                  onChange={(e) => {
                    if (
                      isValidTextInput(e.target.value) &&
                      e.target.value.length <= MAX_MEMO_LENGTH
                    ) {
                      setMemoInput(e.target.value);
                      setContentsState({ ...contentsState, memo: e.target.value });
                    }
                  }}
                  className={
                    "block w-full rounded-md border-0 py-1.5 pl-2 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset focus:ring-indigo-600 placeholder:text-gray-400"
                  }
                />
                <div className="absolute mr-3 right-0 bottom-3 transform -translate-y-1/2 text-s text-gray-500">
                  {`${
                    contentsState.memo ? contentsState.memo.length : memoInput.length
                  }/${MAX_MEMO_LENGTH}`}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ContentFormDetail;
