import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { PermissionContextState } from "contexts/Permission";

import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";
import AdminFrame from "components/Layout/Frame";
import { Page } from "@shopify/polaris";

import CardSpinner from "common/PageSpinner/Card";

import { getPresetDetail } from "../../../rest-apis/presets";

const PresetDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { userPermissions } = useContext(PermissionContextState);
  const canWritePage = () => {
    return userPermissions.permissions.some((p) => p.pageName === "PRESET" && p.canWrite);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [preset, setPreset] = useState([]);

  const fetchPreset = async (id) => {
    setIsLoading(true);

    try {
      const response = await getPresetDetail(id);
      if (response.status === 200) {
        setPreset(response.data);
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        if (status === 400 || status === 404 || status === 500) {
          navigate("/not-found");
        }
      }
    } finally {
      setIsLoading(false);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchPreset(id);
  }, [id]);

  return (
    <>
      <AdminFrame title="프리셋 관리 조회">
        {isLoading ? (
          <CardSpinner />
        ) : (
          <>
            <div className="flex justify-between">
              <div className="flex ml-6 mt-6 items-center">
                <ArrowSmallLeftIcon
                  type="button"
                  className="w-9 h-9 rounded-md bg-transparent px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  onClick={() => {
                    navigate("/presets");
                  }}
                />
                <div className="ml-3 text-xl font-bold text-gray-800">
                  프리셋 관리 조회 | {preset.title}
                </div>
              </div>
              <div className="mt-6 mr-10">
                {canWritePage() && (
                  <button
                    type="button"
                    className="w-14 h-9 rounded-md bg-indigo-600 px-3 py-2 mt-2 ml-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => {
                      setTimeout(() => {
                        navigate(`/presets/${id}/edit`, {
                          state: {
                            preset: preset,
                          },
                        });
                      }, 500);
                    }}
                  >
                    수정
                  </button>
                )}
              </div>
            </div>
            <Page fullWidth>
              {isLoading ? (
                <CardSpinner />
              ) : (
                <div className="flex overflow-x-auto">
                  <div className="w-full mr-4 bg-white shadow sm:rounded-lg mb-5">
                    <div className="px-8 py-6 sm:px-6">
                      <div className="text-base font-semibold">상세 정보</div>
                      <table className="w-full mt-5 text-left table-auto">
                        <tbody>
                          <tr key={`${preset?.id}-title`} className="border-b border-gray-100">
                            <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                              프리셋명
                            </td>
                            <td className="relative py-4">{preset.title}</td>
                          </tr>
                          <tr key={`${preset?.id}-beanName`} className="border-b border-gray-100">
                            <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                              원두명
                            </td>
                            <td className="relative py-4">{preset.bean.name}</td>
                          </tr>
                          <tr key={`${preset?.id}-menuName`} className="border-b border-gray-100">
                            <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                              메뉴명
                            </td>
                            <td className="relative py-4">{preset.menu.name}</td>
                          </tr>
                          <tr key={`${preset?.id}-waterFirst`} className="border-b border-gray-100">
                            <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                              추출 순서
                            </td>
                            <td className="relative py-4">
                              {preset.waterFirst ? "물 먼저" : "에스프레소 먼저"}
                            </td>
                          </tr>
                          <tr key={`${preset?.id}-shot`} className="border-b border-gray-100">
                            <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                              샷
                            </td>
                            <td className="relative py-4">{preset.shot}</td>
                          </tr>
                          <tr
                            key={`${preset?.id}-waterAmount`}
                            className="border-b border-gray-100"
                          >
                            <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                              물(ml)
                            </td>
                            <td className="relative py-4">
                              <div className="flex gap-x-2">
                                <div>{preset.waterAmount}</div>
                                <div>{preset.waterAmount === 0 ? "온수" : preset.waterType}</div>
                              </div>
                            </td>
                          </tr>
                          <tr key={`${preset?.id}-beanAmount`} className="border-b border-gray-100">
                            <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                              원두량(g)
                            </td>
                            <td className="relative py-4">{preset.beanAmount}</td>
                          </tr>
                          <tr
                            key={`${preset?.id}-espressoAmount`}
                            className="border-b border-gray-100"
                          >
                            <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                              에스프레소량(ml)
                            </td>
                            <td className="relative py-4">{preset.espresso}</td>
                          </tr>
                          <tr
                            key={`${preset?.id}-preInfusionTime`}
                            className="border-b border-gray-100"
                          >
                            <td className="w-1/4 py-4 align-top whitespace-nowrap font-medium">
                              뜸들이기(초)
                            </td>
                            <td className="relative py-4">
                              {preset.preInfusionTime}{" "}
                              {preset.preInfusionTime == 0 ? "사용 안 함" : "사용함"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </Page>
          </>
        )}
      </AdminFrame>
    </>
  );
};

export default PresetDetail;
