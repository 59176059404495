/*
이미지 업로드하고 프리뷰까지 해주는 컴포넌트입니다.
속성으로 defaultSrc, setState, height, div를 받고 있습니다.
defaultSrc(string): 수정하기 때 사용하는 이미지로 파이어베이스에 올라간 이미지 명을 받아서 이미지킷으로 보여줍니다.
setState(function): 이미지 업로드 후 파일을 저장할 수 있는 state함수를 받습니다. 이때 useSetState의 함수로 보내줘야합니다.
imageHeight(number): 이미지의 크기를 지정합니다.
zoneHeight(number): 업로드 드롭존 크기를 지정합니다. div가 false라면 업로드 드롭존 크기는 지정하지 않습니다.
div(boolean): true로 보낼 시 이미지 업로드 부분까지 사이즈를 지정할 수 있습니다.
*/

import React, { useCallback, useRef, useState, useEffect } from "react";
import { CloudArrowUpIcon } from "@heroicons/react/24/outline";
import { XCircleIcon } from "@heroicons/react/24/solid";
import { toast } from "react-hot-toast";

import UploadContent from "utils/UploadContent";

const UploadImage = (props) => {
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState(props.defaulImageUrl || "");
  const urlRef = useRef();

  const handleFileChange = useCallback(async (e) => {
    const newFile = e.target.files[0];
    const url = URL.createObjectURL(e.target.files[0]);

    // check file type
    if (newFile.type !== "image/png") {
      toast.error("png 확장자만 등록 가능합니다.");
      return;
    }

    const uploadedUrl = await UploadContent("menus/default", newFile.name, newFile);

    props.setState({ imageUrl: uploadedUrl });
    setFile(e.target.files[0]);
    setUrl(url);
  }, []);

  const handleImageClick = () => {
    urlRef.current.click();
  };

  const handleFileRemove = () => {
    props.setState({ imageUrl: "" });
    setFile(null);
    setUrl("");
  };

  useEffect(() => {
    if (props.defaultImageUrl) {
      setUrl(props.defaultImageUrl);
    }
  }, [props.defaultImageUrl]);

  return (
    <>
      <div className="flex items-center justify-center w-24">
        <label
          htmlFor="dropzone-file"
          className={`flex flex-col items-center justify-center w-full h-24 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 ${
            url ? "hidden" : ""
          }`}
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <CloudArrowUpIcon className="w-7 h-7 text-gray-400" />
          </div>
          <input
            id="dropzone-file"
            ref={urlRef}
            type="file"
            className="hidden"
            onChange={handleFileChange}
          />
        </label>
        {(file || url) && (
          <div className="relative">
            <button
              type="button"
              className="absolute top-0 right-0 m-1 rounded-full"
              onClick={handleFileRemove}
            >
              <XCircleIcon className="h-5 w-5 text-gray-400" />
            </button>
            <img
              src={url}
              alt="preview"
              onClick={handleImageClick}
              className="object-contain h-24 w-24 border border-gray-300 rounded-md"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default UploadImage;
