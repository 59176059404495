import React from "react";
import { Layout } from "@shopify/polaris";

import AdminFrame from "components/Layout/Frame";
import ContentsList from "components/Content/List";

const ContentsPage = () => {
  return (
    <>
      <AdminFrame title="콘텐츠 관리">
        <Layout>
          <Layout.Section>
            <ContentsList />
          </Layout.Section>
        </Layout>
      </AdminFrame>
    </>
  );
};

export default ContentsPage;
