import API from "./api";

export const login = async (user) => {
  const response = await API.post("/login", user);
  return response.data;
};

export const updatePassword = async ({ email, newPassword }) => {
  const response = await API.patch("/password", {
    email: email,
    newPassword: newPassword,
  });
  return response.data;
};
